import styled, { keyframes } from 'styled-components';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { media } from '../../styles/ResponsiveDesign';
import { createPortal } from 'react-dom';

const InfoTipWrapper = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 10;
`;

const InfotipAnimation = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}  
`;

const InfoTipContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isVisible', 'origin'].includes(prop),
})`
  font-size: 1.3rem;
  max-width: 44rem;
  width: auto;
  padding: 0.4rem 0.8rem;
  border-radius: var(--border-radius-sm);
  position: fixed;
  background-color: var(--gray-12);
  color: var(--gray-1);
  border: 0.5px solid var(--gray-6);
  display: flex;
  animation: ${InfotipAnimation} 0.3s ease;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
  transition: opacity 0.2s;
  text-decoration: capitalize !important;

  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transform-origin: ${(props) => props.origin};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: none;

  ${media.mobile`
    display: none;
  `}
`;

const InfoTipPortal = forwardRef(({ tip, style, isVisible }, ref) => {
  return createPortal(
    <InfoTipContainer ref={ref} style={style} isVisible={isVisible}>
      {tip}
    </InfoTipContainer>,
    document.getElementById('menu')
  );
});

export const InfoTip = ({ children, tip, onClick, enabled = true }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 }); //initialize position state using top and left properties
  const tooltipRef = useRef(null); //create a reference to the tooltip element
  const wrapperRef = useRef(null); //create a reference to the wrapper element

  useEffect(() => {
    const handleWindowResize = () => {
      if (wrapperRef.current && tooltipRef.current) {
        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const adjustedX = getAdjustedX(wrapperRect.x, tooltipRect.width);
        const adjustedY = getAdjustedY(wrapperRect.y, tooltipRect.height);
        setPosition({ x: adjustedX, y: adjustedY });
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getAdjustedX = useCallback(
    (toggleXPosition, menuWidth) => {
      //function to adjust the x position of the menu. X is the left edge of the wrapper element and width is the width of the menu
      const windowWidth = window.innerWidth;

      const toggleWidth = wrapperRef.current.getBoundingClientRect().width;

      const menuRightEdge = toggleXPosition + menuWidth + toggleWidth; //get the right edge of the menu

      if (menuRightEdge > windowWidth) {
        //if the menu is going to be outside the viewport to the right

        return windowWidth - menuWidth - 24;
      }

      if (toggleXPosition - menuWidth < 0) {
        return toggleXPosition; //if the menu is going to be outside the viewport to the left
      }
      //center the menu horizontally
      return toggleXPosition + (toggleWidth - menuWidth) / 2;
    },
    [wrapperRef]
  );

  const getAdjustedY = useCallback(
    (toggleYPosition, menuHeight) => {
      //function to adjust the y position of the menu. Y is the top edge of the wrapper element and height is the height of the menu

      const windowHeight = window.innerHeight; //get the height of the viewport

      const toggleHeight = wrapperRef.current.getBoundingClientRect().height;

      const menuBottomEdge = toggleYPosition + menuHeight + toggleHeight;
      if (menuBottomEdge > windowHeight) {
        //if the menu is going to be outside the viewport to the bottom

        return toggleYPosition - menuHeight - 16; //position the menu above the wrapper element by 24px
      }

      return toggleYPosition + toggleHeight + 8;
    },
    [wrapperRef]
  );

  //function to handle mouse enter event. The tooltip.current will be available when the tooltip is rendered
  const handleMouseEnter = (event) => {
    if (!enabled) return;
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const initialX = getAdjustedX(wrapperRect.x, 0);
    const initialY = getAdjustedY(wrapperRect.y, 0);
    setPosition({ x: initialX, y: initialY });
    setVisible(true);
  };

  //useEffect hook to adjust the position of the tooltip when the visibility of the tooltip changes and when tooltipRef.current is available
  useEffect(() => {
    if (visible && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const adjustedX = getAdjustedX(wrapperRect.x, tooltipRect.width);
      const adjustedY = getAdjustedY(wrapperRect.y, tooltipRect.height);
      setPosition({ x: adjustedX, y: adjustedY });
    }
  }, [visible, position.x, position.y]);

  const handleMouseLeave = () => {
    if (!enabled) return;
    setVisible(false);
  };

  function handleOnClick(event) {
    if (!enabled) return;
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    setVisible(false);
    onClick?.();
  }

  return (
    <InfoTipWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={wrapperRef}
      onClick={handleOnClick}
    >
      {children}

      {enabled && (
        <InfoTipPortal
          ref={tooltipRef}
          isVisible={visible}
          tip={tip}
          style={{ left: position.x, top: position.y }}
        />
      )}
    </InfoTipWrapper>
  );
};
