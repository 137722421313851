import styled, { css } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

const headingSizes = {
  h1: {
    desktop: '6.4rem',
    tablet: '5.6rem',
    mobile: '4.8rem',
  },
  h2: {
    desktop: '4.8rem',
    tablet: '4.2rem',
    mobile: '3.6rem',
  },
  h3: {
    desktop: '3.2rem',
    tablet: '2.8rem',
    mobile: '2.4rem',
  },
  h4: {
    desktop: '2.4rem',
    tablet: '2.2rem',
    mobile: '2rem',
  },
  h5: {
    desktop: '2rem',
    tablet: '1.8rem',
    mobile: '1.6rem',
  },
  h6: {
    desktop: '1.6rem',
    tablet: '1.6rem',
    mobile: '1.6rem',
  },
};

const headingMarginBottoms = {
  h1: {
    desktop: '3.2rem',
    tablet: '2.8rem',
    mobile: '2.4rem',
  },
  h2: {
    desktop: '2.8rem',
    tablet: '2.4rem',
    mobile: '2rem',
  },
  h3: {
    desktop: '2.4rem',
    tablet: '2rem',
    mobile: '1.6rem',
  },
  h4: {
    desktop: '2rem',
    tablet: '1.6rem',
    mobile: '1.2rem',
  },
  h5: {
    desktop: '1.6rem',
    tablet: '1.2rem',
    mobile: '0.8rem',
  },
  h6: {
    desktop: '1.2rem',
    tablet: '0.8rem',
    mobile: '0.8rem',
  },
};

const headingLineHeightSizes = {
  h1: { desktop: '3.2rem', tablet: '2.8rem', mobile: '2.4rem' },
  h2: { desktop: '2.8rem', tablet: '2.4rem', mobile: '2.0rem' },
  h3: { desktop: '2.4rem', tablet: '2.0rem', mobile: '1.8rem' },
  h4: { desktop: '2.0rem', tablet: '1.8rem', mobile: '1.6rem' },
  h5: { desktop: '1.8rem', tablet: '1.6rem', mobile: '1.4rem' },
  h6: { desktop: '1.6rem', tablet: '1.4rem', mobile: '1.2rem' },
};

const getResponsiveLineHeight = (tag) => css`
  line-height: ${headingLineHeightSizes[tag].mobile};

  ${media.tablet`
    line-height: ${headingSizes[tag].tablet};
  `}

  ${media.desktop`
    line-height: ${headingSizes[tag].desktop};
  `}
`;

const getResponsiveSize = (tag) => css`
  font-size: ${headingSizes[tag].mobile};

  ${media.tablet`
    font-size: ${headingSizes[tag].tablet};
  `}

  ${media.desktop`
    font-size: ${headingSizes[tag].desktop};
  `}
`;

export const getResponsiveMarginBottom = (tag) => css`
  margin-bottom: ${headingMarginBottoms[tag].mobile};

  ${media.tablet`
    margin-bottom: ${headingMarginBottoms[tag].tablet};
  `}

  ${media.desktop`
    margin-bottom: ${headingMarginBottoms[tag].desktop};
  `}
`;

export const getResponsiveMarginTop = (tag) => css`
  margin-top: ${headingMarginBottoms[tag].mobile};

  ${media.tablet`
    margin-top: ${headingMarginBottoms[tag].tablet};
  `}

  ${media.desktop`
    margin-top: ${headingMarginBottoms[tag].desktop};
  `}
`;

export const getResponsiveHeadingStyles = (tag) => css`
  ${getResponsiveSize(tag)}
  ${getResponsiveLineHeight(tag)}
  /* ${getResponsiveMarginBottom(tag)} */
  font-weight: bold;
  letter-spacing: 0;
  position: relative;
`;

const Heading = styled.h1.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'fade',
      'color',
      'center',
      'uppercase',
      'align',
      'as',
      'fontFamily',
    ].includes(prop),
})`
  ${getResponsiveHeadingStyles('h1')}
  color: ${(props) => props.color || 'var(--gray-12)'};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  text-align: ${(props) => props.align || 'left'};
  font-family: ${(props) => props.fontFamily || 'inherit'};
  & svg {
    height: 24px;
    width: 24px;
  }

  ${(props) =>
    props.as === 'h2' &&
    css`
      ${getResponsiveHeadingStyles('h2')}
    `}
  ${(props) =>
    props.as === 'h3' &&
    css`
      ${getResponsiveHeadingStyles('h3')}
    `}
  ${(props) =>
    props.as === 'h4' &&
    css`
      ${getResponsiveHeadingStyles('h4')}
    `}
  ${(props) =>
    props.as === 'h5' &&
    css`
      ${getResponsiveHeadingStyles('h5')}
    `}
  ${(props) =>
    props.as === 'h6' &&
    css`
      ${getResponsiveHeadingStyles('h6')}
    `}
`;

export default Heading;
