import { useCallback, useMemo } from 'react';
import useTags from '../../hooks/useTags.jsx';
import {
  setPreference,
  updatePreference,
  usePreferenceState,
} from '../../redux/features/preference-slice.js';
import {
  StyledContainer,
  SectionHeader,
  StyledBody,
  SectionHeading,
} from '../../styles/GeneralStyling.jsx';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex.jsx';
import { blogTags } from '../blog/constants.jsx';
import { useDispatch } from 'react-redux';
import useSearch from '../../hooks/useSearch.jsx';
import { useAuth } from '../../redux/features/auth-slice.js';
import { find } from 'lodash';
import {
  useSupabaseOperation,
  useSupabaseQuery,
} from '../../api/supabaseService.js';
import { BackButton } from '../../ui/buttons/BackButton.jsx';
import { usePageTitle } from '../../hooks/usePageTitle.jsx';
import { CloseButton } from '../../ui/buttons/CloseButton.jsx';
import StateDisplay from '../../ui/notifications/StateDisplay.jsx';
import toast from 'react-hot-toast';

const PreferenceDetails = ({ closePopup, mode = 'preference' }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { preferences } = usePreferenceState();
  const isFilterMode = mode === 'filter';
  const isOnboarding = mode === 'onboarding';

  usePageTitle('Preferences');

  const { searchQuery, markup: tagSearchMarkup } = useSearch({
    focusOnMount: !isFilterMode || isOnboarding,
    placeholder: 'Search categories...',
    isSticky: true,
  });

  const {
    isLoading: isLoadingPreferences,
    refetch: refetchPreferences,
    error: preferencesError,
    isError: isPreferencesError,
  } = useSupabaseQuery(
    {
      table: 'content_preferences',
      operation: 'read',
      filters: [{ operator: 'eq', column: 'user_id', value: user?.id }],
      sortBy: [{ column: 'created_at', order: 'desc' }],
      returnData: 'data',
    },

    {
      queryKey: ['content_preferences', { user_id: user?.id }],
      enabled: !!user,
      onSuccess: (data) => {
        dispatch(setPreference(data));
      },
    }
  );
  const { mutate: preferenceMutation, isLoading: isUpdatingPreference } =
    useSupabaseOperation();

  const contentCategories = find(preferences, { key: 'content' });

  const handleSavePreferences = useCallback(
    ({ key, value, category }) => {
      //Check is preference exist to perform insert or update operation

      const doesKeyExist = find(preferences, { key });
      const operation = doesKeyExist ? 'update' : 'insert';
      const filters = doesKeyExist
        ? [{ operator: 'eq', column: 'user_id', value: user.id }]
        : [];
      const payload = {
        ...(operation === 'insert' && { user_id: user.id }),
        key,
        value,
        category,
      };
      preferenceMutation(
        {
          table: 'content_preferences',
          operation,
          payload,
          filters,
        },
        {
          onSuccess: () => {
            dispatch(
              updatePreference({
                value: value,
                key: key,
                category: category,
              })
            );

            toast.success('Preference updated successfully');
          },
          onError: (error) => {
            let displayMessage = 'Failed to update preferences';

            // Check for network connectivity
            if (!navigator.onLine) {
              displayMessage = 'Please check your internet connection';
            } else if (error?.message?.includes('Failed to fetch')) {
              displayMessage = 'Connection issue. Please try again';
            }

            toast({
              type: 'danger',
              message: displayMessage,
              close: true,
            });
          },
        }
      );
    },
    [user?.id, preferences, preferenceMutation, dispatch, toast]
  );

  const categoriesConfig = useMemo(() => {
    return {
      options: blogTags,
      returnType: 'value',
      defaultValues: contentCategories?.value || [],
      showCount: false,
      ...(!isOnboarding && { selectAllLabel: 'Categories' }),
      searchQuery,
      isDisabled: isLoadingPreferences || isPreferencesError,
      onChange: (value) =>
        handleSavePreferences({
          key: 'content',
          value,
          category: 'categories',
        }),
    };
  }, [
    contentCategories,
    searchQuery,
    isLoadingPreferences,
    isUpdatingPreference,
    isPreferencesError,
  ]);

  const { markup: tagPreferenceMarkup } = useTags(categoriesConfig);

  return (
    <StyledContainer size='medium'>
      {!isOnboarding && (
        <SectionHeader>
          <FlexRow justify='between'>
            <SectionHeading>Preferences</SectionHeading>
            {isFilterMode ? (
              <CloseButton onClick={closePopup} />
            ) : (
              <BackButton />
            )}
          </FlexRow>
        </SectionHeader>
      )}
      <StyledBody>
        <FlexColumn gap={1.6}>
          {!isFilterMode && tagSearchMarkup}

          {isPreferencesError && (
            <StateDisplay
              variant='error'
              title='Failed to load preferences'
              page='preferences'
              description={
                preferencesError.message ||
                'Oops! Something went wrong while loading your preferences.'
              }
              action={{ onClick: () => refetchPreferences() }}
            />
          )}

          {!isPreferencesError &&
            (isFilterMode || isOnboarding ? (
              <FlexRow wrap={true}>{tagPreferenceMarkup}</FlexRow>
            ) : (
              <FlexRow wrap={true}>{tagPreferenceMarkup}</FlexRow>
            ))}
        </FlexColumn>
      </StyledBody>
    </StyledContainer>
  );
};

export default PreferenceDetails;
