import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  HiOutlineBell,
  HiOutlineCheck,
  HiOutlineExclamationCircle,
} from 'react-icons/hi2';
import { isEmpty } from 'lodash';
import Button from '../../ui/buttons/Button';
import {
  useMarkAllAsRead,
  useMarkAsRead,
  useNotificationSubscription,
  useRecentNotifications,
  useUnreadCount,
} from './useNotifications';
import { useAuth } from '../../redux/features/auth-slice';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import toast from 'react-hot-toast';
import Heading from '../../ui/typography/Heading';
import { formatDistanceToNow } from 'date-fns';
import { TextFade } from '../../ui/typography/Text';
import { StyledLoading } from '../../styles/GeneralStyling';
import Spinner from '../../ui/loading/Spinner';
import { InfoTip } from '../../ui/notifications/InfoTip';
import { ShowPopup, usePopup } from '../../ui/modal/Popup';
import { CloseButton } from '../../ui/buttons/CloseButton';
import { getFormattedDate } from '../../utils/helpers';
import { useNavigation } from '../../hooks/useNavigation';
import {
  Message,
  MetaData,
  NotificationCard,
  TimeStamp,
} from './NotificationDetails';

const NotificationBell = () => {
  const { user } = useAuth();

  const {
    data: recentNotifications = [],
    isLoading: isFetchingNotifications,
    isError: isFetchingNotificationsError,
  } = useRecentNotifications(user?.id);

  const { data: unreadCount = 0 } = useUnreadCount(user?.id);

  useNotificationSubscription(user?.id);

  return (
    <ShowPopup
      position='top'
      button={
        <BellButton>
          <HiOutlineBell />
          {unreadCount > 0 && <UnreadBadge>{unreadCount}</UnreadBadge>}
        </BellButton>
      }
      component={
        <NotificationPreview
          unreadCount={unreadCount}
          recentNotifications={recentNotifications}
          isFetchingNotifications={isFetchingNotifications}
          isFetchingNotificationsError={isFetchingNotificationsError}
        />
      }
    />
  );
};

const NotificationPreview = ({
  recentNotifications,
  isFetchingNotifications,
  isFetchingNotificationsError,
  unreadCount,
  closePopup,
}) => {
  const { user } = useAuth();
  const { navigateToPath } = useNavigation();
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const { mutate: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllAsRead();

  const handleMarkAllAsRead = () => {
    if (!user?.id || isMarkingAllAsRead) return;
    markAllAsRead(user.id, {
      onSuccess: () => {
        toast.success('All notifications marked as read');
        closePopup();
      },
      onError: (error) => {
        const errorMessage = getCrudErrorMessage(
          'update',
          error,
          'notification'
        );
        toast.error(errorMessage.description);
      },
    });
  };

  const { mutate: markAsRead, isLoading: isMarkingNotification } =
    useMarkAsRead();

  const handleNotificationClick = (notificationId) => {
    setSelectedNotificationId(notificationId);
    markAsRead(notificationId, {
      onError: (error) => {
        const errorMessage = getCrudErrorMessage(
          'update',
          error,
          'notification'
        );
        toast.error(errorMessage.description);
      },
      onSuccess: () => {
        setSelectedNotificationId(null);
        navigateToPath('/notifications', {
          state: {
            notificationId: notificationId,
          },
        });
        closePopup();
      },
    });
  };

  function handleViewAllNotifications() {
    navigateToPath('/notifications');
    closePopup();
  }

  const EmptyState = useMemo(
    () => (
      <EmptyNotificationState>
        <HiOutlineBell />
        <Heading as='h6'>No notifications yet</Heading>
        <TextFade align='center'>
          You have no notifications yet. When you receive a notification, it
          will appear here.
        </TextFade>
      </EmptyNotificationState>
    ),
    []
  );

  const ErrorState = useMemo(
    () => (
      <StyledError>
        <HiOutlineExclamationCircle color='var(--red-9)' />
        <Heading as='h6' color='var(--red-12)'>
          Error fetching notifications
        </Heading>
        <TextFade align='center' color='var(--red-11)'>
          We are experiencing issues fetching notifications. Please try again
          later.
        </TextFade>
      </StyledError>
    ),
    [isFetchingNotificationsError]
  );

  return (
    <NotificationDropdown>
      <DropdownHeader>
        <Heading as='h4'>Notifications</Heading>
        <CloseButton tip='Close Notifications' onClick={closePopup} />
      </DropdownHeader>

      <NotificationList>
        {isFetchingNotifications && (
          <StyledLoading>
            <Spinner />
          </StyledLoading>
        )}

        {isFetchingNotificationsError && ErrorState}

        {isEmpty(recentNotifications) && EmptyState}

        {!isEmpty(recentNotifications) &&
          recentNotifications?.map((notification) => (
            <NotificationCard
              key={notification.id}
              data-priority={notification?.metadata?.priority}
              data-unread={!notification?.read_at}
              onClick={() => handleNotificationClick(notification.id)}
            >
              <NotificationContent>
                <Message data-unread={!notification?.read_at}>
                  {notification.metadata.message}
                </Message>
                <MetaData data-unread={!notification?.read_at}>
                  <InfoTip tip={getFormattedDate(notification?.created_at)}>
                    <TimeStamp>
                      {formatDistanceToNow(notification?.created_at)}
                    </TimeStamp>
                  </InfoTip>
                </MetaData>
              </NotificationContent>
            </NotificationCard>
          ))}
      </NotificationList>

      {!isEmpty(recentNotifications) && (
        <DropdownFooter>
          <Button
            size='small'
            variant='outline'
            onClick={handleViewAllNotifications}
          >
            View all notifications
            <HiOutlineBell />
          </Button>

          {unreadCount > 0 && (
            <Button
              isLoading={isMarkingAllAsRead}
              size='small'
              variant='secondary'
              onClick={handleMarkAllAsRead}
            >
              <HiOutlineCheck />
              Mark all as read
            </Button>
          )}
        </DropdownFooter>
      )}
    </NotificationDropdown>
  );
};

const BellButton = styled.button`
  position: relative;
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  flex: 1;
  background: none;
  border-radius: var(--border-radius-md);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;

  &:active:not(:disabled) {
    transform: scale(0.95);
    background: var(--color-4);
  }

  &:hover:not(:disabled) {
    background: var(--color-3);
  }

  & svg {
    color: var(--color-11);
    height: 2.4rem;
    width: 2.4rem;
    transition: all 200ms ease;
    margin: 0 !important;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    & svg {
      color: var(--gray-11);
    }
  }
`;

const StyledError = styled.div`
  padding: var(--padding);
  display: flex;
  align-items: center;
  gap: var(--gap-lg);
  flex-direction: column;
`;

const UnreadBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: var(--color-9);
  height: 2rem;
  width: 2rem;
  color: var(--label-white);
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationDropdown = styled.div``;

const DropdownHeader = styled.div`
  padding: var(--padding);
  border-bottom: var(--border-md-non);
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 1.6rem;
  }
`;

const NotificationList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const NotificationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);
`;

const EmptyNotificationState = styled.div`
  padding: var(--padding);
  gap: var(--gap-lg);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropdownFooter = styled.div`
  padding: var(--padding);
  border-top: var(--border-sm-non);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-sm);
`;

export default NotificationBell;
