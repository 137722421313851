import { createContext, useContext } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Sidebar from './Sidebar';
import { Container } from './Container';
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const StyledAppLayout = styled.div`
  display: flex;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
    `pin-sidebar`,
    false
  );

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen((prevState) => !prevState);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen: isSidebarOpen,
        openSidebar,
        closeSidebar,
        toggleSidebar,
        setIsSidebarOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

const AppLayout = () => {
  return (
    <StyledAppLayout>
      <Sidebar />

      <MainContent>
        <Header />

        <Container>
          <Outlet />
        </Container>
      </MainContent>
    </StyledAppLayout>
  );
};

export default AppLayout;
