import styled from 'styled-components';

const Text = styled.p.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'color',
      'fontSize',
      'lineHeight',
      'fontWeight',
      'fontFamily',
      'uppercase',
      'align',
      'style',
    ].includes(prop),
})`
  color: ${(props) => props.color || 'var(--gray-12)'};
  font-size: ${(props) => props.fontSize || 'inherit'};
  line-height: ${(props) => props.lineHeight || '1.6rem'};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  text-transform: ${(props) => props.transform || 'none'};
  text-align: ${(props) => props.align || 'left'};
  font-family: ${(props) => props.fontFamily || 'inherit'};
  font-style: ${(props) => props.fontStyle || 'normal'};
`;

export default Text;

// Preset styles (optional)
export const TextXm = (props) => (
  <Text fontSize='1rem' lineHeight='1.6rem' {...props} />
);
export const TextSm = (props) => (
  <Text fontSize='1.2rem' lineHeight='2rem' {...props} />
);
export const TextBase = (props) => <Text lineHeight='2.6rem' {...props} />;
export const TextFade = (props) => (
  <Text color='var(--gray-11)' lineHeight='2.6rem' {...props} />
);
export const TextBold = (props) => (
  <Text as='strong' fontWeight='600' lineHeight='2.6rem' {...props} />
);
export const TextBoldDark = (props) => (
  <Text
    color='var(--color-white)'
    fontWeight='600'
    lineHeight='2.6rem'
    {...props}
  />
);
export const TextFadeDark = (props) => (
  <Text color='var(--label-dark-fade)' lineHeight='2.6rem' {...props} />
);
export const TextFadeLight = (props) => (
  <Text color='var(--label-light-fade)' lineHeight='2.6rem' {...props} />
);
export const TextXl = (props) => (
  <Text fontSize='1.8rem' lineHeight='2.8rem' {...props} />
);
