import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from '../../../styles/ResponsiveDesign';
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Input } from '../../../ui/forms/StyledInput';
import Button from '../../../ui/buttons/Button';
import { Link, useLocation } from 'react-router-dom';
import { FaXTwitter, FaTiktok } from 'react-icons/fa6';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  return (
    <FooterContainer id='footer'>
      <FooterContent>
        {isLandingPage && (
          <MainSection>
            <BrandSection>
              <Logo>BittsBlog</Logo>
              <TagLine>Think. Create. Share. Inspire.</TagLine>
              <SocialLinks>
                <SocialLink href='https://x.com' target='_blank'>
                  <FaXTwitter />
                </SocialLink>
                <SocialLink href='https://github.com' target='_blank'>
                  <FaGithub />
                </SocialLink>
                <SocialLink href='https://linkedin.com' target='_blank'>
                  <FaLinkedin />
                </SocialLink>
                <SocialLink href='https://instagram.com' target='_blank'>
                  <FaInstagram />
                </SocialLink>
                <SocialLink href='https://tiktok.com' target='_blank'>
                  <FaTiktok />
                </SocialLink>
              </SocialLinks>
            </BrandSection>

            <LinksGrid>
              <LinkGroup>
                <GroupTitle>Product</GroupTitle>
                <LinkList>
                  <LinkItem to='/features'>Features</LinkItem>
                  <LinkItem to='/pricing'>Pricing</LinkItem>
                  <LinkItem to='/blog'>Blog</LinkItem>
                  <LinkItem to='/docs'>Documentation</LinkItem>
                </LinkList>
              </LinkGroup>

              <LinkGroup>
                <GroupTitle>Company</GroupTitle>
                <LinkList>
                  <LinkItem to='/about'>About</LinkItem>
                  <LinkItem to='/careers'>Careers</LinkItem>
                  <LinkItem to='/press'>Press Kit</LinkItem>
                  <LinkItem to='/contact'>Contact</LinkItem>
                </LinkList>
              </LinkGroup>

              <LinkGroup>
                <GroupTitle>Resources</GroupTitle>
                <LinkList>
                  <LinkItem to='/community'>Community</LinkItem>
                  <LinkItem to='/help'>Help Center</LinkItem>
                  <LinkItem to='/guidelines'>Guidelines</LinkItem>
                  <LinkItem to='/status'>Status</LinkItem>
                </LinkList>
              </LinkGroup>

              <NewsletterGroup>
                <GroupTitle>Stay Updated</GroupTitle>
                <NewsletterText>
                  Subscribe to our newsletter for the latest updates.
                </NewsletterText>
                <NewsletterForm>
                  <Input disabled type='email' placeholder='Enter your email' />
                  <Button disabled variant='primary' size='medium'>
                    Subscribe
                  </Button>
                </NewsletterForm>
              </NewsletterGroup>
            </LinksGrid>
          </MainSection>
        )}

        <BottomSection>
          <Copyright>© {currentYear} BittsBlog. All rights reserved.</Copyright>
          <LegalLinks>
            <LegalLink to='/terms'>Terms</LegalLink>
            <LegalLink to='/privacy'>Privacy</LegalLink>
            <LegalLink to='/cookies'>Cookies</LegalLink>
          </LegalLinks>
        </BottomSection>
      </FooterContent>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background: var(--gray-2);
  padding: 8rem 2rem 4rem;
  position: relative;
`;

const FooterContent = styled.div`
  max-width: 120rem;
  margin: 0 auto;
`;

const MainSection = styled.div`
  display: flex;
  gap: 8rem;
  margin-bottom: 6rem;

  ${media.tablet`
    flex-direction: column;
    gap: 4rem;
  `}
`;

const BrandSection = styled.div`
  flex-shrink: 0;
`;

const Logo = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  background: linear-gradient(135deg, var(--color-11), var(--color-9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.2rem;
`;

const TagLine = styled.p`
  color: var(--gray-11);
  margin-bottom: 2.4rem;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const SocialLink = styled.a`
  color: var(--gray-11);
  font-size: 2rem;
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-9);
  }
`;

const LinksGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 4rem;
`;

const LinkGroup = styled.div``;

const GroupTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--gray-12);
  margin-bottom: 2rem;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const LinkItem = styled(Link)`
  color: var(--gray-11);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.4rem;
  width: fit-content;

  &:hover:not(:disabled) {
    color: var(--color-9);
  }
`;

const NewsletterGroup = styled(LinkGroup)`
  grid-column: -2 / -1;

  ${media.tablet`
    grid-column: auto;
  `}
`;

const NewsletterText = styled.p`
  color: var(--gray-11);
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const NewsletterInput = styled.input`
  width: 100%;
  padding: 1.2rem 1.6rem;
  border: 1px solid var(--gray-6);
  border-radius: var(--border-radius-btn);
  background: var(--gray-1);
  color: var(--gray-12);
  font-size: 1.4rem;

  &:focus {
    outline: none;
    border-color: var(--color-7);
  }
`;

const SubscribeButton = styled.button`
  width: 100%;
  padding: 1.2rem 2.4rem;
  background: var(--color-9);
  color: var(--label-white);
  border: none;
  border-radius: var(--border-radius-btn);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background: var(--color-10);
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background: var(--gray-6);
  margin: 0 0 2rem 0;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile`
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  `}
`;

const Copyright = styled.p`
  color: var(--gray-11);
  font-size: 1.4rem;
`;

const LegalLinks = styled.div`
  display: flex;
  gap: 2rem;
`;

const LegalLink = styled.a`
  color: var(--gray-11);
  text-decoration: none;
  font-size: 1.4rem;
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-9);
  }
`;

export default Footer;
