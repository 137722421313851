import { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useProfileState } from '../redux/features/profile-slice';
import { HiCloud, HiHeart, HiSparkles, HiSun } from 'react-icons/hi';
import { BiLeaf } from 'react-icons/bi';
import { isEqual } from 'lodash';
import { useAuth } from '../redux/features/auth-slice';

const DarkModeContext = createContext();

export const THEME_CONFIG = {
  azure: {
    name: 'Azure Default',
    description: 'Crisp and clear like a bright blue sky',
    mainColor: 'blue',
    icon: <HiCloud color='var(--blue-9)' />,
  },
  emerald: {
    name: 'Emerald Grove',
    description: 'Lush and vibrant like a mystical forest',
    mainColor: 'green',
    icon: <BiLeaf color='var(--green-9)' />,
  },
  lavender: {
    name: 'Lavender Dreams',
    description: 'Soft and calming like a field of lavender',
    mainColor: 'purple',
    icon: <HiSparkles color='var(--purple-9)' />,
  },

  blush: {
    name: 'Blush Bliss',
    description: 'Playful and delightful like a rosy bloom',
    mainColor: 'pink',
    icon: <HiHeart color='var(--pink-9)' />,
  },
};

const DarkModeProvider = ({ children }) => {
  const { user } = useAuth();
  const currentUserTheme = user?.user_metadata?.theme;
  const currentUserAppearance = user?.user_metadata?.appearance;

  const [appearance, setAppearance] = useLocalStorage(
    'appearance',
    currentUserAppearance ||
      (window.matchMedia('(prefers-color-scheme:dark)').matches
        ? 'dark'
        : 'light')
  );

  const [theme, setTheme] = useLocalStorage(
    'theme',
    currentUserTheme || 'azure'
  );

  // Sync with metadata changes
  useEffect(() => {
    if (currentUserAppearance) {
      setAppearance(currentUserAppearance);
    }
    if (currentUserTheme) {
      setTheme(currentUserTheme);
    }
  }, [currentUserAppearance, currentUserTheme]);

  // Handle class changes
  useEffect(() => {
    document.documentElement.classList.remove('light-mode', 'dark-mode');
    document.documentElement.classList.remove(
      'azure-theme',
      'emerald-theme',
      'lavender-theme',
      'blush-theme'
    );

    document.documentElement.classList.add(`${appearance}-mode`);
    document.documentElement.classList.add(`${theme}-theme`);
  }, [appearance, theme]);

  return (
    <DarkModeContext.Provider
      value={{
        appearance,
        setAppearance,
        theme,
        setTheme,
        themeName: THEME_CONFIG[theme]?.name,
        themeDescription: THEME_CONFIG[theme]?.description,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

const useDarkMode = () => {
  const themeContext = useContext(DarkModeContext);

  if (themeContext === undefined)
    throw new Error('Theme context was used outside theme provider');

  return themeContext;
};

export { DarkModeProvider, useDarkMode };
