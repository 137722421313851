import styled, { css } from 'styled-components';
import { media } from '../styles/ResponsiveDesign';
import { HiOutlineMagnifyingGlass, HiXMark } from 'react-icons/hi2';
import { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useIsMounted from './useIsMounted';
import toast from 'react-hot-toast';

const SearchContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isSticky', 'size'].includes(prop),
})`
  cursor: pointer;
  width: 100%;
  height: ${({ size }) => (size === 'small' ? '3.4rem' : '4.1rem')};
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  border: var(--border-md-non);
  box-shadow: var(--box-shadow-sm);
  z-index: 5;
  background-color: var(--gray-3);
  border-radius: var(--border-radius-md);
  transition: all 0.2s ease;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
    `}

  &:hover:not(:disabled) {
    background-color: var(--gray-4);
    border: var(--border-md-int-str);
  }

  &:active:not(:disabled) {
    background-color: var(--gray-5);
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  & svg {
    color: var(--color-9);
    pointer-events: none;
    z-index: 1;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
  }

  & > svg:first-child {
    left: 1.2rem;
  }
`;

const StyledSearch = styled.input.attrs({ type: 'search' })`
  color: var(--gray-12);
  width: 100%;
  border: none;
  height: 100%;
  padding: 0 1.2rem 0 4rem;
  background-color: transparent;
  border-radius: var(--border-radius-md);
  transition: all 0.2s ease;

  ${media.tablet`
    font-size: 1.6rem;
  `}

  &:disabled {
    background-color: var(--gray-3);
    color: var(--gray-11);
    opacity: 0.75;
    cursor: not-allowed;
  }

  background-color: var(--gray-3);

  &:active {
    background-color: var(--gray-5);
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: var(--gray-11);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-9);
  }

  &:hover {
    background-color: var(--gray-4);
  }
`;

const ResetButton = styled.button`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--gray-11);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:hover {
    color: var(--gray-12);
  }

  & svg {
    width: 1.6rem;
    height: 1.6rem;
    position: static;
  }
`;

/**
 * Custom hook for handling search functionality.
 *
 * @param {Object} params - Configuration options for the search hook.
 * @param {Function} [params.onSearch] - Callback function to be called when search query changes.
 * @param {number} [params.debounce] - Debounce time in milliseconds for search query updates.
 * @param {string} [params.placeholder='Search...'] - Placeholder text for the search input.
 * @param {string} [params.initialValue=''] - Initial value for the search input.
 * @param {boolean} [params.disabled=false] - Whether the search input is disabled.
 * @param {boolean} [params.focusOnMount=false] - Whether to focus the search input on mount.
 * @param {boolean} [params.isSticky=false] - Whether the search input should have sticky positioning.
 * @param {Object} [params.config] - Configuration options for input validation.
 * @returns {Object} An object containing search-related properties and functions.
 * @property {string} searchQuery - The current search query.
 * @property {Function} setSearchQuery - Function to update the search query.
 * @property {React.RefObject} searchInputRef - Ref for the search input element.
 * @property {Function} handleOnSearchQueryChange - Event handler for search input changes.
 * @property {JSX.Element} markup - JSX markup for the search input component.
 */
const useSearch = (params = {}) => {
  const {
    onSearch,
    debounce,
    placeholder = 'Search...',
    initialValue = '',
    disabled = false,
    focusOnMount = false,
    isSticky = false,
    size = 'medium',
    config = {
      type: 'text', // 'text', 'number', 'alphanumeric'
      minLength: 0,
      maxLength: undefined,
      errorMessage: 'Invalid input',
      showToast: true,
      showReset: true,
    },
  } = params;

  const isMounted = useIsMounted();
  const searchInputRef = useRef();

  const [initialSearchQuery, setInitialSearchQuery] = useState(initialValue);

  const [searchQuery, setSearchQuery] = useState(''); //debounced search query

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchQuery(initialSearchQuery);
      onSearch && onSearch(initialSearchQuery);
    }, debounce || 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [debounce, initialSearchQuery]);

  // useEffect(() => {
  //   //call the onSearch callback with the search query
  //   onSearch && onSearch(searchQuery);
  // }, [searchQuery, onSearch]);

  const resetSearchQuery = useCallback(() => {
    setSearchQuery('');
    setInitialSearchQuery('');
  }, []);

  useEffect(() => {
    //set the initial search query
    setInitialSearchQuery(initialValue);
  }, [initialValue]);

  useEffect(() => {
    //focus the search input on mount
    const timeout = setTimeout(() => {
      isMounted() &&
        focusOnMount &&
        searchInputRef &&
        searchInputRef.current.focus();
    }, 0);
    return () => clearTimeout(timeout);
  }, [searchInputRef, focusOnMount]);

  function validateInput(value) {
    if (!value) return true;

    switch (config.type) {
      case 'text':
        // Only letters and spaces
        return /^[A-Za-z\s]*$/.test(value);
      case 'number':
        // Only numbers
        return /^\d*$/.test(value);
      case 'alphanumeric':
        // Letters, numbers and spaces
        return /^[A-Za-z0-9\s]*$/.test(value);
      default:
        return true;
    }
  }

  function handleOnSearchQueryChange(e) {
    const newValue = e.target.value;

    if (!validateInput(newValue)) {
      if (config.showToast) {
        toast.error(config.errorMessage || 'Invalid input type');
      }
      return;
    }

    if (config.minLength && newValue.length < config.minLength) {
      if (config.showToast) {
        toast.error(`Minimum ${config.minLength} characters required`);
      }
      return;
    }

    if (config.maxLength && newValue.length > config.maxLength) {
      if (config.showToast) {
        toast.error(`Maximum ${config.maxLength} characters allowed`);
      }
      return;
    }

    setInitialSearchQuery(newValue);
  }

  const handleReset = useCallback(() => {
    setInitialSearchQuery('');
    setSearchQuery('');
    onSearch && onSearch('');
  }, [onSearch]);

  const markup = (
    <SearchContainer size={size}>
      <HiOutlineMagnifyingGlass />
      <StyledSearch
        ref={searchInputRef}
        placeholder={placeholder}
        type='search'
        value={initialSearchQuery}
        onChange={(e) => handleOnSearchQueryChange(e)}
        disabled={disabled}
      />
      {config.showReset && initialSearchQuery && (
        <ResetButton
          onClick={handleReset}
          type='button'
          aria-label='Reset search'
        >
          <HiXMark />
        </ResetButton>
      )}
    </SearchContainer>
  );

  return {
    markup,
    searchQuery,
    setSearchQuery,
    resetSearchQuery: handleReset,
  };
};

export default useSearch;
