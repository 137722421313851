import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, CheckboxLabel } from '../ui/forms/Checkbox';
import { scaleUp } from '../ui/animations/Animations';
import { SelectionsWrapper } from '../styles/GeneralStyling';
import Skeleton from '../ui/loading/Skeleton';
import { isEmpty, isEqual } from 'lodash';

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1.2rem;
  flex-wrap: wrap;

  & .tags {
    display: flex;
    justify-content: start;
    gap: 1.2rem;
    flex-wrap: wrap;
  }
`;

const SelectAll = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & label {
    font-weight: bold;
  }
`;

const StyledCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0.5px solid var(--color-6);
  height: 2rem;
  min-width: 3.2rem;
  font-weight: bold;
  background-color: var(--color-3);
  border-radius: var(--border-radius-rd);
  color: var(--color-11);

  animation: ${(props) =>
    props.$bounce
      ? css`
          ${scaleUp} 0.5s linear
        `
      : 'none'};
`;

const StyledTagContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
  background-color: transparent;
`;

const TagCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:disabled ~ label {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }

  &:disabled:checked ~ label {
    border: 0.5px solid var(--color-5);
  }

  &:checked ~ label {
    color: var(--color-11);
    border: 0.5px solid var(--color-6);
    background-color: var(--color-3);

    &:hover {
      background-color: var(--color-4);
      color: var(--color-12);
    }

    &:active {
      background-color: var(--color-5);
      border: 0.5px solid var(--color-7);
    }
  }
`;

const Tag = styled.label.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})`
  height: ${(props) => (props.size === 'medium' ? '4rem' : '3.2rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  color: var(--gray-11);
  border: var(--border-sm-int);
  background-color: var(--gray-3);
  padding: 0 1.2rem;
  border-radius: var(--border-radius-btn);
  position: relative;
  cursor: pointer;

  transition: all 300ms ease;

  input:not(:checked) ~ &:hover:not(:disabled) {
    background-color: var(--gray-4);
    border: var(--border-sm-int);
    color: var(--gray-12);

    & svg {
      color: var(--gray-12);
    }
  }

  &:active:not(:disabled) {
    background-color: var(--gray-5);
    border: var(--border-sm-int-str);
  }

  & .count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 2rem;
    color: inherit;
  }
`;

/**
 * @param {Object} data
 * @param {Array<{id: string|number, value: string|number, label: string, count?: number}>} data.options - Array of tag objects
 * @param {Array<string>} data.defaultValues - Array of default selected values
 * @param {function} data.onChange - Callback function when selection changes
 * @param {string} data.selectAllLabel - Label for the "Select All" option
 * @param {string} data.searchQuery - Search query to filter tags
 * @param {Array<string>} data.disabled - Array of disabled tag ids
 * @param {boolean} data.isDisabled - Disable all tags
 * @param {boolean} data.isLoading - Show loading state
 * @param {boolean} data.disableCount - Disable tags with count 0
 * @param {boolean} data.showCount - Show count of tags
 * @param {'id'|'value'} data.returnType - Type of value to return on selection
 * @returns {Object} { markup: JSX.Element, selected: Array<string>, searchResult: number, resetSelections: function }
 */
const useTags = (data) => {
  const {
    options = [],
    defaultValues = [],
    onChange,
    selectAllLabel,
    searchQuery = '',
    disabled = [],
    isDisabled = false,
    isLoading = false,
    disableCount = false,
    showCount = true,
    returnType = 'id',
    size = 'small',
  } = useMemo(() => data, [data]);

  const [selected, setSelected] = useState(defaultValues);
  const [selectAll, setSelectAll] = useState(false);
  const [bounce, setBounce] = useState(false);

  const defaultValueRef = useRef(defaultValues);

  useEffect(() => {
    if (!isEqual(defaultValueRef.current, defaultValues)) {
      defaultValueRef.current = defaultValues;
      setSelected(defaultValues);
    }
  }, [defaultValues]);

  const resetSelections = useCallback(() => {
    setSelected(defaultValueRef.current);
  }, []);

  const filteredOptions = useMemo(() => {
    return searchQuery
      ? options.filter((option) =>
          option.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : options;
  }, [options, searchQuery]);

  useEffect(() => {
    const selectableOptionsCount = filteredOptions.filter(
      (option) =>
        (!disableCount || option.count > 0) &&
        !disabled.includes(returnType === 'id' ? option.id : option.value)
    ).length;

    setSelectAll(
      selected.length === selectableOptionsCount && selectableOptionsCount > 0
    );
  }, [selected.length, filteredOptions, disabled, disableCount, returnType]);

  const searchResult = filteredOptions.length;

  const handleBounce = useCallback(() => {
    setBounce(true);
    setTimeout(() => {
      setBounce(false);
    }, 300);
  }, []);

  const handleChange = useCallback(
    (e, option) => {
      handleBounce();

      const { checked } = e.target;
      const selectedValue = returnType === 'id' ? option.id : option.value;

      if (selectedValue) {
        setSelected((prevSelected) => {
          const newSelected = checked
            ? [...prevSelected, selectedValue]
            : prevSelected.filter((item) => item !== selectedValue);

          // Schedule onChange to run after the current render cycle
          setTimeout(() => {
            onChange?.(newSelected);
          }, 0);

          return newSelected;
        });
      }
    },
    [returnType, onChange, handleBounce]
  );

  const isSelectAllDisabled = useMemo(() => {
    return (
      isDisabled ||
      (disableCount && filteredOptions.some((option) => option.count === 0)) ||
      disabled.length > 0
    );
  }, [isDisabled, disableCount, filteredOptions, disabled]);

  const handleSelectAll = useCallback(() => {
    if (isSelectAllDisabled) return;

    handleBounce();

    setSelectAll((prevState) => !prevState);

    if (selectAll) {
      setSelected((prevState) => {
        const newState = [];
        onChange?.(newState);
        return newState;
      });
    } else {
      setSelected((prevState) => {
        const newState = filteredOptions
          .filter(
            (option) =>
              (!disableCount || option.count > 0) &&
              !disabled.includes(option.id)
          )
          .map((option) => (returnType === 'id' ? option.id : option.value));

        onChange?.(newState);
        return newState;
      });
    }
  }, [
    isSelectAllDisabled,
    disabled,
    disableCount,
    handleBounce,
    filteredOptions,
    onChange,
    selectAll,
    setSelected,
    returnType,
    setSelectAll,
    selected,
  ]);

  const tagId = useId();

  const markup = (
    <TagContainer>
      {isLoading ? (
        <Skeleton
          height={3}
          width={'40%'}
          repeat={!isEmpty(options) ? options.length : 8}
        />
      ) : (
        selectAllLabel && (
          <SelectAll>
            <Checkbox
              disabled={isSelectAllDisabled}
              id={selectAllLabel}
              onChange={handleSelectAll}
              checked={selectAll}
            />
            <CheckboxLabel htmlFor={selectAllLabel}>
              {selectAllLabel}
            </CheckboxLabel>
            <StyledCount $bounce={bounce}>{selected.length}</StyledCount>
          </SelectAll>
        )
      )}

      {isLoading ? (
        <Skeleton width={12} height={3} repeat={8} />
      ) : (
        <SelectionsWrapper>
          {filteredOptions.map((option) => {
            const optionDomId = `${tagId}-${option.id}`;
            return (
              <StyledTagContainer key={option.id}>
                <TagCheckbox
                  onChange={(e) => handleChange(e, option)}
                  id={optionDomId}
                  name={option.label}
                  value={option.value}
                  checked={selected.includes(
                    returnType === 'id' ? option.id : option.value
                  )}
                  disabled={
                    disabled.includes(
                      returnType === 'id' ? option.id : option.value
                    ) ||
                    isDisabled ||
                    (disableCount && option.count === 0)
                  }
                />
                <Tag size={size} htmlFor={optionDomId}>
                  {showCount && (
                    <span className='count'>({option.count || 0})</span>
                  )}
                  {option.label}
                </Tag>
              </StyledTagContainer>
            );
          })}
        </SelectionsWrapper>
      )}
    </TagContainer>
  );

  return { markup, selected, searchResult, resetSelections };
};

export default useTags;
