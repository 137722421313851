import { useAuth } from '../../redux/features/auth-slice';
import { useLocation, Navigate } from 'react-router-dom';
import { getRedirectPathOnAuth } from '../../auth/auth';

const RedirectIfAuthenticated = ({ children }) => {
  const { session, user } = useAuth();
  const location = useLocation();

  // Helper functions for readability
  const isSuspendedRoute = location.pathname === '/suspended';
  const isOnboardingRoute = location.pathname === '/onboarding';

  const isSuspended = user?.user_metadata?.account_status === 'suspended';
  const needsOnboarding = !user?.user_metadata?.onboarding_complete;

  // Only proceed with redirects if we have all necessary data
  if (session) {
    // 1. Handle suspended users
    if (isSuspended && !isSuspendedRoute) {
      return <Navigate to='/suspended' replace />;
    }

    // 2. Handle onboarding
    if (needsOnboarding && !isOnboardingRoute) {
      return <Navigate to='/onboarding' replace />;
    }

    // 3. Handle completed users on auth routes
    if (!needsOnboarding && !isSuspended) {
      const redirectPath = getRedirectPathOnAuth(user);
      return <Navigate to={redirectPath} replace />;
    }
  }

  // Show children (login/signup forms) if not authenticated
  return session ? null : children;
};

export default RedirectIfAuthenticated;
