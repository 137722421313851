import { useState, useCallback } from 'react';

export const isLocalStorageAccessible = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export const useLocalStorage = (key, initialState) => {
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialState;
    }
    if (!isLocalStorageAccessible) {
      console.warn(
        'LocalStorage is not accessible. Falling back to initial state.'
      );
      return initialState;
    }
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialState;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return initialState;
    }
  });

  const setValueAndStorage = useCallback(
    (newValue) => {
      setValue((prevValue) => {
        const valueToStore =
          newValue instanceof Function ? newValue(prevValue) : newValue;

        if (isLocalStorageAccessible) {
          try {
            localStorage.setItem(key, JSON.stringify(valueToStore));
          } catch (error) {
            console.warn(`Error setting localStorage key "${key}":`, error);
          }
        }

        return valueToStore;
      });
    },
    [key]
  );

  return [value, setValueAndStorage];
};
