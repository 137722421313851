import { useEditor } from '../../context/EditorContext';
import { useCallback, useState } from 'react';
import { useToast } from '../../context/ToastProvider';
import { EditorState, RichUtils } from 'draft-js';
import { isURL } from 'validator';
import { HiOutlineLink, HiOutlineNoSymbol } from 'react-icons/hi2';
import { PromptForm } from './PromptForm';
import { ShowPopup, usePopup } from '../../ui/modal/Popup';
import { ToolButton } from '../../ui/layout/Card';
import { StyledLink } from './blogEditorStyles';
import { EditorToolButton } from './ToolCategories';
import { InfoTip } from '../../ui/notifications/InfoTip';

export const LinkEditor = () => {
  const { editorState, setEditorState, isSelectionActive, setShouldFocus } =
    useEditor();
  const { toast } = useToast();
  const { closePopup } = usePopup();
  const [linkState, setLinkState] = useState({
    urlValue: '',
  });

  function resetUrlValue() {
    setLinkState({
      urlValue: '',
    });
  }

  function closeUrlInput() {
    resetUrlValue();

    // Clear the selection
    const selection = editorState.getSelection().merge({
      anchorOffset: 0,
      focusOffset: 0,
    });
    const newEditorState = EditorState.forceSelection(editorState, selection);
    setEditorState(newEditorState);
    setShouldFocus(false);
    closePopup();
  }

  const promptForLink = () => {
    if (!isSelectionActive) {
      toast({
        type: 'warning',
        message: 'Please select text to add a link',
      });
      return;
    }

    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        let url = '';
        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey);
          url = linkInstance.getData().url;
        }

        setLinkState({
          urlValue: url,
        });
      }
      setShouldFocus(false);
    } else {
      toast({
        type: 'warning',
        message: 'Please select text to add a link',
      });
    }
  };

  const confirmLink = (e) => {
    //TODO: check for harmful links using phishtank api
    e.preventDefault();

    if (!linkState.urlValue || !isURL(linkState.urlValue)) {
      resetUrlValue();
      toast({
        type: 'danger',
        message: 'Please enter a valid URL',
      });
      return;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: linkState.urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );

    resetUrlValue();
    setShouldFocus(false);
    closePopup();
  };

  const isLinkActive = useCallback(() => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
      return false;
    }
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    return (
      linkKey !== null && contentState.getEntity(linkKey).getType() === 'LINK'
    );
  }, [editorState]);

  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
    closePopup();
  };

  return (
    <>
      <ShowPopup
        button={
          <InfoTip tip={isLinkActive() ? 'Edit Link' : 'Insert Link'}>
            <EditorToolButton
              variant='gray'
              active={isLinkActive()}
              disabled={!isSelectionActive}
            >
              <HiOutlineLink />
            </EditorToolButton>
          </InfoTip>
        }
        close={false}
        onClick={promptForLink}
        position='top'
        component={
          <PromptForm
            promptState={linkState}
            setPromptState={setLinkState}
            onSubmit={confirmLink}
            closePrompt={closeUrlInput}
            placeholder='Enter link URL...'
            mediaType='link'
          />
        }
      />
      <InfoTip tip='Remove Link'>
        <EditorToolButton
          variant='gray'
          active={isLinkActive()}
          disabled={!isSelectionActive}
          onClick={removeLink}
        >
          <HiOutlineNoSymbol />
        </EditorToolButton>
      </InfoTip>
    </>
  );
};

export const LinkComponent = (props) => {
  const { contentState, children, entityKey } = props;

  const entity = contentState.getEntity(entityKey);
  const entityType = entity.getType();
  const { url } = entity.getData();

  if (entityType === 'LINK') {
    return (
      <StyledLink href={url} target='_blank' rel='noopener noreferrer'>
        {children}
      </StyledLink>
    );
  }
  // Fallback to plain text if the entity type is unknown
  return <span>{children}</span>;
};
