import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  forwardRef,
} from 'react';
import styled, { keyframes, css } from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { HiOutlineChevronRight } from 'react-icons/hi2';
import { createPortal } from 'react-dom';
import { debounce, isEqual } from 'lodash';

export const fadeIn = keyframes`
  0% {  transform: scale(0.95); }
  60% {  transform: scale(1.01); }
  100% {  transform: scale(1); }
`;

export const fadeOut = keyframes`
  0% {  transform: scale(1); }
  60% {  transform: scale(1.01); }
  100% {  transform: scale(0.95); }
`;

export const BubbleWrapper = styled.div`
  font-size: 1.3rem;
  position: relative;
  max-width: 32rem;
  max-height: 40rem;
  width: auto;
  padding: 0.8rem 1.2rem;
  border-radius: var(--border-radius-sm);
  background: var(--color-1);
  color: var(--gray-11);
  overflow-y: auto;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-8);
    border-radius: 4px;
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuOptions = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isVisible', 'origin', 'hasChildren'].includes(prop),
})`
  min-width: 20rem;
  width: max-content;
  position: fixed;
  background: var(--color-1);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  z-index: 9999;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  animation: ${(props) =>
    props.isVisible
      ? css`
          ${fadeIn} 0.3s ease-in-out
        `
      : css`
          ${fadeOut} 0.3s ease-in-out
        `};
  transform-origin: ${(props) => props.origin};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const SubMenu = styled(MenuOptions)`
  position: absolute;
  min-width: 150px;
  top: 0;
  left: 100%;
  overflow: visible;
`;

export const MenuButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  color: var(--gray-11);
  width: 100%;
  padding: 0.8rem 1.2rem;
  gap: 1.2rem;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background 0.2s;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  border-radius: var(--border-radius-sm);

  &:first-child {
    border-top-left-radius: var(--border-radius-md);
    border-top-right-radius: var(--border-radius-md);
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.5px;
      background: var(--gray-6);
    }
  }

  &:hover:not(:disabled) {
    background-color: var(--color-2);
    color: var(--color-11);

    svg {
      color: var(--color-11);
    }
  }

  &:active:not(:disabled) {
    color: var(--gray-12);
    background-color: var(--gray-5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ArrowIcon = styled.span`
  /* margin-left: 8px; */
`;

const MenuOptionsPortal = forwardRef(({ children, ...props }, ref) =>
  createPortal(
    <MenuOptions
      hasChildren={!!children}
      ref={ref}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </MenuOptions>,
    document.getElementById('menu')
  )
);

export const Menus = ({
  toggle,
  children,
  options = [],
  trigger = 'click',
  isVisible,
  enabled = true,
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const isControlled = isVisible !== undefined;
  const isOpen = isControlled ? isVisible : isOpenInternal;

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [origin, setOrigin] = useState('top');
  const [openSubMenus, setOpenSubMenus] = useState({});
  const menuRef = useRef(null);
  const menuItemRefs = useRef({});
  const submenuRefs = useRef({});
  const timeoutRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const MENU_GAP = 8;
  const DEBOUNCE_TIME = 16; //Roughly one frame at 16fps

  const enterTimeoutRef = useRef(null);
  const leaveTimeoutRef = useRef(null);

  const clearTimeouts = useCallback(() => {
    if (enterTimeoutRef.current) {
      clearTimeout(enterTimeoutRef.current);
      enterTimeoutRef.current = null;
    }
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }
  }, []);

  const closeMenu = useCallback(() => {
    if (!isControlled) {
      setIsOpenInternal(false);
    }
  }, [isControlled]);

  const ref = useOutsideClick(closeMenu, true); //This is the toggle ref

  const getAdjustedX = useCallback(
    (toggleXPosition, menuWidth) => {
      const windowWidth = window.innerWidth;

      const toggleWidth = ref.current.getBoundingClientRect().width;

      const menuRightEdge = toggleXPosition + menuWidth + toggleWidth;

      if (menuRightEdge > windowWidth) {
        return windowWidth - menuWidth - MENU_GAP;
      }

      if (toggleXPosition - menuWidth < 0) {
        return toggleXPosition;
      }

      return toggleXPosition + (toggleWidth - menuWidth) / 2;
    },
    [ref]
  );

  const getAdjustedY = useCallback(
    (toggleYPosition, menuHeight) => {
      const windowHeight = window.innerHeight;
      const toggleHeight = ref.current.getBoundingClientRect().height;

      const menuBottomEdge = toggleYPosition + menuHeight + toggleHeight;

      if (menuBottomEdge <= windowHeight) {
        return toggleYPosition + toggleHeight + MENU_GAP;
      }

      setOrigin('bottom');
      return toggleYPosition - menuHeight - MENU_GAP;
    },
    [ref]
  );
  const calculateMenuPosition = useCallback(() => {
    if (!ref?.current || !menuRef?.current) return;

    const toggleRect = ref.current.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();

    const newX = getAdjustedX(toggleRect.x, menuRect.width);
    const newY = getAdjustedY(toggleRect.y, menuRect.height);

    if (!isEqual(newX, menuPosition.x) || !isEqual(newY, menuPosition.y)) {
      setMenuPosition({ x: newX, y: newY });
    }
  }, [getAdjustedX, getAdjustedY, menuPosition.x, menuPosition.y]);

  const debouncedCalculateMenuPosition = useCallback(
    debounce(calculateMenuPosition, DEBOUNCE_TIME),
    [calculateMenuPosition]
  );

  const calculateSubmenuPosition = useCallback(
    (parentElement, submenuElement) => {
      if (parentElement && submenuElement) {
        const parentRect = parentElement.getBoundingClientRect();
        const submenuRect = submenuElement.getBoundingClientRect();

        let top;
        let bottom;
        let left;
        let right;

        let x = parentRect.right;
        let y = parentRect.top;

        if (x + submenuRect.width > window.innerWidth) {
          right = '100%';
          left = 'auto';
        }

        if (y + submenuRect.height > window.innerHeight) {
          bottom = 0;
          top = 'auto';
        }

        return { top, bottom, left, right };
      }
      return { top: 0, left: '100%' };
    },
    []
  );

  const handleToggle = useCallback(
    (event) => {
      if (!enabled) return;
      if (event?.preventDefault) event.preventDefault();
      if (event?.stopPropagation) event.stopPropagation();

      if (isOpen) {
        if (!isControlled) {
          setIsOpenInternal(false);
        }
        setMenuPosition({ x: 0, y: 0 });
        setOpenSubMenus({});
        return;
      }

      const toggleRect = ref.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect() || {
        width: 0,
        height: 0,
      };

      const x = getAdjustedX(toggleRect.x, menuRect.width);
      const y = getAdjustedY(toggleRect.y, menuRect.height);

      setMenuPosition({ x, y });

      if (!isControlled) {
        setIsOpenInternal(true);
      }

      setOpenSubMenus({});
    },
    [isOpen, isControlled, calculateMenuPosition]
  );

  const handleContextMenu = useCallback(
    (event) => {
      if (!enabled) return;
      if (trigger === 'contextMenu') {
        event.preventDefault();
        event.stopPropagation();
        handleToggle(event);
      }
    },
    [trigger, handleToggle]
  );

  const handleMouseEnter = useCallback(() => {
    if (trigger !== 'hover' || !enabled) return;

    clearTimeouts();

    enterTimeoutRef.current = setTimeout(() => {
      const toggleRect = ref.current.getBoundingClientRect();
      const menuRect = menuRef.current?.getBoundingClientRect() || {
        width: 0,
        height: 0,
      };

      const x = getAdjustedX(toggleRect.x, menuRect.width);
      const y = getAdjustedY(toggleRect.y, menuRect.height);

      setMenuPosition({ x, y });

      if (!isControlled) {
        setIsOpenInternal(true);
      }
    }, 100);
  }, [trigger, isControlled, getAdjustedX, getAdjustedY, clearTimeouts]);

  const handleMouseLeave = useCallback(() => {
    if (trigger !== 'hover' || !enabled) return;

    clearTimeouts();

    leaveTimeoutRef.current = setTimeout(() => {
      if (Object.values(openSubMenus).every((isOpen) => !isOpen)) {
        if (!isControlled) {
          setIsOpenInternal(false);
        }
        setMenuPosition({ x: 0, y: 0 });
      }
    }, 200);
  }, [trigger, isControlled, clearTimeouts, openSubMenus]);

  useEffect(() => {
    const handleScrollAndResize = (e) => {
      if (!isOpen || !enabled) return;

      if (e.target === document || e.target === window) {
        debouncedCalculateMenuPosition();
        return;
      }

      const menuElement = menuRef.current;

      if (menuElement && !menuElement.contains(e.target)) {
        debouncedCalculateMenuPosition();
      }
    };

    window.addEventListener('scroll', handleScrollAndResize, { capture: true });
    window.addEventListener('resize', handleScrollAndResize);

    return () => {
      window.removeEventListener('scroll', handleScrollAndResize, {
        capture: true,
      });
      window.removeEventListener('resize', handleScrollAndResize);
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [debouncedCalculateMenuPosition, isOpen, enabled]);

  const handleSubmenuMouseEnter = (itemPath) => {
    setOpenSubMenus((prev) => ({ ...prev, [itemPath]: true }));
  };

  const handleSubmenuMouseLeave = (itemPath) => {
    setOpenSubMenus((prev) => ({ ...prev, [itemPath]: false }));
  };

  const renderMenuItems = (items, parentPath = '') => {
    return items.map((item, index) => {
      const itemPath = `${parentPath}${index}`;
      const hasSubmenu = item.subOptions && item.subOptions.length > 0;

      const getSubmenuPosition = () => {
        const itemElement = menuItemRefs.current[itemPath];
        const submenuElement = submenuRefs.current[itemPath];

        if (itemElement && submenuElement) {
          return calculateSubmenuPosition(itemElement, submenuElement);
        }
      };

      return (
        <MenuButton
          key={itemPath}
          ref={(el) => (menuItemRefs.current[itemPath] = el)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (item.onClick) item.onClick();
            if (hasSubmenu) {
              setOpenSubMenus((prev) => ({
                ...prev,
                [itemPath]: !prev[itemPath],
              }));
            } else {
              if (!isControlled) {
                setIsOpenInternal(false);
              }
              setOpenSubMenus({});
            }
          }}
          onMouseEnter={() => handleSubmenuMouseEnter(itemPath)}
          onMouseLeave={() => handleSubmenuMouseLeave(itemPath)}
        >
          {item?.label && item.label}
          {item?.icon && item.icon}

          {hasSubmenu && (
            <ArrowIcon>
              <HiOutlineChevronRight />
            </ArrowIcon>
          )}
          {hasSubmenu && openSubMenus[itemPath] && (
            <SubMenu
              ref={(el) => (submenuRefs.current[itemPath] = el)}
              style={getSubmenuPosition()}
              isVisible={true}
            >
              {renderMenuItems(item.subOptions, `${itemPath}-`)}
            </SubMenu>
          )}
        </MenuButton>
      );
    });
  };

  useEffect(() => {
    return () => {
      if (enterTimeoutRef.current) {
        clearTimeout(enterTimeoutRef.current);
      }
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      clearTimeouts();
    }
  }, [isOpen, clearTimeouts]);

  return (
    <MenuWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleContextMenu}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MenuToggleWrapper ref={ref}>
        {React.isValidElement(toggle) &&
          React.cloneElement(toggle, {
            onClick: (event) => {
              if (toggle.props.onClick) {
                toggle.props.onClick(event);
              }
              if (trigger === 'click') {
                handleToggle(event);
              }
            },
          })}
        {!React.isValidElement(toggle) && toggle}
      </MenuToggleWrapper>

      <MenuOptionsPortal
        ref={menuRef}
        isVisible={isOpen}
        style={{ left: menuPosition.x, top: menuPosition.y }}
        origin={origin}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children ? children : renderMenuItems(options)}
      </MenuOptionsPortal>
    </MenuWrapper>
  );
};
