import styled, { StyleSheetManager, css } from 'styled-components';
import Heading from '../typography/Heading';

const SnapshotCard = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hover', 'color'].includes(prop),
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.8rem;
  transition: all 0.3s ease;

  ${(props) =>
    props.hover &&
    css`
      padding: 0.4rem;
      border-radius: var(--shadow-md);
      cursor: pointer;

      &:hover {
        border: var(--border-md-int-str);
      }
      &:active {
        border: var(--border-md-int);
      }
    `}
`;

const SnapshotSvg = styled.div`
  align-self: start;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;

  background-color: ${(props) => props.color};
  background-color: transparent;

  border-radius: var(--shadow-md);

  & svg {
    /* margin-top: 0.5rem; */
    flex: 0 0 auto;
    z-index: 1;
    height: 100%;
    width: 100%;
    color: ${(props) => props.color || 'var(--color-9)'};
    stroke-width: 1;
  }
`;

const SnapshotBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
`;
const Description = styled.div`
  color: var(--gray-11);
`;

const SvgCard = (props) => {
  const { title, svg, description, color, hover, onClick } = props;

  return (
    <StyleSheetManager
      shouldForwardProp={(prop) => prop !== 'color' && prop !== 'hover'}
    >
      <SnapshotCard onClick={onClick} hover={hover}>
        {svg && <SnapshotSvg color={color}>{svg}</SnapshotSvg>}

        <SnapshotBody>
          {title && <Heading as='h6'>{title}</Heading>}
          {description && (
            <Description className='description'>{description}</Description>
          )}
        </SnapshotBody>
      </SnapshotCard>
    </StyleSheetManager>
  );
};

export default SvgCard;
