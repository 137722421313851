import styled, { css } from 'styled-components';
import Spinner from '../loading/Spinner';

function Button({
  children,
  isLoading,
  variant,
  color,
  size = 'medium',
  justify = 'center',
  custom,
  type = 'button',
  ...props
}) {
  return (
    <StyledButton
      variant={variant}
      color={color}
      size={size}
      justify={justify}
      isLoading={isLoading}
      custom={custom}
      {...props}
      type={type}
    >
      <ContentWrapper isLoading={isLoading}>{children}</ContentWrapper>

      {isLoading && (
        <SpinnerWrapper>
          <Spinner size={size || 'medium'} />
        </SpinnerWrapper>
      )}
    </StyledButton>
  );
}

const ContentWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => !['isLoading'].includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  transition: opacity 200ms ease;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const size = {
  xsmall: css`
    font-size: 1.2rem;
    padding: 0 1.2rem;
    height: 2.4rem;
  `,
  small: css`
    font-size: 1.2rem;
    padding: 0 1.2rem;
    height: 3.2rem;
  `,
  medium: css`
    font-size: 1.4rem;
    padding: 0 1.6rem;
    height: 4rem;
  `,
  large: css`
    font-size: 1.6rem;
    padding: 0 2.4rem;
    height: 5rem;
  `,
};

const getColorStyle = (color) => css`
  background-color: var(--${color}-9);
  color: var(--label-white);

  &:hover:not(:disabled) {
    background-color: var(--${color}-10);
  }

  &:active:not(:disabled) {
    background-color: var(--${color}-9);
  }

  & svg {
    color: var(--label-white);
  }
`;

const variant = {
  primary: css`
    background-color: var(--color-9);
    color: var(--label-white);

    &:hover:not(:disabled) {
      background-color: var(--color-10);
    }

    &:active:not(:disabled) {
      background-color: var(--color-9);
    }

    & svg {
      color: var(--label-white);
    }
  `,

  complementary: css`
    background-color: var(--comp-9);
    color: var(--label-white);

    &:hover:not(:disabled) {
      background-color: var(--comp-10);
    }

    &:active:not(:disabled) {
      background-color: var(--comp-9);
    }

    & svg {
      color: var(--label-white);
    }
  `,

  secondary: css`
    background-color: var(--gray-3);
    color: var(--gray-12);
    border: 1px solid var(--gray-6);

    &:hover:not(:disabled) {
      background-color: var(--gray-4);
      border-color: var(--gray-7);
    }

    &:active:not(:disabled) {
      background-color: var(--gray-3);
      border-color: var(--gray-8);
    }

    & svg {
      color: var(--gray-12);
    }
  `,
  outline: css`
    background-color: transparent;
    border: 1px solid var(--color-6);
    color: var(--color-11);

    &:hover:not(:disabled) {
      border-color: var(--color-7);
      color: var(--color-10);

      & svg {
        color: var(--color-10);
      }
    }

    &:active:not(:disabled) {
      border-color: var(--color-8);
    }

    & svg {
      color: var(--color-11);
    }
  `,

  danger: css`
    background-color: var(--red-9);
    color: var(--label-white);

    &:hover:not(:disabled) {
      background-color: var(--red-10);
    }

    &:active:not(:disabled) {
      background-color: var(--red-9);
    }

    & svg {
      color: var(--label-white);
    }
  `,
  transparent: css`
    background-color: transparent;
    color: var(--gray-11);

    &:hover:not(:disabled) {
      background-color: var(--gray-3);
      color: var(--gray-12);

      & svg {
        color: var(--gray-12);
      }
    }

    &:active:not(:disabled) {
      background-color: var(--gray-3);
    }

    & svg {
      color: var(--gray-11);
    }
  `,
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['size', 'variant', 'justify', 'isLoading', 'custom', 'color'].includes(
      prop
    ),
})`
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--border-radius-btn);
  font-weight: 500;
  transition: all 200ms ease;
  cursor: pointer;
  overflow: hidden;

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) => size[props.size]}
  ${(props) => {
    if (props.variant) {
      return variant[props.variant];
    }
    if (props.color) {
      return getColorStyle(props.color);
    }
    return variant.primary;
  }}
`;

export default Button;
