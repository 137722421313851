import { useLocation, matchPath, Navigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setLastVisitedPage, useAuth } from '../../redux/features/auth-slice';
import useRoleCheck from '../../hooks/useRoleCheck';
import { usePermissionsState } from '../../redux/features/permissions-slice';

const ProtectedRoutes = ({ children }) => {
  const { session, user } = useAuth();
  const { onboarding_complete, account_status } = user?.user_metadata || {};
  const location = useLocation();
  const dispatch = useDispatch();
  const { hasRouteAccess, isAccountActive } = useRoleCheck();
  const { routePermissions, routes } = usePermissionsState();
  const locationPath = location.pathname;
  const isOnboardingRoute = locationPath === '/onboarding';
  const isUnauthorizedRoute = locationPath === '/unauthorized';
  const isSuspendedRoute = locationPath === '/suspended';
  const isLoginRoute = locationPath === '/login';

  //Find matching route by checking path patterns
  const currentRoute = useMemo(
    () =>
      Object.values(routePermissions).find((route) =>
        matchPath({ path: route.path, exact: true, strict: true }, locationPath)
      ),
    [routePermissions, locationPath]
  );

  const isRouteAccessible = useMemo(() => {
    const isAuthorized =
      currentRoute && hasRouteAccess(currentRoute?.value, currentRoute?.strict);

    return isAuthorized;
  }, [currentRoute]);

  const isSuspended = account_status === 'suspended' && !isSuspendedRoute;
  const isUnauthorized =
    isAccountActive && !isRouteAccessible && !isUnauthorizedRoute;

  useEffect(() => {
    //Store the last visited page if authenticated and not in a pre-auth route
    const isPreAuthRoute = routes.public.some((route) => {
      if (route.path === '/') {
        return false;
      }
      return location.pathname.startsWith(route.path);
    });

    dispatch(
      setLastVisitedPage(
        isPreAuthRoute
          ? '/blog/discover'
          : location.pathname || '/blog/discover'
      )
    );
  }, [locationPath]);

  if (isSuspended && !isSuspendedRoute) {
    return <Navigate to='/suspended' replace />;
  }

  if (!session && !isLoginRoute) {
    return <Navigate to='/login' replace />;
  }

  if (isUnauthorized && !isUnauthorizedRoute) {
    return <Navigate to='/unauthorized' replace />;
  }

  if (!onboarding_complete && !isOnboardingRoute && isAccountActive) {
    return <Navigate to='/onboarding' replace />;
  }

  if (session && isRouteAccessible && isAccountActive) return children;

  return null;
};

export default ProtectedRoutes;
