import supabase from '../../services/supabase';

export async function getBlogCountStatus(userId) {
  if (!userId) return;
  let { data, error } = await supabase.rpc('count_blog_posts_by_status', {
    v_user_id: userId,
  });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export async function createBlog(payload) {
  const { data, error } = await supabase.rpc('create_blog', {
    payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function updateBlog(payload) {
  const { data, error } = await supabase.rpc('update_blog', {
    edit_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function publishBlogPost(payload) {
  const { data, error } = await supabase.rpc('publish_blog', {
    publish_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function cancelSubmission(payload) {
  const { data, error } = await supabase.rpc('cancel_submission', {
    cancel_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function approveBlog(payload) {
  const { data, error } = await supabase.rpc('approve_blog_post', {
    approval_data: payload,
  });
  if (error) throw new Error(error.message);

  console.log('data', data);
  return data;
}

export async function rejectBlog(payload) {
  const { data, error } = await supabase.rpc('reject_blog', {
    rejection_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function approveEditAppeal(payload) {
  const { data, error } = await supabase.rpc('approve_edit_appeal', {
    appeal_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function rejectEditAppeal(payload) {
  const { data, error } = await supabase.rpc('reject_edit_appeal', {
    rejection_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}

export async function updateReviewStatus(payload) {
  const { data, error } = await supabase.rpc('update_blog_review_status', {
    update_data: payload,
  });
  if (error) throw new Error(error.message);
  return data;
}
