import supabase from '../services/supabase';

export async function verifyOTP({ email, token, type }) {
  const { data, error } = await supabase.auth.verifyOtp({
    email,
    token,
    type,
  });

  if (error) {
    throw new Error('Failed to verify OTP: ' + error.message);
  }

  return data;
}

export async function resetPasswordForEmail({ email, redirectTo }) {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: redirectTo,
  });

  if (error) {
    throw new Error('Failed to reset password: ' + error.message);
  }
}

export async function updatePassword({ password }) {
  const { data, error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    throw new Error('Failed to update password: ' + error.message);
  }

  return data;
}
