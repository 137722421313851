import { useMutation, useQueryClient } from 'react-query';
import supabase from '../../services/supabase';
import { useDispatch } from 'react-redux';
import {
  loginFailure,
  loginSuccess,
  updateUser,
} from '../../redux/features/auth-slice';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import toast from 'react-hot-toast';

const getSession = async () => {
  const { data, error } = await supabase.auth.getSession();

  if (!data) {
    return null;
  }

  if (error) {
    throw new Error('Could not get user session: ' + error.message);
  }

  return data.session;
};
export function useGetSession() {
  const dispatch = useDispatch();

  //data can be null if there is no active session
  return useMutation({
    mutationFn: getSession,

    onSuccess: (data) => {
      if (data) {
        const { user, ...session } = data;
        dispatch(updateAuth({ user, session }));
      } else {
        dispatch(updateAuth({ user: null, session: null }));
      }
    },
  });
}

async function refreshSession() {
  const { data, error } = await supabase.auth.refreshSession();

  if (error) {
    throw new Error(error.message);
  }

  if (data) {
    dispatch(
      loginSuccess({
        user: data.user,
        session: data.session,
      })
    );
  } else {
    dispatch(loginFailure('Failed to refresh session'));
  }
}

export const useRefreshSession = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: refreshSession,

    //data can be null if there is no active session
    onSuccess: (data) => {
      if (data) {
        const { user, ...session } = data;

        dispatch(updateAuth({ user, session }));
      } else {
        dispatch(updateAuth({ user: null, session: null }));
      }
    },
  });
};

/**
 * @typedef {Object} UpdateUserPayload
 * @property {string} [email] - New email address
 * @property {string} [password] - New password
 * @property {Object} [data] - Custom user metadata
 * @property {string} [phone] - Phone number
 */

/**
 * Updates the current user's information
 * @param {UpdateUserPayload} payload - The user data to update
 * @returns {Promise<{user: Object}>}
 * @throws {Error} If update fails or user is not authenticated
 */
export const updateCurrentUser = async (payload) => {
  const { data, error } = await supabase.auth.updateUser(payload);

  if (error) {
    throw new Error('Could not update user: ' + error.message);
  }

  return data;
};

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: ({ user }) => {
      dispatch(updateUser({ user: user }));
    },
    onError: (error) => {
      const errorMessage = getCrudErrorMessage('update', error, 'user');
      toast.error(errorMessage.description);
    },
  });
}
