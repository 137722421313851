import Button from '../../ui/buttons/Button';
import { Link } from 'react-router-dom';
import { ButtonText } from '../../ui/buttons/ButtonText';
import {
  StyledAuthContainer,
  StyledFooter,
  StyledForm,
} from '../../ui/forms/StyledAuthContainer';
import {
  Input,
  InputError,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { FlexColumn } from '../../ui/layout/Flex';
import { useId, useState } from 'react';
import { useLogin } from '../../api/authentication/login';
import toast from 'react-hot-toast';
import { useNavigation } from '../../hooks/useNavigation.jsx';
import { HiOutlineEnvelope, HiOutlineLockClosed } from 'react-icons/hi2';
import Heading from '../../ui/typography/Heading.jsx';
import { isEmail } from 'validator';

const LoginForm = () => {
  const emailId = useId();
  const passwordId = useId();
  const { navigateToPath } = useNavigation();
  const [formState, setFormState] = useState({
    email: '',
    password: '',
  });
  const { mutate: login, isLoading, error: loginError } = useLogin();
  const isFormFilled = Object.values(formState).every((value) => value !== '');

  function resetFormState() {
    setFormState({ email: '', password: '' });
  }

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  }

  function validateForm() {
    if (!isFormFilled) {
      return {
        isValid: false,
        message: 'Please fill in all fields',
      };
    }

    if (!isEmail(formState.email)) {
      return {
        isValid: false,
        message: 'Please enter a valid email address',
      };
    }

    return {
      isValid: true,
    };
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const { isValid, message } = validateForm();

    if (!isValid) return toast(message, { type: 'warning' });

    login(formState, {
      onSuccess: (data) => {
        toast.success('Logged in successfully');
        resetFormState();
      },
      onError: (error) => {
        resetFormState();
        if (error.message === 'Email not confirmed') {
          navigateToPath('/verify-email', {
            state: { email: formState?.email, from: '/login' },
          });
          return toast.error('Please verify your email before logging in');
        }
      },
    });
  };

  return (
    <StyledAuthContainer>
      <StyledForm onSubmit={onSubmit}>
        <FlexColumn gap={3.2}>
          <Heading as='h4' align='center'>
            Log in to BittsBlog
          </Heading>
          {loginError && (
            <InputError style={{ textAlign: 'center' }}>
              Unable to authenticate user.
            </InputError>
          )}
          <StyledInputContainer>
            <HiOutlineEnvelope />
            <Input
              value={formState.email}
              name='email'
              id={emailId}
              type='email'
              autoComplete='on'
              placeholder='Email'
              required
              onChange={handleOnChange}
            />
            <InputLabel htmlFor={emailId}>Email</InputLabel>
          </StyledInputContainer>

          <StyledInputContainer>
            <HiOutlineLockClosed />
            <Input
              id={passwordId}
              name='password'
              required
              type='password'
              autoComplete='off'
              placeholder='Password'
              value={formState.password}
              onChange={handleOnChange}
            />
            <InputLabel htmlFor={passwordId}>Password</InputLabel>
          </StyledInputContainer>
        </FlexColumn>

        <Button isLoading={isLoading} type='submit' disabled={isLoading}>
          Login
        </Button>
      </StyledForm>

      <StyledFooter>
        <ButtonText type='button' as={Link} to='/reset-password'>
          Forgot Password?
        </ButtonText>
        <ButtonText type='button' as={Link} to='/signup'>
          Sign Up
        </ButtonText>
      </StyledFooter>
    </StyledAuthContainer>
  );
};

export default LoginForm;
