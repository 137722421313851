import supabase from '../../services/supabase';
import { getCurrentTimestamp } from '../../utils/helpers';

export async function getNotifications({
  userId,
  page = 1,
  limit = 10,
  filter = 'all', // all, unread, read
} = {}) {
  // Ensure page is at least 1
  page = Math.max(1, page);

  // First, get the total count without range
  let countQuery = supabase
    .from('notifications')
    .select('id', { count: 'exact' })
    .eq('user_id', userId);

  // Apply filter if not 'all'
  if (filter === 'read') {
    countQuery = countQuery.not('read_at', 'is', null);
  } else if (filter === 'unread') {
    countQuery = countQuery.is('read_at', null);
  }

  const { count, error: countError } = await countQuery;

  if (countError) throw countError;

  // Calculate total pages
  const totalPages = Math.ceil(count / limit);

  // Adjust page if it exceeds total pages
  page = Math.min(page, Math.max(1, totalPages));

  // Calculate the range for pagination
  const from = (page - 1) * limit;
  const to = from + limit - 1;

  // Build the main query
  let query = supabase
    .from('notifications')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .range(from, to);

  // Apply filter if not 'all'
  if (filter === 'read') {
    query = query.not('read_at', 'is', null);
  } else if (filter === 'unread') {
    query = query.is('read_at', null);
  }

  const { data: notifications, error } = await query;

  if (error) {
    throw error;
  }

  return {
    notifications: notifications || [],
    totalPages,
    currentPage: page,
    totalCount: count,
    hasMore: page < totalPages,
    hasPrevious: page > 1,
  };
}

export async function getRecentNotifications({ userId, limit = 5 } = {}) {
  if (!userId) throw new Error('User ID is required');

  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(limit);

  if (error) {
    throw error;
  }

  return data;
}

export async function markAsRead(notificationId) {
  const { data, error } = await supabase
    .from('notifications')
    .update({ read_at: new Date().toISOString() })
    .eq('id', notificationId)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

export async function markAllAsRead(userId) {
  const { data, error } = await supabase
    .from('notifications')
    .update({ read_at: getCurrentTimestamp() })
    .eq('user_id', userId)
    .is('read_at', null)
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export async function getUnreadCount(userId) {
  if (!userId) throw new Error('User ID is required');

  const { count, error } = await supabase
    .from('notifications')
    .select('*', { count: 'exact', head: true })
    .eq('user_id', userId)
    .is('read_at', null);

  if (error) {
    throw error;
  }

  return count;
}

// Delete a notification
export async function deleteNotification(notificationId) {
  const { data, error } = await supabase
    .from('notifications')
    .delete()
    .eq('id', notificationId)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

// Subscribe to new notifications
export function subscribeToNotifications(userId, onNotification) {
  return supabase
    .channel('notifications')
    .on(
      'postgres_changes',
      {
        event: '*', // Listen to all events (INSERT, UPDATE, DELETE)
        schema: 'public',
        table: 'notifications',
        filter: `user_id=eq.${userId}`,
      },
      (payload) => {
        onNotification(payload);
      }
    )
    .subscribe();
}
// Insert a notification
export async function insertNotification({
  userId,
  entityType,
  entityId,
  metadata,
  actionType,
  actorId,
}) {
  if (!userId) throw new Error('User ID is required');

  const notification = {
    user_id: userId,
    entity_type: entityType,
    entity_id: entityId,
    metadata: metadata, // { message: string, priority: 'low' | 'medium' | 'high' }
    action_type: actionType,
    actor_id: actorId,
    created_at: getCurrentTimestamp(),
    read_at: null,
  };

  const { data, error } = await supabase
    .from('notifications')
    .insert(notification)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}
