import styled, { StyleSheetManager, keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`;

const getBorderRadius = (radius) => {
  switch (radius) {
    case 'none':
      return '0';
    case 'small':
      return 'var(--border-radius-sm)';
    case 'medium':
      return 'var(--border-radius-md)';
    case 'card':
      return 'var(--border-radius-cd)';
    case 'rounded':
    default:
      return 'var(--border-radius-rd)';
  }
};

const SkeletonContainer = styled.div`
  display: inline-flex;
  justify-content: start;
  gap: 1.2rem;
  flex-wrap: wrap;
`;

const SkeletonItem = styled.div`
  height: ${(props) => (props.height ? props.height : '2rem')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-radius: ${(props) => getBorderRadius(props.radius)};
  background-color: var(--gray-3);
  background-image: linear-gradient(
    110deg,
    transparent 40%,
    color-mix(in srgb, var(--gray-4), transparent 50%) 50%,
    transparent 60%
  );
  background-size: 200% 100%;
  background-position-x: 120%;

  @media (prefers-reduced-motion: no-preference) {
    background-position-x: 180%;
    animation: ${loadingAnimation} 2s ease-in-out infinite;
  }
`;

/**
 *
 * @param {*} param0
 * @param {*} width width number value of the skeleton in percentage default is 100
 * @param {*} height height number value  of the skeleton in rem default is 2
 * @param {*} repeat number of times the skeleton should repeat default is 1
 * @returns
 * A skeleton loading component
 */
const Skeleton = ({
  width = '100%',
  height = '2rem',
  repeat = 1,
  radius = 'rounded',
}) => {
  return (
    <StyleSheetManager
      shouldForwardProp={(prop) =>
        prop !== width || prop !== height || prop !== radius
      }
    >
      <SkeletonContainer>
        {Array.from({ length: repeat }, (_, index) => (
          <SkeletonItem
            height={height}
            width={width}
            key={index}
            radius={radius}
          ></SkeletonItem>
        ))}
      </SkeletonContainer>
    </StyleSheetManager>
  );
};

export default Skeleton;
