import { useDarkMode } from '../../context/DarkModeProvider';
import { Menus } from '../menu/Menus';
import Button from '../buttons/Button';
import { THEME_CONFIG } from '../../context/DarkModeProvider';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../redux/features/profile-slice';
import toast from 'react-hot-toast';
import { useUpdateUser } from '../../api/authentication/user';

const getThemeIcon = (theme) => {
  return THEME_CONFIG[theme]?.icon;
};

const ThemeToggle = () => {
  const { theme, setTheme } = useDarkMode();
  const dispatch = useDispatch();

  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser();

  const handleUpdateTheme = (newTheme) => {
    const previousTheme = theme;

    // Optimistically update the UI
    setTheme(newTheme);
    dispatch(updateProfile({ theme: newTheme }));
    // Only sync with backend if user is logged in
    updateUser({ data: { theme: newTheme } });
  };

  const themeOptions = [
    {
      label: THEME_CONFIG.azure.name,
      icon: getThemeIcon('azure'),
      onClick: () => handleUpdateTheme('azure'),
    },
    {
      label: THEME_CONFIG.emerald.name,
      icon: getThemeIcon('emerald'),
      onClick: () => handleUpdateTheme('emerald'),
    },
    {
      label: THEME_CONFIG.lavender.name,
      icon: getThemeIcon('lavender'),
      onClick: () => handleUpdateTheme('lavender'),
    },

    {
      label: THEME_CONFIG.blush.name,
      icon: getThemeIcon('blush'),
      onClick: () => handleUpdateTheme('blush'),
    },
  ];

  return (
    <Menus
      toggle={
        <Button
          style={{ width: 'max-content' }}
          variant='secondary'
          size='small'
          disabled={isUpdatingUser}
        >
          {THEME_CONFIG[theme]?.name}
          {getThemeIcon(theme)}
        </Button>
      }
      options={themeOptions}
    />
  );
};

export default ThemeToggle;
