import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

import useOutsideClick from '../../hooks/useOutsideClick';
import { media } from '../../styles/ResponsiveDesign';
import Spinner from '../loading/Spinner';

const dropList = keyframes`
  0%{
      transform:  scale(0);
    }

    96%{
      transform:  scale(1.05);
    }

    100%{
      transform:  scale(1);
    }
`;

const mobilePopup = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const StyledList = styled.ul`
  position: fixed;
  background-color: var(--gray-1);
  box-shadow: var(--box-shadow-md);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 2px 10px var(--black-a3);

  overflow: hidden;
  z-index: 1000;

  right: ${(props) => props.$rect.x}px;
  top: ${(props) => props.$rect.y}px;
  transform-origin: top;

  animation: ${dropList} 200ms ease;

  ${media.mobile`
    padding: var(--padding);
    width: 100%;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: var(--border-radius-cd);
    border-top-right-radius: var(--border-radius-cd);
    animation: ${mobilePopup} 300ms ease;
    transform-origin: bottom;
  `}
`;

const StyledButton = styled.button`
  padding: var(--padding);
  min-width: 17rem;
  height: 4rem;
  min-height: 4rem;
  width: 100%;
  text-align: left;
  background: none;
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  transition: all 0.2s ease;
  color: var(--gray-12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  transition: all 200ms ease;

  ${media.mobile`
    border-radius: 0;
    padding: 0;
  `}

  &:not(:last-child) {
    box-shadow: 0px 0.5px 0px 0px var(--gray-7);
  }

  &:hover {
    background-color: var(--gray-a5);
  }

  &:focus {
    outline: none;
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--label-sec);
  }

  &:hover svg {
    color: var(--label-pri);
  }
`;

const MenuContext = createContext();

const Menu = ({ children }) => {
  const [open, setOpen] = useState('');
  const [position, setPosition] = useState({});
  const [listRect, setListRect] = useState({
    width: null,
    height: null,
  });

  const closeMenu = () => setOpen('');

  return (
    <MenuContext.Provider
      value={{
        open,
        setOpen,
        closeMenu,
        position,
        setPosition,
        listRect,
        setListRect,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

const Toggle = ({ menuName, children }) => {
  const { open, closeMenu, setPosition, setOpen } = useContext(MenuContext);

  const toggleRef = useRef();

  const updatePosition = useCallback(() => {
    const rect = toggleRef.current.getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let posX = viewportWidth - rect.right;
    let posY = rect.bottom + 8; // Slight offset 8px from the bottom of the toggle

    const listRect = { height: 100 }; // Placeholder for actual list dimensions

    if (viewportHeight - rect.bottom < listRect.height) {
      posY = rect.top - listRect.height - 8; // Adjust based on the actual height of the list
    }

    posX = Math.max(0, posX); // Prevents off-screen to the left
    posY = Math.max(0, posY); // Prevents off-screen to the top

    setPosition({
      x: posX,
      y: posY,
    });
  }, [setPosition]); // Dependencies

  useEffect(() => {
    // Cleanup scroll listener when the component unmounts or menu closes
    return () => window.removeEventListener('scroll', updatePosition);
  }, [updatePosition]); // this runs on mount and unmount

  const handleToggle = (e) => {
    e.stopPropagation();

    updatePosition(); // Update immediately on toggle

    if (menuName === '' || menuName !== open) {
      setOpen(menuName);
      window.addEventListener('scroll', updatePosition);
    } else {
      closeMenu();
      window.addEventListener('scroll', updatePosition);
    }
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      ref={toggleRef}
      onClick={(e) => handleToggle(e)}
    >
      {children}
    </div>
  );
};

const List = ({ children, menuName }) => {
  const { closeMenu, open, position, listRect, setListRect } =
    useContext(MenuContext);

  const ref = useOutsideClick(closeMenu, false);

  // This effect runs when `open` changes. It measures and positions the list.
  useEffect(() => {
    // Ensures the component is visible before measuring
    const timer = setTimeout(() => {
      if (open === menuName && ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();
        setListRect({ width, height });
      }
    }, 201); //Since animation reaction time is set to 200

    return () => {
      clearTimeout(timer);
    };
  }, [open, menuName, ref, setListRect]);

  const isOpen = open === menuName;

  if (!isOpen) return null;

  return createPortal(
    <StyledList ref={ref} $rect={position}>
      {children}
    </StyledList>,
    document.body
  );
};

const Button = ({
  children,
  icon,
  onClick,
  disabled,
  closeOnAction = true,
  isLoading = false,
}) => {
  const { closeMenu } = useContext(MenuContext);

  const handleClick = () => {
    onClick && onClick();

    if (closeOnAction) {
      closeMenu();
    }
  };
  return (
    <StyledButton disabled={disabled || isLoading} onClick={handleClick}>
      <span>{children}</span>
      {icon && !isLoading ? icon : null}
      {isLoading && <Spinner size='xsmall' />}
    </StyledButton>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a Menu provider');
  }
  return context;
};

Menu.Toggle = Toggle;
Menu.List = List;
Menu.Button = Button;

export default Menu;
