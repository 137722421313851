import { useState, useCallback, useEffect } from 'react';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { useEditor } from '../../context/EditorContext';
import { HiOutlinePhoto } from 'react-icons/hi2';
import { PromptForm } from './PromptForm';
import styled from 'styled-components';
import { TextFade } from '../../ui/typography/Text';
import { isURL } from 'validator';
import { useToast } from '../../context/ToastProvider';
import { ToolButton } from '../../ui/layout/Card';
import useIsMounted from '../../hooks/useIsMounted';
import { ShowPopup, usePopup } from '../../ui/modal/Popup';
import { EditorToolButton } from './ToolCategories';
import { InfoTip } from '../../ui/notifications/InfoTip';

const MediaWrapper = styled.figure`
  position: relative;
`;

const EmbedImage = styled.img`
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  max-width: 100%;
  height: auto;
  margin: 1.6rem 0;
`;

export const Media = (props) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, alt } = entity.getData();
  const type = entity.getType();

  const handleMediaError = (e) => {
    const error = e.target.error || {};
    let errorMsg = 'Unknown error occurred';

    switch (error.code) {
      case error.MEDIA_ERR_ABORTED:
        errorMsg = 'You aborted the media playback.';
        break;
      case error.MEDIA_ERR_NETWORK:
        errorMsg = 'A network error caused the media download to fail.';
        break;
      case error.MEDIA_ERR_DECODE:
        errorMsg =
          'The media playback was aborted due to corruption or unsupported features.';
        break;
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        errorMsg = 'The media format is not supported by your browser.';
        break;
      default:
        errorMsg = 'An unknown error occurred.';
    }

    setHasError(true);
    setErrorMessage(errorMsg);
  };

  let content;

  switch (type) {
    case 'image':
      content = (
        <MediaWrapper>
          <EmbedImage
            src={src}
            alt='blog-embed-image'
            onError={handleMediaError}
          />
          <TextFade>{alt}</TextFade>
        </MediaWrapper>
      );
      break;
    case 'video':
      content = (
        <iframe
          width='560'
          height='315'
          src={src}
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      );
      break;
    case 'audio':
      content = (
        <audio controls onError={handleMediaError}>
          <source src={src} type='audio/mpeg' />
          <source src={src} type='audio/ogg' />
          <source src={src} type='audio/wav' />
          Your browser does not support the audio tag or the audio format.
        </audio>
      );
      break;
    default:
      content = null;
  }

  return content;
};

const MediaEditor = () => {
  const { editorState, setEditorState, setShouldFocus } = useEditor();
  const { toast } = useToast();
  const isMounted = useIsMounted();
  const { closePopup } = usePopup();
  const [mediaState, setMediaState] = useState({
    urlValue: '',
    altText: '',
    isImageValid: false,
  });
  const [urlType, setUrlType] = useState('');

  function handleImageLoad() {
    if (isMounted()) {
      setMediaState({ ...mediaState, isImageValid: true });
    }
  }
  function handleImageError() {
    if (isMounted()) {
      setMediaState({ ...mediaState, isImageValid: false });
    }
  }

  useEffect(() => {
    //Revalidate the image url
    if (mediaState?.urlValue) {
      const img = new Image();
      img.src = mediaState.urlValue;
      img.onload = handleImageLoad;
      img.onerror = handleImageError;
    }
  }, [mediaState?.urlValue]);

  const confirmMedia = (e) => {
    e.preventDefault();

    if (
      !mediaState.urlValue ||
      !isURL(mediaState.urlValue) ||
      !mediaState.isImageValid
    ) {
      toast({ type: 'danger', message: 'Please enter a valid url' });
      return;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      urlType,
      'IMMUTABLE',
      { src: mediaState.urlValue, alt: mediaState.altText }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      // The third parameter here is a space string, not an empty string
      //Setting an empty string, will show an error: Unknown DraftEntity key: null
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    );

    setMediaState({ urlValue: '', altText: '' });

    setShouldFocus(true);
    closePopup();
  };

  const promptForMedia = (type) => {
    setMediaState({ urlValue: '', altText: '' });
    setUrlType(type);
    setShouldFocus(false);
  };

  const addAudio = useCallback(() => promptForMedia('audio'), []);
  const addImage = useCallback(() => promptForMedia('image'), []);
  const addVideo = useCallback(() => promptForMedia('video'), []);

  return (
    <>
      <ShowPopup
        button={
          <InfoTip tip='Embed Image'>
            <EditorToolButton variant='gray'>
              <HiOutlinePhoto />
            </EditorToolButton>
          </InfoTip>
        }
        onClick={addImage}
        close={false}
        position='top'
        component={
          <PromptForm
            mediaType='media'
            onSubmit={confirmMedia}
            promptState={mediaState}
            setPromptState={setMediaState}
            placeholder={`Enter ${urlType} URL...`}
            handleImageLoad={handleImageLoad}
            handleImageError={handleImageError}
          />
        }
      />

      {/* <ToolButton
        variant='secondary'
        icon={<HiOutlineUpload />}
        name='Upload File'
      /> */}
    </>
  );
};

export default MediaEditor;
