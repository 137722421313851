import { HiOutlineLockClosed } from 'react-icons/hi2';
import { useNavigation } from '../../hooks/useNavigation';
import { StyledBody } from '../../styles/GeneralStyling';
import StateDisplay from '../notifications/StateDisplay';
import { HiOutlineHome } from 'react-icons/hi';
import LandingLayout from './LandingLayout';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../redux/features/auth-slice';
import useRoleCheck from '../../hooks/useRoleCheck';

const Unauthorized = () => {
  const { navigateToPath } = useNavigation();
  const { isAuthenticated } = useAuth();
  const { isAccountActive } = useRoleCheck();
  const location = useLocation();

  // Check if we got here naturally (through navigation state) or directly
  const from = location.state?.from;

  // If user is authenticated and active, and they accessed this page directly
  // (not redirected from another route), send them to discover
  if (isAuthenticated && isAccountActive && !from) {
    return <Navigate to='/blog/discover' replace />;
  }

  return (
    <LandingLayout>
      <StyledBody>
        <StateDisplay
          variant='error'
          title='Members-Only Content'
          description='This area is reserved for registered members. Sign in to access exclusive content, or head back to explore our public articles.'
          action={{
            label: 'Return to Homepage',
            onClick: () => navigateToPath('/blog/discover', { replace: true }),
            icon: <HiOutlineHome />,
          }}
          icon={<HiOutlineLockClosed />}
        />
      </StyledBody>
    </LandingLayout>
  );
};

export default Unauthorized;
