import styled, { StyleSheetManager, css } from 'styled-components';
import ButtonIcon from '../../ui/buttons/ButtonIcon';
import { HiOutlinePencil } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { media } from '../../styles/ResponsiveDesign';
import { InfoTip } from '../../ui/notifications/InfoTip';
import { useSelector } from 'react-redux';
import { useNavigation } from '../../hooks/useNavigation';

const size = {
  small: css`
    height: 3rem;
    width: 3rem;
    font-size: 1.2rem;
  `,

  medium: css`
    height: 3.6rem;
    width: 3.6rem;
    font-size: 1.4rem;
  `,

  large: css`
    height: 4.4rem;
    width: 4.4rem;
    font-size: 1.6rem;
  `,
};

const StyledAvatar = styled.div`
  ${(props) => size[props.size]}
  cursor: pointer;
  border-radius: var(--border-radius-rd);
  display: grid;
  place-items: center;
  position: relative;
  color: var(--color-9);
  background: var(--color-3);
  border: 1px solid var(--color-6);
  transition: all 200ms ease;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    background: var(--color-4);
    border-color: var(--color-7);
  }

  &:active {
    background: var(--color-5);
    border-color: var(--color-7);
    transform: translateY(0);
  }

  ${media.smScreen`
    height: 3rem;
    width: 3rem;
  `}

  & button {
    height: 2rem;
    width: 2rem;
    position: absolute;
    display: grid;
    place-items: center;
    right: -0.5rem;
    bottom: -0.5rem;
    background-color: var(--color-4);
    border-radius: 50%;
    border: 2px solid var(--color-6);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: var(--color-5);
      transform: scale(1.1);
    }

    & svg {
      height: 65%;
      width: 65%;
      color: var(--color-9);
    }
  }
`;

StyledAvatar.defaultProps = { size: 'medium' };

const Avatar = ({ edit, size, onClick, profile }) => {
  const { navigateToPath } = useNavigation();

  const avatarLabel =
    profile?.first_name && profile?.last_name
      ? profile?.first_name?.[0]?.toUpperCase() +
        profile?.last_name?.[0]?.toUpperCase()
      : 'UN';

  function handleAvatarClick(e) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <StyleSheetManager shouldForwardProp={(props) => props !== 'size'}>
      <StyledAvatar size={size} onClick={handleAvatarClick}>
        {avatarLabel}
        {edit && (
          <ButtonIcon onClick={() => navigateToPath('/profile')}>
            <HiOutlinePencil />
          </ButtonIcon>
        )}
      </StyledAvatar>
    </StyleSheetManager>
  );
};

export default Avatar;
