import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';

// Styled components
const TabListWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'sticky'].includes(prop),
})`
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  top: ${(props) => (props.sticky ? '0' : 'auto')};
  z-index: ${(props) => (props.sticky ? '100' : 'auto')};
  display: flex;
  border-bottom: var(--border-md-int-str);
  flex-wrap: wrap;
  gap: 1.2rem;
  width: max-content;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  background: ${(props) => (props.sticky ? 'var(--gray-a3)' : 'transparent')};
  backdrop-filter: ${(props) => (props.sticky ? 'blur(10px)' : 'none')};
  border-radius: ${(props) => (props.sticky ? 'var(--border-radius-sm)' : '0')};
`;

const TabButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isActive', 'isTabDisabled'].includes(prop),
})`
  padding: 0.75rem 1rem;
  border: none;
  background: none;
  cursor: ${(props) => (props.isTabDisabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => (props.isActive ? 'var(--gray-12)' : 'var(--gray-11)')};
  font-weight: ${(props) => (props.isActive ? '500' : '400')};
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: ${(props) => (props.isTabDisabled ? '0.5' : '1')};

  ${(props) =>
    !props.isTabDisabled &&
    css`
      &:hover {
        color: red;
        color: var(--gray-12);
        & svg {
          color: var(--gray-12);
        }
      }
    `}

  &:focus-visible {
    outline: 2px solid var(--color-9);
    outline-offset: 2px;
    border-radius: 4px;
  }
`;

const TabIndicator = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width', 'offset', 'rtl'].includes(prop),
})`
  position: absolute;
  bottom: -1px;
  left: ${(props) => (props.rtl ? 'auto' : `${props.offset}px`)};
  right: ${(props) => (props.rtl ? `${props.offset}px` : 'auto')};
  width: ${(props) => props.width}px;
  height: 2px;
  background-color: var(--color-9);
  transition: all 0.2s ease;
`;

/**
 * Custom hook for managing tabbed interfaces with animated indicator
 * @param {Object} props - The props object
 * @param {Array<{id: string|number, label: string|ReactNode, icon?: ReactNode}>} props.items - Array of tab items with id, label and optional icon
 * @param {string|number} [props.defaultActiveTab] - ID of initially active tab
 * @param {boolean} [props.rtl=false] - Whether tabs should be rendered right-to-left
 * @returns {Object} Returns object containing:
 * - activeTabId: ID of currently active tab
 * - setActiveTabId: Function to update active tab
 * - TabList: Component that renders the tab list
 * - TabPanel: Component that renders the tab panel
 * - indicatorStyle: Style object for animated indicator
 * - tabRefs: Ref object containing tab element references
 * @example
 * const { TabList, TabPanel, activeTabId } = useTabs({
 *   items: [
 *     { id: 'tab1', label: 'Tab 1' },
 *     { id: 'tab2', label: 'Tab 2' }
 *   ],
 *   defaultActiveTab: 'tab1'
 * });
 *
 * return (
 *   <>
 *     <TabList />
 *     <TabPanel>
 *       {activeTabId === 'tab1' && <Tab1Content />}
 *       {activeTabId === 'tab2' && <Tab2Content />}
 *     </TabPanel>
 *   </>
 * );
 */

export const useTabs = ({
  items,
  defaultActiveTab,
  rtl = false,
  isDisabled = false,
  disabled = [],
  sticky = false,
}) => {
  const [activeTabId, setActiveTabId] = useState(
    defaultActiveTab || items[0]?.id
  );

  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, offset: 0 });
  const tabRefs = useRef(new Map());
  const observerRef = useRef(null);

  const updateIndicator = useCallback((tabId) => {
    const currentTab = tabRefs.current.get(tabId);

    if (currentTab) {
      const { width, left } = currentTab.getBoundingClientRect();

      const parentLeft =
        currentTab.parentElement?.getBoundingClientRect().left || 0;

      const offset = left - parentLeft;
      setIndicatorStyle({ width, offset });
    }
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => updateIndicator(activeTabId);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeTabId, updateIndicator]);

  //ResizeObserver for dynamic content
  useEffect(() => {
    observerRef.current = new ResizeObserver(() => {
      updateIndicator(activeTabId);
    });

    tabRefs.current.forEach((tab) => {
      observerRef.current?.observe(tab);
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [activeTabId, updateIndicator]);

  useEffect(() => {
    // Small timeout to ensure DOM is ready
    const timer = setTimeout(() => {
      updateIndicator(activeTabId);
    }, 0);

    return () => clearTimeout(timer);
  }, [updateIndicator]); // Run once on mount

  useEffect(() => {
    updateIndicator(activeTabId);
  }, [activeTabId, updateIndicator]);

  const handleKeyDown = (e, currentIndex) => {
    const tabIds = items.map((item) => item.id);
    let newIndex = currentIndex;

    switch (e.key) {
      case 'ArrowRight':
        newIndex = rtl ? currentIndex - 1 : currentIndex + 1;
        break;
      case 'ArrowLeft':
        newIndex = rtl ? currentIndex + 1 : currentIndex - 1;
        break;
      default:
        return;
    }

    if (newIndex >= 0 && newIndex < tabIds.length) {
      setActiveTabId(tabIds[newIndex]);
      updateIndicator(tabIds[newIndex]);
    }
  };

  const TabList = useCallback(
    () => (
      <TabListWrapper
        role='tablist'
        aria-orientation='horizontal'
        isDisabled={isDisabled}
        aria-disabled={isDisabled}
        sticky={sticky}
      >
        {items.map((item, index) => {
          const isTabDisabled = disabled.includes(item.id);

          return (
            <TabButton
              key={item.id}
              ref={(el) => el && tabRefs.current.set(item.id, el)}
              isActive={activeTabId === item.id}
              isTabDisabled={isTabDisabled}
              aria-disabled={isTabDisabled}
              onClick={() => {
                if (isDisabled || isTabDisabled) return;
                setActiveTabId(item.id);
                updateIndicator(item.id);
              }}
              onKeyDown={(e) => {
                if (isDisabled || isTabDisabled) return;
                const currentIndex = items.findIndex(
                  (tab) => tab.id === item.id
                );
                let newIndex;

                if (e.key === 'ArrowRight') {
                  newIndex = currentIndex + 1;
                } else if (e.key === 'ArrowLeft') {
                  newIndex = currentIndex - 1;
                } else {
                  return;
                }

                // Check if new index is valid
                if (newIndex >= 0 && newIndex < items.length) {
                  const nextId = items[newIndex].id;
                  setActiveTabId(nextId);
                  updateIndicator(nextId);
                  // Focus the new tab
                  tabRefs.current.get(nextId)?.focus();
                }
              }}
            >
              {item.label}
              {item?.svg && item.svg}
            </TabButton>
          );
        })}
        <TabIndicator
          width={indicatorStyle.width}
          offset={indicatorStyle.offset}
          rtl={rtl}
        />
      </TabListWrapper>
    ),
    [activeTabId, rtl, indicatorStyle, isDisabled, disabled, sticky, items]
  );

  const TabPanel = useCallback(
    () => (
      <div>
        {items.map((item) => (
          <div
            key={item.id}
            role='tabpanel'
            id={`panel-${item.id}`}
            aria-labelledby={`tab-${item.id}`}
            hidden={activeTabId !== item.id}
          >
            {item.content}
          </div>
        ))}
      </div>
    ),
    [activeTabId, items]
  );

  return {
    TabList,
    TabPanel,
    activeTabId,
    setActiveTabId,
  };
};
