import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  HiOutlineBookOpen,
  HiOutlineGlobeAlt,
  HiOutlinePencilSquare,
  HiOutlineUserGroup,
} from 'react-icons/hi2';

// Separate motion components to handle props correctly
const MotionSection = motion(styled.section``);
const MotionDiv = motion(styled.div``);

const AboutSection = () => {
  const stats = [
    { number: '5,000+', label: 'Active Writers', icon: <HiOutlineUserGroup /> },
    {
      number: '10,000+',
      label: 'Stories Published',
      icon: <HiOutlinePencilSquare />,
    },
    {
      number: '50,000+',
      label: 'Monthly Readers',
      icon: <HiOutlineBookOpen />,
    },
    {
      number: '5,000+',
      label: 'Community Members',
      icon: <HiOutlineGlobeAlt />,
    },
  ];

  return (
    <Container id='about'>
      {/* Hero Section */}
      <HeroSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <HeroContent>
          <Overline>Our Story</Overline>
          <Title>Empowering Voices, Connecting Stories</Title>
          <Description>
            Founded in November 2023, BittsBlog emerged from a simple belief:
            every writer deserves a platform that celebrates their unique voice
            while maintaining the highest standards of quality.
          </Description>
        </HeroContent>
      </HeroSection>

      {/* Mission Section */}
      <Section>
        <MotionDiv
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <SectionTitle>Our Mission</SectionTitle>
          <MissionText>
            To create a thriving ecosystem where verified creators can share
            meaningful stories, connect with engaged readers, and grow together
            as a community.
          </MissionText>
        </MotionDiv>
      </Section>

      {/* Stats Section */}

      {/* <StatsSection>
        {stats.map((stat, index) => (
          <StatCard
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <StatNumber>{stat.number}</StatNumber>
            <StatLabel>{stat.label}</StatLabel>
          </StatCard>
        ))}
      </StatsSection> */}

      {/* Values Section */}
      <Section>
        <MotionDiv
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <SectionTitle>Our Values</SectionTitle>
          <ValuesGrid>
            <ValueCard>
              <ValueTitle>Authenticity</ValueTitle>
              <ValueText>
                We celebrate unique voices and original perspectives. Our
                verified creator program ensures every story comes from a place
                of genuine expertise and passion.
              </ValueText>
            </ValueCard>
            <ValueCard>
              <ValueTitle>Excellence</ValueTitle>
              <ValueText>
                Quality isn't just a benchmark—it's our foundation. We provide
                tools and support that help creators craft their best work while
                maintaining high standards.
              </ValueText>
            </ValueCard>
            <ValueCard>
              <ValueTitle>Community</ValueTitle>
              <ValueText>
                We're building more than a platform—we're nurturing a community.
                Here, creators and readers connect, collaborate, and inspire
                each other to grow.
              </ValueText>
            </ValueCard>
            <ValueCard>
              <ValueTitle>Innovation</ValueTitle>
              <ValueText>
                We constantly evolve to meet the needs of modern blogging. Our
                platform combines cutting-edge technology with intuitive design
                to enhance creativity.
              </ValueText>
            </ValueCard>
          </ValuesGrid>
        </MotionDiv>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 8rem;
`;

const HeroSection = styled(MotionSection)`
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-2);
  padding: 8rem 2rem;
`;

const HeroContent = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  text-align: center;
`;

const Overline = styled.div`
  color: var(--color-9);
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: clamp(4rem, 5vw, 5.6rem);
  font-weight: 700;
  margin-bottom: 2.4rem;
  background: linear-gradient(135deg, var(--color-11), var(--color-9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
`;

const Description = styled.p`
  font-size: 2rem;
  color: var(--gray-11);
  line-height: 1.6;
  max-width: 60ch;
  margin: 0 auto;
`;

const Section = styled.section`
  padding: 8rem 2rem;
  max-width: 120rem;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  color: var(--gray-12);
`;

const MissionText = styled.p`
  font-size: 2.4rem;
  color: var(--gray-11);
  line-height: 1.6;
  text-align: center;
  max-width: 80ch;
  margin: 0 auto;
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 3rem;
  max-width: 120rem;
  margin: 0 auto 8rem;
  padding: 0 2rem;
`;

const StatCard = styled(motion.div)`
  text-align: center;
  padding: 3rem;
  border-radius: var(--border-radius-cd);
  background: linear-gradient(145deg, var(--gray-2), var(--gray-1));
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(135deg, var(--color-6), var(--comp-6));
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0.5;
  }
`;

const StatNumber = styled.div`
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(135deg, var(--color-11), var(--comp-9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.8rem;
`;

const StatLabel = styled.div`
  font-size: 1.6rem;
  color: var(--gray-11);
`;
const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 3rem;
`;

const ValueCard = styled.div`
  padding: var(--padding);
  background: var(--gray-2);
  border-radius: var(--border-radius-cd);
  border: 1px solid var(--gray-6);
`;

const ValueTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--gray-12);
  margin-bottom: 1.6rem;
`;

const ValueText = styled.p`
  font-size: 1.6rem;
  color: var(--gray-11);
  line-height: 1.6;
`;

export default AboutSection;
