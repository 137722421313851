export const countries = [
  { id: 'AF', label: 'Afghanistan', icon: '🇦🇫', code: 93, value: 'AF' },
  { id: 'AL', label: 'Albania', icon: '🇦🇱', code: 355, value: 'AL' },
  { id: 'DZ', label: 'Algeria', icon: '🇩🇿', code: 213, value: 'DZ' },
  { id: 'AD', label: 'Andorra', icon: '🇦🇩', code: 376, value: 'AD' },
  { id: 'AO', label: 'Angola', icon: '🇦🇴', code: 244, value: 'AO' },
  { id: 'AG', label: 'Antigua and Barbuda', icon: '🇦🇬', code: 1, value: 'AG' },
  { id: 'AR', label: 'Argentina', icon: '🇦🇷', code: 54, value: 'AR' },
  { id: 'AM', label: 'Armenia', icon: '🇦🇲', code: 374, value: 'AM' },
  { id: 'AU', label: 'Australia', icon: '🇦🇺', code: 61, value: 'AU' },
  { id: 'AT', label: 'Austria', icon: '🇦🇹', code: 43, value: 'AT' },
  { id: 'AZ', label: 'Azerbaijan', icon: '🇦🇿', code: 994, value: 'AZ' },
  { id: 'BS', label: 'Bahamas', icon: '🇧🇸', code: 1, value: 'BS' },
  { id: 'BH', label: 'Bahrain', icon: '🇧🇭', code: 973, value: 'BH' },
  { id: 'BD', label: 'Bangladesh', icon: '🇧🇩', code: 880, value: 'BD' },
  { id: 'BB', label: 'Barbados', icon: '🇧🇧', code: 1, value: 'BB' },
  { id: 'BY', label: 'Belarus', icon: '🇧🇾', code: 375, value: 'BY' },
  { id: 'BE', label: 'Belgium', icon: '🇧🇪', code: 32, value: 'BE' },
  { id: 'BZ', label: 'Belize', icon: '🇧🇿', code: 501, value: 'BZ' },
  { id: 'BJ', label: 'Benin', icon: '🇧🇯', code: 229, value: 'BJ' },
  { id: 'BT', label: 'Bhutan', icon: '🇧🇹', code: 975, value: 'BT' },
  { id: 'BO', label: 'Bolivia', icon: '🇧🇴', code: 591, value: 'BO' },
  {
    id: 'BA',
    label: 'Bosnia and Herzegovina',
    icon: '🇧🇦',
    code: 387,
    value: 'BA',
  },
  { id: 'BW', label: 'Botswana', icon: '🇧🇼', code: 267, value: 'BW' },
  { id: 'BR', label: 'Brazil', icon: '🇧🇷', code: 55, value: 'BR' },
  { id: 'BN', label: 'Brunei', icon: '🇧🇳', code: 673, value: 'BN' },
  { id: 'BG', label: 'Bulgaria', icon: '🇧🇬', code: 359, value: 'BG' },
  { id: 'BF', label: 'Burkina Faso', icon: '🇧🇫', code: 226, value: 'BF' },
  { id: 'BI', label: 'Burundi', icon: '🇧🇮', code: 257, value: 'BI' },
  { id: 'CV', label: 'Cabo Verde', icon: '🇨🇻', code: 238, value: 'CV' },
  { id: 'KH', label: 'Cambodia', icon: '🇰🇭', code: 855, value: 'KH' },
  { id: 'CM', label: 'Cameroon', icon: '🇨🇲', code: 237, value: 'CM' },
  { id: 'CA', label: 'Canada', icon: '🇨🇦', code: 1, value: 'CA' },
  {
    id: 'CF',
    label: 'Central African Republic',
    icon: '🇨🇫',
    code: 236,
    value: 'CF',
  },
  { id: 'TD', label: 'Chad', icon: '🇹🇩', code: 235, value: 'TD' },
  { id: 'CL', label: 'Chile', icon: '🇨🇱', code: 56, value: 'CL' },
  { id: 'CN', label: 'China', icon: '🇨🇳', code: 86, value: 'CN' },
  { id: 'CO', label: 'Colombia', icon: '🇨🇴', code: 57, value: 'CO' },
  { id: 'KM', label: 'Comoros', icon: '🇰🇲', code: 269, value: 'KM' },
  { id: 'CG', label: 'Congo', icon: '🇨🇬', code: 242, value: 'CG' },
  { id: 'CD', label: 'Congo (DRC)', icon: '🇨🇩', code: 243, value: 'CD' },
  { id: 'CR', label: 'Costa Rica', icon: '🇨🇷', code: 506, value: 'CR' },
  { id: 'CI', label: 'Côte d’Ivoire', icon: '🇨🇮', code: 225, value: 'CI' },
  { id: 'HR', label: 'Croatia', icon: '🇭🇷', code: 385, value: 'HR' },
  { id: 'CU', label: 'Cuba', icon: '🇨🇺', code: 53, value: 'CU' },
  { id: 'CY', label: 'Cyprus', icon: '🇨🇾', code: 357, value: 'CY' },
  { id: 'CZ', label: 'Czechia', icon: '🇨🇿', code: 420, value: 'CZ' },
  { id: 'DK', label: 'Denmark', icon: '🇩🇰', code: 45, value: 'DK' },
  { id: 'DJ', label: 'Djibouti', icon: '🇩🇯', code: 253, value: 'DJ' },
  { id: 'DM', label: 'Dominica', icon: '🇩🇲', code: 1, value: 'DM' },
  { id: 'DO', label: 'Dominican Republic', icon: '🇩🇴', code: 1, value: 'DO' },
  { id: 'EC', label: 'Ecuador', icon: '🇪🇨', code: 593, value: 'EC' },
  { id: 'EG', label: 'Egypt', icon: '🇪🇬', code: 20, value: 'EG' },
  { id: 'SV', label: 'El Salvador', icon: '🇸🇻', code: 503, value: 'SV' },
  { id: 'GQ', label: 'Equatorial Guinea', icon: '🇬🇶', code: 240, value: 'GQ' },
  { id: 'ER', label: 'Eritrea', icon: '🇪🇷', code: 291, value: 'ER' },
  { id: 'EE', label: 'Estonia', icon: '🇪🇪', code: 372, value: 'EE' },
  { id: 'ET', label: 'Ethiopia', icon: '🇪🇹', code: 251, value: 'ET' },
  { id: 'FJ', label: 'Fiji', icon: '🇫🇯', code: 679, value: 'FJ' },
  { id: 'FI', label: 'Finland', icon: '🇫🇮', code: 358, value: 'FI' },
  { id: 'FR', label: 'France', icon: '🇫🇷', code: 33, value: 'FR' },
  { id: 'GA', label: 'Gabon', icon: '🇬🇦', code: 241, value: 'GA' },
  { id: 'GM', label: 'Gambia', icon: '🇬🇲', code: 220, value: 'GM' },
  { id: 'GE', label: 'Georgia', icon: '🇬🇪', code: 995, value: 'GE' },
  { id: 'DE', label: 'Germany', icon: '🇩🇪', code: 49, value: 'DE' },
  { id: 'GH', label: 'Ghana', icon: '🇬🇭', code: 233, value: 'GH' },
  { id: 'GR', label: 'Greece', icon: '🇬🇷', code: 30, value: 'GR' },
  { id: 'GD', label: 'Grenada', icon: '🇬🇩', code: 1, value: 'GD' },
  { id: 'GT', label: 'Guatemala', icon: '🇬🇹', code: 502, value: 'GT' },
  { id: 'GN', label: 'Guinea', icon: '🇬🇳', code: 224, value: 'GN' },
  { id: 'GW', label: 'Guinea-Bissau', icon: '🇬🇼', code: 245, value: 'GW' },
  { id: 'GY', label: 'Guyana', icon: '🇬🇾', code: 592, value: 'GY' },
  { id: 'HT', label: 'Haiti', icon: '🇭🇹', code: 509, value: 'HT' },
  { id: 'HN', label: 'Honduras', icon: '🇭🇳', code: 504, value: 'HN' },
  { id: 'HU', label: 'Hungary', icon: '🇭🇺', code: 36, value: 'HU' },
  { id: 'IS', label: 'Iceland', icon: '🇮🇸', code: 354, value: 'IS' },
  { id: 'IN', label: 'India', icon: '🇮🇳', code: 91, value: 'IN' },
  { id: 'ID', label: 'Indonesia', icon: '🇮🇩', code: 62, value: 'ID' },
  { id: 'IR', label: 'Iran', icon: '🇮🇷', code: 98, value: 'IR' },
  { id: 'IQ', label: 'Iraq', icon: '🇮🇶', code: 964, value: 'IQ' },
  { id: 'IE', label: 'Ireland', icon: '🇮🇪', code: 353, value: 'IE' },
  { id: 'IL', label: 'Israel', icon: '🇮🇱', code: 972, value: 'IL' },
  { id: 'IT', label: 'Italy', icon: '🇮🇹', code: 39, value: 'IT' },
  { id: 'JM', label: 'Jamaica', icon: '🇯🇲', code: 1, value: 'JM' },
  { id: 'JP', label: 'Japan', icon: '🇯🇵', code: 81, value: 'JP' },
  { id: 'JO', label: 'Jordan', icon: '🇯🇴', code: 962, value: 'JO' },
  { id: 'KZ', label: 'Kazakhstan', icon: '🇰🇿', code: 7, value: 'KZ' },
  { id: 'KE', label: 'Kenya', icon: '🇰🇪', code: 254, value: 'KE' },
  { id: 'KI', label: 'Kiribati', icon: '🇰🇮', code: 686, value: 'KI' },
  { id: 'KW', label: 'Kuwait', icon: '🇰🇼', code: 965, value: 'KW' },
  { id: 'KG', label: 'Kyrgyzstan', icon: '🇰🇬', code: 996, value: 'KG' },
  { id: 'LA', label: 'Laos', icon: '🇱🇦', code: 856, value: 'LA' },
  { id: 'LV', label: 'Latvia', icon: '🇱🇻', code: 371, value: 'LV' },
  { id: 'LB', label: 'Lebanon', icon: '🇱🇧', code: 961, value: 'LB' },
  { id: 'LS', label: 'Lesotho', icon: '🇱🇸', code: 266, value: 'LS' },
  { id: 'LR', label: 'Liberia', icon: '🇱🇷', code: 231, value: 'LR' },
  { id: 'LY', label: 'Libya', icon: '🇱🇾', code: 218, value: 'LY' },
  { id: 'LI', label: 'Liechtenstein', icon: '🇱🇮', code: 423, value: 'LI' },
  { id: 'LT', label: 'Lithuania', icon: '🇱🇹', code: 370, value: 'LT' },
  { id: 'LU', label: 'Luxembourg', icon: '🇱🇺', code: 352, value: 'LU' },
  { id: 'MG', label: 'Madagascar', icon: '🇲🇬', code: 261, value: 'MG' },
  { id: 'MW', label: 'Malawi', icon: '🇲🇼', code: 265, value: 'MW' },
  { id: 'MY', label: 'Malaysia', icon: '🇲🇾', code: 60, value: 'MY' },
  { id: 'MV', label: 'Maldives', icon: '🇲🇻', code: 960, value: 'MV' },
  { id: 'ML', label: 'Mali', icon: '🇲🇱', code: 223, value: 'ML' },
  { id: 'MT', label: 'Malta', icon: '🇲🇹', code: 356, value: 'MT' },
  { id: 'MH', label: 'Marshall Islands', icon: '🇲🇭', code: 692, value: 'MH' },
  { id: 'MR', label: 'Mauritania', icon: '🇲🇷', code: 222, value: 'MR' },
  { id: 'MU', label: 'Mauritius', icon: '🇲🇺', code: 230, value: 'MU' },
  { id: 'MX', label: 'Mexico', icon: '🇲🇽', code: 52, value: 'MX' },
  { id: 'FM', label: 'Micronesia', icon: '🇫🇲', code: 691, value: 'FM' },
  { id: 'MD', label: 'Moldova', icon: '🇲🇩', code: 373, value: 'MD' },
  { id: 'MC', label: 'Monaco', icon: '🇲🇨', code: 377, value: 'MC' },
  { id: 'MN', label: 'Mongolia', icon: '🇲🇳', code: 976, value: 'MN' },
  { id: 'ME', label: 'Montenegro', icon: '🇲🇪', code: 382, value: 'ME' },
  { id: 'MA', label: 'Morocco', icon: '🇲🇦', code: 212, value: 'MA' },
  { id: 'MZ', label: 'Mozambique', icon: '🇲🇿', code: 258, value: 'MZ' },
  { id: 'MM', label: 'Myanmar', icon: '🇲🇲', code: 95, value: 'MM' },
  { id: 'NA', label: 'Namibia', icon: '🇳🇦', code: 264, value: 'NA' },
  { id: 'NR', label: 'Nauru', icon: '🇳🇷', code: 674, value: 'NR' },
  { id: 'NP', label: 'Nepal', icon: '🇳🇵', code: 977, value: 'NP' },
  { id: 'NL', label: 'Netherlands', icon: '🇳🇱', code: 31, value: 'NL' },
  { id: 'NZ', label: 'New Zealand', icon: '🇳🇿', code: 64, value: 'NZ' },
  { id: 'NI', label: 'Nicaragua', icon: '🇳🇮', code: 505, value: 'NI' },
  { id: 'NE', label: 'Niger', icon: '🇳🇪', code: 227, value: 'NE' },
  { id: 'NG', label: 'Nigeria', icon: '🇳🇬', code: 234, value: 'NG' },
  { id: 'NO', label: 'Norway', icon: '🇳🇴', code: 47, value: 'NO' },
  { id: 'OM', label: 'Oman', icon: '🇴🇲', code: 968, value: 'OM' },
  { id: 'PK', label: 'Pakistan', icon: '🇵🇰', code: 92, value: 'PK' },
  { id: 'PW', label: 'Palau', icon: '🇵🇼', code: 680, value: 'PW' },
  { id: 'PA', label: 'Panama', icon: '🇵🇦', code: 507, value: 'PA' },
  { id: 'PG', label: 'Papua New Guinea', icon: '🇵🇬', code: 675, value: 'PG' },
  { id: 'PY', label: 'Paraguay', icon: '🇵🇾', code: 595, value: 'PY' },
  { id: 'PE', label: 'Peru', icon: '🇵🇪', code: 51, value: 'PE' },
  { id: 'PH', label: 'Philippines', icon: '🇵🇭', code: 63, value: 'PH' },
  { id: 'PL', label: 'Poland', icon: '🇵🇱', code: 48, value: 'PL' },
  { id: 'PT', label: 'Portugal', icon: '🇵🇹', code: 351, value: 'PT' },
  { id: 'QA', label: 'Qatar', icon: '🇶🇦', code: 974, value: 'QA' },
  { id: 'RO', label: 'Romania', icon: '🇷🇴', code: 40, value: 'RO' },
  { id: 'RU', label: 'Russia', icon: '🇷🇺', code: 7, value: 'RU' },
  { id: 'RW', label: 'Rwanda', icon: '🇷🇼', code: 250, value: 'RW' },
  {
    id: 'KN',
    label: 'Saint Kitts and Nevis',
    icon: '🇰🇳',
    code: 1,
    value: 'KN',
  },
  { id: 'LC', label: 'Saint Lucia', icon: '🇱🇨', code: 1, value: 'LC' },
  {
    id: 'VC',
    label: 'Saint Vincent and the Grenadines',
    icon: '🇻🇨',
    code: 1,
    value: 'VC',
  },
  { id: 'WS', label: 'Samoa', icon: '🇼🇸', code: 685, value: 'WS' },
  { id: 'SM', label: 'San Marino', icon: '🇸🇲', code: 378, value: 'SM' },
  {
    id: 'ST',
    label: 'Sao Tome and Principe',
    icon: '🇸🇹',
    code: 239,
    value: 'ST',
  },
  { id: 'SA', label: 'Saudi Arabia', icon: '🇸🇦', code: 966, value: 'SA' },
  { id: 'SN', label: 'Senegal', icon: '🇸🇳', code: 221, value: 'SN' },
  { id: 'RS', label: 'Serbia', icon: '🇷🇸', code: 381, value: 'RS' },
  { id: 'SC', label: 'Seychelles', icon: '🇸🇨', code: 248, value: 'SC' },
  { id: 'SL', label: 'Sierra Leone', icon: '🇸🇱', code: 232, value: 'SL' },
  { id: 'SG', label: 'Singapore', icon: '🇸🇬', code: 65, value: 'SG' },
  { id: 'SK', label: 'Slovakia', icon: '🇸🇰', code: 421, value: 'SK' },
  { id: 'SI', label: 'Slovenia', icon: '🇸🇮', code: 386, value: 'SI' },
  { id: 'SB', label: 'Solomon Islands', icon: '🇸🇧', code: 677, value: 'SB' },
  { id: 'SO', label: 'Somalia', icon: '🇸🇴', code: 252, value: 'SO' },
  { id: 'ZA', label: 'South Africa', icon: '🇿🇦', code: 27, value: 'ZA' },
  { id: 'ES', label: 'Spain', icon: '🇪🇸', code: 34, value: 'ES' },
  { id: 'LK', label: 'Sri Lanka', icon: '🇱🇰', code: 94, value: 'LK' },
  { id: 'SD', label: 'Sudan', icon: '🇸🇩', code: 249, value: 'SD' },
  { id: 'SR', label: 'Suriname', icon: '🇸🇷', code: 597, value: 'SR' },
  { id: 'SZ', label: 'Swaziland', icon: '🇸🇿', code: 268, value: 'SZ' },
  { id: 'SE', label: 'Sweden', icon: '🇸🇪', code: 46, value: 'SE' },
  { id: 'CH', label: 'Switzerland', icon: '🇨🇭', code: 41, value: 'CH' },
  { id: 'SY', label: 'Syria', icon: '🇸🇾', code: 963, value: 'SY' },
  { id: 'TW', label: 'Taiwan', icon: '🇹🇼', code: 886, value: 'TW' },
  { id: 'TJ', label: 'Tajikistan', icon: '🇹🇯', code: 992, value: 'TJ' },
  { id: 'TZ', label: 'Tanzania', icon: '🇹🇿', code: 255, value: 'TZ' },
  { id: 'TH', label: 'Thailand', icon: '🇹🇭', code: 66, value: 'TH' },
  { id: 'TL', label: 'Timor-Leste', icon: '🇹🇱', code: 670, value: 'TL' },
  { id: 'TG', label: 'Togo', icon: '🇹🇬', code: 228, value: 'TG' },
  { id: 'TO', label: 'Tonga', icon: '🇹🇴', code: 676, value: 'TO' },
  { id: 'TT', label: 'Trinidad and Tobago', icon: '🇹🇹', code: 1, value: 'TT' },
  { id: 'TN', label: 'Tunisia', icon: '🇹🇳', code: 216, value: 'TN' },
  { id: 'TR', label: 'Turkey', icon: '🇹🇷', code: 90, value: 'TR' },
  { id: 'TM', label: 'Turkmenistan', icon: '🇹🇲', code: 993, value: 'TM' },
  { id: 'TV', label: 'Tuvalu', icon: '🇹🇻', code: 688, value: 'TV' },
  { id: 'UG', label: 'Uganda', icon: '🇺🇬', code: 256, value: 'UG' },
  { id: 'UA', label: 'Ukraine', icon: '🇺🇦', code: 380, value: 'UA' },
  {
    id: 'AE',
    label: 'United Arab Emirates',
    icon: '🇦🇪',
    code: 971,
    value: 'AE',
  },
  { id: 'GB', label: 'United Kingdom', icon: '🇬🇧', code: 44, value: 'GB' },
  { id: 'US', label: 'United States', icon: '🇺🇸', code: 1, value: 'US' },
  { id: 'UY', label: 'Uruguay', icon: '🇺🇾', code: 598, value: 'UY' },
  { id: 'UZ', label: 'Uzbekistan', icon: '🇺🇿', code: 998, value: 'UZ' },
  { id: 'VU', label: 'Vanuatu', icon: '🇻🇺', code: 678, value: 'VU' },
  { id: 'VE', label: 'Venezuela', icon: '🇻🇪', code: 58, value: 'VE' },
  { id: 'VN', label: 'Vietnam', icon: '🇻🇳', code: 84, value: 'VN' },
  { id: 'YE', label: 'Yemen', icon: '🇾🇪', code: 967, value: 'YE' },
  { id: 'ZM', label: 'Zambia', icon: '🇿🇲', code: 260, value: 'ZM' },
  { id: 'ZW', label: 'Zimbabwe', icon: '🇿🇼', code: 263, value: 'ZW' },
];
