import styled, { StyleSheetManager, keyframes, css } from 'styled-components';
import Overlay, { overlayVariants } from '../modal/Overlay';

const fade = keyframes`
  from { opacity: 1 }
  to { opacity: 0.25 }
`;

const size = {
  xsmall: '1.6rem',
  small: '2rem',
  medium: '2.4rem',
  large: '2.8rem',
  xlarge: '3.2rem',
};

const SpinnerContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})`
  position: relative;
  width: ${(props) => size[props.size] || size.medium};
  height: ${(props) => size[props.size] || size.medium};
  display: inline-block;
  background: transparent;
  pointer-events: none;
  z-index: 1;

  & > div {
    width: 6%;
    height: 16%;
    background: var(--gray-12);
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: var(--box-shadow-sm);
    animation: ${fade} 1s linear infinite;
    z-index: 2;
  }

  & > div:nth-child(-n + 12) {
    transform: rotate(calc(-30deg * var(--i))) translate(0, -130%);
    animation-delay: calc(-0.0833s * var(--i));
  }

  ${(props) =>
    (props.size === 'xsmall' || props.size === 'small') &&
    css`
      & > div {
        width: 8%;
        height: 20%;
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      & > div {
        width: 6%;
        height: 16%;
      }
    `}

  ${(props) =>
    (props.size === 'large' || props.size === 'xlarge') &&
    css`
      & > div {
        width: 4%;
        height: 12%;
      }
    `}
`;

/**
 * @param {Object} props
 * @param {('xsmall'|'small'|'medium'|'large'|'xlarge')} [props.size='small'] - Size of the spinner
 * @returns {JSX.Element}
 */
const Spinner = ({ size = 'medium' }) => {
  const divs = Array.from({ length: 12 }, (_, i) => (
    <div key={i} style={{ '--i': i }}></div>
  ));

  return <SpinnerContainer size={size}>{divs}</SpinnerContainer>;
};

export default Spinner;

export const FullScreenLoading = () => {
  return (
    <Overlay
      variants={overlayVariants}
      initial='initial'
      animate='animate'
      exit='exit'
      transition={{ duration: 0.2 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
        }}
      >
        <Spinner size='xlarge' />
      </div>
    </Overlay>
  );
};

/*
 Explanation of Each Part:
& > div:nth-child(-n + 12):

& typically refers to the parent selector in a CSS preprocessor like Sass or Less. In plain CSS, this would be part of a rule within a specific context defined by the parent selector.
> is the child combinator, meaning that the styling will apply only to direct children of the parent element referenced by &.
div:nth-child(-n + 12) selects <div> elements that are among the first 12 children of their parent. nth-child(-n + 12) can be a bit tricky:
n is a counter starting from 0.
-n + 12 computes to 12, 11, 10, ..., down to 1 as n increases from 0 upwards. This effectively selects the first 12 child elements.
transform: rotate(calc(-30deg * var(--i))) translate(0, -130%);:

transform property applies two transformations:
rotate(calc(-30deg * var(--i))): This rotates an element by a multiple of -30 degrees. The multiplier var(--i) is expected to be a custom property that should be defined in the element or inherited from its parents. For instance, if --i is 1, the rotation is -30 degrees; if --i is 2, it's -60 degrees, and so on.
translate(0, -130%): This moves the element upward by 130% of its own height. The 0 indicates there is no horizontal movement.
The combination of these transformations can be used to create effects like fan-out animations or radial positioning.
animation-delay: calc(-0.0833s * var(--i));:

animation-delay sets a delay before the animation starts.
calc(-0.0833s * var(--i)): This calculation creates a staggered delay effect for animations. Each element with a different --i value will start its animation at different times. If --i is 1, the delay is -0.0833s, which effectively makes the animation start sooner than it would without the delay. For --i as 2, the delay is -0.1666s, and so forth.
 */
