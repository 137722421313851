import Button from '../../ui/buttons/Button';
import { Link } from 'react-router-dom';
import { ButtonText } from '../../ui/buttons/ButtonText';
import { TextFade, TextSm } from '../../ui/typography/Text';
import {
  StyledAuthContainer,
  StyledForm,
  StyledFooter,
} from '../../ui/forms/StyledAuthContainer';
import { useState } from 'react';
import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { useSignup } from '../../api/authentication/signup';
import toast from 'react-hot-toast';
import { usePasswordValidation } from '../../auth/usePasswordValidation';
import PasswordStrengthIndicator from '../../auth/PasswordStrengthIndicator';
import { FlexRow } from '../../ui/layout/Flex';
import { HiOutlineEnvelope, HiOutlineLockClosed } from 'react-icons/hi2';
import { Menus } from '../../ui/menu/Menus';
import { isEmail } from 'validator';

const SignupForm = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    repeatPassword: '',
  });
  function resetFormState() {
    setFormState({ email: '', password: '', repeatPassword: '' });
  }

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  }

  const { isLoading: isSigningUp, mutate: signupUser } = useSignup();

  const { validations, strength, isValid, config } = usePasswordValidation(
    formState.password
  );

  const isFormFilled = Object.values(formState).every((value) => value !== '');

  function validateForm() {
    if (!isFormFilled) {
      return { isValid: false, message: 'Please fill in all fields' };
    }
    if (!isValid) {
      return { isValid: false, message: 'Please enter a valid password' };
    }
    if (formState.password !== formState.repeatPassword) {
      return { isValid: false, message: 'Passwords do not match' };
    }

    if (!isEmail(formState.email)) {
      return { isValid: false, message: 'Please enter a valid email address' };
    }

    return { isValid: true };
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const { isValid, message } = validateForm();
    if (!isValid) return toast(message, { type: 'warning' });

    signupUser(
      { email: formState.email, password: formState.password },
      {
        onSuccess: () => {
          resetFormState();
        },
      }
    );
  };

  return (
    <StyledAuthContainer>
      <StyledForm onSubmit={handleOnSubmit}>
        <FlexRow justify='between'>
          <TextFade>Already have an account?</TextFade>
          <ButtonText type='button' as={Link} to='/login'>
            Log In
          </ButtonText>
        </FlexRow>

        <StyledInputContainer>
          <HiOutlineEnvelope />
          <Input
            onChange={handleOnChange}
            type='text'
            autoComplete='on'
            placeholder='Email'
            disabled={isSigningUp}
            name='email'
            id={'email'}
            value={formState.email}
            required
          />
          <InputLabel>Email</InputLabel>
        </StyledInputContainer>

        <Menus
          toggle={
            <StyledInputContainer>
              <HiOutlineLockClosed />
              <Input
                value={formState.password}
                onChange={handleOnChange}
                id={'password'}
                type='password'
                autoComplete='off'
                placeholder='Password'
                name='password'
                disabled={isSigningUp}
                required
              />
              <InputLabel>Password</InputLabel>
            </StyledInputContainer>
          }
        >
          <PasswordStrengthIndicator
            validations={validations}
            strength={strength}
            config={config}
          />
        </Menus>

        <StyledInputContainer>
          <HiOutlineLockClosed />
          <Input
            value={formState.repeatPassword}
            onChange={handleOnChange}
            type='password'
            autoComplete='off'
            placeholder='Repeat password'
            name='repeatPassword'
            id={'repeatPassword'}
            disabled={isSigningUp || !isValid}
            required
            error={
              formState.repeatPassword &&
              formState.password !== formState.repeatPassword
            }
          />
          <InputLabel>Repeat password</InputLabel>
        </StyledInputContainer>

        <Button type='submit' isLoading={isSigningUp} disabled={isSigningUp}>
          Create Account
        </Button>
      </StyledForm>
      <StyledFooter>
        <TextSm color='var(--gray-11)' align='center'>
          By clicking Create Account, you agree to our{' '}
          <ButtonText type='button' as={Link} to='/terms'>
            Terms and Conditions
          </ButtonText>{' '}
          and{' '}
          <ButtonText type='button' as={Link} to='/privacy'>
            Privacy Policy.
          </ButtonText>
        </TextSm>
      </StyledFooter>
    </StyledAuthContainer>
  );
};

export default SignupForm;
