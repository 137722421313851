import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { StyledAuthContainer } from '../../ui/forms/StyledAuthContainer';
import { TextBase } from '../../ui/typography/Text';
import { ButtonText } from '../../ui/buttons/ButtonText';
import { Link } from 'react-router-dom';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import supabase from '../../services/supabase';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { useNavigation } from '../../hooks/useNavigation';
import StateDisplay from '../../ui/notifications/StateDisplay';
import Spinner from '../../ui/loading/Spinner';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { getCurrentTimestamp } from '../../utils/helpers';
import { Input, StyledInputContainer } from '../../ui/forms/StyledInput';
import Button from '../../ui/buttons/Button';
import { useVerifyOTP } from '../../auth/useAuth';
import { usePageTitle } from '../../hooks/usePageTitle';

const VerifyEmailForm = () => {
  usePageTitle('Verify Email');
  const location = useLocation();
  const { navigateToPath } = useNavigation();
  const { mutate: verifyOTP, isLoading: verifyOTPLoading } = useVerifyOTP();
  const { email, from, resendType } = location?.state ?? {};
  const COOLDOWN_DURATION = 60;
  const MESSAGE_DURATION = 3000;
  const [cooldownTime, setCooldownTime] = useLocalStorage(
    'verificationCooldown',
    0
  );
  const [showStatus, setShowStatus] = useState(false);
  const [attempts, setAttempts] = useLocalStorage('verificationAttempts', 0);
  const [lastResetTime, setLastResetTime] = useLocalStorage(
    'verificationResetTime',
    null
  );

  const [token, setToken] = useState('');

  // Cooldown durations in seconds
  const COOLDOWN_DURATIONS = {
    0: 60, // 1st attempt: 1 minute
    1: 120, // 2nd attempt: 2 minutes
    2: 300, // 3rd attempt: 5 minutes
    3: 600, // 4th attempt: 10 minutes
    default: 1800, // Subsequent attempts: 30 minutes
  };

  function handleTokenHashChange(e) {
    setToken(e.target.value);
  }

  // Validate the OTP code
  function validateCode() {
    if (token.length !== 6) {
      return { isValid: false, message: 'Please enter a 6-digit code' };
    }

    if (isNaN(token)) {
      return { isValid: false, message: 'Please enter a valid code' };
    }

    return { isValid: true, message: '' };
  }

  // Generate the authentication configuration based on the from state
  const generateAuthConfig = useCallback(() => {
    if (from === '/login') {
      return { path: '/verify-success', type: 'signup' };
    }
    if (from === '/reset-password') {
      return { path: '/update-password', type: 'recovery' };
    }
    if (from === '/signup') {
      return { path: '/verify-success', type: 'signup' };
    }

    return { path: '/login', type: 'signup' };
  }, [from]);

  // Handle the OTP code submission
  function handleCodeSubmit(e) {
    if (!email || !from) {
      return;
    }
    e.preventDefault();
    const { isValid, message } = validateCode();
    //Validate the OTP code
    if (!isValid) {
      toast(message, { type: 'warning' });
      return;
    }

    verifyOTP(
      { email: email, token, type: generateAuthConfig().type },
      {
        onSuccess: () => {
          const { path, type } = generateAuthConfig();
          toast.success('User verified successfully!');

          // Navigate to the appropriate page based on the from state
          navigateToPath(path, {
            replace: true,
            state: { email, from, type },
          });
        },
        onError: (error) => {
          toast.error('Failed to verify OTP');
        },
      }
    );
  }

  // Reset attempts after 24 hours
  useEffect(() => {
    if (!lastResetTime) return;

    const RESET_AFTER = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const now = getCurrentTimestamp();

    if (now - lastResetTime > RESET_AFTER) {
      setAttempts(0);
      setLastResetTime(now);
    }
  }, [lastResetTime, setAttempts, setLastResetTime]);

  // Resend the verification email
  const {
    mutate: resendVerification,
    isLoading: resendLoading,
    isError: resendError,
    isSuccess: resendSuccess,
  } = useMutation({
    mutationFn: async () => {
      if (!email) {
        toast('Email is required', { type: 'warning' });
        return;
      }

      if (cooldownTime > 0) {
        toast(`Please wait ${cooldownTime} seconds before trying again`, {
          type: 'warning',
        });
        return;
      }

      const { error } = await supabase.auth.resend({
        type: resendType,
        email: email,
      });

      if (error) {
        if (error.message.includes('rate limit')) {
          // Handle Supabase rate limit
          const newAttempts = attempts + 1;
          setAttempts(newAttempts);
          if (!lastResetTime) setLastResetTime(getCurrentTimestamp());

          // Set progressive cooldown
          const cooldown =
            COOLDOWN_DURATIONS[attempts] || COOLDOWN_DURATIONS.default;
          setCooldownTime(cooldown);
          toast(`Too many attempts. Please wait ${cooldown / 60} minutes.`, {
            type: 'warning',
          });
        }
        toast(error.message, { type: 'warning' });
      }

      // Successful resend
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      const cooldown =
        COOLDOWN_DURATIONS[attempts] || COOLDOWN_DURATIONS.default;
      setCooldownTime(cooldown);

      return true;
    },
    onSuccess: () => {
      toast.success('Verification email sent successfully!');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // Auto-trigger resend if coming from login
  useEffect(() => {
    if (from === '/login' && email) {
      toast.dismiss();
      resendVerification();
    }
  }, [from, resendVerification, email]);

  useEffect(() => {
    if (!email && !from) {
      navigateToPath('/signup');
    }
  }, [navigateToPath]);

  // Add countdown timer effect
  useEffect(() => {
    if (cooldownTime <= 0) return;

    const timer = setInterval(() => {
      setCooldownTime((current) => {
        if (current <= 0) {
          clearInterval(timer);
          return 0;
        }
        return current - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [cooldownTime, setCooldownTime]);

  // Cleanup on unmount or when status changes
  useEffect(() => {
    if (!showStatus) return;

    const timer = setTimeout(() => {
      setShowStatus(false);
    }, MESSAGE_DURATION);

    return () => clearTimeout(timer);
  }, [showStatus]);

  if (resendLoading && from === '/login' && email) {
    return (
      <StyledAuthContainer>
        <StateDisplay
          variant='general'
          title='Sending Verification Email'
          description={
            <>
              Sending verification email to:
              <EmailText>{email}</EmailText>
              <Spinner />
            </>
          }
        />
      </StyledAuthContainer>
    );
  }

  return (
    <StyledAuthContainer>
      <IconWrapper>
        <EnvelopeIcon />
      </IconWrapper>

      <Title>Verify your email</Title>

      <FlexColumn gap={1.6} as='form' onSubmit={handleCodeSubmit}>
        <MessageContainer>
          <TextBase>
            We've just sent a 6-digit code to <EmailText>{email}</EmailText>
            {'. '}
            Please check your email and enter the code to verify your account.
          </TextBase>
        </MessageContainer>

        <StyledInputContainer>
          <Input
            value={token}
            onChange={handleTokenHashChange}
            type='number'
            placeholder='Enter code'
            required
            autoComplete='off'
          />
        </StyledInputContainer>

        <ActionContainer>
          <FlexRow justify='center'>
            <ButtonText
              as='button'
              onClick={() => resendVerification()}
              disabled={resendLoading || cooldownTime > 0}
              type='button'
            >
              {resendLoading
                ? 'Sending...'
                : cooldownTime > 0
                ? `Resend available in ${cooldownTime}s`
                : 'Resend verification email'}
            </ButtonText>
          </FlexRow>

          {cooldownTime > 0 && (
            <ProgressBarContainer>
              <ProgressBar
                duration={COOLDOWN_DURATION}
                remaining={cooldownTime}
              />
            </ProgressBarContainer>
          )}

          <Button
            isLoading={verifyOTPLoading}
            disabled={resendLoading || cooldownTime > 0}
            type='submit'
          >
            Verify Email
          </Button>

          <FlexRow justify='center'>
            <ButtonText as={Link} to='/login' autoComplete='off' type='button'>
              Back to Log In
            </ButtonText>
          </FlexRow>
        </ActionContainer>
      </FlexColumn>
    </StyledAuthContainer>
  );
};

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background: var(--color-3);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 0 auto;
  svg {
    width: 24px;
    height: 24px;
    color: var(--color-11);
  }
`;

const Title = styled(TextBase)`
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--color-12);
  text-align: center;
`;

const MessageContainer = styled.div`
  background: var(--gray-2);
  border-radius: var(--border-radius-md);
`;

const EmailText = styled.span`
  color: var(--blue-9);
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);

  ${ButtonText} {
    color: var(--blue-9);
    font-weight: 500;

    &:hover {
      color: var(--blue-10);
    }
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background: var(--gray-3);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['remaining', 'duration'].includes(prop),
})`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.remaining / props.duration) * 100}%;
  background: var(--blue-9);
  transition: width 1s linear;
  border-radius: var(--border-radius-sm);
`;

export default VerifyEmailForm;
