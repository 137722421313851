import styled, { css } from 'styled-components';
import { HiChevronDown, HiOutlineCheck } from 'react-icons/hi2';
import {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useId } from 'react';
import { fadeIn, fadeOut } from '../ui/menu/Menus';
import { createPortal } from 'react-dom';
import { useMenuPosition } from './useMenuPosition';
import useOutsideClick from './useOutsideClick';
import Skeleton from '../ui/loading/Skeleton';
import { debounce } from 'lodash';

const DropdownContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width', 'disabled'].includes(prop),
})`
  width: ${({ width }) => width || 'max-content'};
  max-width: 100%;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  gap: 0.8rem;
  z-index: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const DropdownButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['size', 'color', 'disabled'].includes(prop),
})`
  position: relative;
  max-width: 100%;
  min-width: 160px;
  height: ${({ size }) => (size === 'small' ? '3.2rem' : '4rem')};
  border: var(--border-md-non);
  border-radius: var(--border-radius-md);
  background-color: var(--gray-3);
  color: ${({ color }) => (color ? 'var(--gray-12)' : 'var(--gray-11)')};
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  padding: 0 2.4rem 0 0.8rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;

  &:hover {
    border: ${({ disabled }) =>
      disabled ? 'var(--border-md-non)' : 'var(--border-md-int)'};
  }

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  .dropdown-arrow {
    position: absolute;
    right: 0.8rem;
    transition: transform 200ms ease;
    &.rotate {
      transform: rotate(180deg);
    }
  }
`;

const SearchOption = styled.div.withConfig({
  shouldForwardProp: (prop) => !['buttonHeight'].includes(prop),
})`
  padding: 0.4rem;
  border-bottom: 1px solid var(--gray-4);
  margin-bottom: 0.4rem;

  input {
    width: 100%;
    height: ${({ buttonHeight }) =>
      buttonHeight === 'small' ? '2.4rem' : '3.2rem'};
    padding: 0 0.8rem;
    border: 1px solid var(--gray-4);
    border-radius: var(--border-radius-md);
    background: var(--gray-2);
    color: inherit;

    &:focus {
      outline: none;
      border-color: var(--gray-6);
    }

    &::placeholder {
      color: var(--gray-11);
    }
  }
`;

const DropdownPortal = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen', 'maxHeight'].includes(prop),
})`
  position: fixed;
  z-index: 1000;
  min-width: 150px;
  background-color: var(--gray-3);
  background: var(--card-gradient);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: 0.4rem;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;

  /* Custom Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--gray-4);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-8);
    border-radius: 100px;
    border: 2px solid var(--gray-4);

    &:hover {
      background: var(--gray-9);
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--gray-8) var(--gray-4);

  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${fadeIn} 0.3s ease-in-out
        `
      : css`
          ${fadeOut} 0.3s ease-in-out
        `};
  transform-origin: ${(props) => props.origin};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;

const StyledDropdown = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  display: grid;
  grid-template-rows: 1fr;
  z-index: 10;
  min-width: 160px;
  max-width: 100%;

  & > * {
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledOption = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size', 'disabled'].includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.8rem;
  cursor: pointer;
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 1.2rem;
    `}
  height: ${(props) => (props.size === 'small' ? '3.2rem' : '4rem')};
  padding: 0 0.8rem;
  border-radius: var(--border-radius-md);
  position: relative;
  transition: all 200ms ease;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none !important;
      cursor: not-allowed !important;
    `}

  &:hover {
    background-color: var(--gray-4);
  }

  & input {
    display: none;
    pointer-events: none;

    &:disabled ~ label {
      color: var(--gray-11);
      opacity: 0.75;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  & label {
    pointer-events: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  & .selected {
    color: var(--gray-12);
    height: 1.6rem;
    width: 1.6rem;
  }
`;

// New dropdown content component
const DropdownContent = forwardRef(
  ({ isOpen, children, position, origin }, ref) => {
    return createPortal(
      <DropdownPortal
        ref={ref}
        isOpen={isOpen}
        style={{ left: position.x, top: position.y }}
        origin={origin}
      >
        {children}
      </DropdownPortal>,
      document.getElementById('menu')
    );
  }
);

/**
 * @param {Object} data
 * @param {Array<{id: string|number, value: string|number, label: string}>} data.options - Array of option objects
 * @param {string|number} data.defaultValue - Default selected value
 * @param {string} data.placeholder - Placeholder text when nothing is selected
 * @param {boolean} data.search - Enable search functionality
 * @param {function} data.onChange - Callback function when selection changes
 * @param {Array<string|number>} data.disabled - Array of disabled option ids
 * @param {boolean} data.isDisabled - Disable the entire dropdown
 * @param {'small'|'medium'} data.size - Size of the dropdown
 * @param {'id'|'value'} data.returnType - Type of value to return on selection
 * @returns {Object} { dropdown: JSX.Element, selected: string|number }
 */
const useDropdown = (data = {}) => {
  const {
    options = [],
    defaultValue = null,
    placeholder = 'Select...',
    search = false,
    onChange,
    disabled = [],
    isDisabled = false,
    buttonHeight = 'medium',
    returnType = 'id',
    excludeSelected = false,
    width,
    buttonStyles = {},
    searchStyles = {},
    maxHeight = '28rem',
  } = data;

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const [searchQuery, setSearchQuery] = useState('');
  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ref = useOutsideClick(closeDropdown);
  const menuRef = useRef(null);
  const dropdownId = useId();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 300),
    []
  );

  const { position, calculateMenuPosition, origin } = useMenuPosition({
    toggleRef: ref,
    menuRef,
    isOpen,
  });

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const filteredOptions = useMemo(() => {
    let filtered = options;
    if (searchQuery) {
      filtered = filtered.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (excludeSelected) {
      filtered = filtered.filter((option) =>
        returnType === 'id' ? option.id !== selected : option.value !== selected
      );
    }
    return filtered;
  }, [searchQuery, options, excludeSelected, selected, returnType]);

  const selectedOption = useMemo(() => {
    return options.find((option) =>
      returnType === 'id' ? option.id === selected : option.value === selected
    );
  }, [selected, options, returnType]);

  const handleToggle = useCallback((e) => {
    e.stopPropagation();
    calculateMenuPosition();
    setIsOpen((prev) => !prev);
  }, []);

  const handleSearchQueryChange = useCallback(
    (e) => {
      e.stopPropagation();
      debouncedSearch(e.target.value);
      setIsOpen(true);
    },
    [debouncedSearch]
  );

  const handleChange = useCallback(
    (option, e) => {
      e.stopPropagation();
      if (
        isDisabled ||
        disabled.includes(returnType === 'id' ? option.id : option.value)
      ) {
        return;
      } else {
        const selectedValue = returnType === 'id' ? option.id : option.value;
        setSelected(selectedValue);
        setSearchQuery('');
        onChange?.(selectedValue);
        setIsOpen(false);
      }
    },
    [disabled, isDisabled, returnType, onChange]
  );

  useEffect(() => {
    const handleEnterPress = (e) => {
      e.stopPropagation();
      if (e.key === 'Enter' && filteredOptions.length === 1) {
        handleChange(filteredOptions[0]);
      }
    };
    document.addEventListener('keydown', handleEnterPress);
    return () => document.removeEventListener('keydown', handleEnterPress);
  }, [filteredOptions, handleChange]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const renderDropdownContent = useCallback(
    () => (
      <DropdownContent
        ref={menuRef}
        isOpen={isOpen}
        position={position}
        origin={origin}
        maxHeight={maxHeight}
      >
        <StyledDropdown width={width} isOpen={isOpen}>
          {search && (
            <SearchOption buttonHeight={buttonHeight}>
              <input
                type='search'
                placeholder='Search...'
                onChange={(e) => debouncedSearch(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                autoComplete='off'
              />
            </SearchOption>
          )}

          {filteredOptions.map((option, index) => {
            const optionDomId = `${dropdownId}-${option.id}`;
            return (
              <StyledOption
                onClick={(e) => handleChange(option, e)}
                key={optionDomId}
                buttonHeight={buttonHeight}
                index={index}
                disabled={disabled.includes(
                  returnType === 'id' ? option.id : option.value
                )}
              >
                <input
                  type='radio'
                  id={optionDomId}
                  name={dropdownId}
                  value={option.value}
                  disabled={disabled.includes(
                    returnType === 'id' ? option.id : option.value
                  )}
                  checked={
                    selected ===
                    (returnType === 'id' ? option.id : option.value)
                  }
                  aria-checked={
                    selected ===
                    (returnType === 'id' ? option.id : option.value)
                  }
                  onChange={(e) => handleChange(option, e)}
                />
                <label htmlFor={optionDomId}>{option.label}</label>
                {selected ===
                  (returnType === 'id' ? option.id : option.value) && (
                  <HiOutlineCheck className='selected' />
                )}
              </StyledOption>
            );
          })}
        </StyledDropdown>
      </DropdownContent>
    ),
    [
      dropdownId,
      isOpen,
      selected,
      filteredOptions,
      buttonHeight,
      disabled,
      returnType,
      handleChange,
      position,
      origin,
      search,
      debouncedSearch,
    ]
  );

  const markup = (
    <DropdownContainer
      onClick={(e) => e.stopPropagation()}
      width={width}
      disabled={isDisabled}
    >
      <DropdownButton
        ref={ref}
        color={!!selected}
        type='button'
        onClick={handleToggle}
        buttonHeight={buttonHeight}
        disabled={isDisabled}
      >
        <span>{selectedOption?.label || placeholder}</span>
        <HiChevronDown
          style={{ pointerEvents: 'none' }}
          className={`dropdown-arrow ${isOpen ? 'rotate' : ''}`}
        />
      </DropdownButton>
      {renderDropdownContent()}
    </DropdownContainer>
  );

  return { markup, selected };
};

export default useDropdown;
