import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';

const initialState = {
  isLoading: false,
  error: null,
  blogDetails: {
    blog_id: nanoid(6),
    title: '',
    summary: '',
    slug: '',
    category: [],
    cover_img_url: '',
    status: 'draft',
    tags: [],
    type: 'single', //or thread
  },
  blogContent: [],

  blogAuthor: {},

  blogSettings: {},
  editorState: {
    isSelectionActive: false,
  },
  editorSettings: {
    isFullScreen: false,
    autoSave: true,
    spellingAndGrammar: true,
    autoCapitalize: 'sentence',
  },
  editorConfig: {
    mode: 'view',
  },
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setBlogDetails: (state, action) => {
      state.blogDetails = action.payload;
    },
    updateBlogDetails: (state, action) => {
      state.blogDetails = { ...state.blogDetails, ...action.payload };
    },

    setBlogSettings: (state, action) => {
      state.blogSettings = action.payload;
    },

    updateBlogSettings: (state, action) => {
      state.blogSettings = { ...state.blogSettings, ...action.payload };
    },

    setBlogContent: (state, action) => {
      state.blogContent = action.payload;
    },

    updateBlogContent: (state, action) => {
      state.blogContent = { ...state.blogContent, ...action.payload };
    },

    setBlogAuthor: (state, action) => {
      state.blogAuthor = action.payload;
    },

    resetBlog: () => initialState,

    resetBlogContent: (state) => {
      state.blogContent = initialState.blogContent;
    },
    resetBlogDetails: (state) => {
      state.blogDetails = initialState.blogDetails;
    },
    resetBlogAuthor: (state) => {
      state.blogAuthor = initialState.blogAuthor;
    },

    resetBlogSettings: (state) => {
      state.blogSettings = initialState.blogSettings;
    },

    setEditorConfig: (state, action) => {
      state.editorConfig = {
        ...state.editorConfig,
        ...action.payload,
      };
    },

    setEditorSettings: (state, action) => {
      state.editorSettings = {
        ...state.editorSettings,
        ...action.payload,
      };
    },
  },
});

export const {
  setEditorConfig,
  resetBlog,
  resetBlogContent,
  setEditorSettings,
  updateBlogDetails,
  resetBlogDetails,
  setBlogContent,
  updateBlogContent,
  setBlogDetails,
  setIsLoading,
  setError,
  setBlogSettings,
  updateBlogSettings,
  setBlogAuthor,
  resetBlogAuthor,
} = blogSlice.actions;

export const useBlogState = () => useSelector((state) => state.blog);

export default blogSlice.reducer;
