import { useMemo } from 'react';

const defaultConfig = {
  minLength: 6,
  requireUpperCase: true,
  requireLowerCase: true,
  requireNumbers: true,
  requireSpecialChars: true,
  specialCharsRegex: /[!@#$%^&*(),.?":{}|<>]/,
};

export const usePasswordValidation = (password = '', userConfig = null) => {
  return useMemo(() => {
    const config = { ...defaultConfig, ...userConfig };

    // Get validation checks
    const validations = {
      hasLength: password.length >= config.minLength,
      ...(config.requireUpperCase && {
        hasUpperCase: /[A-Z]/.test(password),
      }),
      ...(config.requireLowerCase && {
        hasLowerCase: /[a-z]/.test(password),
      }),
      ...(config.requireNumbers && {
        hasNumber: /[0-9]/.test(password),
      }),
      ...(config.requireSpecialChars && {
        hasSpecialChar: config.specialCharsRegex.test(password),
      }),
    };

    // Get enabled validations (filtering out undefined ones)
    const enabledValidations = Object.entries(validations).filter(
      ([, value]) => value !== undefined
    );

    // Calculate strength percentage
    const totalChecks = enabledValidations.length;
    const passedChecks = enabledValidations.filter(
      ([, isValid]) => isValid
    ).length;
    const strength = Math.round((passedChecks / totalChecks) * 100);

    // Password is valid if all enabled validations pass
    const isValid = passedChecks === totalChecks;

    return {
      validations,
      strength,
      isValid,
      config,
    };
  }, [password, userConfig]);
};
