import styled from 'styled-components';
import { motion } from 'framer-motion';
import { HiOutlineCheck, HiOutlineXMark } from 'react-icons/hi2';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  padding: 4px;
`;

const StrengthBar = styled(motion.div)`
  height: 0.4rem;
  border-radius: var(--border-radius-rd);
  background: var(--gray-4);
  overflow: hidden;
`;

const StrengthIndicator = styled(motion.div)`
  height: 100%;
  border-radius: var(--border-radius-rd);
  background: ${({ $percentage }) =>
    $percentage <= 25
      ? 'var(--red-9)'
      : $percentage <= 50
      ? 'var(--orange-9)'
      : $percentage <= 75
      ? 'var(--blue-9)'
      : 'var(--green-9)'};
`;

const ValidationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--gap-sm);
  list-style: none;
`;

const ValidationItem = styled.li`
  display: flex;
  font-size: 1rem;
  align-items: center;
  gap: var(--gap-sm);
  color: var(--gray-11);

  svg {
    height: 1.4rem;
    width: 1.4rem;
    color: ${({ $isValid }) => ($isValid ? 'var(--green-9)' : 'var(--red-9)')};
  }
`;

const PasswordStrengthIndicator = ({ validations, strength, config }) => {
  return (
    <Container>
      <StrengthBar>
        <StrengthIndicator
          $percentage={strength}
          initial={{ width: 0 }}
          animate={{ width: `${strength}%` }}
          transition={{ duration: 0.3 }}
        />
      </StrengthBar>

      <ValidationList>
        {config.minLength && (
          <ValidationItem $isValid={validations.hasLength}>
            {validations.hasLength ? <HiOutlineCheck /> : <HiOutlineXMark />}
            At least {config.minLength} characters
          </ValidationItem>
        )}

        {config.requireUpperCase && (
          <ValidationItem $isValid={validations.hasUpperCase}>
            {validations.hasUpperCase ? <HiOutlineCheck /> : <HiOutlineXMark />}
            One uppercase letter
          </ValidationItem>
        )}
        {config.requireLowerCase && (
          <ValidationItem $isValid={validations.hasLowerCase}>
            {validations.hasLowerCase ? <HiOutlineCheck /> : <HiOutlineXMark />}
            One lowercase letter
          </ValidationItem>
        )}
        {config.requireNumbers && (
          <ValidationItem $isValid={validations.hasNumber}>
            {validations.hasNumber ? <HiOutlineCheck /> : <HiOutlineXMark />}
            One number
          </ValidationItem>
        )}
        {config.requireSpecialChars && (
          <ValidationItem $isValid={validations.hasSpecialChar}>
            {validations.hasSpecialChar ? (
              <HiOutlineCheck />
            ) : (
              <HiOutlineXMark />
            )}
            One special character
          </ValidationItem>
        )}
      </ValidationList>
    </Container>
  );
};

export default PasswordStrengthIndicator;
