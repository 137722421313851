import { HiOutlineArrowLeft } from 'react-icons/hi2';
import { useNavigation } from '../../hooks/useNavigation';
import { InfoTip } from '../notifications/InfoTip';
import ButtonIcon from './ButtonIcon';

export const BackButton = ({ onClick, tip = 'Back' }) => {
  const { navigateBack } = useNavigation();

  function handleClick() {
    if (onClick) onClick();
    else navigateBack();
  }

  return (
    <InfoTip tip={tip}>
      <ButtonIcon onClick={handleClick} variant='gray'>
        <HiOutlineArrowLeft />
      </ButtonIcon>
    </InfoTip>
  );
};
