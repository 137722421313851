import { ColorButton } from './blogEditorStyles';

export const roleOptions = [
  { id: 1, label: 'Admin', value: 'admin' },
  { id: 2, label: 'Guest', value: 'guest' },
  { id: 3, label: 'User', value: 'user' },
  { id: 4, label: 'Creator', value: 'creator' },
];

export const blogTypes = [
  { id: 1, label: 'Audio', value: 'audio' },
  { id: 2, label: 'Button', value: 'button' },
  { id: 3, label: 'Carousel', value: 'carousel' },
  { id: 4, label: 'Code', value: 'code' },
  { id: 5, label: 'Divider', value: 'divider' },
  { id: 6, label: 'Embed', value: 'embed' },
  { id: 7, label: 'Form', value: 'form' },
  { id: 8, label: 'Gallery', value: 'gallery' },
  { id: 9, label: 'Header', value: 'header' },
  { id: 10, label: 'Image', value: 'image' },
  { id: 11, label: 'List', value: 'list' },
  { id: 12, label: 'Link', value: 'link' },
  { id: 13, label: 'Map', value: 'map' },
  { id: 14, label: 'Paragraph', value: 'paragraph' },
  { id: 15, label: 'Quote', value: 'quote' },
  { id: 16, label: 'Raw HTML', value: 'raw_html' },
  { id: 17, label: 'Spacer', value: 'spacer' },
  { id: 18, label: 'Table', value: 'table' },
  { id: 19, label: 'Video', value: 'video' },
  { id: 20, label: 'Accordion', value: 'accordion' },
];

export const blogSortByOptions = [
  { id: 1, label: 'Newest First', value: 'created_at_desc' },
  { id: 2, label: 'Oldest First', value: 'created_at_asc' },
  { id: 5, label: 'Title A-Z', value: 'title_asc' },
  { id: 6, label: 'Title Z-A', value: 'title_desc' },
];

export const blogFiltersDiscover = [
  { id: 2, label: 'For You', value: 'for-you' },
  { id: 1, label: 'Top', value: 'all' },
  {
    id: 3,
    label: 'Technology',
    value: 'technology',
  },
  { id: 5, label: 'Finance', value: 'finance' },
  { id: 6, label: 'Sports', value: 'sports' },
  { id: 7, label: 'Entertainment', value: 'entertainment' },
];

export const blogFiltersReview = [
  {
    id: 2,
    label: 'All',
    value: '',
  },

  { id: 1, label: 'Drafts', value: 'draft' },

  { id: 5, label: 'Pending', value: 'pending' },
  {
    id: 3,
    label: 'Published',
    value: 'published',
  },
  { id: 6, label: 'Rejected', value: 'rejected' },
];

export const blogTags = [
  { id: 1, label: 'Arts', value: 'arts' },
  { id: 2, label: 'Automotive', value: 'automotive' },
  { id: 3, label: 'Beauty', value: 'beauty' },
  { id: 4, label: 'Business', value: 'business' },
  { id: 5, label: 'Climate', value: 'climate' },
  { id: 6, label: 'Culture', value: 'culture' },
  { id: 7, label: 'Education', value: 'education' },
  { id: 8, label: 'Entertainment', value: 'entertainment' },
  { id: 9, label: 'Environmental', value: 'environmental' },
  { id: 10, label: 'Fashion', value: 'fashion' },
  { id: 11, label: 'Finance', value: 'finance' },
  { id: 12, label: 'Fitness', value: 'fitness' },
  { id: 13, label: 'Food', value: 'food' },
  { id: 14, label: 'Health', value: 'health' },
  { id: 15, label: 'Home and Garden', value: 'home-and-garden' },
  { id: 16, label: 'Innovation', value: 'innovation' },
  { id: 17, label: 'Lifestyle', value: 'lifestyle' },
  { id: 18, label: 'Marketing', value: 'marketing' },
  { id: 19, label: 'Medicine', value: 'medicine' },
  { id: 20, label: 'Nursing', value: 'nursing' },
  { id: 21, label: 'Parenting', value: 'parenting' },
  { id: 22, label: 'Pets', value: 'pets' },
  { id: 23, label: 'Photography', value: 'photography' },
  { id: 24, label: 'Real Estate', value: 'real-estate' },
  { id: 25, label: 'Science', value: 'science' },
  { id: 26, label: 'Self Improvement', value: 'self-improvement' },
  { id: 27, label: 'Sports', value: 'sports' },
  { id: 28, label: 'Technology', value: 'technology' },
  { id: 29, label: 'Travel', value: 'travel' },
  { id: 30, label: 'Writing', value: 'writing' },
];

export const flagReasons = [
  [
    {
      id: 1,
      label: 'Contains offensive language',
      value: 'offensive',
    },
    {
      id: 2,
      label: 'Contains personal attacks or harassment',
      value: 'harassment',
    },
    {
      id: 3,
      label: 'Contains sensitive or explicit imagery',
      value: 'explicit_content',
    },
    {
      id: 4,
      label: 'Encourages violence or terrorism',
      value: 'violence',
    },
    {
      id: 5,
      label: 'False or misleading claims',
      value: 'misleading',
    },
    {
      id: 6,
      label: 'Hate speech or discriminatory content',
      value: 'hate_speech',
    },
    {
      id: 7,
      label: 'Inappropriate or adult content',
      value: 'adult_content',
    },
    {
      id: 8,
      label: 'Irrelevant or nonsensical content',
      value: 'irrelevant',
    },
    {
      id: 9,
      label: 'Misinformation or fake news',
      value: 'misinformation',
    },
    {
      id: 10,
      label: 'Plagiarized or stolen content',
      value: 'plagiarism',
    },
    {
      id: 11,
      label: 'Privacy violation or leaks sensitive information',
      value: 'privacy_violation',
    },
    {
      id: 12,
      label: 'Promotes fraud or scams',
      value: 'fraud',
    },
    {
      id: 13,
      label: 'Promotes illegal activities',
      value: 'illegal',
    },
    {
      id: 14,
      label: 'Promotes self-harm or suicide',
      value: 'self_harm',
    },
    {
      id: 15,
      label: 'Sexually explicit or pornographic material',
      value: 'pornographic',
    },
    {
      id: 16,
      label: 'Spam or advertising unrelated products',
      value: 'spam',
    },
    {
      id: 17,
      label: 'Threatens individuals or groups',
      value: 'threat',
    },
    {
      id: 18,
      label: 'Trolling or disruptive content',
      value: 'trolling',
    },
    {
      id: 19,
      label: 'Violates copyright or intellectual property',
      value: 'copyright_violation',
    },
    {
      id: 20,
      label: 'Violates community guidelines',
      value: 'guidelines_violation',
    },
    {
      id: 21,
      label: 'Other (Specify)',
      value: 'other',
    },
  ],
];

export const fontStyleOptions = [];

export const fontSizeOptions = [];

export const customStyleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  UPPERCASE: {
    textTransform: 'uppercase',
  },
  LOWERCASE: {
    textTransform: 'lowercase',
  },
  CAPITALIZE: {
    textTransform: 'capitalize',
  },
  BOLD: { fontWeight: 'bold' },
  ITALIC: { fontStyle: 'italic' },
  UNDERLINE: { textDecoration: 'underline' },

  AUTO: { color: 'var(--auto-12)' },
  BROWN: { color: 'var(--brown-9)' },
  GREEN: { color: 'var(--green-9)' },
  TEAL: { color: 'var(--teal-9)' },
  RED: { color: 'var(--red-9)' },
  BLUE: { color: 'var(--blue-9)' },
  ORANGE: { color: 'var(--orange-9)' },
  PINK: { color: 'var(--pink-9)' },
  YELLOW: { color: 'var(--amber-9)' },
  PURPLE: { color: 'var(--purple-9)' },

  FONTSIZE_8: { fontSize: '8px' },
  FONTSIZE_10: { fontSize: '10px' },
  FONTSIZE_12: { fontSize: '12px' },
  FONTSIZE_14: { fontSize: '14px' },
  FONTSIZE_16: { fontSize: '16px' },
  FONTSIZE_18: { fontSize: '18px' },
  FONTSIZE_20: { fontSize: '20px' },
  FONTSIZE_24: { fontSize: '24px' },
  FONTSIZE_28: { fontSize: '28px' },
  FONTSIZE_32: { fontSize: '32px' },
  FONTSIZE_38: { fontSize: '38px' },
  FONTSIZE_46: { fontSize: '46px' },
  FONTSIZE_54: { fontSize: '54px' },
  FONTSIZE_62: { fontSize: '62px' },
  FONTSIZE_72: { fontSize: '72px' },

  FONTFAMILY_arial: { fontFamily: 'Arial, sans-serif' },
  FONTFAMILY_helvetica: { fontFamily: 'Helvetica, sans-serif' },
  FONTFAMILY_times: { fontFamily: '"Times New Roman", serif' },
  FONTFAMILY_courier: { fontFamily: '"Courier New", monospace' },
  FONTFAMILY_verdana: { fontFamily: 'Verdana, sans-serif' },
  FONTFAMILY_georgia: { fontFamily: 'Georgia, serif' },
  FONTFAMILY_palatino: { fontFamily: '"Palatino Linotype", serif' },
  FONTFAMILY_garamond: { fontFamily: 'Garamond, serif' },
};

export const editorColorOptions = [
  {
    id: 'AUTO',
    label: 'Auto',
    value: 'var(--auto-12)',
    icon: <ColorButton style={{ backgroundColor: 'var(--auto-12)' }} />,
  },

  {
    id: 'BROWN',
    label: 'Brown',
    value: 'var(--brown-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--brown-9)' }} />,
  },

  {
    id: 'GREEN',
    label: 'Green',
    value: 'var(--green-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--green-9)' }} />,
  },

  {
    id: 'TEAL',
    label: 'Teal',
    value: 'var(--teal-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--teal-9)' }} />,
  },

  {
    id: 'RED',
    label: 'Red',
    value: 'var(--red-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--red-9)' }} />,
  },

  {
    id: 'BLUE',
    label: 'Blue',
    value: 'var(--blue-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--blue-9)' }} />,
  },

  {
    id: 'ORANGE',
    label: 'Orange',
    value: 'var(--orange-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--orange-9)' }} />,
  },

  {
    id: 'PINK',
    label: 'Pink',
    value: 'var(--pink-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--pink-9)' }} />,
  },

  {
    id: 'AMBER',
    label: 'Amber',
    value: 'var(--amber-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--amber-9)' }} />,
  },

  {
    id: 'PURPLE',
    label: 'Purple',
    value: 'var(--purple-9)',
    icon: <ColorButton style={{ backgroundColor: 'var(--purple-9)' }} />,
  },
];

export const editorFontOptions = [
  { id: 8, label: 8, value: 8 },
  { id: 10, label: 10, value: 10 },
  { id: 12, label: 12, value: 12 },
  { id: 14, label: 14, value: 14 },
  { id: 16, label: 16, value: 16 },
  { id: 18, label: 18, value: 18 },
  { id: 20, label: 20, value: 20 },
  { id: 24, label: 24, value: 24 },
  { id: 28, label: 28, value: 28 },
  { id: 32, label: 32, value: 32 },
  { id: 38, label: 38, value: 38 },
  { id: 46, label: 46, value: 46 },
  { id: 54, label: 54, value: 54 },
  { id: 62, label: 62, value: 62 },
  { id: 72, label: 72, value: 72 },
];

export const fontFamilyOptions = [
  { id: 'default', label: 'Default', value: '"Merriweather", serif' },
  { id: 'arial', label: 'Arial', value: 'Arial, sans-serif' },
  { id: 'helvetica', label: 'Helvetica', value: 'Helvetica, sans-serif' },
  { id: 'times', label: 'Times New Roman', value: '"Times New Roman", serif' },
  { id: 'courier', label: 'Courier', value: '"Courier New", monospace' },
  { id: 'verdana', label: 'Verdana', value: 'Verdana, sans-serif' },
  { id: 'georgia', label: 'Georgia', value: 'Georgia, serif' },
  { id: 'palatino', label: 'Palatino', value: '"Palatino Linotype", serif' },
  { id: 'poppins', label: 'Poppins', value: '"Poppins", sans-serif' },
];
