import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { Card, CardBody, CardHeader } from '../../ui/layout/Card';
import Button from '../../ui/buttons/Button';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import {
  updateProfile,
  useProfileState,
} from '../../redux/features/profile-slice';
import Heading from '../../ui/typography/Heading';
import { FlexColumn, FlexRow, RequiredIndicator } from '../../ui/layout/Flex';
import { TextBase, TextFade } from '../../ui/typography/Text';
import DarkModeToggle from '../../ui/toggle/DarkModeToggle';
import { useAuth } from '../../redux/features/auth-slice';
import { StyledProfileSection } from './ProfileTabs';
import ThemeToggle from '../../ui/toggle/ThemeToggle';
import toast from 'react-hot-toast';
import { useMutateProfile } from './useProfile';
import { getCurrentTimestamp } from '../../utils/helpers';
import {
  updateCurrentUser,
  useUpdateUser,
} from '../../api/authentication/user';
import useRoleCheck from '../../hooks/useRoleCheck';
import { ShowPopup } from '../../ui/modal/Popup';
import RequestCreatorAccess from './RequestCreatorAccess';
import {
  HiOutlineCheckBadge,
  HiOutlineClock,
  HiOutlineKey,
} from 'react-icons/hi2';
import { useMutation } from 'react-query';

const BioData = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { profile } = useProfileState();
  const { mutate: updateUser } = useUpdateUser();
  const { isUser, isCreator } = useRoleCheck();
  const [userProfile, setUserProfile] = useState({
    first_name: user?.user_metadata?.first_name || '',
    last_name: user?.user_metadata?.last_name || '',
  });

  const creatorStatus = useMemo(() => {
    if (isUser(true)) {
      return {
        labelColor: 'var(--color-12)',
        descriptionColor: 'var(--color-11)',
        backgroundColor: 'color',
        label: 'Creator Access',
        description: 'Start your journey as a creator now!',
        icon: HiOutlineKey,
        isAllowed: true,
      };
    }

    if (profile?.creator_verified_at) {
      return {
        labelColor: 'var(--green-12)',
        descriptionColor: 'var(--green-11)',
        backgroundColor: 'green',
        label: 'Verified Creator',
        description:
          'You are a verified creator! All published and public blogs will appear on the feed.',
        isAllowed: false,
        icon: HiOutlineCheckBadge,
      };
    }

    return {
      labelColor: 'var(--yellow-12)',
      descriptionColor: 'var(--yellow-11)',
      backgroundColor: 'yellow',
      label: 'Creator Status: Probation',
      description:
        'You can now publish blogs for verification. Our moderators will review your content to grant full creator access.',
      isAllowed: false,
      icon: HiOutlineClock,
    };
  }, [profile?.creator_verified_at]);

  const { mutateProfile, isLoading: isUpdatingProfile } = useMutateProfile();

  const { mutate: updateUserMutation, isLoading: isUpdatingUser } = useMutation(
    {
      mutationFn: updateCurrentUser,
      onSuccess: ({ user }) => {
        toast.success('Updated profile successfully');
        dispatch(updateUser({ user }));
        dispatch(
          updateProfile({
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
          })
        );
      },
      onError: (error) => {
        const errorMessage = getCrudErrorMessage('update', error, 'user');
        toast.error(errorMessage.description);
      },
    }
  );

  function handleOnChange(e) {
    const { name, value } = e.target;

    // Input validation based on field type
    if (name === 'first_name' || name === 'last_name') {
      if (value.length > 50) {
        toast.dismiss();
        toast.error(
          `${
            name === 'first_name' ? 'First' : 'Last'
          } name must be less than 50 characters`
        );
        return;
      }

      // Only allow letters, spaces, and hyphens
      if (!/^[a-zA-Z\s-]*$/.test(value)) {
        toast.dismiss();
        toast.error(
          `${
            name === 'first_name' ? 'First' : 'Last'
          } Name can only contain letters, spaces, and hyphens`
        );
        return;
      }
    }

    setUserProfile((prev) => ({ ...prev, [name]: value }));
  }

  const isReadyToSave = !!userProfile.first_name && !!userProfile.last_name;
  const hasChanges =
    user?.user_metadata?.first_name?.trim() !==
      userProfile.first_name?.trim() ||
    user?.user_metadata?.last_name?.trim() !== userProfile.last_name?.trim();

  function onSubmit(e) {
    e.preventDefault();

    // Check if there are changes to be saved
    if (!hasChanges) {
      toast.error('No changes made to profile data');
      return;
    }

    if (!isReadyToSave) {
      toast.error('Changes cannot be applied to blank fields');
      return;
    }

    const trimmedUserProfile = {
      ...userProfile,
      first_name: userProfile.first_name.trim(),
      last_name: userProfile.last_name.trim(),
    };
    const now = getCurrentTimestamp();

    mutateProfile(
      user?.id,
      {
        ...trimmedUserProfile,
        updated_at: now,
      },
      {
        onSuccess: () => {
          updateUserMutation({
            data: {
              first_name: userProfile.first_name,
              last_name: userProfile.last_name,
            },
          });
        },
      }
    );
  }

  const handleReset = () => {
    if (hasChanges) {
      setUserProfile({
        first_name: user?.user_metadata?.first_name || '',
        last_name: user?.user_metadata?.last_name || '',
      });
    }
  };

  const canRequestCreatorAccess = useMemo(() => {
    if (isCreator(true)) {
      return {
        isAllowed: false,
        message: 'You already have creator access',
      };
    }

    if (profile?.creator_verified_at) {
      return {
        isAllowed: false,
        message: 'You have already been verified as a creator',
      };
    }

    return {
      isAllowed: true,
    };
  }, [profile]);

  return (
    <StyledProfileSection>
      {
        <Card backgroundColor={creatorStatus.backgroundColor} padding='0'>
          <CardHeader border='none'>
            <FlexRow>
              <Heading style={{ color: creatorStatus.labelColor }} as='h6'>
                {creatorStatus.label}
              </Heading>
              <creatorStatus.icon style={{ color: creatorStatus.labelColor }} />
            </FlexRow>
          </CardHeader>
          <CardBody>
            <FlexColumn gap={3.2}>
              <FlexRow justify='between' align='start'>
                <TextFade color={creatorStatus.descriptionColor}>
                  {creatorStatus.description}
                </TextFade>
                {creatorStatus.isAllowed && (
                  <ShowPopup
                    button={
                      <Button
                        variant='primary'
                        size='small'
                        isLoading={isUpdatingProfile}
                        disabled={
                          isUpdatingProfile ||
                          !canRequestCreatorAccess.isAllowed
                        }
                      >
                        Request Access
                      </Button>
                    }
                    component={<RequestCreatorAccess />}
                  />
                )}
              </FlexRow>
            </FlexColumn>
          </CardBody>
        </Card>
      }

      <Card padding='0' as='form' onSubmit={(e) => onSubmit(e)}>
        <CardHeader border='none'>
          <Heading as='h6'>Personal</Heading>
        </CardHeader>
        <CardBody>
          <FlexColumn gap={3.2}>
            <FlexRow>
              <StyledInputContainer>
                <Input
                  autoComplete='on'
                  type='text'
                  placeholder='First Name'
                  name='first_name'
                  value={userProfile?.first_name}
                  onChange={(e) => handleOnChange(e)}
                  required
                  disabled={isUpdatingProfile}
                />
                <InputLabel>
                  First Name <RequiredIndicator />
                </InputLabel>
              </StyledInputContainer>
            </FlexRow>

            <StyledInputContainer>
              <Input
                autoComplete='on'
                type='text'
                placeholder='Last Name'
                name='last_name'
                value={userProfile?.last_name}
                onChange={(e) => handleOnChange(e)}
                required
                disabled={isUpdatingProfile}
              />
              <InputLabel>
                Last Name <RequiredIndicator />
              </InputLabel>
            </StyledInputContainer>

            <ButtonGroup>
              <Button
                disabled={isUpdatingProfile || !hasChanges}
                variant='secondary'
                onClick={handleReset}
                size='small'
              >
                Reset Bio
              </Button>

              <Button
                disabled={isUpdatingProfile || !isReadyToSave || !hasChanges}
                type='submit'
                isLoading={isUpdatingProfile}
                size='small'
              >
                Update Profile
              </Button>
            </ButtonGroup>
          </FlexColumn>
        </CardBody>
      </Card>

      <Card padding='0'>
        <CardHeader border='none'>
          <Heading as='h6'>General</Heading>
        </CardHeader>
        <CardBody>
          <FlexColumn gap={3.2}>
            <FlexRow justify='between'>
              <TextBase>Appearance</TextBase>

              <DarkModeToggle />
            </FlexRow>
            <FlexRow justify='between'>
              <TextBase>Theme</TextBase>
              <ThemeToggle />
            </FlexRow>
          </FlexColumn>
        </CardBody>
      </Card>
    </StyledProfileSection>
  );
};

export default BioData;
