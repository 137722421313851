import styled, { ThemeProvider, css } from 'styled-components';
import { useCallback, useEffect, useId, useState } from 'react';
import { useToast } from '../context/ToastProvider';
import Skeleton from '../ui/loading/Skeleton';

const StyledToggle = styled.label`
  min-width: 4rem;
  width: 4rem;
  height: 2.4rem;
  border-radius: var(--border-radius-rd);
  background-color: ${(props) =>
    props.theme.isOn ? 'var(--color-9)' : 'var(--gray-8)'};
  cursor: pointer;

  ${(props) =>
    props.theme.disabled &&
    css`
      cursor: not-allowed;
    `}

  position: relative;
  transition: all 300ms ease-in-out;

  & input {
    display: none;

    &:disabled ~ * {
      cursor: not-allowed;
      opacity: 0.75;
    }
  }

  & div {
    width: 2rem;
    height: 2rem;
    border-radius: var(--border-radius-rd);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 1.2rem;
    transform: translate(-50%, -50%);

    visibility: hidden;
    opacity: 0;

    transition: all 200ms ease-in-out;
    background-color: var(--gray-10);

    svg {
      width: 2.1rem;
      height: 2.1rem;
    }
  }

  & .on {
    left: 2.8rem;
    visibility: visible;
    opacity: 1;

    background-color: var(--label-white);
  }

  & .off {
    visibility: visible;
    opacity: 1;

    background-color: var(--label-white);
  }
`;

/**
 *
 * @param {*} initialState //initial state of the toggle
 * @param {*} initialState.defaultValue //default state of the toggle which is a boolean value default value is false
 * @param {*} initialState.disabled //boolean value to disable the toggle
 * @returns
 * toggle //html element with toggle
 * isOn //boolean value state of the toggle
 */
const useToggle = (initialState) => {
  const {
    defaultValue = false,
    disabled,
    onChange,
    isLoading,
  } = initialState || {};
  const [isOn, setisOn] = useState(defaultValue);
  const reset = useCallback(
    (value) => setisOn(value || defaultValue),
    [defaultValue]
  );

  const toggleId = useId();

  const handleToggle = (e) => {
    const { checked } = e.target;

    if (checked) {
      setisOn(true);
      onChange && onChange(true);
    } else {
      setisOn(false);
      onChange && onChange(false);
    }
  };

  useEffect(() => {
    setisOn(defaultValue);
  }, [defaultValue]);

  const toggle = (
    <ThemeProvider theme={{ isOn, disabled, isLoading }}>
      {isLoading ? (
        <Skeleton height='2.4rem' width='4rem' />
      ) : (
        <StyledToggle htmlFor={toggleId}>
          <input
            type='checkbox'
            id={toggleId}
            onChange={handleToggle}
            disabled={disabled}
            checked={!!isOn}
          />

          <div className={!isOn ? 'off' : ''}></div>

          <div className={isOn ? 'on' : ''}></div>
        </StyledToggle>
      )}
    </ThemeProvider>
  );

  return { toggle, isOn, reset };
};

export default useToggle;
