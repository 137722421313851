import { useRef, useEffect } from 'react';

/**
 * Hook to detect clicks outside of a referenced element (e.g., a modal).
 * @param {Function} handler - Function to be called when an outside click is detected.
 * @param {boolean} listenCapturing - Boolean to listen to capturing phase, default is false.
 * @returns {Object} - Ref to attach to the element to detect outside clicks.
 */
const useOutsideClick = (handler, listenCapturing = true) => {
  const ref = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    };

    const handleEscapePress = (e) => {
      if (e.key === 'Escape' && ref.current) {
        handler();
      }
    };

    document.addEventListener('click', handleOutsideClick, listenCapturing);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener(
        'click',
        handleOutsideClick,
        listenCapturing
      );
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [handler, listenCapturing]);

  return ref;
};

export default useOutsideClick;
