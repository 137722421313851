import { HiOutlineX } from 'react-icons/hi';
import ButtonIcon from './ButtonIcon';
import { InfoTip } from '../notifications/InfoTip';

export const CloseButton = ({
  onClick,
  tip = 'Close',
  isLoading = false,
  disabled = false,
}) => {
  function handleClick() {
    onClick();
  }
  return (
    <InfoTip tip={tip}>
      <ButtonIcon
        variant='danger'
        onClick={handleClick}
        isLoading={isLoading}
        disabled={disabled}
      >
        <HiOutlineX />
      </ButtonIcon>
    </InfoTip>
  );
};
