import {
  cloneElement,
  createContext,
  isValidElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { createPortal } from 'react-dom';
import Overlay, { overlayVariants } from './Overlay';
import useOutsideClick from '../../hooks/useOutsideClick';
import { CloseButton } from '../buttons/CloseButton';
import { StyledHeader } from '../../styles/GeneralStyling';
import { media } from '../../styles/ResponsiveDesign';
import { useId } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const StyledModalHeader = styled(StyledHeader)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const MotionModal = styled(motion.div)`
  position: fixed;
  top: ${(props) => (props.position === 'top' ? '5.6rem' : '50%')};
  left: 50%;
  width: ${(props) => {
    switch (props.size) {
      case 'small':
        return '44rem';
      case 'medium':
        return '72rem';
      case 'large':
        return '60%';
      default:
        return '72rem';
    }
  }};
  max-width: 60%;
  height: auto;

  ${media.tablet`
    width: calc(98% - var(--padding-hor));
    max-width: calc(98% - var(--padding-hor));
    max-height: 90%;
  `}

  ${media.mobile`
    width: calc(100% - var(--padding-hor));
    max-width: calc(100% - var(--padding-hor));
    max-height: 90%;
  `}

  border-radius: var(--border-radius-lg);
  background-color: var(--gray-2);
  box-shadow: var(--box-shadow-md);
  overflow: hidden;
  z-index: 100000;
`;

const ModalContext = createContext();

const Modal = ({ children }) => {
  const [openModal, setOpenModal] = useState('');

  const closeModal = useCallback(() => {
    setOpenModal('');
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, setOpenModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

const Open = ({ children, modalName, onClick, ...props }) => {
  const { setOpenModal } = useContext(ModalContext);

  return cloneElement(children, {
    onClick: (e) => {
      setOpenModal(modalName);
      document.body.style.overflow = 'hidden';
      onClick?.(e);
    },
    ...props,
  });
};

const Window = ({
  children,
  modalName,
  position,
  close = true,
  showButton,
  header,
  size = 'medium',
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const ref = useOutsideClick(closeModal);
  const isOpen = openModal === modalName;

  const isFunctionalOrClassComponent =
    isValidElement(children) && typeof children.type === 'function';

  return createPortal(
    <StyleSheetManager
      shouldForwardProp={(props) =>
        props !== 'position' || props !== 'size' || props !== 'unmount'
      }
    >
      <AnimatePresence>
        {isOpen && (
          <>
            <Overlay
              variants={overlayVariants}
              initial='initial'
              animate='animate'
              exit='exit'
              transition={{ duration: 0.2 }}
            />
            <MotionModal
              id='modal-container'
              position={position}
              size={size}
              ref={close ? ref : null}
              initial={{
                scale: 0.5,
                opacity: 0,
                y: position === 'top' ? 0 : '-50%',
                x: '-50%',
                rotate: -5,
              }}
              animate={{
                scale: 1,
                opacity: 1,
                y: position === 'top' ? 0 : '-50%',
                x: '-50%',
                rotate: 0,
              }}
              exit={{
                scale: 0.8,
                opacity: 0,
                y: position === 'top' ? 0 : '-50%',
                x: '-50%',
                rotate: 5,
              }}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 25,
                mass: 0.8,
                restDelta: 0.01,
              }}
            >
              {header && (
                <StyledModalHeader>
                  {header}
                  {(showButton || close) && (
                    <CloseButton onClick={closePopup} />
                  )}
                </StyledModalHeader>
              )}
              {isFunctionalOrClassComponent
                ? cloneElement(children, { closeModal })
                : children}
            </MotionModal>
          </>
        )}
      </AnimatePresence>
    </StyleSheetManager>,
    document.getElementById('modal')
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a Modal provider');
  }
  return context;
};

export const ShowModal = ({
  modalName,
  button,
  component,
  onClick,
  close,
  showButton = true,
  header,
  footer,
  position,
  size,
}) => {
  const modalId = useId();

  return (
    <>
      {showButton && (
        <Modal.Open modalName={modalName || modalId} onClick={onClick}>
          {button}
        </Modal.Open>
      )}
      <Modal.Window
        modalName={modalName || modalId}
        header={header}
        footer={footer}
        size={size}
        close={close}
        position={position}
      >
        {component}
      </Modal.Window>
    </>
  );
};

Modal.Open = Open;
Modal.Window = Window;

export default Modal;
