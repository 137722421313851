import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { EditorState } from 'draft-js';
import {
  convertFromRawContent,
  convertToRawContent,
  createEmptyEditorState,
  getWordCount,
} from '../features/blog/helpers';
import { useSupabaseOperation, useSupabaseQuery } from '../api/supabaseService';
import {
  setBlogSettings,
  setError,
  setIsLoading,
  updateBlogContent,
  updateBlogDetails,
  useBlogState,
} from '../redux/features/blog-slice';
import { debounce, isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { getCurrentTimestamp } from '../utils/helpers';
import toast from 'react-hot-toast';
import { getCrudErrorMessage } from '../utils/errorHandlers';
import { useAuth } from '../redux/features/auth-slice';
import { useSearchParams } from 'react-router-dom';
import { useEditorMode } from '../features/blog/useEditorMode';
import { useMutation } from 'react-query';
import { updateBlog } from '../features/blog/ApiBlog';

const AUTOSAVE_INTERVAL = 5000;

const EditorContext = createContext();
export const useEditor = () => useContext(EditorContext);

export const EditorProvider = ({ children }) => {
  const [shouldFocus, setShouldFocus] = useState(true);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const { blogDetails } = useBlogState();
  const blogId = searchParams.get('id');
  const [editorState, setEditorStateUpdate] = useState(createEmptyEditorState);
  const editorStateRef = useRef(editorState);
  //Track editor permissions
  const { hasAccess: isEditing } = useEditorMode();

  const { mutate: updateBlogMutation, isLoading: isSaving } = useMutation({
    mutationFn: updateBlog,
  });

  const setEditorState = useCallback(
    (newState) => {
      if (isEditing) {
        setEditorStateUpdate(newState);
      }
    },
    [setEditorStateUpdate, isEditing]
  );

  const initializeEditorState = useCallback(
    (data) => {
      if (isEmpty(data)) {
        const emptyState = createEmptyEditorState();
        editorStateRef.current = emptyState;
        setEditorState(emptyState);
        return emptyState;
      }

      const { blog_contents, blog_settings, ...blogDetails } = data;
      const objectBlogContent = blog_contents?.[0];
      const rawContentString = objectBlogContent.content;
      const newEditorState = convertFromRawContent(rawContentString);

      // Update both state and ref
      setEditorState(newEditorState);
      editorStateRef.current = newEditorState;

      // Update Redux store
      dispatch(updateBlogDetails(blogDetails));
      dispatch(updateBlogContent(objectBlogContent));
      dispatch(setBlogSettings(blog_settings));
      return newEditorState;
    },
    [dispatch, setEditorState]
  );

  const {
    data,
    isLoading,
    refetch: refetchBlog,
    isError,
    error,
  } = useSupabaseQuery(
    {
      table: 'blog_posts',
      operation: 'read',
      fields: ['*'],
      filters: [{ column: 'blog_id', operator: 'eq', value: blogId }],
      relations: {
        blog_contents: {
          fields: ['*'],
        },
        blog_settings: {
          fields: ['*'],
        },
      },
      count: false,
      returnData: 'data',
      single: true,
    },
    {
      queryKey: ['blog_posts', { blogId: blogId }],
      enabled: !!blogId,
      onMutate: () => {
        dispatch(setIsLoading(true));
        dispatch(setError(null));
      },
      onSuccess: (blog) => {
        initializeEditorState(blog);
      },
      onError: (error) => {
        const errorMessage = getCrudErrorMessage('read', error, 'Blog');
        toast.error(errorMessage.title);
        dispatch(setIsLoading(false));
        dispatch(setError(errorMessage.title));
      },
    }
  );

  useEffect(() => {
    if (data && isEditing && !isEmpty(data)) {
      initializeEditorState(data);
    }
  }, [data, isEditing]);

  useEffect(() => {
    setHasUnsavedChanges(
      !isEqual(
        convertToRawContent(editorState),
        convertToRawContent(editorStateRef.current)
      )
    );
  }, [editorState, editorStateRef]);

  useEffect(() => {
    //Update the selection state
    if (isEditing) {
      const selection = editorState.getSelection();
      setIsSelectionActive(!selection?.isCollapsed());
    }
  }, [editorState, isEditing]);

  const undo = useCallback(() => {
    if (isEditing) {
      setEditorState(EditorState?.undo(editorState));
    }
  }, [editorState, setEditorState, isEditing]);

  const redo = useCallback(() => {
    if (isEditing) {
      setEditorState(EditorState?.redo(editorState));
    }
  }, [editorState, setEditorState, isEditing]);

  const wordCount = useMemo(() => {
    return getWordCount(editorState);
  }, [editorState]);

  const saveContent = useCallback(
    (formData) => {
      toast.dismiss();
      // Pre-save checks
      if (!user) {
        return toast.error('You must be logged in to create a blog');
      }

      if (!isEditing) {
        return toast.error('You must be in edit mode to save a blog');
      }

      if (!hasUnsavedChanges) {
        return toast('No changes to save');
      }

      const contentString = convertToRawContent(editorState);

      const payload = {
        blog_id: blogDetails?.blog_id,
        content: {
          content: contentString,
        },
      };

      updateBlogMutation(payload, {
        onSuccess: () => {
          // Update the reference state to match current state
          editorStateRef.current = editorState;
          setHasUnsavedChanges(false);

          // Update Redux store
          dispatch(
            updateBlogDetails({
              updated_at: getCurrentTimestamp(),
            })
          );
          dispatch(
            updateBlogContent({
              content: contentString,
            })
          );

          setHasUnsavedChanges(false);

          // Show success message
          toast.success('Successfully saved changes');
        },

        onError: (error) => {
          const errorMessage = getCrudErrorMessage('update', error, 'Blog');
          toast.error(errorMessage.title);
        },
      });
    },
    [
      user,
      editorState,
      blogDetails?.blog_id,
      dispatch,
      hasUnsavedChanges,
      dispatch,
      isEditing,
      editorStateRef,
    ]
  );

  // Auto-save setup with debounce
  const debouncedContentUpdateSave = useMemo(() => {
    return debounce(saveContent, AUTOSAVE_INTERVAL);
  }, [saveContent]);

  // Auto-save effect
  useEffect(() => {
    if (!isEditing || !hasUnsavedChanges) return;

    debouncedContentUpdateSave();

    return () => {
      debouncedContentUpdateSave.cancel();
    };
  }, [isEditing, hasUnsavedChanges, debouncedContentUpdateSave]);

  const value = {
    shouldFocus,
    setShouldFocus,
    isSaving,
    isEditing,
    editorState,
    setEditorState,
    saveContent,
    undo,
    redo,
    isSelectionActive,
    hasUnsavedChanges,
    wordCount,
    isLoading,
    error,
    isError,
    refetchBlog,
    data,
  };

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};
