import { useDispatch } from 'react-redux';
import {
  useSupabaseOperation,
  useSupabaseQuery,
} from '../../../api/supabaseService';
import {
  setRoleHierarchy,
  setInitialization,
  setRoutePermissions,
  setRoutes,
} from '../../../redux/features/permissions-slice';
import { FullScreenLoading } from '../../../ui/loading/Spinner';
import StateDisplay from '../../../ui/notifications/StateDisplay';

const AppInitialization = ({ children }) => {
  const dispatch = useDispatch();
  const {
    mutate: fetchRoutes,
    isLoading: isFetchingRoutes,
    isError: isFetchingRoutesError,
  } = useSupabaseOperation();

  useSupabaseQuery(
    {
      table: 'permissions',
      operation: 'read',
      returnData: 'data',
      count: false,
    },

    {
      refetchOnReconnect: true,
      queryKey: ['permissions'],

      onSuccess: (data) => {
        dispatch(setRoleHierarchy(data));
        dispatch(setInitialization({ isLoading: true }));
        fetchRoutes(
          {
            table: 'routes',
            operation: 'read',
            returnData: 'data',
            count: false,
            sort: [{ column: 'order', order: 'asc' }],
          },
          {
            onSuccess: (data) => {
              dispatch(setRoutes(data));
              dispatch(setRoutePermissions(data));
              dispatch(
                setInitialization({
                  isLoading: false,
                  isInitialized: true,
                  error: null,
                })
              );
            },
            onError: (error) => {
              dispatch(
                setInitialization({
                  isLoading: false,
                  isInitialized: false,
                  error,
                })
              );
            },
            onSettled: () => {
              dispatch(setInitialization({ isLoading: false }));
            },
          }
        );
      },
      onError: (error) => {
        dispatch(
          setInitialization({ isLoading: false, isInitialized: false, error })
        );
      },
    }
  );

  if (isFetchingRoutes) {
    return <FullScreenLoading />;
  }

  if (isFetchingRoutesError) {
    return (
      <StateDisplay
        variant='error'
        title='Error'
        description='Unable to initialize application'
      />
    );
  }

  return children;
};

export default AppInitialization;
