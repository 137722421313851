import { motion } from 'framer-motion';
import styled from 'styled-components';

const Overlay = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: var(--black-a10);
`;

// Default animation variants can be exported if you want to reuse them
export const overlayVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 0.75,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

export default Overlay;
