import { motion } from 'framer-motion';
import { useSidebar } from '../app/AppLayout';
import ButtonIcon from './ButtonIcon';
import { HiOutlineXMark, HiOutlineBars3 } from 'react-icons/hi2';
import styled from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

const StyledToggleButton = styled(motion.div)`
  display: none;
  ${media.tabletLandscape`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: var(--border-radius-md);
    background-color: var(--gray-2);
    border: 1px solid var(--gray-6);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--gray-3);
      border-color: var(--gray-7);
    }
  `}
`;

export const ToggleButton = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <StyledToggleButton
      onClick={toggleSidebar}
      animate={{ rotate: isSidebarOpen ? 180 : 0 }}
      transition={{ duration: 0.3 }}
      variant='gray'
    >
      {isSidebarOpen ? <HiOutlineXMark /> : <HiOutlineBars3 />}
    </StyledToggleButton>
  );
};
