import { differenceInDays, format, formatDistanceToNow } from 'date-fns';
import { FastAverageColor } from 'fast-average-color';
import { customAlphabet } from 'nanoid';
import {
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineUserCircle,
} from 'react-icons/hi2';

export const handleTextHighlight = (text, keywords) => {
  const parts = text?.split(new RegExp(`(${keywords.join('|')})`, 'gi'));

  return parts?.map((part, index) =>
    keywords.includes(part.toLowerCase().trim()) ? (
      <span
        key={index}
        style={{
          color: 'var(--label-pri)',
          fontWeight: 'bold',
        }}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const getCurrentTimestamp = (format = 'iso') => {
  const now = new Date();

  if (format === 'iso') {
    return now.toISOString();
  }

  if (format === 'locale') {
    return now.toLocaleString();
  }

  return now;
};

export const generateReadableId = (length = 10) => {
  const nanoid = customAlphabet('1234567890', length);
  return nanoid();
};

export const getFormattedDate = (date, config) => {
  if (!date) return null;
  return format(date, 'EEE, MMM dd, yyyy, hh:mm a');
};

export const getTimeLapseInfo = (submittedDate) => {
  if (!submittedDate) return null;
  const daysPassed = differenceInDays(new Date(), new Date(submittedDate));
  const distanceToNow = formatDistanceToNow(submittedDate, {
    addSuffix: true,
  });

  if (daysPassed <= 3) {
    return {
      daysPassed,
      variant: 'success',
      icon: <HiOutlineCheckCircle />,
      message: `${daysPassed} days since submission`,
      submitted: distanceToNow,
      color: 'green',
      priority: 'low',
      description:
        'Your blog is being reviewed within our standard timeframe. We aim to complete the review soon.',
    };
  } else if (daysPassed <= 6) {
    return {
      daysPassed,
      variant: 'info',
      icon: <HiOutlineClock />,
      message: `${daysPassed} days since submission`,
      submitted: distanceToNow,
      color: 'blue',
      priority: 'medium',
      description:
        'Your blog review is taking slightly longer than usual. We appreciate your patience.',
    };
  } else if (daysPassed <= 9) {
    return {
      daysPassed,
      variant: 'warning',
      icon: <HiOutlineInformationCircle />,
      message: `${daysPassed} days since submission`,
      submitted: distanceToNow,
      color: 'yellow',
      priority: 'high',
      description:
        'Your blog review is delayed. Our moderators have been notified and will prioritize your review.',
    };
  } else {
    return {
      daysPassed,
      variant: 'danger',
      icon: <HiOutlineExclamationCircle />,
      message: `${daysPassed} days since submission`,
      submitted: distanceToNow,
      color: 'red',
      priority: 'critical',
      description:
        'Your blog review is significantly delayed. We apologize for the inconvenience. Please contact support if you need immediate assistance.',
    };
  }
};

export function getReviewStatus(review) {
  const { review_completed_at, review_started_at, moderator_assigned_at } =
    review;

  if (review_completed_at) {
    return {
      value: 'completed',
      label: 'Completed',
      description: 'The review process has been completed.',
      color: 'green',
      icon: <HiOutlineCheckCircle />,
    };
  }

  if (review_started_at) {
    return {
      value: 'in_progress',
      label: 'In Progress',
      description: 'A moderator is currently reviewing this blog.',
      color: 'blue',
      icon: <HiOutlineInformationCircle />,
    };
  }

  if (moderator_assigned_at) {
    return {
      value: 'assigned',
      label: 'Moderator Assigned',
      description:
        'A moderator has been assigned and will begin the review shortly.',
      color: 'yellow',
      icon: <HiOutlineUserCircle />,
    };
  }

  return {
    value: 'pending',
    label: 'Pending Assignment',
    description: 'This blog is waiting for moderator assignment.',
    color: 'gray',
    icon: <HiOutlineClock />,
  };
}

const fac = new FastAverageColor();

export async function getImageColors(imageUrl) {
  try {
    const color = await fac.getColorAsync(imageUrl);
    const isDark = color.isDark;

    return {
      imageColor: color.rgb,
      textColor: isDark ? '#edeef0' : '#1c2024',
      hoverColor: `${color.rgb.slice(0, -1)}, 0.8)`, // Convert rgb(r,g,b) to rgb(r,g,b,0.8)
      theme: isDark ? 'dark' : 'light',
    };
  } catch (error) {
    // Fallback colors
    return {
      imageColor: 'var(--gray-3)',
      textColor: 'var(--gray-12)',
      hoverColor: 'var(--gray-4)',
      theme: 'light',
    };
  }
}
