import styled, { css } from 'styled-components';
import {
  getResponsiveHeadingStyles,
  getResponsiveMarginBottom,
  getResponsiveMarginTop,
} from '../../ui/typography/Heading';
import {
  getTableBodyCellStyling,
  getTableBodyRowStyling,
  getTableHeaderStyling,
  getTableStyling,
} from '../../ui/layout/StyledTable';
import { media } from '../../styles/ResponsiveDesign';

export const StyledEditorContainer = styled.div`
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const EditorWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isFullScreen'].includes(prop),
})`
  padding: 0;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100;
  max-width: 1080px;
`;

export const ColorButton = styled.div`
  border: var(--border-sm-non);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--border-radius-sm);
  width: 16px;
  height: 16px;
`;

export const StyledLink = styled.a`
  &:link,
  &:visited {
    outline: none;
    border: none;
    color: var(--color-9);
    font-weight: normal;
    background: none;
    cursor: pointer;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: var(--color-12);
  }
`;

export const StyledEditorBody = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['textAlign', 'config', 'hidePlaceholder'].includes(prop),
})`
  flex: 1;
  line-height: 28.8px;
  font-weight: 400;
  font-size: 1.6rem;
  ${media.mobileLandscape`
    line-height: 24.4px;

  `}

  & .DraftEditor-root {
    padding: var(--padding);
    outline: var(--border-sm-int);
    background-color: transparent;
    min-height: 20rem;
    border-radius: var(--border-radius-md);
    transition: outline 0.1s ease-in-out;
    padding-bottom: 4.2rem;
    width: 100%;
    margin: 0 auto;
    max-width: 120rem;

    ${(props) =>
      props.config.mode === 'read' &&
      css`
        outline: none;
        border-radius: none;
        padding: 0;
        max-width: 72rem;
        min-height: 100%;
      `}

    /* Text Align Styling */
    & .align-left {
      text-align: left !important;
    }
    & .align-center {
      text-align: center !important;
    }
    & .align-right {
      text-align: right !important;
    }

    & .public-DraftStyleDefault-block {
      text-align: inherit !important;
      margin: 0;
    }

    & .public-DraftEditorPlaceholder-root {
      color: var(--gray-9);
      font-style: italic;
      ${({ hidePlaceholder }) =>
        hidePlaceholder &&
        css`
          display: none;
        `}
    }

    /* Blockquote Styling */
    & .blockquote {
      border-left: 4px solid var(--gray-7);
      margin: var(--padding);
      padding: var(--padding);
      font-style: italic;
      color: var(--gray-11);
      text-align: left;
      position: relative;

      &::before {
        content: '"';
        position: absolute;
        left: 0.5rem;
        top: -0.5rem;
        font-size: 2rem;
        color: var(--gray-11);
        font-family: Georgia, serif;
      }
    }

    /* List Styling */
    & ol,
    & ul {
      margin-left: 1.5em;
      padding-left: 1em;
    }

    & li {
      margin-bottom: 0.5em;
    }

    & li.public-DraftStyleDefault-depth1 {
      margin-left: 1.5em;
    }
    & li.public-DraftStyleDefault-depth2 {
      margin-left: 3em;
    }
    & li.public-DraftStyleDefault-depth3 {
      margin-left: 4.5em;
    }
    & li.public-DraftStyleDefault-depth4 {
      margin-left: 6em;
    }
  }

  /* Inline Styling */
  & .public-DraftStyleDefault-bold {
    font-weight: bold;
  }
  & .public-DraftStyleDefault-italic {
    font-style: italic;
  }
  & .public-DraftStyleDefault-underline {
    text-decoration: underline;
  }

  /* Heading Styles */
  h1 {
    ${getResponsiveHeadingStyles('h1')}
    ${getResponsiveMarginBottom('h1')}
    ${getResponsiveMarginTop('h1')}
  }
  h2 {
    ${getResponsiveHeadingStyles('h2')}
    ${getResponsiveMarginBottom('h2')}
    ${getResponsiveMarginTop('h2')}
  }
  h3 {
    ${getResponsiveHeadingStyles('h3')}
    ${getResponsiveMarginBottom('h3')}
    ${getResponsiveMarginTop('h3')}
  }
  h4 {
    ${getResponsiveHeadingStyles('h4')}
    ${getResponsiveMarginBottom('h4')}
    ${getResponsiveMarginTop('h4')}
  }
  h5 {
    ${getResponsiveHeadingStyles('h5')}
    ${getResponsiveMarginBottom('h5')}
    ${getResponsiveMarginTop('h5')}
  }
  h6 {
    ${getResponsiveHeadingStyles('h6')}
    ${getResponsiveMarginBottom('h6')}
    ${getResponsiveMarginTop('h6')}
  }

  /* Media Embeds */
  & .media-embed {
    display: block;
    margin: 1.5rem auto;
    max-width: 100%;
    border-radius: var(--border-radius-md);
    overflow: hidden;

    & iframe {
      width: 100%;
      height: 315px;
    }
  }

  /* Code Block Styling */
  & .code-block {
    background-color: var(--gray-3);
    padding: var(--padding);
    border-radius: var(--border-radius-md);
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2rem;
    overflow-x: auto;
  }

  /* Custom Table Styling */
  & table {
    ${getTableStyling()}

    /* Table caption */
  & caption {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--gray-12);
    }

    /* Table head styling */
    & thead {
      tr,
      th {
        ${getTableHeaderStyling()}
      }

      th {
        ${getTableHeaderStyling()}
      }
    }

    /* Table body styling */
    & tbody {
      ${getTableBodyRowStyling()} /* Applies styling for tbody rows */
    
    tr {
        ${getTableBodyRowStyling()}
      }

      td {
        ${getTableBodyCellStyling()}
      }
    }

    /* Table foot styling, if used */
    & tfoot {
      tr {
        ${getTableHeaderStyling()}
      }

      th,
      td {
        padding: var(--padding);
        text-align: left;
      }
    }

    /* Table row styling */
    & tr {
      border-top: var(--border-md-non);
      border-bottom: 2px solid var(--gray-6);
    }

    /* Styling for table header cells */
    & th {
      ${getTableHeaderStyling()}
    }

    /* Styling for table body cells */
    & td {
      ${getTableBodyCellStyling()}
    }
  }
`;
