import { useState, useEffect } from 'react';

export const useMediaQuery = (breakpoint) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = `(max-width: ${breakpoint}em)`;
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = (e) => setMatches(e.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, breakpoint]);

  return matches;
};
