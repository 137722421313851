import styled from 'styled-components';
import { motion } from 'framer-motion';
import DarkModeToggle from '../toggle/DarkModeToggle';
import Search from '../../features/search/Search';
import ProfileMenu from '../../features/profile/ProfileMenu';
import { useSidebar } from './AppLayout';
import { ToggleButton } from '../buttons/ToggleButton';
import useRoleCheck from '../../hooks/useRoleCheck';
import ButtonIcon from '../buttons/ButtonIcon';
import NotificationBell from '../../features/notification/NotificationBell';
const StyledHeader = styled(motion.header).withConfig({
  shouldForwardProp: (prop) => !['isSidebarOpen'].includes(prop),
})`
  width: 100%;
  padding: var(--padding);
  height: var(--header-height);
  min-height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  background-color: var(--gray-1);
  border-bottom: var(--border-sm-non);
  position: relative;
  z-index: 10;
  isolation: isolate;
`;

const HeaderTools = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: flex-end;
  margin-left: auto;
`;

const Header = () => {
  const { isSidebarOpen } = useSidebar();
  const { isUser } = useRoleCheck();

  return (
    <StyledHeader
      isSidebarOpen={isSidebarOpen}
      initial={false}
      animate={{
        paddingLeft: isSidebarOpen ? '2.4rem' : '1.6rem',
      }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
    >
      {!isSidebarOpen && <ToggleButton />}

      {isUser() && (
        <HeaderTools>
          <>
            <NotificationBell />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <ProfileMenu />
            </motion.div>
          </>
        </HeaderTools>
      )}
    </StyledHeader>
  );
};

export default Header;
