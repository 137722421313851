import StyledToast from '../ui/notifications/StyledToast';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SECS_PER_TOAST } from '../utils/constants';
import useDelayUnmount from '../hooks/useDelayUnmount';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toggleToast, setToggleToast] = useState(null); //State to control the toast

  const [lastValidToast, setLastValidToast] = useState(null);
  const showToast = useDelayUnmount(!!toggleToast, 1000);

  useEffect(() => {
    if (toggleToast) {
      setLastValidToast(toggleToast);
      const timeoutId = setTimeout(() => {
        setToggleToast(null);
      }, toggleToast.time || SECS_PER_TOAST);

      return () => clearTimeout(timeoutId);
    }
  }, [toggleToast]);

  const handleClose = () => {
    setToggleToast(null);
  };

  const toast = useCallback((params) => {
    setToggleToast(params);
  }, []);

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      {showToast && lastValidToast && (
        <StyledToast
          type={lastValidToast.type}
          message={lastValidToast.message}
          close={lastValidToast.close}
          handleClose={handleClose}
          unmount={!toggleToast}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
