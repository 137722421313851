import { HiOutlineLockClosed } from 'react-icons/hi2';
import Button from '../../ui/buttons/Button';
import {
  StyledAuthContainer,
  StyledForm,
} from '../../ui/forms/StyledAuthContainer';
import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { TextFade } from '../../ui/typography/Text';
import { useLogout } from '../../api/authentication/logout';
import { useNavigation } from '../../hooks/useNavigation';
import { useUpdatePassword } from '../../auth/useAuth';
import { useAuth } from '../../redux/features/auth-slice';
import { useState } from 'react';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import toast from 'react-hot-toast';
import { getRedirectPathOnAuth } from '../../auth/auth';
import { usePasswordValidation } from '../../auth/usePasswordValidation';
import PasswordStrengthIndicator from '../../auth/PasswordStrengthIndicator';
import { Menus } from '../../ui/menu/Menus';
import { Navigate, useLocation } from 'react-router-dom';
import { usePageTitle } from '../../hooks/usePageTitle';

const UpdatePasswordDetails = () => {
  usePageTitle('Update Password');
  const { mutate: logOut } = useLogout();
  const location = useLocation();
  const { from } = location?.state ?? {};
  const { navigateToPath } = useNavigation();
  const { user, session } = useAuth();
  const { mutate: updatePassword, isLoading: updatePasswordLoading } =
    useUpdatePassword();
  const [updatePasswordState, setUpdatePasswordState] = useState({
    password: '',
    confirmPassword: '',
  });
  const { validations, strength, isValid, config } = usePasswordValidation(
    updatePasswordState.password
  );

  const resetPasswordState = () => {
    setUpdatePasswordState({
      password: '',
      confirmPassword: '',
    });
  };

  function handlePasswordChange(e) {
    const { name, value } = e.target;
    setUpdatePasswordState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function validatePassword() {
    if (updatePasswordState.password !== updatePasswordState.confirmPassword) {
      return {
        isValid: false,
        message: 'Passwords do not match',
      };
    }
    return {
      isValid: true,
      message: 'Passwords match',
    };
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const { isValid, message } = validatePassword();
    if (!isValid) {
      toast(message, { type: 'warning' });
      return;
    }
    updatePassword(
      { password: updatePasswordState.password },
      {
        onSuccess: () => {
          resetPasswordState();
          toast.success('Password updated successfully!');
          if (user && session) {
            const redirectPath = getRedirectPathOnAuth(user);
            navigateToPath(redirectPath, { replace: true });
          } else {
            logOut();
            navigateToPath('/auth/login', { replace: true });
          }
        },
        onError: (error) => {
          resetPasswordState();
          const errorMessage = getCrudErrorMessage(
            'auth',
            error,
            'updatePassword'
          );
          toast.error(errorMessage?.description);
        },
      }
    );
  };

  if (!from) {
    return <Navigate to='/auth/login' replace />;
  }

  return (
    <StyledAuthContainer>
      <StyledForm onSubmit={onSubmit}>
        <TextFade>Enter your new password below.</TextFade>

        <Menus
          toggle={
            <StyledInputContainer>
              <HiOutlineLockClosed />
              <Input
                required
                type='password'
                autoComplete='off'
                placeholder='New Password'
                disabled={updatePasswordLoading}
                name='password'
                value={updatePasswordState.password}
                onChange={handlePasswordChange}
              />
              <InputLabel>New Password</InputLabel>
            </StyledInputContainer>
          }
        >
          <PasswordStrengthIndicator
            validations={validations}
            strength={strength}
            config={config}
          />
        </Menus>

        <StyledInputContainer>
          <HiOutlineLockClosed />
          <Input
            type='password'
            autoComplete='off'
            name='confirmPassword'
            value={updatePasswordState.confirmPassword}
            onChange={handlePasswordChange}
            required
            placeholder='Confirm Password'
            disabled={updatePasswordLoading || !isValid}
            error={
              updatePasswordState.confirmPassword &&
              updatePasswordState.password !==
                updatePasswordState.confirmPassword
            }
          />
          <InputLabel>Confirm Password</InputLabel>
        </StyledInputContainer>

        <Button
          isLoading={updatePasswordLoading}
          type='submit'
          disabled={updatePasswordLoading}
        >
          Update Password
        </Button>
      </StyledForm>
    </StyledAuthContainer>
  );
};

export default UpdatePasswordDetails;
