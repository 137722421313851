import { useMutation } from 'react-query';
import supabase from '../services/supabase';

/**
 * Verifies user's current password
 */
const verifyPassword = async ({ email, password }) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  return data;
};

/**
 * Updates user's password
 */
const updatePassword = async ({ password }) => {
  const { data, error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    throw error;
  }

  return data;
};

export function useVerifyPassword() {
  return useMutation({
    mutationFn: verifyPassword,
  });
}

export function useUpdatePassword() {
  return useMutation({
    mutationFn: updatePassword,
  });
}

export function getRedirectPathOnAuth(user) {
  const role = user?.user_metadata?.role;
  switch (role) {
    case 'admin':
      return '/dashboard/admin';
    case 'moderator':
      return '/dashboard/moderator';
    case 'creator':
      return '/dashboard/creator';
    case 'user':
      return '/discover';
    default:
      return '/login';
  }
}
