import styled from 'styled-components';

export const ButtonText = styled.button`
  color: var(--color-9);
  cursor: pointer;
  text-decoration: none;
  transition: all 300ms ease;
  font-weight: normal;
  font-size: inherit;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: inline;
  vertical-align: baseline;
  line-height: inherit;
  transition: all 300ms ease;

  &:hover:not(:disabled) {
    color: var(--color-10);
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
`;
