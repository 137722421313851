import supabase from '../../services/supabase';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/auth-slice';
import { useMutation } from 'react-query';
import { clearProfile } from '../../redux/features/profile-slice';

async function logoutUser() {
  let { error } = await supabase.auth.signOut();
  if (error) {
    throw new Error(error.message);
  }
}

export function useLogout() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: logoutUser,

    onSettled: () => {
      dispatch(clearProfile()); //clear the profile state
      dispatch(logout()); //clear the auth state
      //Rest local storage
      localStorage.clear();
    },
  });
}
