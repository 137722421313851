import { useMemo } from 'react';
import { blogTags } from '../blog/constants';
import useTags from '../../hooks/useTags';
import {
  SectionHeader,
  SectionHeading,
  StyledBody,
  StyledContainer,
  StyledFooter,
} from '../../styles/GeneralStyling';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import { CloseButton } from '../../ui/buttons/CloseButton';
import Button from '../../ui/buttons/Button';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import { useMutateProfile } from './useProfile';
import { useAuth } from '../../redux/features/auth-slice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import { updateProfile } from '../../redux/features/profile-slice';
import { useUpdateUser } from '../../api/authentication/user';
import { useInsertNotification } from '../notification/useNotifications';

const RequestCreatorAccess = ({ closePopup, onTagChange }) => {
  const { mutateProfile } = useMutateProfile();
  const { user } = useAuth();
  const { mutate: updateUser } = useUpdateUser();
  const dispatch = useDispatch();
  const categoriesConfig = useMemo(() => {
    return {
      options: blogTags,
      returnType: 'value',
      defaultValues: [],
      showCount: false,
      isDisabled: false,
      isLoading: false,
      onChange: onTagChange,
    };
  }, []);

  const {
    markup: tagPreferenceMarkup,
    selected: selectedCategories,
    resetSelections,
  } = useTags(categoriesConfig);

  const { mutate: insertNotification, isLoading: isInsertingNotification } =
    useInsertNotification();

  const validateForm = useMemo(() => {
    if (selectedCategories.length === 0) {
      return {
        isAllowed: false,
        message: 'Please select at least 1 category',
      };
    }

    if (selectedCategories.length > 6) {
      return {
        isAllowed: false,
        message: 'You can only select up to 6 categories',
      };
    }

    return {
      isAllowed: true,
      message: 'You can request creator access',
    };
  }, [selectedCategories]);

  function handleSubmit() {
    toast.dismiss();
    if (!validateForm.isAllowed) {
      return toast(validateForm.message, { type: 'warning' });
    }

    updateUser(
      {
        data: {
          role: 'creator',
        },
      },
      {
        onSuccess: () => {
          mutateProfile(
            user?.id,
            {
              role: 'creator',
              categories: selectedCategories,
            },
            {
              onSuccess: () => {},
              onError: (error) => {
                resetSelections();
                const errorMessage = getCrudErrorMessage(
                  'update',
                  error,
                  'profiles'
                );
                toast.error(errorMessage.description);
              },
            }
          );
          toast.success('Creator access requested successfully');
          dispatch(updateProfile({ role: 'creator' }));
          closePopup();

          insertNotification({
            userId: user?.id,
            entityType: 'profile',
            entityId: user?.id,
            metadata: {
              message: 'You have requested creator access',
              priority: 'low',
            },
            actionType: 'creator_access_requested',
            actorId: user?.id,
          });
        },
        onError: (error) => {
          const errorMessage = getCrudErrorMessage('update', error, 'user');
          toast.error(errorMessage.description);
        },
      }
    );
  }

  return (
    <StyledContainer size='medium'>
      <SectionHeader>
        <FlexRow justify='between'>
          <SectionHeading>Creator Access</SectionHeading>

          <CloseButton onClick={closePopup} />
        </FlexRow>
      </SectionHeader>
      <StyledBody>
        <FlexColumn gap={1.6}>
          {/* <StateDisplay
            variant='error'
            title='Failed to load preferences'
            page='preferences'
            description={
              'Oops! Something went wrong while loading  preferences.'
            }
            // action={{ onClick: () => refetchPreferences() }}
          /> */}

          <FlexRow wrap={true}>{tagPreferenceMarkup}</FlexRow>
        </FlexColumn>
      </StyledBody>
      <StyledFooter>
        <ButtonGroup>
          <Button variant='secondary' size='small'>
            Cancel
          </Button>
          <Button size='small' onClick={handleSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </StyledFooter>
    </StyledContainer>
  );
};

export default RequestCreatorAccess;
