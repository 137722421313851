import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getNotifications,
  getRecentNotifications,
  markAsRead,
  markAllAsRead,
  getUnreadCount,
  subscribeToNotifications,
  deleteNotification,
  insertNotification,
} from './ApiNotification';
import { useEffect } from 'react';
import supabase from '../../services/supabase';

// Query keys for better organization
export const notificationKeys = {
  all: ['notifications'],
  recent: () => [...notificationKeys.all, 'recent'],
  list: (filters) => [...notificationKeys.all, 'list', filters],
  unreadCount: () => [...notificationKeys.all, 'unread'],
};

// Hook for fetching paginated notifications
export function useNotifications({ userId, ...params } = {}) {
  return useQuery({
    queryKey: notificationKeys.list(params),
    queryFn: () => getNotifications({ userId, ...params }),
    enabled: !!userId, // Only run query if userId exists
    keepPreviousData: true,
  });
}

// Hook for fetching recent notifications (for the bell dropdown)
export function useRecentNotifications(userId) {
  return useQuery({
    queryKey: notificationKeys.recent(),
    queryFn: () => getRecentNotifications({ userId }),
    enabled: !!userId,
    refetchInterval: 30000,
  });
}

// Hook for deleting a notification
export function useDeleteNotification() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: notificationKeys.all });
    },
  });
}

// Hook for getting unread count
export function useUnreadCount(userId) {
  return useQuery({
    queryKey: notificationKeys.unreadCount(),
    queryFn: () => getUnreadCount(userId),
    enabled: !!userId,
    refetchInterval: 30000,
  });
}

// Hook for marking a notification as read
export function useMarkAsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markAsRead,
    onSuccess: (updatedNotification) => {
      // Update queries that might be affected
      queryClient.invalidateQueries({ queryKey: notificationKeys.all });
    },
    // Optimistic update
    onMutate: async (notificationId) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({ queryKey: notificationKeys.all });

      // Update the notification optimistically
      queryClient.setQueriesData(
        { queryKey: notificationKeys.all },
        (oldData) => {
          // Handle both list and recent notifications
          if (Array.isArray(oldData)) {
            return oldData.map((notification) =>
              notification.id === notificationId
                ? { ...notification, read_at: new Date().toISOString() }
                : notification
            );
          }
          return oldData;
        }
      );
    },
  });
}

// Hook for marking all notifications as read
export function useMarkAllAsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markAllAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: notificationKeys.all });
    },
  });
}

// Hook for inserting a notification
export function useInsertNotification() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: insertNotification,
    onSuccess: (newNotification) => {
      // Optimistically update the recent notifications list
      queryClient.setQueryData(notificationKeys.recent(), (old = []) =>
        [newNotification, ...old].slice(0, 5)
      );

      // Increment unread count
      queryClient.setQueryData(
        notificationKeys.unreadCount(),
        (count = 0) => count + 1
      );

      // Invalidate all notification queries to refetch
      queryClient.invalidateQueries({ queryKey: notificationKeys.all });
    },
  });
}

export function useNotificationSubscription(userId) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!userId) return;

    const subscription = supabase
      .channel('notifications')
      .on(
        'postgres_changes',
        {
          event: '*', // Listen to all events (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${userId}`,
        },
        (payload) => {
          console.log('payload', payload);
          // Handle different types of changes
          switch (payload.eventType) {
            case 'INSERT':
              // Add new notification to recent list
              queryClient.setQueryData(notificationKeys.recent(), (old = []) =>
                [payload.new, ...old].slice(0, 5)
              );
              // Update unread count
              queryClient.setQueryData(
                notificationKeys.unreadCount(),
                (count = 0) => count + 1
              );
              break;

            case 'UPDATE':
              // Update notification in all queries
              queryClient.setQueriesData(
                { queryKey: notificationKeys.all },
                (old) => {
                  if (!old) return old;
                  if (Array.isArray(old)) {
                    return old.map((notification) =>
                      notification.id === payload.new.id
                        ? payload.new
                        : notification
                    );
                  }
                  return old;
                }
              );
              // Update unread count if notification was marked as read
              if (
                payload.old.read_at === null &&
                payload.new.read_at !== null
              ) {
                queryClient.setQueryData(
                  notificationKeys.unreadCount(),
                  (count = 0) => Math.max(0, count - 1)
                );
              }
              break;

            case 'DELETE':
              // Remove notification from all queries
              queryClient.setQueriesData(
                { queryKey: notificationKeys.all },
                (old) => {
                  if (!old) return old;
                  if (Array.isArray(old)) {
                    return old.filter(
                      (notification) => notification.id !== payload.old.id
                    );
                  }
                  return old;
                }
              );
              // Update unread count if deleted notification was unread
              if (payload.old.read_at === null) {
                queryClient.setQueryData(
                  notificationKeys.unreadCount(),
                  (count = 0) => Math.max(0, count - 1)
                );
              }
              break;
          }
        }
      )
      .subscribe();

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, [userId, queryClient]);
}
