import { useMemo } from 'react';
import {
  useSupabaseOperation,
  useSupabaseQuery,
} from '../../api/supabaseService';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import toast from 'react-hot-toast';

export const profileFields = [
  'user_id',
  'email',
  'first_name',
  'last_name',
  'created_at',
  'country',
  'role',
  'bio',
  'profile_picture_url',
  'cover_photo_url',
  'account_status',
  'categories',
  'creator_verified_at',
];

// Define all possible relations with their fields
export const PROFILE_RELATIONS = {
  blog_posts: {
    fields: ['*'],
  },
  content_preferences: {
    fields: ['*'],
  },
  preferences: {
    fields: ['*'],
  },
  engagements: {
    fields: ['*'],
  },
};

export const useFetchProfile = (options) => {
  const {
    users,
    onSuccess,
    onError,
    onSettled,
    enabled,
    relationTables = [],
  } = options;

  const single = Array.isArray(users) ? false : true;

  // Create relations object based on requested tables
  const selectedRelations = useMemo(() => {
    return relationTables.reduce((acc, tableName) => {
      if (PROFILE_RELATIONS[tableName]) {
        acc[tableName] = PROFILE_RELATIONS[tableName];
      }
      return acc;
    }, {});
  }, [relationTables]);

  const fetchFilters = useMemo(() => {
    if (single) {
      return [
        {
          column: 'user_id',
          value: users,
          operator: 'eq',
        },
      ];
    } else {
      return [{ operator: 'in', value: users, column: 'user_id' }];
    }
  }, [users, single]);

  const baseQueryOptions = {
    table: 'profiles',
    operation: 'read',
    fields: profileFields,
    single,
    filters: fetchFilters,
    relations: selectedRelations,
  };

  const { data, isLoading, error, isError, refetch } = useSupabaseQuery(
    baseQueryOptions,
    {
      queryKey: ['profiles', { users, relations: relationTables }],
      enabled: !!users && enabled,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      onSuccess,
      onError: (error) => {
        const errorMessage = getCrudErrorMessage('read', error, 'profile');
        toast.error(errorMessage.description);
        if (onError) {
          onError(error);
        }
      },
      onSettled: () => {
        if (onSettled) {
          onSettled();
        }
      },
    }
  );

  return { data, isLoading, error, isError, refetch };
};

export function useMutateProfile() {
  const {
    mutate: updateProfile,
    isLoading,
    isError,
    error,
  } = useSupabaseOperation();

  const mutateProfile = (
    users,
    payload,
    config = { showToast: true, relations: false }
  ) => {
    const filters = Array.isArray(users)
      ? [{ operator: 'in', value: users, column: 'user_id' }]
      : [{ operator: 'eq', value: users, column: 'user_id' }];

    const operation = config?.operation || 'update';

    updateProfile({
      table: 'profiles',
      operation,
      filters: filters,
      payload,
      relations: config?.relations || {},
      onSuccess: (data) => {
        if (config.showToast) {
          toast.success('Profile updated successfully');
        }
        config.onSuccess?.(data);
      },
      onError: (error) => {
        const errorMessage = getCrudErrorMessage('update', error, 'profile');
        if (config.showToast) {
          toast.error(errorMessage.description);
        }
        config.onError?.(error);
      },
      onSettled: () => {
        config.onSettled?.();
      },
      ...config,
    });
  };

  return {
    mutateProfile,
    isLoading,
    isError,
    error,
  };
}
