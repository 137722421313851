import { useEffect, useState } from 'react';

/**
 *
 * @param {*} isMounted //boolean value that indicates whether the component is mounted or not
 * @param {*} delay //delay in milliseconds before the component is unmounted. Default value is 300ms
 * @returns //returns a boolean value that indicates whether the component should be mounted or not
 */
const useDelayUnmount = (isMounted, delay = 300) => {
  const [isComponentMounted, setisComponentMounted] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isMounted && !isComponentMounted) {
      setisComponentMounted(true);
    } else if (!isMounted && isComponentMounted) {
      timeoutId = setTimeout(() => {
        setisComponentMounted(false);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, isMounted, isComponentMounted]);

  return isComponentMounted;
};

export default useDelayUnmount;
