export const crudErrorMessages = {
  read: {
    title: 'Error Loading Data',
    defaultDescription: 'Unable to load the requested data',
  },
  insert: {
    title: 'Error Creating Data',
    defaultDescription: 'Unable to create new entry',
  },
  update: {
    title: 'Error Updating Data',
    defaultDescription: 'Unable to update changes',
  },
  delete: {
    title: 'Error Deleting Data',
    defaultDescription: 'Unable to delete the selected item',
  },
  auth: {
    title: 'Authentication Error',
    defaultDescription: 'Unable to authenticate user',
  },
};

export const getCrudErrorMessage = (operation, error, context) => {
  if (!navigator.onLine) {
    return {
      title: 'No Internet Connection',
      description: 'Please check your internet connection and try again',
    };
  }

  if (error?.message?.includes('Failed to fetch')) {
    return {
      title: 'Connection Error',
      description: 'Unable to reach our servers. Please try again later',
    };
  }

  // Auth specific errors
  if (error?.code === 'auth/invalid-email') {
    return {
      title: 'Invalid Email',
      description: 'Please provide a valid email address',
    };
  }

  if (error?.code === 'auth/email-already-in-use') {
    return {
      title: 'Email In Use',
      description: 'This email is already registered',
    };
  }

  if (error?.code === 'auth/wrong-password') {
    return {
      title: 'Invalid Credentials',
      description: 'Incorrect email or password',
    };
  }

  if (error?.code === 'auth/user-not-found') {
    return {
      title: 'Account Not Found',
      description: 'No account exists with this email',
    };
  }

  if (error?.code === 'auth/too-many-requests') {
    return {
      title: 'Too Many Attempts',
      description: 'Please try again later',
    };
  }

  if (error?.code === '23505') {
    // Unique violation
    return {
      title: 'Profile Error',
      description: 'A profile with this information already exists',
    };
  }

  if (error?.code === 'PGRST116') {
    // Not found in database
    return {
      title: 'Profile Not Found',
      description: 'Unable to find user profile',
    };
  }

  const baseError = crudErrorMessages[operation];

  return {
    title: baseError.title,
    description: error?.message || baseError.defaultDescription,
  };
};
