import { useEffect, useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import supabase from '../../services/supabase';
import toast from 'react-hot-toast';
import { logout, updateAuth } from '../../redux/features/auth-slice';
import { useDispatch } from 'react-redux';
import { clearProfile, setProfile } from '../../redux/features/profile-slice';
import { useMutation, useQuery } from 'react-query';
import { FullScreenLoading } from '../loading/Spinner';

function SessionManager({ children }) {
  const TEST_MODE = false;
  // More logical test mode timings
  const SESSION_DURATION = TEST_MODE ? 30 * 1000 : 3600 * 1000; // 30 seconds in test
  const CHECK_BEFORE_EXPIRY_TIME = TEST_MODE ? 5 * 1000 : 5 * 60 * 1000; // check 5 seconds before
  const INACTIVE_THRESHOLD = TEST_MODE ? 10 * 1000 : 30 * 60 * 1000; // inactive after 10 seconds
  const DEBOUNCE_USER_ACTIVITY_TIME = 300;
  const DEBOUNCE_SCROLL_TIME = 1000; // Longer debounce for scroll events
  const lastActivityTimeRef = useRef(Date.now());
  const checkTimerRef = useRef(null);
  const dispatch = useDispatch();
  const [session, setSession] = useState(null);

  const { mutate: getSession, isLoading: getSessionLoading } = useMutation({
    mutationFn: async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (!session || error) {
        return null;
      }
      return session;
    },
    onSuccess: (session) => {
      setSession(session);
      scheduleSessionCheck(session);
    },
    onError: () => {
      handleAuthReset();
    },
  });

  useEffect(() => {
    //Get user session
    getSession();
    // Listen for auth state changes
    const {
      data: { authListener },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      dispatch(updateAuth({ user: session?.user, session: session }));
      scheduleSessionCheck(session);
    });

    return () => authListener?.subscription?.unsubscribe();
  }, []);

  const resetInactiveTimer = useCallback(() => {
    lastActivityTimeRef.current = 0;
  }, []);

  const handleAuthReset = useCallback(() => {
    dispatch(clearProfile());
    dispatch(logout());
    //Rest local storage
    localStorage.clear();
  }, [dispatch]);

  const handleLogout = useCallback(async () => {
    if (checkTimerRef.current) {
      clearTimeout(checkTimerRef.current);
    }

    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error('Failed to sign out');
    } else {
      toast.success('Signed out successfully');
    }

    // Always reset auth state
    resetInactiveTimer();
    handleAuthReset();
  }, [handleAuthReset, resetInactiveTimer]);

  //Fetch and populate profile state
  useQuery({
    queryKey: ['profile', { session: session }],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('user_id', session?.user?.id)
        .single();

      if (error) {
        return null;
      }
      return data;
    },
    enabled: !!session,
    onSuccess: (data) => {
      dispatch(setProfile(data));
    },
  });

  const { mutate: refreshSession } = useMutation({
    mutationFn: async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.refreshSession();
      if (error || !session) {
        return null;
      }
      return session;
    },
  });

  const scheduleSessionCheck = useCallback(
    (session) => {
      if (!session?.expires_at) return;

      if (checkTimerRef.current) {
        clearTimeout(checkTimerRef.current);
      }

      const currentTime = Date.now();
      const expiryTimeInMs = TEST_MODE
        ? currentTime + 20 * 1000 // 20 seconds from now
        : session.expires_at * 1000;
      const timeUntilExpiry = expiryTimeInMs - currentTime;
      const checkTime = timeUntilExpiry - CHECK_BEFORE_EXPIRY_TIME;

      if (checkTime <= 0) {
        handleLogout();
        return;
      }

      checkTimerRef.current = setTimeout(() => {
        const currentTime = Date.now();
        const inactiveTime = currentTime - lastActivityTimeRef.current;
        const remainingTime = expiryTimeInMs - currentTime;

        if (
          lastActivityTimeRef.current > 0 &&
          inactiveTime < INACTIVE_THRESHOLD &&
          remainingTime <= CHECK_BEFORE_EXPIRY_TIME
        ) {
          refreshSession();
        } else if (inactiveTime >= INACTIVE_THRESHOLD) {
          handleLogout();
        }
      }, checkTime);

      return () => {
        if (checkTimerRef.current) {
          clearTimeout(checkTimerRef.current);
          checkTimerRef.current = null;
        }
      };
    },
    [handleLogout, dispatch]
  );

  // Only call resetInactiveTimer in the actual user activity handler
  const handleUserActivity = useCallback(() => {
    lastActivityTimeRef.current = Date.now();
  }, []);

  const debouncedHandleUserActivity = useCallback(
    debounce(handleUserActivity, DEBOUNCE_USER_ACTIVITY_TIME),
    []
  );

  const debouncedHandleScroll = useCallback(
    debounce(handleUserActivity, DEBOUNCE_SCROLL_TIME),
    []
  );

  useEffect(() => {
    // Only add event listeners if user is logged in

    const standardEvents = [
      { name: 'click', options: {} },
      { name: 'keydown', options: {} },
      { name: 'touchstart', options: { passive: true } },
      { name: 'input', options: {} },
      { name: 'submit', options: {} },
      { name: 'focus', options: {} },
    ];

    standardEvents.forEach(({ name, options }) => {
      window.addEventListener(name, debouncedHandleUserActivity, options);
    });

    window.addEventListener('scroll', debouncedHandleScroll, {
      passive: true,
    });

    return () => {
      standardEvents.forEach(({ name, options }) => {
        window.removeEventListener(name, debouncedHandleUserActivity, options);
      });
      window.removeEventListener('scroll', debouncedHandleScroll, {
        passive: true,
      });
      debouncedHandleUserActivity.cancel();
      debouncedHandleScroll.cancel();
    };
  }, [debouncedHandleUserActivity, debouncedHandleScroll, session]); // Track user instead of session

  // Clear all timers and listeners
  useEffect(() => {
    return () => {
      if (checkTimerRef.current) {
        clearTimeout(checkTimerRef.current);
      }
      debouncedHandleUserActivity.cancel();
    };
  }, [debouncedHandleUserActivity]);

  if (getSessionLoading) {
    return <FullScreenLoading />;
  }

  return children;
}

export default SessionManager;
