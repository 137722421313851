import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import authSlice from './redux/features/auth-slice';
import profileSlice from './redux/features/profile-slice';
import preferenceSlice from './redux/features/preference-slice';
import blogSlice from './redux/features/blog-slice';
import bookmarkSlice from './redux/features/bookmark-slice';
import permissionsSlice from './redux/features/permissions-slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'profile'],
};

const rootReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  preference: preferenceSlice,
  blog: blogSlice,
  bookmark: bookmarkSlice,
  permissions: permissionsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);
