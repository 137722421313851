import styled from 'styled-components';
import { Card, CardBody } from '../../ui/layout/Card';
import { Input, StyledInputContainer } from '../../ui/forms/StyledInput';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexColumn } from '../../ui/layout/Flex';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import Button from '../../ui/buttons/Button';
import { isEmpty } from 'lodash';
import { InputError } from '../../styles/GeneralStyling';
import isURL from 'validator/lib/isURL';
import { useEditor } from '../../context/EditorContext';

const StyledLinkForm = styled(Card)`
  min-width: 44rem;
`;

const StyledPreviewImage = styled.figure`
  display: block;
  max-width: 44rem;
  height: auto;
  max-height: 32rem;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  overflow: hidden;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

/**
 * Form component for handling link and media prompts in the editor
 * @component
 * @param {Object} props
 * @param {Function} props.onSubmit - Handler function called when form is submitted
 * @param {Object} props.promptState - State object containing form values
 * @param {Function} props.setPromptState - Function to update form state
 * @param {string} props.placeholder - Placeholder text for URL input
 * @param {Function} props.handleImageLoad - Handler for when image preview loads successfully
 * @param {Function} props.handleImageError - Handler for when image preview fails to load
 * @param {('media'|'link')} props.mediaType - Type of prompt - either 'media' or 'link'
 * @param {Function} props.closePopup - Function to close the prompt popup
 * @returns {JSX.Element} Rendered form component
 */

export const PromptForm = ({
  onSubmit,
  promptState,
  setPromptState,
  placeholder,
  handleImageLoad,
  handleImageError,
  mediaType,
  closePopup,
}) => {
  const isMedia = mediaType === 'media';
  const isLink = mediaType === 'link';
  const urlInputRef = useRef();
  const [errors, setErrors] = useState({});
  const { setShouldFocus } = useEditor();

  const clearErrors = useCallback((field) => {
    if (field) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    } else {
      setErrors({});
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let newErrors = {};

    if (isMedia) {
      if (!promptState.isImageValid) {
        newErrors.urlValue = 'Url provided is not a valid image';
      }
      if (!promptState.urlValue || !isURL(promptState.urlValue)) {
        newErrors.urlValue = 'Please enter a valid url';
      }
      if (!promptState.altText) {
        newErrors.altText = 'Image description is required';
      }
    }

    if (!isEmpty(newErrors)) {
      setErrors(newErrors);
      return;
    }

    clearErrors();

    onSubmit(e);
  };

  function handleClose() {
    if (isMedia) {
      clearErrors();
    }
    setShouldFocus(true);
    closePopup();
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setPromptState({ ...promptState, [name]: value });
    clearErrors(name);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (urlInputRef && urlInputRef.current) {
        urlInputRef.current.focus();
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [urlInputRef]);

  return (
    <StyledLinkForm as={'form'} onSubmit={handleSubmit}>
      <CardBody>
        <FlexColumn gap={1.6}>
          <StyledInputContainer>
            <Input
              name='urlValue'
              ref={urlInputRef}
              type='text'
              value={promptState.urlValue}
              onChange={handleChange}
              placeholder={placeholder || 'Enter URL...'}
              error={errors?.urlValue}
            />
            {errors?.urlValue && <InputError>{errors?.urlValue}</InputError>}
          </StyledInputContainer>

          {promptState.urlValue && isMedia && promptState.isImageValid && (
            <StyledPreviewImage style={{ alignSelf: 'center' }}>
              <img
                src={promptState.urlValue}
                loading='lazy'
                alt={promptState.altText}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            </StyledPreviewImage>
          )}

          {isMedia && promptState.urlValue && promptState.isImageValid && (
            <StyledInputContainer>
              <Input
                name='altText'
                type='text'
                value={promptState.altText}
                onChange={handleChange}
                placeholder={'Enter image description...'}
                error={errors?.altText}
              />
              {errors?.altText && <InputError>{errors?.altText}</InputError>}
            </StyledInputContainer>
          )}

          <ButtonGroup>
            <Button
              size='small'
              onClick={handleClose}
              variant='secondary'
              type='button'
            >
              Close
            </Button>

            <Button size='small' variant='primary' type='submit'>
              Confirm
            </Button>
          </ButtonGroup>
        </FlexColumn>
      </CardBody>
    </StyledLinkForm>
  );
};
