import { useMutation } from 'react-query';
import { resetPasswordForEmail, updatePassword, verifyOTP } from './ApiAuth';

export const useVerifyOTP = () => {
  return useMutation({
    mutationFn: verifyOTP,
  });
};

export const useResetPasswordForEmail = () => {
  return useMutation({
    mutationFn: resetPasswordForEmail,
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: updatePassword,
  });
};
