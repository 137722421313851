import {
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import { LinkComponent } from './LinkEditor';
import { Media } from './MediaEditor';
import toast from 'react-hot-toast';

export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkComponent,
  },
]);

export const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
};

export function handleTabKey(e, editorState, setEditorState) {
  e.preventDefault();

  const maxDepth = 4;
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getStartKey());

  if (
    block.getType().startsWith('ordered-list-item') ||
    block.getType().startsWith('unordered-list-item')
  ) {
    // For list items, handle indentation
    const newEditorState = RichUtils.onTab(e, editorState, maxDepth);
    if (newEditorState !== editorState) {
      setEditorState(newEditorState);
    }
  } else {
    // For regular paragraphs, insert a tab character
    e.preventDefault();
    const newContentState = Modifier.replaceText(content, selection, '\t');
    setEditorState(
      EditorState.push(editorState, newContentState, 'insert-characters')
    );
  }
  return null;
}

export const keyCommand = (e, os) => {
  const keyCode = e.keyCode;

  const isCtrlOrCmd = os === 'mac' ? e.metaKey : e.ctrlKey;

  if (isCtrlOrCmd) {
    switch (keyCode) {
      case 90: // Z key
        e.preventDefault();
        return os === 'mac' && e.shiftKey ? 'redo' : 'undo';
      case 89: // Y key
        e.preventDefault();
        return os !== 'mac' ? 'redo' : null;
      case 66: // B key
        e.preventDefault();
        return 'bold';
      case 73: // I key
        e.preventDefault();
        return 'italic';
      case 85: // U key
        e.preventDefault();
        return 'underline';
      case 83: // S key
        e.preventDefault();
        return 'save';
      case 76: // L key
        e.preventDefault();
        return 'align-left';
      case 69: // E key
        e.preventDefault();
        return 'align-center';
      case 82: // R key
        e.preventDefault();
        return 'align-right';
      default:
        return null;
    }
  }
  return null;
};

export const isEditorEmpty = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return (
    !contentState.hasText() &&
    contentState.getBlockMap().first().getType() === 'unstyled'
  );
};

export const handleKeyCommand = (command, editorState) => {
  switch (command) {
    case 'undo':
      undo();
      return 'handled';
    case 'redo':
      redo();
      return 'handled';
    case 'bold':
      setEditorState(toggleInlineStyle(editorState, 'BOLD'));
      return 'handled';
    case 'italic':
      setEditorState(toggleInlineStyle(editorState, 'ITALIC'));
      return 'handled';
    case 'underline':
      setEditorState(toggleInlineStyle(editorState, 'UNDERLINE'));
      return 'handled';
    case 'save':
      saveContent();
      return 'handled';
    case 'align-left':
      setEditorState(toggleAlignment(editorState, 'left'));
      return 'handled';
    case 'align-center':
      setEditorState(toggleAlignment(editorState, 'center'));
      return 'handled';
    case 'align-right':
      setEditorState(toggleAlignment(editorState, 'right'));
      return 'handled';
  }

  const newState = RichUtils.handleKeyCommand(editorState, command);
  if (newState) {
    setEditorState(newState);
    return 'handled';
  }
  return 'not-handled';
};

export const toggleAlignment = (editorState, alignment) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockKey = selection.getStartKey();
  const blockRange = selection.merge({
    anchorKey: blockKey,
    focusKey: blockKey,
  });

  const newContentState = Modifier.mergeBlockData(contentState, blockRange, {
    textAlign: alignment,
  });

  return EditorState.push(editorState, newContentState, 'change-block-data');
};

export const toggleInlineStyle = (editorState, style) => {
  return RichUtils.toggleInlineStyle(
    EditorState.forceSelection(editorState, editorState.getSelection()),
    style
  );
};

export const toggleBlockType = (editorState, blockType) => {
  return RichUtils.toggleBlockType(
    EditorState.forceSelection(editorState, editorState.getSelection()),
    blockType
  );
};

export const blockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  const textAlign = contentBlock.getData().get('textAlign');

  const alignmentStyles = {
    left: 'align-left',
    center: 'align-center',
    right: 'align-right',
  };

  const styles = [
    alignmentStyles[textAlign],
    type === 'blockquote' ? 'blockquote' : '',
  ].filter(Boolean); // Remove any empty strings

  return styles.join(' ');
};

export const createEmptyEditorState = () => {
  return EditorState.createEmpty(decorator);
};

export const convertFromRawContent = (contentString) => {
  if (!contentString) {
    return createEmptyEditorState();
  }

  try {
    // 1. Parse the JSON string to get the raw content state
    const rawContentState = JSON.parse(contentString);

    // 2. Validate the raw content state has required properties
    if (!rawContentState.blocks || !Array.isArray(rawContentState.blocks)) {
      throw new Error('Invalid content structure: missing blocks array');
    }

    // 3. Convert the raw content state to ContentState
    const contentState = convertFromRaw(rawContentState);

    // 4. Create and return new EditorState with the content and decorator
    return EditorState.createWithContent(contentState, decorator);
  } catch (error) {
    console.error('Error converting content from raw:', error);
    toast.error('Failed to load content. Using empty editor.');
    return createEmptyEditorState();
  }
};

export const convertToRawContent = (editorState) => {
  if (!editorState) {
    toast.error('No editor state provided to convert');
    return '';
  }

  try {
    // 1. Get the current content state
    const contentState = editorState.getCurrentContent();

    // 2. Convert to raw format
    const rawContentState = convertToRaw(contentState);

    // 3. Convert to JSON string
    const contentString = JSON.stringify(rawContentState);

    if (!contentString) {
      throw new Error('Failed to stringify content');
    }

    return contentString;
  } catch (error) {
    console.error('Error converting content to raw:', error);
    toast.error('Failed to save content');
    return '';
  }
};

export function getWordCount(editorState) {
  if (!editorState) return 0;
  const contentState = editorState?.getCurrentContent();
  const plainText = contentState?.getPlainText();
  return plainText
    ?.trim()
    ?.split(/\s+/)
    ?.filter((word) => word !== '').length;
}

export function calculateReadingTime(wordCount, readingSpeed = 200) {
  if (!wordCount) return 0;
  const readingTime = Math.ceil(wordCount / readingSpeed);
  return readingTime;
}

export function uploadMedia(mutationFn, payload) {}
