import styled, { css } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi2';
import Button from '../buttons/Button';

// Reusable styling functions
export const getTableHeaderStyling = () => css`
  padding: var(--padding);
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  color: var(--gray-12);
  border-bottom: var(--border-md-non);
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: var(--gray-3);
  }

  &:active:not(:disabled) {
    background-color: var(--gray-4);
  }

  & svg {
    height: 1.4rem;
    width: 1.4rem;
    vertical-align: middle;
    margin-left: 0.4rem;
  }
`;

export const getTableBodyRowStyling = (props) => css`
  transition: all 200ms ease;

  &:nth-of-type(even) {
    background-color: var(--gray-1);
  }

  &:nth-of-type(odd) {
    background-color: var(--gray-2);
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const getTableBodyCellStyling = () => css`
  padding: var(--padding);
  vertical-align: middle;
  white-space: normal;
  color: var(--gray-12);
  border-bottom: var(--border-sm-non);
  transition: all 0.3s ease;
`;

export const getTableStyling = () => css`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  & ::-webkit-scrollbar {
    display: none;
  }

  ${media.mobile`
    font-size: 1.4rem;
  `}
`;

// Styled components

export const TableScrollContainer = styled.div`
  overflow-x: auto;
  min-height: 20rem;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: var(--gray-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-6);
    border-radius: var(--border-radius-sm);
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  background: var(--gray-2);
  border-radius: var(--border-radius-lg);
  min-height: 20rem;
  overflow: hidden;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: var(--gray-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-6);
    border-radius: var(--border-radius-sm);
  }
`;

export const TableHeader = styled.div`
  padding: var(--padding);
  border-bottom: var(--border-md-non);
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: start;
  gap: 1.2rem;
  width: 100%;
`;

export const TableFooter = styled.div`
  padding: var(--padding);
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--border-md-non);
`;

export const Table = styled.table.withConfig({
  shouldForwardProp: (prop) =>
    !['uppercase', 'bold', 'hover', 'active'].includes(prop),
})`
  ${getTableStyling()}
`;

export const Caption = styled.caption`
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--gray-12);
  padding: var(--padding);
  background-color: var(--gray-1);
  border-bottom: var(--border-md-non);
`;

export const TableHead = styled.thead`
  /* background-color: var(--gray-2); */
`;

export const TableHeadRow = styled.tr`
  border-bottom: var(--border-md-non);
`;

export const TableHeadCell = styled.th.withConfig({
  shouldForwardProp: (prop) => !['uppercase', 'bold'].includes(prop),
})`
  ${(props) => getTableHeaderStyling(props)}
`;

export const TableBody = styled.tbody`
  /* background-color: var(--gray-2); */
`;

export const TableBodyRow = styled.tr.withConfig({
  shouldForwardProp: (prop) => !['hover', 'active'].includes(prop),
})`
  ${(props) => getTableBodyRowStyling(props)}
  ${(props) =>
    props.active &&
    css`
      background-color: var(--gray-3) !important;
    `}
`;

export const TableBodyCell = styled.td`
  ${getTableBodyCellStyling()}
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding);
  background-color: var(--gray-2);
  border-top: var(--border-md-non);
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
`;

const PaginationInfo = styled.span`
  font-size: 1.4rem;
  color: var(--gray-11);
`;

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: var(--gap-md);
`;

const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-md);
  background-color: var(--gray-3);
  color: var(--gray-11);
  border: none;
  border-radius: var(--border-radius-btn);
  font-size: 1.4rem;
  font-weight: 600;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: var(--gray-4);
  }

  &:active:not(:disabled) {
    background-color: var(--gray-5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const TablePagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPrevious,
  onNext,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <PaginationWrapper>
      <PaginationInfo>
        Showing {startItem} - {endItem} of {totalItems}
      </PaginationInfo>
      <PaginationControls>
        <PaginationButton onClick={onPrevious} disabled={currentPage === 1}>
          <HiOutlineChevronLeft />
          Previous
        </PaginationButton>
        <PaginationButton
          onClick={onNext}
          disabled={currentPage === totalPages}
        >
          Next
          <HiOutlineChevronRight />
        </PaginationButton>
      </PaginationControls>
    </PaginationWrapper>
  );
};

export const TableStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--padding);
  text-align: center;
  background-color: var(--gray-3);
  min-height: 20rem;
  gap: var(--gap-lg);
`;

export const StateTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--gray-12);
`;

export const StateDescription = styled.p`
  font-size: 1.4rem;
  color: var(--gray-11);
`;

export const EmptyState = ({ title, description, action }) => (
  <TableStateWrapper>
    <StateTitle>{title || 'No data available'}</StateTitle>
    <StateDescription>
      {description || 'There are no items to display at the moment.'}
    </StateDescription>
    {action && (
      <Button
        variant={action.variant || 'primary'}
        type={action.type || 'button'}
        onClick={action.onClick}
      >
        {action.text || action.label}
        {action.icon && action.icon}
      </Button>
    )}
  </TableStateWrapper>
);

export const ErrorState = ({ title, description, action }) => (
  <TableStateWrapper>
    <StateTitle>{title || 'An error occurred'}</StateTitle>
    <StateDescription>
      {description ||
        'We encountered an issue while loading the data. Please try again.'}
    </StateDescription>
    {action && (
      <Button
        variant={action.variant || 'primary'}
        type={action.type || 'button'}
        onClick={action.onClick}
      >
        {action?.text || action?.label}
        {action?.icon && action.icon}
      </Button>
    )}
  </TableStateWrapper>
);

// New component for responsive table wrapper

/**
 * import styled, { css } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

// ... (previous table styling code remains the same)

// New components for empty and error states
export const TableStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--padding-xl);
  text-align: center;
  background-color: var(--gray-1);
  border-radius: 8px;
  min-height: 200px;
`;

export const StateTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--gray-11);
  margin-bottom: var(--margin-sm);
`;

export const StateDescription = styled.p`
  font-size: 1.4rem;
  color: var(--gray-9);
  margin-bottom: var(--margin-md);
`;

export const StateAction = styled.button`
  padding: var(--padding) var(--padding-lg);
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary-color-dark);
  }
`;

export const EmptyState = ({ title, description, actionText, onAction }) => (
  <TableStateWrapper>
    <StateTitle>{title || 'No data available'}</StateTitle>
    <StateDescription>{description || 'There are no items to display at the moment.'}</StateDescription>
    {actionText && <StateAction onClick={onAction}>{actionText}</StateAction>}
  </TableStateWrapper>
);

export const ErrorState = ({ title, description, actionText, onAction }) => (
  <TableStateWrapper>
    <StateTitle>{title || 'An error occurred'}</StateTitle>
    <StateDescription>{description || 'We encountered an issue while loading the data. Please try again.'}</StateDescription>
    {actionText && <StateAction onClick={onAction}>{actionText}</StateAction>}
  </TableStateWrapper>
);

// Footer component for pagination
export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-lg);
  background-color: var(--gray-2);
  border-top: 1px solid var(--gray-4);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const PaginationInfo = styled.span`
  font-size: 1.4rem;
  color: var(--gray-10);
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: var(--margin-sm);
`;

export const PaginationButton = styled.button`
  padding: var(--padding) var(--padding-lg);
  background-color: var(--white);
  color: var(--gray-11);
  border: 1px solid var(--gray-5);
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--gray-3);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// Updated Table component to include potential empty/error states and footer
export const EnhancedTable = ({
  children,
  isEmpty,
  isError,
  emptyProps,
  errorProps,
  footerProps,
}) => (
  <TableWrapper>
    <Table>
      {isEmpty && <EmptyState {...emptyProps} />}
      {isError && <ErrorState {...errorProps} />}
      {!isEmpty && !isError && children}
    </Table>
    {footerProps && (
      <TableFooter>
        <PaginationInfo>{footerProps.paginationInfo}</PaginationInfo>
        <PaginationControls>
          <PaginationButton onClick={footerProps.onPrevious} disabled={footerProps.isFirstPage}>
            Previous
          </PaginationButton>
          <PaginationButton onClick={footerProps.onNext} disabled={footerProps.isLastPage}>
            Next
          </PaginationButton>
        </PaginationControls>
      </TableFooter>
    )}
  </TableWrapper>
);
 */

/**
 *   <TableWrapper>
          <Table>
            <Caption>Your Table Caption</Caption>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell>Header 1</TableHeadCell>
                <TableHeadCell>Header 2</TableHeadCell>
                <TableHeadCell>Header 3</TableHeadCell>
                <TableHeadCell>Header 4</TableHeadCell>
                <TableHeadCell>Header 5</TableHeadCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              <TableBodyRow>
                <TableBodyCell>Data 1</TableBodyCell>
                <TableBodyCell>Data 2</TableBodyCell>
                <TableBodyCell>Data 3</TableBodyCell>
                <TableBodyCell>Data 4</TableBodyCell>
                <TableBodyCell>Data 5</TableBodyCell>
              </TableBodyRow>
              <TableBodyRow>
                <TableBodyCell>Data 1</TableBodyCell>
                <TableBodyCell>Data 2</TableBodyCell>
                <TableBodyCell>Data 3</TableBodyCell>
                <TableBodyCell>Data 4</TableBodyCell>
                <TableBodyCell>Data 5</TableBodyCell>
              </TableBodyRow>
              <TableBodyRow>
                <TableBodyCell>Data 1</TableBodyCell>
                <TableBodyCell>Data 2</TableBodyCell>
                <TableBodyCell>Data 3</TableBodyCell>
                <TableBodyCell>Data 4</TableBodyCell>
                <TableBodyCell>Data 5</TableBodyCell>
              </TableBodyRow>
              <TableBodyRow>
                <TableBodyCell>Data 1</TableBodyCell>
                <TableBodyCell>Data 2</TableBodyCell>
                <TableBodyCell>Data 3</TableBodyCell>
                <TableBodyCell>Data 4</TableBodyCell>
                <TableBodyCell>Data 5</TableBodyCell>
              </TableBodyRow>
            </TableBody>
          </Table>
        </TableWrapper>
 */
