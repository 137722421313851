import { css } from 'styled-components';

export const DESKTOP = 90; // 1440px / 16
export const LAPTOP = 90; // 1440px / 16
export const TABLET_LANDSCAPE = 63.98; // (1024px / 16) - 0.02
export const TABLET = 52.105; // (834px / 16) - 0.02
export const MOBILE_LANDSCAPE = 47.98; // (768px / 16) - 0.02
export const MOBILE = 29.98; // (480px / 16) - 0.02
export const SM_SCREEN = 19.92; // (319px / 16) - 0.02

// breakpoint values in em, with adjustment
export const breakpoints = {
  desktop: DESKTOP,
  laptop: LAPTOP,
  tabletLandscape: TABLET_LANDSCAPE,
  tablet: TABLET,
  mobileLandscape: MOBILE_LANDSCAPE,
  mobile: MOBILE,
  smScreen: SM_SCREEN,
};

// Function to generate media queries
export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    if (label === 'desktop') {
      return css`
        @media (min-width: ${breakpoints[label]}em) {
          ${css(...args)};
        }
      `;
    } else {
      return css`
        @media (max-width: ${breakpoints[label]}em) {
          ${css(...args)};
        }
      `;
    }
  };
  return accumulator;
}, {});

/* 
// Define the breakpoint values in px
export const breakpoints = {
  desktop: 1440,
  laptop: 1440,
  tabletLandscape: 1024,
  tablet: 834,
  mobileLandscape: 768,
  mobile: 480,
  smScreen: 319,
};

// Function to generate media queries
export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16;
  const adjustedCalculation = 0.02;
  accumulator[label] = (...args) => {
    if (label === 'desktop') {
      return css`
        @media (min-width: ${emSize}em) {
          ${css(...args)};
        }
      `;
    } else {
      return css`
        @media (max-width: ${emSize - adjustedCalculation}em) {
          ${css(...args)};
        }
      `;
    }
  };
  return accumulator;
}, {});
*/
