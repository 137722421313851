import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';

const initialState = {
  initialization: {
    isLoading: false,
    isInitialized: false,
    error: null,
  },
  roleHierarchy: {},
  routes: {
    all: [],
    public: [],
    protected: [],
    navigation: [],
  },
  routePermissions: {},
  featurePermissions: {},
  isRouteAuthorized: false,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setRoleHierarchy: (state, action) => {
      const hierarchy = action.payload;
      const roleHierarchy = hierarchy.reduce((acc, role) => {
        acc[role.value] = {
          id: role.id,
          label: role.label,
          value: role.value,
          description: role.description,
          permissions: role.permissions,
        };
        return acc;
      }, {});
      state.roleHierarchy = roleHierarchy;
    },

    setIsRouteAuthorized: (state, action) => {
      state.isRouteAuthorized = action.payload;
    },

    clearIsRouteAuthorized: (state) => {
      state.isRouteAuthorized = false;
    },

    clearRoleHierarchy: (state) => {
      state.roleHierarchy = {};
      state.initialization.isInitialized = false;
    },

    setRoutes: (state, action) => {
      const routes = action.payload;
      state.routes.all = routes;
      state.routes.public = filter(routes, { is_public: true });
      state.routes.protected = filter(routes, { is_public: false });
      state.routes.navigation = filter(routes, { category: 'nav' });
    },
    clearRoutes: (state) => {
      state.routes = initialState.routes;
      state.initialization.isInitialized = false;
    },
    setRoutePermissions: (state, action) => {
      const permissionsMap = action.payload.reduce((acc, route) => {
        acc[route.value] = {
          permissions: route.permissions,
          is_public: route.is_public,
          is_enabled: route.is_enabled,
          strict: route.role_structure === 'strict',
          path: route.path,
          value: route.value,
        };
        return acc;
      }, {});

      state.routePermissions = permissionsMap;
    },
    clearRoutePermissions: (state) => {
      state.routePermissions = {};
      state.initialization.isInitialized = false;
    },
    setFeaturePermissions: (state, action) => {
      state.featurePermissions = action.payload;
    },
    clearFeaturePermissions: (state) => {
      state.featurePermissions = {};
      state.initialization.isInitialized = false;
    },
    setInitialization: (state, action) => {
      state.initialization = {
        ...state.initialization,
        ...action.payload,
      };
    },
  },
});

export const {
  setRoleHierarchy,
  clearRoleHierarchy,
  setRoutes,
  clearRoutes,
  setRoutePermissions,
  clearRoutePermissions,
  setFeaturePermissions,
  clearFeaturePermissions,
  setInitialization,
  setIsRouteAuthorized,
  clearIsRouteAuthorized,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;

export const usePermissionsState = () => {
  return useSelector((state) => state.permissions);
};

export const useRoutes = () => {
  return useSelector((state) => state.permissions.routes);
};

export const useRoutePermissions = () => {
  return useSelector((state) => state.permissions.routePermissions);
};

export const useRouteAccess = (routeValue) => {
  return useSelector((state) => {
    const route = state.permissions.routePermissions[routeValue];
    return {
      isPublic: route?.isPublic || false,
      permissions: route?.permissions || [],
      path: route?.path,
    };
  });
};
