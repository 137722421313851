import { useCallback, useState } from 'react';
import { HiOutlineUpload } from 'react-icons/hi';
import styled from 'styled-components';
import { useToast } from '../context/ToastProvider';

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--padding);
  border: 2px dashed var(--gray-7);
  border-radius: var(--border-radius-md);
  background-color: var(--gray-3);
  transition: all 0.3s ease;

  &:hover {
    border-color: var(--color-9);
    background-color: var(--gray-4);
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  position: relative;
  border: none;
  outline: none;
  letter-spacing: 0.5px;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0 1.2rem;
  height: 3.2rem;
  background-color: var(--color-9);
  color: var(--label-white);
  border-radius: var(--border-radius-btn);
  transition: background-color 0.3s ease;

  & svg {
    color: inherit;
  }

  &:hover {
    background-color: var(--color-10);
  }

  &:active {
    background-color: var(--color-9);
  }
`;

export const PreviewImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  max-height: auto;
  margin-top: 20px;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
`;

/**
 * Custom hook for handling file uploads with preview functionality.
 *
 * @param {Object} config - Configuration options for the file upload.
 * @param {Object} config - Specific configuration for the file input.
 * @param {React.ReactNode} config.icon - Icon to display on the file input button.
 * @param {string} config.label - Label text for the file input button.
 * @param {boolean} config.preview - Whether to show a preview of the uploaded file.
 * @param {string[]} [config.types] - Array of allowed file types.
 * @param {number} [config.maxSize] - Maximum allowed file size in bytes.
 *
 * @returns {Object} An object containing:
 *   - markup: JSX for the file input and preview
 *   - selected: The currently selected file
 *   - setSelected: Function to set the selected file
 *   - previewUrl: URL for the file preview (if applicable)
 *   - setPreviewUrl: Function to set the preview URL
 *   - handleFileChange: Function to handle file selection changes
 */

export const useFileUpload = ({ config }) => {
  const [selected, setSelected] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState(null);
  const { toast } = useToast();

  const validateFile = useCallback(
    (file) => {
      if (config.types && !config.types.includes(file.type)) {
        toast({
          type: 'danger',
          message: `Invalid file type. Allowed types: ${config.types.join(
            ', '
          )}`,
        });

        throw new FileUploadError(
          `Invalid file type. Allowed types: ${config.types.join(', ')}`,
          'type'
        );
      }

      if (config.maxSize && file.size > config.maxSize) {
        const maxSizeMB = config.maxSize / (1024 * 1024);
        toast({
          type: 'danger',
          message: `File size exceeds ${maxSizeMB} MB limit.`,
        });
        throw new FileUploadError(
          `File size exceeds ${maxSizeMB} MB limit.`,
          'size'
        );
      }
    },
    [config]
  );

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    setError(null);

    try {
      validateFile(file);
      if (file) {
        setSelected(file);

        // Create preview for image files
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewUrl(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          setPreviewUrl(null);
        }
      }
    } catch (err) {
      if (err instanceof FileUploadError) {
        setError(err);
        toast({
          type: 'danger',
          message: err.message,
        });
      } else {
        console.error('Unexpected error:', err);
        toast({
          type: 'danger',
          message: 'An unexpected error occurred',
        });
      }
      setSelected(null);
      setPreviewUrl(null);
    }
  };

  const markup = (
    <FileUploadContainer>
      <FileInput
        type='file'
        id='cover-image'
        accept='image/*'
        onChange={handleFileChange}
      />
      <FileLabel htmlFor='cover-image'>
        {config?.icon || <HiOutlineUpload />}
        {selected ? 'Change File' : config?.label || 'Choose File'}
      </FileLabel>
      {previewUrl && config?.preview && (
        <PreviewImage src={previewUrl} alt='Preview' />
      )}
    </FileUploadContainer>
  );

  return { markup, selected, error };
};
