import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  preferences: [],
  isLoading: false,
  error: null,
};

const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    clearPreference: (state) => initialState,

    setPreference: (state, action) => {
      state.preferences = action.payload;
    },
    updatePreference: (state, action) => {
      const { key, ...updates } = action.payload;
      const index = state.preferences.findIndex((pref) => pref.key === key);
      if (index !== -1) {
        state.preferences[index] = { ...state.preferences[index], ...updates };
      } else {
        state.preferences.push({ key, ...updates });
      }
    },
    setPreferenceLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPreferenceError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  clearPreference,
  setPreference,
  updatePreference,
  setPreferenceLoading,
  setPreferenceError,
} = preferenceSlice.actions;

export const usePreferenceState = () =>
  useSelector((state) => state.preference);

export default preferenceSlice.reducer;
