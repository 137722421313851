import styled from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

export const StyledAuthContainer = styled.div`
  width: 44rem;
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  overflow: hidden;
  background-color: var(--gray-2);
  border-radius: var(--border-radius-cd);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.mobile`
    width: 98%;
  `}
`;

export const StyledHeader = styled.div`
  padding: var(--padding-ver) var(--padding-hor) 0 var(--padding-hor);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid var(----border-md-non);
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
