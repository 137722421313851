import { useState } from 'react';
import styled from 'styled-components';
import { TextBase } from '../../ui/typography/Text';
import Heading from '../../ui/typography/Heading';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.2rem;

  width: 100%;
`;

const StyledHistory = styled(TextBase)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  min-height: 4rem;
  transition: all 200ms ease;

  &:not(:last-child) {
    border-bottom: var(--border-sm-int);
  }

  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-a5);

    color: var(--color-9);
  }

  & .recent {
    flex: 1;
  }

  & .controls {
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: 0.8rem;

    & button {
      height: 1.6rem;
      width: 1.6rem;

      flex: 0;
      background: none;

      &:hover {
        background-color: var(--gray-4);
      }
    }
  }
`;

const NoResource = styled.div`
  align-self: center;
  justify-self: center;
  color: var(--label-sec);
`;

const data = ['Acetaminophen', 'Midodrine', 'Pathophysiology'];

const SearchHistory = ({ closeModal }) => {
  const [recent, setRecent] = useState(data);

  function handleDelete(index) {
    setRecent((prevState) => prevState.filter((rec, ind) => ind !== index));
  }

  return (
    <Wrapper>
      {Array.isArray(data) && data.length > 0 && (
        <Heading as='h5'>Search history</Heading>
      )}

      {data && data.length > 0 ? (
        recent.map((rec, index) => (
          <StyledHistory key={index}>{rec}</StyledHistory>
        ))
      ) : (
        <NoResource>No search history</NoResource>
      )}
    </Wrapper>
  );
};

export default SearchHistory;
