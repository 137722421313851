import Button from '../../ui/buttons/Button';
import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import { countries } from '../../utils/countries';
import { useId, useState } from 'react';
import { StyledCard } from './OnboardingDetails';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import { HiOutlineCheckCircle } from 'react-icons/hi2';
import { useAuth } from '../../redux/features/auth-slice.js';
import { useMutateProfile } from '../profile/useProfile.jsx';
import { getCurrentTimestamp } from '../../utils/helpers.jsx';
import Select from '../../ui/menu/Select.jsx';
import { TextFade } from '../../ui/typography/Text.jsx';
import toast from 'react-hot-toast';
import Heading from '../../ui/typography/Heading.jsx';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const CompleteProfile = ({ next, isOnboarding }) => {
  const { user } = useAuth();
  const firstNameId = useId();
  const lastNameId = useId();
  const [formState, setFormState] = useState({
    first_name: user?.user_metadata?.first_name || '',
    last_name: user?.user_metadata?.last_name || '',
    country: user?.user_metadata?.country || '',
  });
  const isBioCompleted =
    user?.user_metadata?.onboarding_steps?.bio?.isCompleted;

  const {
    mutateProfile,
    isLoading: isUpdatingProfile,
    isError: isUpdatingProfileError,
  } = useMutateProfile();

  function handleOnInputChange(e) {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormState((prevState) => ({ ...prevState, [name]: trimmedValue }));
  }

  const isReadyToSave =
    formState.first_name && formState.last_name && formState.country;

  function handleSaveAndProceed() {
    if (!isReadyToSave) {
      toast.error('All fields are required to proceed');
      return;
    }

    const { first_name, last_name, country } = formState;
    const now = getCurrentTimestamp();

    mutateProfile(
      user?.id,
      {
        first_name,
        last_name,
        country,
        updated_at: now,
      },
      {
        onSuccess: () => {
          next('bio', {
            first_name: formState.first_name,
            last_name: formState.last_name,
            country: formState.country,
          });
        },
      }
    );
  }

  return (
    <StyledCard>
      <FlexColumn>
        <FlexColumn style={{ gap: '1.2rem', marginBottom: '2.4rem' }}>
          <Heading as={'h4'}>Let's get to know you better</Heading>
          <TextFade>
            Tell us a bit about yourself! This helps us create a personalized
            space for you to share your stories and connect with fellow readers
            and writers from around the world.
          </TextFade>
        </FlexColumn>

        <FlexColumn style={{ marginTop: '0.8rem' }} $gap={1.6}>
          <FlexRow>
            <StyledInputContainer>
              <Input
                required
                id={firstNameId}
                name='first_name'
                value={formState.first_name}
                autoComplete='off'
                type='text'
                placeholder='First Name'
                onChange={(e) => handleOnInputChange(e)}
                disabled={isUpdatingProfile || isBioCompleted}
              />
              <InputLabel htmlFor={firstNameId}>First Name</InputLabel>
            </StyledInputContainer>

            <StyledInputContainer>
              <Input
                id={lastNameId}
                required
                name='last_name'
                value={formState.last_name}
                autoComplete='off'
                type='text'
                placeholder='Last Name'
                onChange={(e) => handleOnInputChange(e)}
                disabled={isUpdatingProfile || isBioCompleted}
              />
              <InputLabel htmlFor={lastNameId}>Last Name</InputLabel>
            </StyledInputContainer>
          </FlexRow>

          <FlexColumn>
            <TextFade>Country</TextFade>
            <Select
              required
              value={formState.country}
              onChange={(e) =>
                setFormState((prevState) => ({
                  ...prevState,
                  country: e.target.value,
                }))
              }
              disabled={isUpdatingProfile || isBioCompleted}
            >
              <option value=''>Select Country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.label}
                </option>
              ))}
            </Select>
          </FlexColumn>
        </FlexColumn>

        <ButtonGroup>
          <Button
            disabled={isUpdatingProfile || isOnboarding || isBioCompleted}
            style={{ alignSelf: 'center' }}
            onClick={handleSaveAndProceed}
            variant={isBioCompleted ? 'success' : 'primary'}
            isLoading={isUpdatingProfile || isOnboarding}
          >
            Save & Proceed
            <ArrowRightIcon />
          </Button>
        </ButtonGroup>
      </FlexColumn>
    </StyledCard>
  );
};

export default CompleteProfile;
