import { useEffect, useMemo } from 'react';

export const usePageTitle = (title, options = {}) => {
  const { siteName = 'BittsBlog', template = '%title% | %siteName%' } = options;

  const computedTitle = useMemo(() => {
    return title
      ? template.replace('%title%', title).replace('%siteName%', siteName)
      : siteName;
  }, [title, siteName, template]);

  useEffect(() => {
    const previousTitle = document.title || siteName;
    document.title = computedTitle;

    return () => {
      document.title = previousTitle;
    };
  }, [computedTitle, siteName]);
};
