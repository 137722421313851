import React from 'react';
import styled from 'styled-components';
import {
  HiOutlineInformationCircle,
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2';
import Button from '../buttons/Button';
import Heading from '../typography/Heading';
import { TextFade } from '../typography/Text';

const StateDisplayWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  width: 100%;
  min-height: 16rem;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-lg);
  justify-content: center;
  text-align: center;
  padding: var(--padding);
  box-shadow: var(--shadow-md);
  background-color: ${(props) => {
    switch (props.variant) {
      case 'error':
        return 'var(--red-3)';
      case 'success':
        return 'var(--green-3)';
      case 'warning':
        return 'var(--orange-3)';
      case 'info':
        return 'var(--blue-3)';
      default:
        return 'var(--gray-2)';
    }
  }};
  overflow: hidden;
  border-radius: var(--border-radius-cd);
`;

const IconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 100%;
    width: 100%;
    color: ${(props) => {
      switch (props.variant) {
        case 'error':
          return 'var(--red-9)';
        case 'success':
          return 'var(--green-9)';
        case 'warning':
          return 'var(--orange-9)';
        case 'info':
          return 'var(--blue-9)';
        default:
          return 'var(--gray-11)';
      }
    }};
  }
`;

const Title = styled(Heading).withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  color: ${(props) => {
    switch (props.variant) {
      case 'error':
        return 'var(--red-12)';
      case 'success':
        return 'var(--green-12)';
      case 'warning':
        return 'var(--orange-12)';
      case 'info':
        return 'var(--blue-12)';
      default:
        return 'var(--gray-12)';
    }
  }};
`;

const Description = styled.div.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})`
  text-align: center;
  color: ${(props) => {
    switch (props.variant) {
      case 'error':
        return 'var(--red-11)';
      case 'success':
        return 'var(--green-11)';
      case 'warning':
        return 'var(--orange-11)';
      case 'info':
        return 'var(--blue-11)';
      default:
        return 'var(--gray-11)';
    }
  }};
`;

const StateDisplay = ({
  title,
  description,
  icon,
  action,
  variant = 'empty',
  button,
}) => {
  const getIcon = () => {
    switch (variant) {
      case 'error':
        return <HiOutlineExclamationCircle />;
      case 'success':
        return <HiOutlineCheckCircle />;
      case 'warning':
        return <HiOutlineExclamationTriangle />;
      case 'info':
        return <HiOutlineInformationCircle />;
      default:
        return <HiOutlineInformationCircle />;
    }
  };

  return (
    <StateDisplayWrapper variant={variant}>
      <IconWrapper variant={variant}>{icon || getIcon()}</IconWrapper>
      <Title as={'h5'} variant={variant}>
        {title}
      </Title>
      <Description variant={variant}>{description}</Description>
      {action && (
        <Button
          isLoading={action?.isLoading}
          onClick={action?.onClick}
          variant={
            action?.variant ||
            (variant === 'error'
              ? variant === 'success'
                ? 'success'
                : 'danger'
              : 'primary')
          }
        >
          {action?.label || 'Action'}
          {action?.icon && action?.icon}
        </Button>
      )}
      {button && button}
    </StateDisplayWrapper>
  );
};

export default StateDisplay;
