import styled from 'styled-components';
import Button from '../../ui/buttons/Button';
import Select from '../../ui/menu/Select';
import ButtonIcon from '../../ui/buttons/ButtonIcon';
import { getFormattedDate } from '../../utils/helpers';
import {
  SectionHeading,
  StyledBodyScroll,
  StyledContainer,
  StyledFooter,
  StyledHeader,
  StyledLoading,
} from '../../styles/GeneralStyling';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import {
  HiOutlineCheck,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineEllipsisVertical,
  HiOutlineExclamationCircle,
  HiOutlineTrash,
} from 'react-icons/hi2';
import { formatDistanceToNow } from 'date-fns';
import { InfoTip } from '../../ui/notifications/InfoTip';
import { MenuButton, Menus } from '../../ui/menu/Menus';
import {
  useDeleteNotification,
  useMarkAsRead,
  useUnreadCount,
  useNotifications,
} from './useNotifications';
import { useAuth } from '../../redux/features/auth-slice';
import { useState } from 'react';
import toast from 'react-hot-toast';
import React from 'react';
import Spinner from '../../ui/loading/Spinner';
import StateDisplay from '../../ui/notifications/StateDisplay';
import { isEmpty } from 'lodash';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

const NotificationDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const activeNotificationId = location.state?.notificationId;
  const [filter, setFilter] = useState('all');
  const ITEMS_PER_PAGE = 8;

  const { user } = useAuth();
  const {
    data: notificationsData,
    isLoading: isFetchingNotifications,
    error: fetchNotificationsError,
    refetch: refetchNotifications,
  } = useNotifications({
    userId: user?.id,
    page: currentPage,
    limit: ITEMS_PER_PAGE,
    filter,
  });

  const notifications = notificationsData?.notifications || [];
  const totalPages = notificationsData?.totalPages || 1;

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const { data: unreadCount = 0 } = useUnreadCount(user?.id);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);

  const { mutate: deleteNotification, isLoading: isDeletingNotification } =
    useDeleteNotification();
  const { mutate: markAsRead, isLoading: isMarkingNotification } =
    useMarkAsRead();

  const handleDelete = (notificationId) => {
    setSelectedNotificationId(notificationId);
    deleteNotification(notificationId, {
      onError: (error) => {
        const errorMessage = getCrudErrorMessage(
          'delete',
          error,
          'notification'
        );
      },
      onSuccess: () => {
        toast.success('Notification deleted successfully');
      },
      onSettled: () => {
        setSelectedNotificationId(null);
      },
    });
  };

  const handleMarkAsRead = (notificationId) => {
    setSelectedNotificationId(notificationId);
    markAsRead(notificationId, {
      onError: (error) => {
        const errorMessage = getCrudErrorMessage(
          'update',
          error,
          'notification'
        );
        toast.error(errorMessage.description);
      },
      onSettled: () => {
        setSelectedNotificationId(null);
      },
    });
  };

  function handleFilterChange(e) {
    setFilter(e.target.value);
  }

  const LoadingMarkup = (
    <StyledLoading>
      <Spinner size='medium' />
    </StyledLoading>
  );

  const EmptyMarkup = (
    <StateDisplay
      variant='empty'
      title='No notifications found'
      type='notifications'
      description='You can try adjusting your filters to see more results.'
    />
  );

  const ErrorMarkup = (
    <StateDisplay
      variant='error'
      title='Error fetching notifications'
      type='notifications'
      description='Oops! Something went wrong while fetching your notifications.'
      action={{
        label: 'Retry',
        onClick: refetchNotifications,
        icon: <HiOutlineRefresh />,
      }}
      icon={<HiOutlineExclamationCircle />}
    />
  );

  return (
    <StyledContainer size='small' role='region' aria-label='Notifications'>
      <StyledHeader>
        <FlexRow justify='between'>
          <SectionHeading>Notifications</SectionHeading>
          <ActionButtons>
            {unreadCount > 0 && (
              <Button variant='secondary' size='small'>
                Mark all as read
              </Button>
            )}

            <Select
              size='small'
              onChange={handleFilterChange}
              aria-label='Filter notifications'
            >
              <option value='all'>All</option>
              <option value='unread'>Unread</option>
              <option value='read'>Read</option>
            </Select>
          </ActionButtons>
        </FlexRow>
      </StyledHeader>

      <StyledBodyScroll>
        <FlexColumn>
          {/* Loading state */}
          {isFetchingNotifications && LoadingMarkup}

          {/* Error state */}
          {fetchNotificationsError && ErrorMarkup}

          {/* Empty state */}
          {isEmpty(notifications) && !isFetchingNotifications && EmptyMarkup}

          {/* Notifications state */}
          {!isFetchingNotifications &&
            !fetchNotificationsError &&
            !isEmpty(notifications) &&
            notifications?.map((notification) => {
              const isUnread = !notification.read_at;
              const priority = notification.metadata.priority;
              const message = notification.metadata.message;
              const createdAt = notification.created_at;
              const notificationId = notification.id;
              const isActive = activeNotificationId === notificationId;

              return (
                <NotificationCard
                  key={notificationId}
                  data-priority={priority}
                  data-unread={isUnread}
                  data-active={isActive}
                >
                  <NotificationContent>
                    <Message data-priority={priority} data-unread={isUnread}>
                      {message}
                    </Message>
                    <MetaData data-priority={priority}>
                      <InfoTip tip={getFormattedDate(createdAt)}>
                        <TimeStamp>
                          {formatDistanceToNow(createdAt, {
                            addSuffix: true,
                          })}
                        </TimeStamp>
                      </InfoTip>
                    </MetaData>
                  </NotificationContent>
                  <Menus
                    toggle={
                      <ButtonIcon
                        isLoading={
                          (isDeletingNotification || isMarkingNotification) &&
                          selectedNotificationId === notificationId
                        }
                        disabled={
                          isMarkingNotification || isDeletingNotification
                        }
                        variant='transparent'
                      >
                        <HiOutlineEllipsisVertical />
                      </ButtonIcon>
                    }
                  >
                    {isUnread && (
                      <MenuButton
                        onClick={() => handleMarkAsRead(notificationId)}
                        disabled={isMarkingNotification}
                      >
                        Mark as read
                        <HiOutlineCheck />
                      </MenuButton>
                    )}
                    <MenuButton
                      style={{ color: 'var(--red-11)' }}
                      onClick={() => handleDelete(notificationId)}
                      disabled={isDeletingNotification}
                    >
                      Delete
                      <HiOutlineTrash style={{ color: 'var(--red-11)' }} />
                    </MenuButton>
                  </Menus>
                </NotificationCard>
              );
            })}
        </FlexColumn>
      </StyledBodyScroll>

      <StyledFooter>
        <ButtonGroup justify='between'>
          <Button
            variant='outline'
            size='small'
            disabled={
              currentPage === 1 ||
              isFetchingNotifications ||
              isEmpty(notifications)
            }
            onClick={handlePreviousPage}
            aria-label='Previous page'
          >
            <HiOutlineChevronLeft />
            Previous
          </Button>
          <PageInfo>
            {!isEmpty(notifications) && `Page ${currentPage} of ${totalPages}`}
          </PageInfo>
          <Button
            variant='outline'
            size='small'
            disabled={
              currentPage === totalPages ||
              isFetchingNotifications ||
              isEmpty(notifications)
            }
            onClick={handleNextPage}
          >
            Next
            <HiOutlineChevronRight />
          </Button>
        </ButtonGroup>
      </StyledFooter>
    </StyledContainer>
  );
};

export const NotificationCard = styled.div`
  padding: var(--padding);
  border-radius: var(--border-radius-lg);
  background: var(--gray-2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--gap-md);
  transition: all 200ms ease;
  position: relative;

  &:hover {
    background: var(--gray-3);
  }

  &[data-priority='high'] {
    background: var(--red-2);

    &:hover {
      background: var(--red-3);
    }
    &:active {
      background: var(--red-4);
    }
  }

  &[data-priority='medium'] {
    background: var(--amber-2);

    &:hover {
      background: var(--amber-3);
    }
    &:active {
      background: var(--amber-4);
    }
  }

  &[data-priority='low'] {
    background: var(--gray-2);

    &:hover {
      background: var(--gray-3);
    }
    &:active {
      background: var(--gray-4);
    }
  }

  &[data-unread='true'] {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 70%;
      background: var(--blue-9);
      border-radius: 0 2px 2px 0;
    }
  }

  @keyframes highlightNotification {
    0% {
      background-position: -100% 0;
    }
    50% {
      background-position: 200% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  &[data-active='true'] {
    background: linear-gradient(
      90deg,
      var(--gray-2) 0%,
      var(--green-3) 50%,
      var(--gray-2) 100%
    );
    background-size: 200% 100%;
    animation: highlightNotification 2s ease forwards;
    border-color: var(--green-6);
  }
`;

const NotificationContent = styled.div`
  flex: 1;
`;

export const Message = styled.p`
  font-weight: ${(props) => (props['data-unread'] ? '600' : '400')};

  &[data-priority='high'] {
    color: var(--red-12);
  }

  &[data-priority='medium'] {
    color: var(--amber-12);
  }

  &[data-priority='low'] {
    color: var(--gray-12);
  }
`;

export const MetaData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-md);
  color: var(--gray-11);

  &[data-priority='high'] {
    color: var(--red-11);
  }

  &[data-priority='medium'] {
    color: var(--amber-11);
  }

  &[data-priority='low'] {
    color: var(--gray-11);
  }
`;

export const TimeStamp = styled.span`
  font-size: 1.2rem;
  color: inherit;
`;

const PageInfo = styled.span`
  color: var(--gray-11);
`;

const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--gap-md);
`;

export default NotificationDetails;
