import styled, { css } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';
import { HiCheck } from 'react-icons/hi2';

const StyledCard = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'padding',
      'radius',
      'border',
      'hover',
      'width',
      'backgroundColor',
    ].includes(prop),
})`
  overflow: hidden;
  width: 100%;
  padding: ${(props) => props.padding || 'var(--padding)'};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `var(--${backgroundColor}-2)` : 'var(--gray-2)'};
  border-radius: ${(props) => props.radius || 'var(--border-radius-cd)'};
  box-shadow: var(--shadow-md);

  ${(props) =>
    props.hover &&
    css`
      transition: transform 0.3s ease;
      border: var(--border-sm-int);
      cursor: pointer;
      &:hover {
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(1px);
      }
    `}
`;

export const Card = ({ children, ...props }) => (
  <StyledCard {...props}>{children}</StyledCard>
);

const StyledCardHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => !['padding', 'radius', 'border'].includes(prop),
})`
  padding: var(--padding);
  border-bottom: ${(props) =>
    !props.border ? 'var(--border-sm-non)' : 'none'};
  height: min-content;
`;

export const CardHeader = ({ children, ...props }) => (
  <StyledCardHeader {...props}>{children}</StyledCardHeader>
);

const StyledCardBody = styled.div.withConfig({
  shouldForwardProp: (prop) => !['padding', 'radius', 'border'].includes(prop),
})`
  padding: var(--padding);
  flex: 1;
`;

export const CardBody = ({ children, ...props }) => (
  <StyledCardBody {...props}>{children}</StyledCardBody>
);

const StyledCardFooter = styled.div.withConfig({
  shouldForwardProp: (prop) => !['padding', 'radius', 'border'].includes(prop),
})`
  padding: var(--padding);
  border-top: ${(props) => (!props.border ? 'var(--border-sm-non)' : 'none')};
  height: min-content;
`;

export const CardFooter = ({ children, ...props }) => (
  <StyledCardFooter {...props}>{children}</StyledCardFooter>
);

export const CardGrid = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.width || '25rem'}, 1fr)
  );
  gap: 2.4rem;
`;

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--border-radius-md);
  background-color: var(--gray-3);
  cursor: pointer;
  color: var(--gray-11);
  transition: all 0.2s ease-in-out;
  min-width: 80px;
  height: 80px;
  border: var(--border-sm-int);
  position: relative;
  &:hover {
    background-color: var(--gray-4);
    border: var(--border-sm-int-str);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-3);
    border: var(--border-sm-non);
    opacity: 0.5;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--gray-5);
      border: var(--border-sm-int-str);
    `}

  &:active {
    background-color: var(--gray-5);
    border: var(--border-sm-int);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
    color: var(--gray-11);

    ${(props) =>
      props.active &&
      css`
        color: var(--color-9);
      `}
  }
`;

const ButtonName = styled.span.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  color: var(--gray-11);
  color: ${(props) => (props.active ? 'var(--color-9)' : 'var(--gray-11)')};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  white-space: normal;
  width: min-content;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const ActiveIndicator = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: var(--green-9);
  color: white;
  border-radius: var(--border-radius-rd);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  z-index: 1;

  svg {
    width: 14px;
    height: 14px;
    margin: 0;
  }
`;

export const ToolButton = ({
  icon,
  name,
  onClick,
  active,
  disabled,
  children,
}) => {
  function handleClick(e) {
    e.stopPropagation();
    onClick?.(e);
  }
  return (
    <StyledButton
      disabled={disabled}
      active={active}
      type='button'
      onClick={handleClick}
    >
      {icon && icon}
      <ButtonName active={active}>{name}</ButtonName>
      {active && (
        <ActiveIndicator>
          <HiCheck color='var(--label-white)' />
        </ActiveIndicator>
      )}
      {children}
    </StyledButton>
  );
};
