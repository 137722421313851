import Button from '../../ui/buttons/Button';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';

import { StyledCard } from './OnboardingDetails';

import PreferenceDetails from '../preferences/PreferenceDetails';
import { useAuth } from '../../redux/features/auth-slice';
import { TextFade } from '../../ui/typography/Text';
import Heading from '../../ui/typography/Heading';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';

const SetPreferences = ({ next, isOnboarding }) => {
  const { user } = useAuth();
  const firstName = user?.user_metadata?.first_name || '';

  function handleFinishOnboarding() {
    next('preferences', {
      onboarding_complete: true,
    });
  }

  return (
    <StyledCard padding={'0'}>
      <FlexColumn>
        <FlexColumn>
          <FlexColumn
            style={{
              padding:
                'var(--padding-ver) var(--padding-hor) 0 var(--padding-hor)',
            }}
          >
            <Heading as={'h4'}>{`One last thing, ${firstName}!`}</Heading>
            <TextFade>
              Tell us what topics interest you, and we'll curate your discovery
              feed accordingly. Don't worry, you can always update these later
              on the preferences page.
            </TextFade>
          </FlexColumn>
          <PreferenceDetails mode='onboarding' />
        </FlexColumn>

        <ButtonGroup style={{ padding: 'var(--padding)' }} justify='end'>
          <Button
            isLoading={isOnboarding}
            variant='primary'
            onClick={handleFinishOnboarding}
          >
            Start My Journey
            <HiOutlineRocketLaunch />
          </Button>
        </ButtonGroup>
      </FlexColumn>
    </StyledCard>
  );
};

export default SetPreferences;
