import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

export const StyledNavLink = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['isSidebarOpen'].includes(prop),
})`
  flex-shrink: 0;

  &:link,
  &:visited {
    height: 4rem;
    position: relative;
    width: ${(props) => (props.isSidebarOpen ? '100%' : '4.4rem')};
    color: var(--gray-12);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    font-weight: normal;
    padding: 0 1.2rem;
    border-radius: var(--border-radius-sm);
    transition: all 300ms ease;
  }

  &:hover {
    background-color: ${(props) =>
      props.isSidebarOpen ? 'var(--color-4)' : 'none'};
    transform: translateX(2px);

    ${media.mobile`
      background-color: var(--color-4);
    `}
  }

  &:active,
  &.active:link,
  &.active:visited {
    background-color: ${(props) =>
      props.isSidebarOpen ? 'var(--color-5)' : 'transparent'};
    color: var(--gray-12);

    &::before {
      height: 32px;
    }

    ${media.mobile`
      background-color: var(--color-5);
    `}
  }

  & svg {
    flex-shrink: 0;
    height: 2rem;
    width: 2rem;
    color: var(--label-sec);
    transition: transform 300ms ease;
  }

  &:hover svg {
    transform: scale(1.1);
    color: var(--color-9);
  }

  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: var(--color-9);
    transform: scale(1);
  }
`;

export const LinkText = styled(Link)`
  &:link,
  &:visited {
    outline: none;
    border: none;
    color: var(--color-9);
    font-weight: normal;
    background: none;
    cursor: pointer;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: var(--color-12);
  }
`;

export const ButtonText = styled.button`
  color: var(--blue-9);
  cursor: pointer;
  text-decoration: none;
  transition: all 300ms ease;
  font-weight: normal;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: inline;
  vertical-align: baseline;
  line-height: inherit;

  &:hover:not(:disabled) {
    text-decoration: underline;
    filter: brightness(0.95);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
