import styled, { css } from 'styled-components';

const justify = {
  start: css`
    justify-content: start;
  `,
  center: css`
    justify-content: center;
  `,
  end: css`
    justify-content: end;
  `,

  between: css`
    justify-content: space-between;
  `,
};

const ButtonGroup = styled.div.withConfig({
  shouldForwardProp: (prop) => !['justify', 'width'].includes(prop),
})`
  width: ${(props) => props.width};
  /* flex-wrap: 1; */
  display: flex;
  align-items: center;
  gap: 0.8rem;

  ${(props) => justify[props.justify]}
`;

ButtonGroup.defaultProps = {
  justify: 'end',
};

export default ButtonGroup;
