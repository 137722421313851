import styled, { css } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

export const StyledInputContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  isolation: isolate;

  &:has(svg) > input {
    padding: 0 4.8rem;
  }

  &:has(svg) > label {
    left: 4.6rem;
  }

  & svg {
    height: 2.4rem;
    width: 2.4rem;
    position: absolute;
    top: 50%;
    left: 1.6rem;
    left: 2.4rem;
    transform: translate(-50%, -50%);
    color: var(--gray-10);

    z-index: 1;
    pointer-events: none;
  }
`;

export const Input = styled.input.withConfig({
  shouldForwardProp: (props) => !['error', 'success'].includes(props),
})`
  font: inherit;
  width: 100%;
  flex: 1;
  position: relative;
  height: 4rem;
  max-height: 4rem;
  min-height: 4rem;
  background-color: ${({ error }) =>
    error ? 'var(--red-2)' : 'var(--gray-2)'};
  border-radius: var(--border-radius-md);
  outline: none;
  border: 1px solid
    ${({ error, success }) =>
      error ? 'var(--red-9)' : success ? 'var(--green-9)' : 'var(--gray-6)'};
  transition: all 200ms ease;
  padding: 0 1.2rem;
  color: var(--gray-12);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  position: relative;

  ${media.tablet`
    font-size: 1.6rem;
  `}

  &:hover:not(:disabled) {
    background-color: var(--gray-3);
    ${({ error }) =>
      error &&
      css`
        border: 1px solid var(--red-9);
        background-color: var(--red-3);
      `}
  }

  &:focus:not(:disabled) {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-9);
  }

  &:focus ~ label {
    color: var(--color-9);
  }
  &::placeholder {
    color: var(--gray-11);
  }

  &::-ms-input-placeholder {
    color: var(--gray-11);
  }

  &:placeholder-shown ~ label {
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-11);
    visibility: hidden;
    opacity: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 4.4rem var(--gray-2) inset !important;
    box-shadow: 0 0 0 4.4rem var(--gray-2) inset !important;
    -webkit-text-fill-color: var(--gray-12) !important;
  }

  &:-moz-autofill,
  &:-moz-autofill:hover,
  &:-moz-autofill:focus,
  &:-moz-autofill:active {
    -webkit-box-shadow: 0 0 0 4.4rem var(--gray-2) inset !important;
    box-shadow: 0 0 0 4.4rem var(--gray-2) inset !important;
    -webkit-text-fill-color: var(--gray-12) !important;
  }

  &:-internal-autofill-selected {
    background-color: var(--gray-2) !important;
    color: var(--gray-12) !important;
  }

  &:disabled {
    background-color: var(--gray-2) !important;
    color: var(--gray-11) !important;
    opacity: 0.75 !important;
    cursor: not-allowed !important;

    &:hover {
      border: 1px solid var(--gray-6) !important;
    }
  }

  &:disabled ~ label {
    background-color: var(--gray-2) !important;
    color: var(--gray-11) !important;
  }
`;

export const InputLabel = styled.label.withConfig({
  shouldForwardProp: (props) => !['error', 'success'].includes(props),
})`
  font: inherit;
  position: absolute;
  min-height: 1.6rem;
  pointer-events: none;
  top: 0;
  transform: translateY(-50%);
  left: 0.8rem;
  opacity: 1;
  padding: 0 0.4rem;
  border-radius: var(--border-radius-sm);
  font-size: 1.2rem;
  background-color: var(--gray-2);
  color: ${({ error, success }) =>
    error ? 'var(--red-9)' : success ? 'var(--green-9)' : 'var(--gray-11)'};
  transition: all 200ms ease;
`;

export const InputError = styled.span`
  color: var(--red-9);
`;
