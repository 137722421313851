import {
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useId,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { StyleSheetManager, css, keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import useOutsideClick from '../../hooks/useOutsideClick';
import Overlay, { overlayVariants } from './Overlay';
import { CloseButton } from '../buttons/CloseButton';
import { StyledFooter, StyledHeader } from '../../styles/GeneralStyling';
import { media } from '../../styles/ResponsiveDesign';

const StyledPopupHeader = styled(StyledHeader)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const StyledPopup = styled.div`
  position: fixed;
  ${(props) => (props.position === 'top' ? 'top: 0;' : 'bottom: 0;')}
  left: 50%;
  transform: translate(
    -50%,
    ${(props) => (props.position === 'top' ? '-100%' : '100%')}
  );
  width: ${(props) => {
    switch (props.size) {
      case 'small':
        return '44rem';
      case 'medium':
        return '72rem';
      case 'large':
        return '128rem';
      default:
        return '72rem';
    }
  }};
  max-width: 60%;
  max-height: 95%;
  height: ${(props) => (props.height ? props.height : 'auto')};

  border-radius: ${(props) =>
    props.position === 'top'
      ? '0 0 var(--border-radius-cd) var(--border-radius-cd)'
      : 'var(--border-radius-cd) var(--border-radius-cd) 0 0'};
  box-shadow: var(--shadow-md);
  background-color: var(--gray-2);

  overflow: hidden;

  z-index: 1000;

  ${(props) =>
    props.$unmount
      ? css`
          -webkit-animation: ${props.position === 'top'
              ? fromTopOut
              : fromBottomOut}
            ease-in-out 0.2s forwards 0s;
          animation: ${props.position === 'top' ? fromTopOut : fromBottomOut}
            ease-in-out 0.2s forwards 0s;
        `
      : css`
          -webkit-animation: ${(props) =>
              props.position === 'top' ? fromTopIn : fromBottomIn}
            ease-in-out 0.2s forwards 0s;
          animation: ${(props) =>
              props.position === 'top' ? fromTopIn : fromBottomIn}
            ease-in-out 0.2s forwards 0s;
        `}

  ${media.tablet`
  width: 90%;
  max-width: 90%;
`}

  ${media.mobileLandscape`
  width: 100%;
  max-width: 100%;
`}
`;

const PopupContext = createContext();

const Popup = ({ children }) => {
  const [openPopup, setOpenPopup] = useState('');

  const closePopup = () => {
    setOpenPopup('');
    document.body.style.overflow = 'auto';
  };
  return (
    <PopupContext.Provider value={{ openPopup, setOpenPopup, closePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

const Open = ({ children, popupName, onClick, ...props }) => {
  const { setOpenPopup } = useContext(PopupContext);

  return cloneElement(children, {
    onClick: (e) => {
      setOpenPopup(popupName);
      document.body.style.overflow = 'hidden';
      onClick?.(e);
    },
    ...props,
  });
};

const MotionPopup = styled(motion.div)`
  position: fixed;
  ${(props) => (props.position === 'top' ? 'top: 0;' : 'bottom: 0;')}
  left: 50%;
  width: ${(props) => {
    switch (props.size) {
      case 'small':
        return '44rem';
      case 'medium':
        return '72rem';
      case 'large':
        return '128rem';
      default:
        return '72rem';
    }
  }};
  max-width: 60%;
  max-height: 95%;
  height: ${(props) => (props.height ? props.height : 'auto')};
  border-radius: ${(props) =>
    props.position === 'top'
      ? '0 0 var(--border-radius-cd) var(--border-radius-cd)'
      : 'var(--border-radius-cd) var(--border-radius-cd) 0 0'};
  box-shadow: var(--shadow-md);
  background-color: var(--gray-2);
  overflow: hidden;
  z-index: 1000;

  ${media.tablet`
    width: 100%;
    max-width: 100%;
  `}
`;

const Window = ({
  children,
  popupName,
  position = 'bottom',
  close = true,
  showButton = true,
  header,
  footer,
  size = 'medium',
  height = 'auto',
}) => {
  const { openPopup, closePopup } = useContext(PopupContext);
  const ref = useOutsideClick(closePopup);
  const isOpen = openPopup === popupName;

  const isFunctionalOrClassComponent =
    isValidElement(children) && typeof children.type === 'function';

  return createPortal(
    <StyleSheetManager
      shouldForwardProp={(prop) =>
        prop !== 'position' ||
        prop !== 'size' ||
        prop !== 'unmount' ||
        prop !== 'height'
      }
    >
      <AnimatePresence>
        {isOpen && (
          <>
            <Overlay
              variants={overlayVariants}
              initial='initial'
              animate='animate'
              exit='exit'
            />
            <MotionPopup
              position={position}
              size={size}
              ref={close ? ref : null}
              height={height}
              initial={{
                y: position === 'top' ? '-100%' : '100%',
                x: '-50%',
                opacity: 0,
              }}
              animate={{
                y: 0,
                x: '-50%',
                opacity: 1,
              }}
              exit={{
                y: position === 'top' ? '-100%' : '100%',
                x: '-50%',
                opacity: 0,
              }}
              transition={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
            >
              {header && (
                <StyledPopupHeader>
                  {header}
                  {(showButton || close) && (
                    <CloseButton onClick={closePopup} />
                  )}
                </StyledPopupHeader>
              )}

              {isFunctionalOrClassComponent
                ? cloneElement(children, { closePopup })
                : children}

              {footer && <StyledFooter>{footer}</StyledFooter>}
            </MotionPopup>
          </>
        )}
      </AnimatePresence>
    </StyleSheetManager>,
    document.getElementById('modal')
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a Popup provider');
  }
  return context;
};

export const ShowPopup = ({
  popupName,
  button,
  position,
  component,
  onClick,
  close,
  showButton = true,
  header,
  footer,
  size,
  height = 'auto',
}) => {
  const popupId = useId();

  return (
    <>
      {showButton && (
        <Popup.Open popupName={popupName || popupId} onClick={onClick}>
          {button}
        </Popup.Open>
      )}
      <Popup.Window
        popupName={popupName || popupId}
        position={position}
        header={header}
        footer={footer}
        size={size}
        height={height}
        close={close}
      >
        {component}
      </Popup.Window>
    </>
  );
};

Popup.Open = Open;
Popup.Window = Window;

export default Popup;
