import styled, { css } from 'styled-components';
import {
  HiOutlineArrowUturnLeft,
  HiOutlineArrowUturnRight,
  HiOutlineBars3,
  HiOutlineBars3BottomLeft,
  HiOutlineBars3BottomRight,
  HiOutlineChatBubbleBottomCenter,
  HiOutlineSun,
  HiOutlineMoon,
  HiOutlineListBullet,
  HiOutlineFolder,
} from 'react-icons/hi2';
import {
  BoldIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  ItalicIcon,
  NumberedListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '@heroicons/react/24/outline';
import { LuCaseUpper, LuCaseLower } from 'react-icons/lu';
import { InfoTip } from '../../ui/notifications/InfoTip';
import ButtonIcon from '../../ui/buttons/ButtonIcon';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import { useCallback } from 'react';
import { useEditor } from '../../context/EditorContext';
import ColorfulEditor from './ColorfulEditor';
import { FontFamilyEditor } from './FontFamilyEditor';
import { FontSizeEditor } from './FontSizeEditor';
import { toggleAlignment, toggleBlockType, toggleInlineStyle } from './helpers';
import { LinkEditor } from './LinkEditor';
import MediaEditor from './MediaEditor';
import useToggle from '../../hooks/useToggle';
import { TextFade } from '../../ui/typography/Text';
import { useDarkMode } from '../../context/DarkModeProvider';
import { useToast } from '../../context/ToastProvider';
import { ToolButton } from '../../ui/layout/Card';
import { Menus } from '../../ui/menu/Menus';
import {
  setEditorSettings,
  useBlogState,
} from '../../redux/features/blog-slice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const StyledToolsContainer = styled.div`
  /* padding: var(--padding); */
  /* border-bottom: var(--border-md-non); */
`;

const StyledToolsRow = styled(FlexRow)`
  padding-top: 1.6rem;
  gap: 1.2rem;
  position: relative;
`;

export const EditorToolButton = styled(ButtonIcon).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  ${(props) =>
    props.active &&
    css`
      background-color: var(--color-9);
    `}
`;

export const HomeTools = () => {
  const { editorState, setEditorState } = useEditor();

  const getCurrentBlockType = useCallback(() => {
    const selection = editorState.getSelection();
    return editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  }, [editorState]);

  const isInlineStyleActive = useCallback(
    (style) => {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    },
    [editorState]
  );

  const isBlockTypeActive = useCallback(
    (blockType) => {
      return getCurrentBlockType() === blockType;
    },
    [getCurrentBlockType]
  );

  const getTextAlignment = useCallback(() => {
    const selection = editorState.getSelection();
    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    return currentBlock.getData().get('textAlign') || 'left';
  }, [editorState]);

  const handleMetadataChange = (key, value) => {
    if (key === 'inLine') {
      setEditorState(toggleInlineStyle(editorState, value));
    }
    if (key === 'block') {
      setEditorState(toggleBlockType(editorState, value));
    }
    if (key === 'align') {
      setEditorState(toggleAlignment(editorState, value));
    }
  };
  const home = [
    {
      title: 'Bold',
      icon: <BoldIcon />,
      active: isInlineStyleActive('BOLD'),
      onClick: () => handleMetadataChange('inLine', 'BOLD'),
    },

    {
      title: 'Italic',
      icon: <ItalicIcon />,
      active: isInlineStyleActive('ITALIC'),
      onClick: () => handleMetadataChange('inLine', 'ITALIC'),
    },
    {
      title: 'Underline',
      icon: <UnderlineIcon />,
      active: isInlineStyleActive('UNDERLINE'),
      onClick: () => handleMetadataChange('inLine', 'UNDERLINE'),
    },
    {
      title: 'Strikethrough',
      icon: <StrikethroughIcon />,
      active: isInlineStyleActive('STRIKETHROUGH'),
      onClick: () => handleMetadataChange('inLine', 'STRIKETHROUGH'),
    },
    {
      title: 'Heading-1',
      icon: <H1Icon />,
      active: isBlockTypeActive('header-four'),
      onClick: () => handleMetadataChange('block', 'header-four'),
    },
    {
      title: 'Heading-2',
      icon: <H2Icon />,
      active: isBlockTypeActive('header-five'),
      onClick: () => handleMetadataChange('block', 'header-five'),
    },
    {
      title: 'Heading-3',
      icon: <H3Icon />,
      active: isBlockTypeActive('header-six'),
      onClick: () => handleMetadataChange('block', 'header-six'),
    },

    {
      title: 'Uppercase',
      icon: <LuCaseUpper />,
      active: isInlineStyleActive('UPPERCASE'),
      onClick: () => handleMetadataChange('inLine', 'UPPERCASE'),
    },

    {
      title: 'Lowercase',
      icon: <LuCaseLower />,
      active: isInlineStyleActive('LOWERCASE'),
      onClick: () => handleMetadataChange('inLine', 'LOWERCASE'),
    },
    {
      title: 'Ordered List',
      icon: <NumberedListIcon />,
      active: isBlockTypeActive('ordered-list-item'),
      onClick: () => handleMetadataChange('block', 'ordered-list-item'),
    },
    {
      title: 'Unordered List',
      icon: <HiOutlineListBullet />,
      active: isBlockTypeActive('unordered-list-item'),
      onClick: () => handleMetadataChange('block', 'unordered-list-item'),
    },

    {
      title: 'Align Left',
      icon: <HiOutlineBars3BottomLeft />,
      active: getTextAlignment() === 'left',
      onClick: () => handleMetadataChange('align', 'left'),
    },
    {
      title: 'Align Center',
      icon: <HiOutlineBars3 />,
      active: getTextAlignment() === 'center',
      onClick: () => handleMetadataChange('align', 'center'),
    },
    {
      title: 'Align Right',
      icon: <HiOutlineBars3BottomRight />,
      active: getTextAlignment() === 'right',
      onClick: () => handleMetadataChange('align', 'right'),
    },
    {
      title: 'Blockquote',
      icon: <HiOutlineChatBubbleBottomCenter />,
      active: isBlockTypeActive('blockquote'),
      onClick: () => handleMetadataChange('block', 'blockquote'),
    },
  ];
  return (
    <StyledToolsContainer>
      <FlexColumn gap={1.6}>
        <StyledToolsRow>
          {home?.map((tool) => (
            <InfoTip key={tool.title} tip={tool.title}>
              <EditorToolButton
                variant='gray'
                active={tool.active}
                onClick={tool.onClick}
              >
                {tool.icon}
              </EditorToolButton>
            </InfoTip>
          ))}
        </StyledToolsRow>
        <FlexRow>
          {/* <ColorfulEditor /> */}
          {/* <FontFamilyEditor /> */}
          {/* <FontSizeEditor /> */}
        </FlexRow>
      </FlexColumn>
    </StyledToolsContainer>
  );
};
export const InsertTools = ({ shouldFocus, setShouldFocus }) => {
  return (
    <StyledToolsContainer>
      <StyledToolsRow>
        <LinkEditor />

        <MediaEditor />
      </StyledToolsRow>
    </StyledToolsContainer>
  );
};
export const DesignTools = () => {
  return <StyledToolsContainer>Design</StyledToolsContainer>;
};
export const LayoutTools = () => {
  return <StyledToolsContainer>Layout</StyledToolsContainer>;
};
export const ReviewTools = () => {
  const dispatch = useDispatch();
  const { editorSettings } = useBlogState();
  const { spellingAndGrammar, autoCapitalize } = editorSettings;

  function handleSpellingAndGrammar() {
    dispatch(setEditorSettings({ spellingAndGrammar: !spellingAndGrammar }));

    toast.success(
      `Spelling and Grammar check ${
        !spellingAndGrammar ? 'enabled' : 'disabled'
      }`
    );
  }

  function handleAutoCapitalize(type) {
    dispatch(setEditorSettings({ autoCapitalize: type }));
  }

  const reviewTools = [
    {
      title: 'Spelling & Grammar',
      icon: 'abc',
      active: spellingAndGrammar,
      onClick: handleSpellingAndGrammar,
    },
  ];

  return (
    <StyledToolsContainer>
      <StyledToolsRow>
        {reviewTools.map((tool, index) => (
          <ToolButton
            key={index}
            name={tool.title}
            variant='gray'
            active={tool.active}
            onClick={tool.onClick}
          ></ToolButton>
        ))}

        <Menus
          toggle={
            <ToolButton
              active={autoCapitalize !== 'none'}
              name='Auto Capitalize'
            ></ToolButton>
          }
          trigger='click'
          options={[
            {
              label: 'Sentences',
              onClick: () => handleAutoCapitalize('sentences'),
            },
            {
              label: 'Words',
              onClick: () => handleAutoCapitalize('words'),
            },
            {
              label: 'Characters',
              onClick: () => handleAutoCapitalize('characters'),
            },
            {
              label: 'None',
              onClick: () => handleAutoCapitalize('none'),
            },
          ]}
        />
      </StyledToolsRow>
    </StyledToolsContainer>
  );
};
export const ViewTools = () => {
  const { theme, setTheme } = useDarkMode();

  const viewTools = [
    {
      title: 'Light Mode',
      icon: <HiOutlineSun color='var(--orange-9)' />,
      active: theme === 'light',
      onClick: () => setTheme('light'),
    },

    {
      title: 'Dark Mode',
      icon: <HiOutlineMoon />,
      active: theme === 'dark',
      onClick: () => setTheme('dark'),
    },
  ];
  return (
    <StyledToolsContainer>
      <StyledToolsRow>
        {viewTools.map((tool) => (
          <ToolButton
            key={tool.title}
            name={tool.title}
            icon={tool.icon}
            variant='gray'
            active={tool.active}
            onClick={tool.onClick}
          />
        ))}
      </StyledToolsRow>
    </StyledToolsContainer>
  );
};
export const SettingTools = () => {
  const dispatch = useDispatch();

  const { toggle: autosaveToggleMarkup, isOn: isAutosaveOn } = useToggle({
    defaultValue: true,
    onChange: (newState) => dispatch(setEditorSettings({ autoSave: newState })),
  });

  return (
    <StyledToolsContainer>
      <StyledToolsRow>
        <FlexRow>
          <InfoTip tip='Turn on autosave to save changes automatically'>
            <TextFade>Autosave</TextFade>
          </InfoTip>
          {autosaveToggleMarkup}
        </FlexRow>
      </StyledToolsRow>
    </StyledToolsContainer>
  );
};
export const CommonTools = () => {
  const { saveContent, undo, redo } = useEditor();
  const commonTools = [
    {
      title: 'Save',
      icon: <HiOutlineFolder />,
      active: false,
      onClick: saveContent,
    },
    {
      title: 'Undo',
      icon: <HiOutlineArrowUturnLeft />,
      active: false,
      onClick: undo,
    },
    {
      title: 'Redo',
      icon: <HiOutlineArrowUturnRight />,
      active: false,
      onClick: redo,
    },
  ];

  return (
    <div style={{ display: 'flex', gap: '0.8rem' }}>
      {commonTools?.map((tool) => (
        <InfoTip key={tool.title} tip={tool.title}>
          <ButtonIcon
            variant='transparent'
            active={tool.active}
            onClick={tool.onClick}
          >
            {tool.icon}
          </ButtonIcon>
        </InfoTip>
      ))}
    </div>
  );
};
