import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from './styles/GlobalStyles';
import { DarkModeProvider } from './context/DarkModeProvider';
import { SidebarProvider } from './ui/app/AppLayout';
import { ToastProvider } from './context/ToastProvider';
import Menu from './ui/menu/Menu';
import { Provider } from 'react-redux';
import { persistor, store } from './store.js';
import Modal from './ui/modal/Modal.jsx';
import { PersistGate } from 'redux-persist/integration/react';
import Aside from './ui/modal/Aside.jsx';
import Popup from './ui/modal/Popup.jsx';
import AppInitialization from './features/admin/permissions/AppInitialization.jsx';
import AppRoutes from './ui/app/AppRoutes.jsx';
import { Toaster } from 'react-hot-toast';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import SessionManager from './ui/app/SessionManager.jsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <Toaster
        toastOptions={{
          boxShadow: 'var(--box-shadow-md)',

          success: {
            style: {
              background: 'var(--green-3)',
              color: 'var(--green-11)',
            },
          },
          error: {
            style: {
              background: 'var(--red-3)',
              color: 'var(--red-11)',
            },
          },
          blank: {
            icon: <HiOutlineInformationCircle color='var(--blue-11)' />,
            style: {
              background: 'var(--blue-3)',
              color: 'var(--blue-11)',
            },
          },
          warning: {
            icon: <HiOutlineExclamationTriangle color='var(--yellow-11)' />,
            style: {
              background: 'var(--yellow-3)',
              color: 'var(--yellow-11)',
            },
            duration: 4000,
          },
        }}
      />
      <ToastProvider>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <AppInitialization>
              <SessionManager>
                <Modal>
                  <Popup>
                    <Aside>
                      <SidebarProvider>
                        <Menu>
                          <Popup>
                            <DarkModeProvider>
                              <GlobalStyles />
                              <BrowserRouter>
                                <AppRoutes />
                              </BrowserRouter>
                            </DarkModeProvider>
                          </Popup>
                        </Menu>
                      </SidebarProvider>
                    </Aside>
                  </Popup>
                </Modal>
              </SessionManager>
            </AppInitialization>
          </QueryClientProvider>
        </PersistGate>
      </ToastProvider>
    </Provider>
  );
}

export default App;
