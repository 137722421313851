import styled from 'styled-components';
import { HiOutlineLockClosed, HiOutlineUserPlus } from 'react-icons/hi2';
import BioData from './BioData';
import Security from './Security';
import { FlexColumn, FlexRow } from '../../ui/layout/Flex';
import {
  SectionHeader,
  SectionHeading,
  StyledBodyScroll,
  StyledContainer,
} from '../../styles/GeneralStyling';
import { useTabs } from '../../hooks/useTabs';
import { usePageTitle } from '../../hooks/usePageTitle';

const tabIds = {
  general: 'General',
  security: 'Security',
};

export const StyledProfileSection = styled(FlexColumn)`
  padding: var(--padding-ver) 0 var(--padding-ver) 0;
  gap: 1.6rem;
`;

const ProfileTabs = () => {
  const tabs = [
    {
      id: 'general',
      label: 'General',
      content: <BioData />,
      svg: <HiOutlineUserPlus />,
    },

    {
      id: 'security',
      label: 'Security',
      content: <Security />,
      svg: <HiOutlineLockClosed />,
    },
  ];

  const { TabList, TabPanel, activeTabId } = useTabs({
    items: tabs,
    padding: false,
  });

  usePageTitle(`${tabIds[activeTabId]} Settings`);

  return (
    <StyledContainer size='medium'>
      <SectionHeader>
        <FlexRow justify='between'>
          <SectionHeading>Account</SectionHeading>
          {TabList()}
        </FlexRow>
      </SectionHeader>
      <StyledBodyScroll>
        <FlexColumn gap={1.6}>{TabPanel()}</FlexColumn>
      </StyledBodyScroll>
    </StyledContainer>
  );
};

export default ProfileTabs;
