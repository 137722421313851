import styled from 'styled-components';
import { StyledNavLink } from '../links/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakpoints, media } from '../../styles/ResponsiveDesign';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useSidebar } from './AppLayout';
import ButtonIcon from '../buttons/ButtonIcon';
import {
  HiOutlineArrowLeftOnRectangle,
  HiOutlinePencilSquare,
} from 'react-icons/hi2';
import { InfoTip } from '../notifications/InfoTip';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import useRoleCheck from '../../hooks/useRoleCheck';
import { useNavLinks } from '../../hooks/useNavLinks';
import { motion, AnimatePresence } from 'framer-motion';
import { useMemo } from 'react';

const StyledSideBar = styled(motion.aside).withConfig({
  shouldForwardProp: (prop) => !['isSidebarOpen'].includes(prop),
})`
  padding: 1.6rem 0.4rem;
  border-right: var(--border-sm-non);
  background-color: var(--gray-1);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  overflow-y: auto;
  isolation: isolate;

  ${media.tabletLandscape`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    width: 24rem;
    transform: translateX(${(props) =>
      props.isSidebarOpen ? '0' : '-100%'}) !important;
  `}
`;

const StyledDivider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const StyledSidebarTools = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
  gap: 1.6rem;

  ${media.tabletLandscape`
      display:none;
  `}
`;

const AnimatedNavLink = styled(motion.div)`
  width: 100%;
`;

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSidebarOpen, toggleSidebar, closeSidebar } = useSidebar();
  const isTabletLandscapeOrSmaller = useMediaQuery(breakpoints.tabletLandscape);
  const { isCreator } = useRoleCheck();
  const { accessibleLinks } = useNavLinks();

  function handleOutsideClick() {
    if (isTabletLandscapeOrSmaller) {
      closeSidebar();
    }
  }
  function createBlog() {
    navigate('/create/new');
    handleOutsideClick();
  }

  const ref = useOutsideClick(handleOutsideClick);

  const sidebarAnimation = useMemo(() => {
    return {
      initial: false,
      animate: {
        width: !isTabletLandscapeOrSmaller
          ? isSidebarOpen
            ? '24rem'
            : '4.8rem'
          : '24rem',
        transition: {
          type: 'spring',
          stiffness: 400,
          damping: 30,
        },
      },
    };
  }, [isTabletLandscapeOrSmaller, isSidebarOpen]);

  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemAnimation = {
    hidden: {
      x: -20,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 24,
      },
    },
  };

  return (
    <StyledSideBar
      ref={ref}
      isSidebarOpen={isSidebarOpen}
      {...sidebarAnimation}
    >
      <motion.div
        animate={{
          x: isSidebarOpen ? 0 : -10,
          opacity: isSidebarOpen ? 1 : 0.7,
        }}
        transition={{ duration: 0.2 }}
      >
        <StyledSidebarTools>
          <InfoTip tip={isSidebarOpen ? 'Hide sidebar' : 'Show sidebar'}>
            <ButtonIcon color='color' onClick={toggleSidebar}>
              <motion.div
                animate={{ rotate: isSidebarOpen ? 0 : 180 }}
                transition={{ duration: 0.3 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <HiOutlineArrowLeftOnRectangle />
              </motion.div>
            </ButtonIcon>
          </InfoTip>

          {isCreator() && (
            <InfoTip tip='Create blog'>
              <ButtonIcon color='color' onClick={createBlog}>
                <motion.div>
                  <HiOutlinePencilSquare />
                </motion.div>
              </ButtonIcon>
            </InfoTip>
          )}
        </StyledSidebarTools>
      </motion.div>

      <StyledDivider
        as={motion.div}
        variants={containerAnimation}
        initial='hidden'
        animate='show'
      >
        {accessibleLinks.map((navLink, index) => (
          <AnimatedNavLink key={index} variants={itemAnimation}>
            <InfoTip
              tip={navLink.name}
              enabled={!isSidebarOpen && !isTabletLandscapeOrSmaller}
            >
              <StyledNavLink
                onClick={handleOutsideClick}
                isSidebarOpen={isSidebarOpen}
                to={navLink.to}
              >
                <motion.div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.2rem',
                  }}
                >
                  {location?.pathname === navLink.to
                    ? navLink.activeSvg
                    : navLink.inactiveSvg}
                  <AnimatePresence>
                    {isSidebarOpen && (
                      <motion.span
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        transition={{ duration: 0.2 }}
                      >
                        {navLink.name}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </motion.div>
              </StyledNavLink>
            </InfoTip>
          </AnimatedNavLink>
        ))}
      </StyledDivider>
    </StyledSideBar>
  );
};

export default Sidebar;
