import { HiOutlineEnvelope } from 'react-icons/hi2';
import { useNavigation } from '../../hooks/useNavigation';
import { StyledBody, StyledContainer } from '../../styles/GeneralStyling';
import StateDisplay from '../notifications/StateDisplay';
import { ButtonText } from '../buttons/ButtonText';
import { useLogout } from '../../api/authentication/logout';
import useRoleCheck from '../../hooks/useRoleCheck';
import { Navigate } from 'react-router-dom';

const Suspended = () => {
  const { navigateToPath } = useNavigation();
  const { mutate: logout } = useLogout();
  const { status } = useRoleCheck();
  const isSuspended = status === 'suspended';

  if (!isSuspended) {
    return <Navigate to='/blog/discover' replace />;
  }

  if (isSuspended) {
    return (
      <StyledContainer size='small'>
        <StyledBody>
          <StateDisplay
            variant='error'
            title='Account Temporarily Paused'
            description={
              <div>
                We've had to temporarily pause your account. If you think this
                was done in error, our support team is here to help! Reach out
                to us at support@bittsblog.com or,{' '}
                <ButtonText
                  onClick={() =>
                    logout({
                      onSuccess: () => navigateToPath('/login'),
                    })
                  }
                >
                  Sign Out
                </ButtonText>
              </div>
            }
            action={{
              label: 'Contact Support',
              onClick: () =>
                (window.location.href = 'mailto:support@bittsblog.com'),
              icon: <HiOutlineEnvelope />,
            }}
          />
        </StyledBody>
      </StyledContainer>
    );
  }

  return null;
};

export default Suspended;
