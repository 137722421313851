import {
  HiOutlineArrowRightOnRectangle,
  HiOutlineUserPlus,
} from 'react-icons/hi2';
import Avatar from './Avatar';
import { useLogout } from '../../api/authentication/logout';
import { MenuButton, Menus } from '../../ui/menu/Menus';
import { useNavigation } from '../../hooks/useNavigation';
import { useAuth } from '../../redux/features/auth-slice';

const ProfileMenu = () => {
  const { navigateToPath } = useNavigation();
  const { user } = useAuth();
  const { mutate: logoutUser } = useLogout();

  function handleLogout() {
    logoutUser();
  }

  return (
    <Menus
      toggle={
        <Avatar
          profile={{
            first_name: user?.user_metadata?.first_name,
            last_name: user?.user_metadata?.last_name,
          }}
          tip={user?.user_metadata?.first_name || 'Unknown'}
        />
      }
    >
      <MenuButton onClick={() => navigateToPath('/profile')}>
        Manage Account
        <HiOutlineUserPlus />
      </MenuButton>
      <MenuButton style={{ color: 'var(--red-9)' }} onClick={handleLogout}>
        Sign Out
        <HiOutlineArrowRightOnRectangle style={{ color: 'var(--red-9)' }} />
      </MenuButton>
    </Menus>
  );
};

export default ProfileMenu;
