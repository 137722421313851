import styled, { css } from 'styled-components';

const justify = {
  start: css`
    justify-content: start;
  `,
  center: css`
    justify-content: center;
  `,
  end: css`
    justify-content: end;
  `,

  between: css`
    justify-content: space-between;
  `,
};

const align = {
  start: css`
    align-items: start;
  `,
  center: css`
    align-items: center;
  `,
  end: css`
    align-items: end;
  `,

  stretch: css`
    align-items: stretch;
  `,
};

export const FlexRow = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['justify', 'align', 'gap', 'width', 'wrap', 'overflow'].includes(prop),
})`
  width: ${(props) => props.width || '100%'};
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${(props) => props.$gap || props.gap || 0.8}rem;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  position: relative;
  overflow: ${(props) => props.overflow};

  ${(props) =>
    props.$wrap &&
    css`
      flex-wrap: wrap;
    `}

  ${(props) => justify[props.$justify || props.justify]}
  ${(props) => align[props.$align || props.align]}
`;

export const FlexColumn = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['justify', 'align', 'gap', 'width'].includes(prop),
})`
  width: ${(props) => props.width || props.width || '100%'};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap || props.gap || 0.8}rem;
  position: relative;
  overflow: ${(props) => props.overflow};

  ${(props) => justify[props.$justify || props.justify]}
  ${(props) => align[props.$align || props.align]}
`;

export const FlexCenter = styled.div.withConfig({
  shouldForwardProp: (prop) => !['justify', 'align', 'gap'].includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
`;

export const ScrollableContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap || '1.2rem'}; /* Optional gap between cards */

  &::-webkit-scrollbar {
    height: 8px; /* Customize scrollbar height */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Customize scrollbar color */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const LineBreak = styled.hr`
  border: none;
  height: ${({ size = '1px' }) => size};
  background-color: ${({ color = 'var(--gray-7)' }) => color};
  margin: 0.8rem 0;
`;

export default LineBreak;

import PropTypes from 'prop-types';

const StyledSpan = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'show',
})`
  color: var(--red-9);
  margin-left: 4px;
`;

export const RequiredIndicator = ({ symbol = '*', show = true }) => {
  if (!show) return null;
  return <StyledSpan show={show}>{symbol}</StyledSpan>;
};

RequiredIndicator.propTypes = {
  symbol: PropTypes.string,
  showIndicator: PropTypes.bool,
};
