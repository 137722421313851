import { useMemo } from 'react';
import useRoleCheck from './useRoleCheck';
import { usePermissionsState } from '../redux/features/permissions-slice';
import {
  HiBookmark,
  HiCog6Tooth,
  HiGlobeAlt,
  HiOutlineBookmark,
  HiOutlineCog6Tooth,
  HiOutlineGlobeAlt,
  HiOutlinePencilSquare,
  HiOutlineSquares2X2,
  HiPencilSquare,
  HiSquares2X2,
  HiOutlineChartBar,
  HiChartBar,
  HiOutlineUser,
  HiUser,
  HiOutlineHome,
  HiHome,
  HiChatBubbleBottomCenterText,
  HiOutlineChatBubbleBottomCenterText,
  HiShieldCheck,
  HiOutlineShieldCheck,
} from 'react-icons/hi2';
import { filter } from 'lodash';

export const iconMapping = {
  admin: {
    inactive: <HiOutlineShieldCheck />,
    active: <HiShieldCheck />,
  },
  moderator: {
    inactive: <HiOutlineShieldCheck />,
    active: <HiShieldCheck />,
  },
  dashboard: {
    active: <HiSquares2X2 />,
    inactive: <HiOutlineSquares2X2 />,
  },
  create: {
    active: <HiPencilSquare />,
    inactive: <HiOutlinePencilSquare />,
  },
  my_blogs: {
    active: <HiUser />,
    inactive: <HiOutlineUser />,
  },
  discover: {
    active: <HiGlobeAlt />,
    inactive: <HiOutlineGlobeAlt />,
  },
  bookmarks: {
    active: <HiBookmark />,
    inactive: <HiOutlineBookmark />,
  },
  preferences: {
    active: <HiCog6Tooth />,
    inactive: <HiOutlineCog6Tooth />,
  },
  support: {
    active: <HiChatBubbleBottomCenterText />,
    inactive: <HiOutlineChatBubbleBottomCenterText />,
  },
  analytics: {
    active: <HiChartBar />,
    inactive: <HiOutlineChartBar />,
  },
};

export const useNavLinks = () => {
  const { routes, routePermissions } = usePermissionsState();
  const { hasRouteAccess } = useRoleCheck();

  const mainLinks = useMemo(() => {
    // filter out disabled routes
    const links = filter(routes.navigation, { is_enabled: true }).map(
      (route) => ({
        name: route.label,
        value: route.value,
        to: route.path,
        inactiveSvg: iconMapping[route.value]?.inactive,
        activeSvg: iconMapping[route.value]?.active,
        permissions: routePermissions[route.value]?.permissions,
        strict: routePermissions[route.value]?.strict,
      })
    );

    return links;
  }, [routes.navigation, routePermissions]);

  const accessibleLinks = useMemo(() => {
    const filtered = mainLinks.filter((link) => {
      const hasAccess = hasRouteAccess(link.value);

      return hasAccess;
    });

    return filtered;
  }, [mainLinks, hasRouteAccess]);

  return { mainLinks, accessibleLinks };
};
