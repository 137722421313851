import { useEffect, useRef, useState } from 'react';
import { HiOutlineMagnifyingGlass, HiOutlineXMark } from 'react-icons/hi2';
import styled from 'styled-components';
import ButtonIcon from '../../ui/buttons/ButtonIcon';
import SearchHistory from './SearchHistory';
import { media } from '../../styles/ResponsiveDesign';

const StyledForm = styled.form`
  width: 100%;
  background-color: var(--bg-sec);
  display: flex;
  flex-direction: column;

  & > * {
    padding: 1.6rem 2.4rem;
  }

  & .close-btn {
    border: var(--border-sm-int);
  }
`;

const Header = styled.div`
  width: 100%;
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;

  border-bottom: var(--border-sm-int);

  outline: none;

  & .search-btn {
    &:hover {
      background: none;
    }
  }
`;

const Body = styled.div`
  width: 100%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const StyledInput = styled.input.attrs({ type: 'search' })`
  flex: 1;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  transition: all 0.2s ease;
  background-color: transparent;
  cursor: pointer;

  ${media.tablet`
    font-size: 1.6rem;
  `}

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: var(--gray-11);
  }

  &:focus {
    outline: none !important;
  }
`;

const Search = (props) => {
  const { closeModal } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');

  const searchInputRef = useRef();

  const handleSearchTerm = (event) => {
    setDebouncedTerm(event.target.value);
  };

  useEffect(() => {
    searchInputRef && searchInputRef.current.focus();

    const handleEscPress = (event) => {
      if (event.key === 'Escape') {
        closeModal?.();
      }
    };

    document.addEventListener('keydown', handleEscPress);

    const intervalId = setTimeout(() => {
      setSearchTerm(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(intervalId);
      document.removeEventListener('keypress', handleEscPress);
    };
  }, [debouncedTerm, closeModal]);

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Header>
        <ButtonIcon className='search-btn'>
          <HiOutlineMagnifyingGlass />
        </ButtonIcon>
        <StyledInput
          ref={searchInputRef}
          onChange={(event) => handleSearchTerm(event)}
          value={debouncedTerm}
          type='search'
          placeholder='Search'
        />
        <ButtonIcon onClick={closeModal} className='close-btn'>
          <HiOutlineXMark />
        </ButtonIcon>
      </Header>

      <Body>
        <SearchHistory closeModal={closeModal} />
      </Body>
    </StyledForm>
  );
};

export default Search;
