import styled from 'styled-components';

export const StyledCheckboxContainer = styled.div`
  position: relative;
  width: auto;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.8rem;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  height: 1.8rem;
  min-width: 1.8rem;
  width: 1.8rem;
  flex: 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: var(--border-md-int);
  border-radius: 4px;

  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  overflow: hidden;

  transform-origin: center;
  transition: all 0.2s ease;

  &:checked {
    border: 1px solid var(--color-8);
  }

  &::before {
    position: absolute;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    content: '';
    background-color: var(--color-9);
    height: 70%;
    width: 70%;
    border-radius: 2px;
    transform-origin: center;
    transition: all 0.2s ease;
  }
  &:checked::before {
    transform: scale(1);
  }

  &:hover:not(:checked) {
    border: var(--border-md-int-str);
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--color-9) !important;
    box-shadow: 0 0 0 2px var(--color-6) !important;
  }

  &:disabled {
    border: var(--border-md-non) !important;
    cursor: not-allowed !important;

    &:hover {
      border: var(--border-md-non);
    }
  }

  &:disabled ~ label {
    cursor: not-allowed !important;
    opacity: 0.75 !important;
    cursor: not-allowed !important;
    color: var(--label-sec) !important;
  }
`;

export const CheckboxLabel = styled.label`
  font: inherit;
  color: ${({ $error, $success, $fade }) =>
    $error
      ? 'var(--red-9)'
      : $success
      ? 'var(--green-9)'
      : $fade
      ? 'var(--gray-11)'
      : 'var(--gray-12)'};
  text-transform: ${(props) => props.$uppercase && 'uppercase'};
`;
