import { useCallback, useMemo } from 'react';
import { usePermissionsState } from '../redux/features/permissions-slice';
import { isEmpty } from 'lodash';
import { useAuth } from '../redux/features/auth-slice';

const useRoleCheck = () => {
  const { user } = useAuth();
  const { roleHierarchy, featurePermissions, routePermissions } =
    usePermissionsState();

  const isAccountActive = useMemo(() => {
    return user?.user_metadata?.account_status === 'active';
  }, [user?.user_metadata?.account_status]);

  const checkRole = useCallback(
    (requiredPermissions, strict = false) => {
      // Get user role
      const userRole = user?.user_metadata?.role || 'guest';

      // Check if account is active
      if (!isAccountActive) {
        return false;
      }

      // Handle array of permissions
      const permissions = Array.isArray(requiredPermissions)
        ? requiredPermissions
        : [requiredPermissions];

      if (strict) {
        const strictCheck = permissions.includes(userRole);

        return strictCheck;
      }

      // For hierarchical check
      const hierarchicalCheck = permissions.some(
        (permission) =>
          roleHierarchy[userRole]?.permissions?.includes(permission) || false
      );

      return hierarchicalCheck;
    },
    [
      user?.user_metadata?.role,
      featurePermissions,
      roleHierarchy,
      isAccountActive,
    ]
  );

  const roleCheckFactory = useCallback(
    (role) =>
      (strict = false) =>
        checkRole(role, strict),
    [checkRole]
  );

  const hasFeatureAccess = useCallback(
    (feature) => {
      if (isEmpty(featurePermissions)) return false;

      if (!isAccountActive) return false;

      const featureConfig = featurePermissions[feature];
      if (!featureConfig) return false;

      if (!featureConfig.is_enabled) return false;

      return checkRole(featureConfig.permissions);
    },
    [checkRole, featurePermissions]
  );

  const hasRouteAccess = useCallback(
    (route) => {
      if (isEmpty(routePermissions)) {
        return false;
      }

      const routeConfig = routePermissions[route];
      // If route config is not found, return false
      if (!routeConfig) {
        return false;
      }

      // If route is disabled, return false
      if (!routeConfig.is_enabled) {
        return false;
      }

      // If it's a public route, allow access regardless of account status
      if (routeConfig.is_public) {
        return true;
      }

      // For non-public routes, check account status and permissions
      if (!isAccountActive) {
        return false;
      }

      // Check if user has access to the route
      const roleCheck = checkRole(routeConfig.permissions, routeConfig.strict);
      return roleCheck;
    },
    [checkRole, routePermissions, isAccountActive]
  );

  return {
    isAdmin: roleCheckFactory('admin'),
    isModerator: roleCheckFactory('moderator'),
    isCreator: roleCheckFactory('creator'),
    isUser: roleCheckFactory('user'),
    isGuest: roleCheckFactory('guest'),
    checkRole,
    hasFeatureAccess,
    hasRouteAccess,
    role: user?.user_metadata?.role || 'guest',
    isAccountActive,
    status: user?.user_metadata?.account_status,
  };
};

export default useRoleCheck;
