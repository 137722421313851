import { useState } from 'react';

import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { Card, CardBody, CardHeader } from '../../ui/layout/Card';
import Button from '../../ui/buttons/Button';
import { FlexColumn } from '../../ui/layout/Flex';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import Heading from '../../ui/typography/Heading';
import { useAuth } from '../../redux/features/auth-slice';
import { useUpdatePassword, useVerifyPassword } from '../../auth/auth';
import toast from 'react-hot-toast';
import { usePasswordValidation } from '../../auth/usePasswordValidation';
import PasswordStrengthIndicator from '../../auth/PasswordStrengthIndicator';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import { Menus } from '../../ui/menu/Menus';

const Security = () => {
  const { user } = useAuth();
  const [formState, setFormState] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { mutate: verifyPassword, isLoading: isVerifying } =
    useVerifyPassword();
  const { mutate: updatePassword, isLoading: isUpdating } = useUpdatePassword();
  const { validations, strength, isValid, config } = usePasswordValidation(
    formState.newPassword
  );

  function handleChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }

  function resetForm() {
    setFormState({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    // First verify current password
    if (!isValid) {
      return toast.error('Please enter a valid password');
    }

    verifyPassword(
      {
        email: user?.email,
        password: formState.currentPassword,
      },
      {
        onSuccess: () => {
          // If verification succeeds, update to new password
          updatePassword(
            {
              password: formState.newPassword,
            },
            {
              onSuccess: () => {
                toast.success('Password updated successfully');
                resetForm();
              },
              onError: (error) => {
                const errorMessage = getCrudErrorMessage(
                  'update',
                  error,
                  'profiles'
                );
                toast.error(errorMessage.description);
              },
            }
          );
        },
        onError: () => {
          // Handle incorrect current password
          toast.error('Unable to update password');
        },
      }
    );
  };

  return (
    <Card padding='0'>
      <CardHeader border='none'>
        <FlexColumn>
          <Heading as='h6'>Change Password</Heading>
        </FlexColumn>
      </CardHeader>
      <CardBody>
        <form onSubmit={onSubmit}>
          <FlexColumn gap={3.2}>
            <StyledInputContainer>
              <Input
                type='password'
                name='currentPassword'
                placeholder='Current Password'
                value={formState.currentPassword}
                onChange={handleChange}
                required
                disabled={isVerifying || isUpdating}
                autoComplete='off'
              />
              <InputLabel>Current Password</InputLabel>
            </StyledInputContainer>

            <Menus
              toggle={
                <StyledInputContainer>
                  <Input
                    type='password'
                    name='newPassword'
                    value={formState.newPassword}
                    onChange={handleChange}
                    required
                    placeholder='New Password'
                    disabled={isVerifying || isUpdating}
                    autoComplete='off'
                  />
                  <InputLabel>New Password</InputLabel>
                </StyledInputContainer>
              }
            >
              <PasswordStrengthIndicator
                validations={validations}
                strength={strength}
                config={config}
              />
            </Menus>

            <StyledInputContainer>
              <Input
                type='password'
                name='confirmPassword'
                value={formState.confirmPassword}
                onChange={handleChange}
                required
                placeholder='Confirm New Password'
                disabled={isVerifying || isUpdating || !isValid}
                autoComplete='off'
                error={
                  formState.confirmPassword &&
                  formState.newPassword !== formState.confirmPassword
                }
              />
              <InputLabel>Confirm Password</InputLabel>
            </StyledInputContainer>
            <ButtonGroup>
              <Button
                type='submit'
                disabled={isVerifying || isUpdating || !isValid}
                isLoading={isVerifying || isUpdating}
              >
                Update Password
              </Button>
            </ButtonGroup>
          </FlexColumn>
        </form>
      </CardBody>
    </Card>
  );
};

export default Security;
