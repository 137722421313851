import React from 'react';
import styled, { css } from 'styled-components';
import { TextFade } from '../../ui/typography/Text';
import Heading from '../../ui/typography/Heading';
import { HiOutlineStar } from 'react-icons/hi2';
import Button from '../buttons/Button';

const Card = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['gradient', 'color', 'hover', 'disabled'].includes(prop),
})`
  border-radius: var(--border-radius-cd);
  padding: var(--padding);
  background: white;
  box-shadow: var(--shadow-md);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: var(--gray-2);
  transition: border 0.2s ease transform 0.2s ease;
  background: ${(props) => {
    if (props.gradient) return props.gradient;

    const color = props.color || 'gray';
    return `-webkit-linear-gradient(
      to top right,
      var(--${color}-a1) 0%,
      var(--${color}-a3) 33%,
      var(--${color}-a6) 66%,
      var(--${color}-a9) 100%
    )`;
  }};

  background: ${(props) => {
    if (props.gradient) return props.gradient;

    const color = props.color || 'gray';
    return `linear-gradient(
      to top right,
      var(--${color}-a1) 0%,
      var(--${color}-a3) 33%,
      var(--${color}-a6) 66%,
      var(--${color}-a9) 100%
    )`;
  }};

  ${(props) =>
    props.hover &&
    css`
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover:not(:disabled) {
        background-position: right center;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      background: var(--gray-2);
    `}
`;

const BackgroundIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  position: absolute;
  width: 4px;
  height: 4px;
  color: ${(props) => props.color && `var(--${props.color}-9)`};
  opacity: 0.25;

  & svg {
    color: currentColor;
  }
`;

const Icon = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  font-size: 24px;
  color: ${(props) => props.color && `var(--${props.color}-12)`};

  & svg {
    width: 24px;
    height: 24px;
    color: currentColor;
  }
`;

const Title = styled(Heading).withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  color: ${(props) => props.color && `var(--${props.color}-12)`};
`;

const Description = styled(TextFade).withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  color: ${(props) => props.color && `var(--${props.color}-11)`};
`;

const GradientCard = ({
  hover = false,
  icon,
  title,
  description,
  color = 'red',
  onClick,
  disabled = false,
  action,
  isLoading = false,
}) => {
  const generateRandomPosition = () => ({
    top: `${Math.random() * 80 + 10}%`,
    left: `${Math.random() * 80 + 10}%`,
  });

  const iconPosition = Array.from({ length: 8 }, generateRandomPosition);

  function handleCardClick() {
    if (disabled) return;
    onClick?.();
  }

  return (
    <Card
      onClick={handleCardClick}
      hover={hover}
      color={color}
      disabled={disabled}
    >
      {iconPosition.map((position, index) => (
        <BackgroundIcon color={color} key={index} style={position}>
          {icon ? icon : <HiOutlineStar />}
        </BackgroundIcon>
      ))}
      <Icon color={color}>{icon}</Icon>
      <Title color={color} as={'h5'}>
        {title}
      </Title>
      <Description color={color}>{description}</Description>
      {action && (
        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={action?.onClick}
            variant={action?.variant || 'primary'}
            color={action?.color}
            isLoading={isLoading}
            disabled={action?.disabled}
          >
            {action?.label}
            {action?.icon}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default GradientCard;
