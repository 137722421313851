import { Link } from 'react-router-dom';
import Button from '../../ui/buttons/Button';
import { ButtonText } from '../../ui/buttons/ButtonText';
import {
  StyledAuthContainer,
  StyledFooter,
  StyledForm,
} from '../../ui/forms/StyledAuthContainer';
import {
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import { TextFade } from '../../ui/typography/Text';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { useState } from 'react';
import { FlexRow, RequiredIndicator } from '../../ui/layout/Flex';
import toast from 'react-hot-toast';
import { useNavigation } from '../../hooks/useNavigation';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import { useResetPasswordForEmail } from '../../auth/useAuth';
import { usePageTitle } from '../../hooks/usePageTitle';

const ResetPassword = () => {
  usePageTitle('Reset Password');
  const { navigateToPath } = useNavigation();
  const [email, setEmail] = useState('');
  const { mutate: resetPassword, isLoading: resetPasswordLoading } =
    useResetPasswordForEmail();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;
    resetPassword(
      { email, redirectTo: `${window.location.origin}/verify-email` },
      {
        onSuccess: () => {
          toast.success('Reset link sent to your email!');
          navigateToPath('/verify-email', {
            replace: true,
            state: {
              email,
              from: '/reset-password',
              resendType: 'recovery',
            },
          });
        },
        onError: (error) => {
          const errorMessage = getCrudErrorMessage(
            'auth',
            error,
            'resetPassword'
          );
          toast.error(errorMessage?.description);
        },
      }
    );
  };

  return (
    <StyledAuthContainer>
      <StyledForm onSubmit={handleSubmit}>
        <TextFade>
          Enter your email address and we will send you a code to reset your
          password.
        </TextFade>
        <StyledInputContainer>
          <Input
            required
            type='email'
            autoComplete='email'
            placeholder='Email address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={resetPasswordLoading}
          />
          <InputLabel>
            Email <RequiredIndicator />
          </InputLabel>
          <HiOutlineEnvelope />
        </StyledInputContainer>
        <Button
          type='submit'
          isLoading={resetPasswordLoading}
          disabled={resetPasswordLoading}
        >
          Reset Password
        </Button>
      </StyledForm>

      <StyledFooter>
        <FlexRow justify='between'>
          <TextFade>Remembered your password?</TextFade>

          <ButtonText as={Link} to='/login'>
            Log In
          </ButtonText>
        </FlexRow>
      </StyledFooter>
    </StyledAuthContainer>
  );
};

export default ResetPassword;
