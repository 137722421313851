import { HiOutlineMoon, HiOutlineSun } from 'react-icons/hi2';
import { useDarkMode } from '../../context/DarkModeProvider';
import { Menus } from '../menu/Menus';
import Button from '../buttons/Button';
import { useSupabaseOperation } from '../../api/supabaseService';
import { useAuth } from '../../redux/features/auth-slice';
import { useToast } from '../../context/ToastProvider';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../redux/features/profile-slice';
import { useUpdateUser } from '../../api/authentication/user';

const appearanceLabels = {
  light: 'Light',
  dark: 'Dark',
};

const getThemeIcon = (theme) => {
  switch (theme) {
    case 'light':
      return <HiOutlineSun color='var(--yellow-11)' />;
    case 'dark':
      return <HiOutlineMoon color='var(--gray-11)' />;
  }
};

const DarkModeToggle = () => {
  const { appearance, setAppearance } = useDarkMode();
  const { user } = useAuth();
  const { toast } = useToast();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser();
  const dispatch = useDispatch();

  const handleUpdateAppearance = (newAppearance) => {
    const previousAppearance = appearance;

    // Optimistically update the UI
    setAppearance(newAppearance);
    dispatch(updateProfile({ appearance: newAppearance }));
    // Only sync with backend if user is logged in
    updateUser({ data: { appearance: newAppearance } });
  };

  const appearanceOptions = [
    {
      label: 'Light',
      icon: getThemeIcon('light'),
      onClick: () => handleUpdateAppearance('light'),
    },
    {
      label: 'Dark',
      icon: getThemeIcon('dark'),
      onClick: () => handleUpdateAppearance('dark'),
    },
  ];

  return (
    <Menus
      toggle={
        <Button
          style={{ width: 'max-content' }}
          variant='secondary'
          size='small'
          disabled={isUpdatingUser}
        >
          {appearanceLabels[appearance]}
          {getThemeIcon(appearance)}
        </Button>
      }
      options={appearanceOptions}
    />
  );
};

export default DarkModeToggle;
