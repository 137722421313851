import { useMutation } from 'react-query';
import supabase from '../../services/supabase';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import { useNavigation } from '../../hooks/useNavigation';
import toast from 'react-hot-toast';

export const DEFAULT_PROFILE = {
  role: 'user',
  first_name: '',
  last_name: '',
  profile_picture_url: '',
  theme: 'azure',
  appearance: 'light',
  subscription: 'free',
  language: 'en',
  account_status: 'active',
  onboarding_complete: false,
  country: '',
  two_factor_auth_enabled: false,
  onboarding_steps: {
    welcome: { id: 'welcome', order: 1, isCompleted: false },
    bio: { id: 'bio', order: 2, isCompleted: false },
    preferences: { id: 'preferences', order: 3, isCompleted: false },
  },
};

export const CLEAR_VERIFICATION_STATES = () => {
  localStorage.removeItem('verificationAttempts');
  localStorage.removeItem('verificationResetTime');
  localStorage.removeItem('verificationCooldown');
};

async function signupUser({ email, password }) {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: `${window.location.origin}/verify-success`,
      data: {
        ...DEFAULT_PROFILE,
      },
    },
  });

  if (error) {
    throw error;
  }

  return { data };
}

export function useSignup() {
  const { navigateToPath } = useNavigation();
  return useMutation(signupUser, {
    onSuccess: (data, variables) => {
      navigateToPath('/verify-email', {
        replace: true,
        state: {
          email: variables.email,
          from: '/signup',
          resendType: 'signup',
        },
      });
    },
    onError: (error) => {
      const errorMessage = getCrudErrorMessage('auth', error, 'signup');
      toast.error(errorMessage?.description);
    },
  });
}
