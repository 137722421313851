import styled from 'styled-components';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { StyledAuthContainer } from '../../ui/forms/StyledAuthContainer';
import { TextFade } from '../../ui/typography/Text';
import { ButtonText } from '../../ui/buttons/ButtonText';
import Button from '../../ui/buttons/Button';
import Heading from '../../ui/typography/Heading';
import { useNavigation } from '../../hooks/useNavigation';
import { useAuth } from '../../redux/features/auth-slice';
import { useLogout } from '../../api/authentication/logout';
import { Navigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { usePageTitle } from '../../hooks/usePageTitle';

const VerifySuccessDetails = () => {
  usePageTitle('Email Verified');
  const { navigateToPath } = useNavigation();
  const { session } = useAuth();
  const { mutate: logOut } = useLogout();
  const location = useLocation();
  const { email, from } = location?.state ?? {};

  const handleContinue = useCallback(() => {
    if (session) {
      navigateToPath('/onboarding', { replace: true });
    } else {
      logOut();
      navigateToPath('/login', { replace: true });
    }
  }, [session]);

  if (!email && !from) {
    return <Navigate to='/login' replace={true} />;
  }

  return (
    <StyledAuthContainer>
      <IconWrapper>
        <CheckCircleIcon />
      </IconWrapper>

      <Title as={'h3'}>Email Verified!</Title>

      <MessageContainer>
        <TextFade align='center' color='var(--green-11)'>
          Your email has been successfully verified.
        </TextFade>
      </MessageContainer>

      <ActionContainer>
        <Button
          onClick={handleContinue}
          style={{ width: '100%' }}
          variant='primary'
        >
          {session ? 'Continue to Onboarding' : 'Log In'}
          <ArrowRightIcon />
        </Button>
      </ActionContainer>
    </StyledAuthContainer>
  );
};

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background: var(--green-3);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 0 auto;

  svg {
    width: 24px;
    height: 24px;
    color: var(--green-11);
  }
`;

const Title = styled(Heading)`
  color: var(--green-12);
  text-align: center;
`;

const MessageContainer = styled.div`
  background: var(--green-2);
  color: var(--green-11);
  padding: var(--padding-md);
  border-radius: var(--border-radius-md);
`;

const ActionContainer = styled.div`
  ${ButtonText} {
    background: var(--blue-9);
    color: white;
    padding: var(--padding-ver) var(--padding-hor);
    border-radius: var(--border-radius-md);
    border: none;
    width: 100%;
    font-weight: 500;

    &:hover {
      background: var(--blue-10);
    }
  }
`;

export default VerifySuccessDetails;
