import styled, { keyframes } from 'styled-components';
import Heading from '../ui/typography/Heading';
import { media } from './ResponsiveDesign';

export const StyledLoading = styled.div`
  width: 100%;
  flex: 1;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const maxWidthValues = {
  small: '640px',
  medium: '720px',
  large: '1440px',
};

export const StyledSectionContainer = styled.section.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'maxWidth',
      'margin',
      'padding',
      'backgroundColor',
      'borderRadius',
      'boxShadow',
      'start',
      'end',
    ].includes(prop),
})`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => maxWidthValues[props.size] || maxWidthValues.large};
  margin: ${(props) => props.margin || '0 auto'};
  padding: ${(props) => props.padding || 0};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  border-radius: ${(props) => props.borderRadius || '0'};
  box-shadow: ${(props) => props.boxShadow || 'none'};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-column: ${(props) => `${props.start} / ${props.end || 'auto'}`};

  ${media.mobile`
    grid-column:1/-1;
    width:100%;
    max-width:100%;
  `}
`;

export const StyledContainer = ({ children, size, ...props }) => (
  <StyledSectionContainer size={size} {...props}>
    {children}
  </StyledSectionContainer>
);

export const StyledHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => !['border'].includes(prop),
})`
  padding: var(--padding);
  border: none;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  ${media.mobile`
    border-bottom: var(--border-sm-non);
  `}
`;

export const SectionHeader = styled.div`
  padding: var(--padding);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  width: 100%;
`;

export const StyledBody = styled.div`
  padding: var(--padding);
  grid-area: body;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const StyledBodyScroll = styled.div`
  padding: var(--padding);
  position: relative;
  grid-area: body;
  overflow: auto;
  width: 100%;
  flex: 1;

  /* Modern scrollbar styling */
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--gray-3);
    border-radius: var(--border-radius-rd);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-7);
    border-radius: var(--border-radius-rd);
    border: 2px solid var(--gray-3);

    &:hover {
      background: var(--gray-8);
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--gray-7) var(--gray-3);
`;

export const StyledFooter = styled.div`
  padding: var(--padding);

  border: none;
  width: 100%;
  margin-top: auto;

  ${media.mobile`
    border-top: var(--border-sm-non);
  `}
`;

export const SectionFooter = styled.div`
  padding: var(--padding);
  padding-top: 0;
  width: 100%;
  margin-top: auto;
`;

export const SectionHeading = styled(Heading).attrs({ as: 'h4' })`
  color: var(--color-9);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 3.6rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  svg {
    width: 2rem;
    height: 2rem;
    color: var(--gray-11);

    ${media.mobile`
      width: 2rem;
      height: 2rem;
    `}
  }
`;

export const SelectionsWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 0.8rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0.2rem;
  transition: all 0.3s ease;
`;
export const Required = styled.span`
  font-size: 1.4rem;
  color: var(--color-red);
`;

export const InputError = styled.span`
  text-align: center;
  color: var(--red-9);
`;

export const InputWarning = styled.span`
  text-align: center;
  color: var(--color-orange);
`;

export const slideAnimation = keyframes`
  0%{
      transform:  translateY(1rem);
    }
    
    100%{
      transform:  translate(0);
    }
`;

export const StyledTags = styled.span`
  color: var(--label-pri);
  background-color: var(--fill-pri);

  padding: 0 0.8rem;

  border-radius: var(--border-radius-rd);
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  padding: inherit;

  & li {
    list-style-type: disc;
  }
`;

const StyledCount = styled.span`
  background-color: var(--color-9);
  color: var(--label-white);
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
`;
