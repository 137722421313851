import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  bookmarks: [],
  isLoading: false,
  error: null,
};

const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    clearBookmarks: (state) => initialState,

    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },

    addBookmark: (state, action) => {
      state.bookmarks.push(action.payload);
    },
    removeBookmark: (state, action) => {
      state.bookmarks = state.bookmarks.filter(
        (bookmark) => bookmark.blog_id !== action.payload
      );
    },
    setBookmarksLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setBookmarkError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  clearBookmarks,
  setBookmarks,
  addBookmark,
  removeBookmark,
  setBookmarkError,
  setBookmarksLoading,
} = bookmarkSlice.actions;

export const useBookmarkState = () => useSelector((state) => state.bookmark);

export default bookmarkSlice.reducer;
