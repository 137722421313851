import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getCurrentTimestamp } from '../../utils/helpers';

const initialState = {
  user: null,
  isLoading: false,
  error: null,
  isAuthenticated: false,
  session: null,
  lastVisitedPage: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.session = action.payload.session;
    },
    loginFailure: (state, action) => {
      state.user = null;
      state.isLoading = false;
      state.error = {
        message: action.payload?.message || 'An unknown error occurred',
        code: action.payload?.code,
        timestamp: getCurrentTimestamp(),
      };
      state.isAuthenticated = false;
      state.session = null;
    },

    logout(state) {
      Object.assign(state, initialState);
      localStorage.removeItem('supabase.auth.token');
      sessionStorage.removeItem('supabase.auth.token');
    },

    updateAuth(state, action) {
      state.user = action.payload.user;
      state.session = action.payload.session;
      state.isAuthenticated = true;
    },

    updateSession(state, action) {
      state.session = action.payload.session;
    },

    updateUser(state, action) {
      state.user = action.payload.user;
    },

    setLastVisitedPage: (state, action) => {
      state.lastVisitedPage = action.payload;
    },
  },
});

export const {
  logout,
  loginRequest,
  loginFailure,
  loginSuccess,
  updateAuth,
  setLastVisitedPage,
  updateSession,
  updateUser,
} = authSlice.actions;

export const selectAuth = (state) => state.auth;

export const useAuth = () => useSelector((state) => state.auth);

export default authSlice.reducer;
