import { useMutation, useQuery, useQueryClient } from 'react-query';
import supabase from '../services/supabase';
import { nanoid } from 'nanoid';
import { filter, isEmpty } from 'lodash';
import { useNavigation } from '../hooks/useNavigation';

/**
 * Performs a Supabase operation based on the provided options.
 *
 * @param {Object} options - The options for the Supabase operation.
 * @param {string} options.table - The name of the table to operate on.
 * @param {string} options.operation - The type of operation to perform (e.g., 'select', 'insert', 'update', 'delete').
 * @param {Object} [options.payload] - The data payload for insert or update operations.
 * @param {Array} [options.filters=[]] - An array of filter objects to apply to the query.
 * @param {Array} [options.sort=[]] - An array of sort objects to apply to the query.
 * @param {number} [options.limit] - The maximum number of rows to return.
 * @param {number} [options.offset] - The number of rows to skip before starting to return rows.
 * @param {boolean} [options.count=false] - Whether to return the total count of rows.
 * @param {string|Array} [options.fields='*'] - The fields to select. Can be a string or an array of field names.
 * @returns {Promise<Object>} The result of the Supabase operation.
 */
export async function supabaseOperation(options) {
  const {
    single = false,
    table,
    operation,
    payload,
    filters = [],
    sort = [],
    limit,
    offset,
    count = false,
    fields = '*', // Default to all fields
    returnData = 'data',
    relations = {},
    search,
  } = options;

  // Function to process fields and relations
  const processFieldsAndRelations = (fields, relations) => {
    // If fields is a string and there are no relations, return as is
    if (typeof fields === 'string' && Object.keys(relations).length === 0) {
      return fields;
    }

    // If fields is a string, start with that
    let select = typeof fields === 'string' ? fields : fields.join(', ');

    // Process relations recursively
    Object.entries(relations).forEach(([relationName, relationOptions]) => {
      // Process the fields for this relation
      const relationFields = Array.isArray(relationOptions.fields)
        ? relationOptions.fields.join(', ')
        : relationOptions.fields || '*';

      // Add support for inner join
      const joinType = relationOptions.inner ? '!inner' : '';

      // Check if this relation has nested relations
      if (
        relationOptions.relations &&
        Object.keys(relationOptions.relations).length > 0
      ) {
        const nestedSelect = processFieldsAndRelations(
          relationOptions.fields,
          relationOptions.relations
        );
        select += `, ${relationName}${joinType}(${nestedSelect})`;
      } else {
        // No nested relations, just add the fields
        select += `, ${relationName}${joinType}(${relationFields})`;
      }
    });

    return select;
  };

  const select = processFieldsAndRelations(fields, relations);

  const processAllFilters = (mainFilters = [], relations = {}) => {
    // Start with main table filters
    let allFilters = [...mainFilters];

    // Process relation filters
    Object.entries(relations).forEach(([tableName, relationConfig]) => {
      if (relationConfig.filters) {
        // Add table prefix to each filter's column
        const relationFilters = relationConfig.filters.map((filter) => ({
          ...filter,
          column: `${tableName}.${filter.column}`,
        }));

        // Add to allFilters array
        allFilters = [...allFilters, ...relationFilters];
      }
    });

    return allFilters;
  };

  const processAllSorts = (mainSort = [], relations = {}) => {
    // Start with main table sorts
    let allSorts = [...mainSort];

    // Process relation sorts
    Object.entries(relations).forEach(([tableName, relationConfig]) => {
      if (relationConfig.sort) {
        // Add table prefix to each sort's column
        const relationSorts = relationConfig.sort.map((sort) => ({
          ...sort,
          column: `${tableName}.${sort.column}`,
        }));

        // Add to allSorts array
        allSorts = [...allSorts, ...relationSorts];
      }
    });

    return allSorts;
  };

  const allFilters = processAllFilters(filters, relations);
  const allSorts = processAllSorts(sort, relations);

  const applyFilters = (query, allFilters) => {
    if (!allFilters || isEmpty(allFilters)) return query;

    allFilters.forEach((filter) => {
      const { operator, column, value } = filter;

      // Handle different search operators
      switch (operator) {
        case 'not':
          if (value === null) {
            query = query.not(column, 'is', null);
          } else {
            query = query.not(column, 'eq', value);
          }
          break;
        case 'ilike':
          query = query.ilike(column, `%${value}%`);
          break;
        case 'ilike_starts':
          query = query.ilike(column, `${value}%`);
          break;
        case 'ilike_ends':
          query = query.ilike(column, `%${value}`);
          break;
        case 'textSearch':
          query = query.textSearch(column, value);
          break;
        case 'or':
          if (Array.isArray(value)) {
            query = query.or(
              value.map((val) => `${column}.ilike.%${val}%`).join(',')
            );
          }
          break;
        default:
          query = query[operator](column, value);
      }
    });
    return query;
  };

  function applySort(query) {
    // sort:[{ column: 'created_at', order: 'desc' }];
    if (!sort || isEmpty(sort)) return query;

    sort.forEach((sort) => {
      const { column, order } = sort;

      query = query.order(column, {
        ascending: order === 'asc',
      });
    });

    return query;
  }

  const applyPagination = (query) => {
    if (limit !== undefined) {
      query = query.limit(limit);
    }
    if (offset !== undefined) {
      query = query.range(offset, offset + (limit || 0) - 1);
    }
    return query;
  };

  let query = supabase.from(table);

  // Apply search if provided
  if (search) {
    const { columns, query: searchQuery, type = 'ilike' } = search;

    if (columns && searchQuery) {
      // If multiple columns are provided, create OR conditions
      if (Array.isArray(columns) && !isEmpty(columns)) {
        const searchConditions = columns.map((column) => ({
          operator: type,
          column,
          value: type === 'textSearch' ? searchQuery : `%${searchQuery}%`,
        }));

        // Add these conditions to filters
        filters.push(...searchConditions);
      } else if (typeof columns === 'string') {
        // Single column search
        filters.push({
          operator: type,
          column: columns,
          value: type === 'textSearch' ? searchQuery : `%${searchQuery}%`,
        });
      }
    }
  }

  switch (operation) {
    case 'read':
    case 'filter':
      query = query.select(select, { count: count ? 'exact' : undefined });
      if (single) {
        query = query.single();
      }
      query = applyFilters(query, allFilters);
      query = applySort(query);
      query = applyPagination(query);

      break;

    case 'create':
      query = query.insert(payload);
      if (single) {
        query = query.select().single();
      }
      break;

    case 'update':
      if (!payload || Object.keys(payload).length === 0) {
        throw new Error('Update operation requires a payload');
      }
      query = query.update(payload);
      query = applyFilters(query, allFilters);
      if (single) {
        query = query.select().single();
      }
      break;

    case 'delete':
      query = query.delete();
      query = applyFilters(query, allFilters);
      break;

    case 'upsert':
      //upsert is used to insert a new row if it doesn't exist, or update it if it does.
      //TODO primary key is required in the payload
      query = query.upsert(payload);
      break;

    case 'insert': {
      const formattedPayload = Array.isArray(payload) ? payload : [payload];
      query = query.insert(formattedPayload);
      query = applyFilters(query, allFilters);
      query = query.select(fields);
      break;
    }

    default:
      throw new Error('Invalid operation type.');
  }

  const { data, error, count: totalCount } = await query;

  // Handle the "no rows returned" error specifically
  if (error?.code === 'PGRST116') {
    if (single) {
      return null;
    }
    return [];
  }

  // Handle other errors
  if (error) {
    throw new Error(`Error ${operation}ing ${table}: ${error.message}`);
  }

  if (single) {
    return data;
  }

  if (returnData === 'data') {
    return data;
  } else if (returnData === 'count') {
    return totalCount;
  } else {
    return { data, totalCount };
  }
}

export const useSupabaseOperation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: supabaseOperation,

    onMutate: (variables) => {
      variables?.onMutate && variables.onMutate(variables);
    },

    onSuccess: (data, variables) => {
      variables?.onSuccess && variables.onSuccess(data, variables);
    },

    onError: (error, variables) => {
      variables?.onError && variables.onError(error, variables);
    },
  });
};

export const useSupabaseQuery = (options, config = {}) => {
  return useQuery(
    config.queryKey || [options.table],
    () => supabaseOperation({ ...options, operation: 'read' }),
    {
      refetchOnWindowFocus: false,
      enabled: config?.enabled ?? true,
      ...config,
    }
  );
};

// Helper function to get file extension
const getFileExtension = (filename) => {
  return filename.split('.').pop().toLowerCase();
};

const getMediaType = (fileExtension) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];
  const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];
  const audioExtensions = ['mp3', 'wav', 'ogg', 'aac'];

  if (imageExtensions.includes(fileExtension)) return 'image';
  if (videoExtensions.includes(fileExtension)) return 'video';
  if (audioExtensions.includes(fileExtension)) return 'audio';
  return 'file'; // Default type for unknown extensions
};

const supabaseMediaOperation = async ({
  file,
  bucket,
  path = '',
  contentType,
  cacheControl = '3600',
  operation = 'upload',
}) => {
  const fileExtension = getFileExtension(file?.name);
  const mediaType = getMediaType(fileExtension);
  const fileName = `${mediaType}${nanoid()}.${fileExtension}`;
  const filePath = `${path}/${fileName}`;

  let query = supabase.storage.from(bucket);

  const options = {
    contentType: contentType || file.type,
    cacheControl,
    upsert: operation === 'update',
  };

  if (operation === 'upload') {
    query = query.upload(filePath, file, options);
  } else if (operation === 'update') {
    query = query.update(path, file, options);
  } else {
    throw new Error(`Unsupported operation: ${operation}`);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(`Error in ${operation} operation: ${error.message}`);
  }

  return {
    ...data,
    path: operation === 'upload' ? filePath : path,
    operation,
  };
};

export const useSupabaseMediaUpload = () => {
  return useMutation({
    mutationFn: supabaseMediaOperation,
    onSuccess: (data, variables) => {},
    onError: (error) => {
      throw new Error(error.message);
    },
  });
};
