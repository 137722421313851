import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  SectionHeading,
  SelectionsWrapper,
  StyledBody,
  StyledBodyScroll,
  StyledContainer,
  StyledFooter,
  StyledHeader,
} from '../../styles/GeneralStyling';

import {
  InputError,
  Input,
  InputLabel,
  StyledInputContainer,
} from '../../ui/forms/StyledInput';
import {
  StyledTextareaContainer,
  Textarea,
  TextareaLabel,
} from '../../ui/forms/Textarea';
import { FlexColumn, FlexRow, RequiredIndicator } from '../../ui/layout/Flex';
import useSearch from '../../hooks/useSearch';
import useTags from '../../hooks/useTags';
import slugify from 'react-slugify';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import Button from '../../ui/buttons/Button';
import { blogTags } from './constants';
import { PreviewImage } from '../../hooks/useFileUpload';
import { HiOutlineX } from 'react-icons/hi';
import { isURL } from 'validator';
import {
  resetBlogDetails,
  setBlogDetails,
  setBlogSettings,
  updateBlogDetails,
  useBlogState,
} from '../../redux/features/blog-slice';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import useIsMounted from '../../hooks/useIsMounted';
import { useNavigation } from '../../hooks/useNavigation';
import { CloseButton } from '../../ui/buttons/CloseButton';
import { usePageTitle } from '../../hooks/usePageTitle';
import toast from 'react-hot-toast';
import StateDisplay from '../../ui/notifications/StateDisplay';
import { convertToRawContent, createEmptyEditorState } from './helpers';
import { useAuth } from '../../redux/features/auth-slice';
import { useLocation } from 'react-router-dom';
import supabase from '../../services/supabase';
import { useMutation, useQuery } from 'react-query';
import { nanoid } from 'nanoid';
import { createBlog, updateBlog } from './ApiBlog';

async function fetchBlogAndSettings(blogId) {
  const { data, error } = await supabase
    .from('blog_posts')
    .select('*, blog_settings(*)')
    .eq('blog_id', blogId)
    .single();
  if (error) {
    throw error;
  }
  return data;
}

const CreateBlog = ({ mode = 'create', closeAside, blogToEdit }) => {
  const location = useLocation();
  const isEditing = mode === 'edit';
  const isCreating = mode === 'create';
  const { navigateToPath } = useNavigation();
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [isCoverImageValid, setIsCoverImageValid] = useState(false);
  const { blogDetails, blogSettings } = useBlogState();
  const isBlogOwner = user?.id === blogToEdit?.user_id;
  const initialBlogDetails = useRef(blogDetails);
  usePageTitle('Create New Blog');
  const userCanEdit = (isEditing && blogSettings?.allow_edit) || isCreating;

  const {
    mutate: createBlogMutation,
    isLoading: isCreatingBlogAndRelatedData,
  } = useMutation({
    mutationFn: createBlog,
    onSuccess: (data) => {
      navigateToPath(`/workspace/${blogDetails?.slug}?id=${data?.blog_id}`);
    },
    onError: (error) => {
      const errorMessage = getCrudErrorMessage('insert', error, 'Blog content');
      toast.error(errorMessage.title);
    },
  });

  const { mutate: updateBlogMutation, isLoading: isUpdatingBlog } = useMutation(
    {
      mutationFn: updateBlog,
      onSuccess: () => {
        toast.success('Blog edited successfully');
        const currentUrl = location.pathname + location.search;
        const newUrl = `/workspace/${blogDetails?.slug}?id=${blogDetails?.blog_id}`;
        if (currentUrl !== newUrl) {
          window.history.replaceState(null, '', newUrl);
        }
        closeAside?.();
      },
      onError: (error) => {
        const errorMessage = getCrudErrorMessage('update', error, 'Blog');
        toast.error(errorMessage.title);
      },
    }
  );

  const { isLoading: isBlogLoading, data: blogAndSettingsData } = useQuery({
    queryKey: ['blog_posts', { blog_id: blogToEdit?.blog_id }],
    queryFn: () => fetchBlogAndSettings(blogToEdit?.blog_id),
    enabled: isEditing && isBlogOwner && blogToEdit && isMounted(),
    onSuccess: (blog) => {
      const { blog_settings, ...blogDetails } = blog;
      initialBlogDetails.current = blogDetails; //For comparison when resetting blog details
      dispatch(setBlogDetails(blogDetails));
      dispatch(setBlogSettings(blog_settings));
      reset({
        title: blog.title,
        summary: blog.summary,
        cover_img_url: blog.cover_img_url,
      });
    },
    onError: (error) => {
      const errorMessage = getCrudErrorMessage('read', error, 'Blog');
      toast.error(errorMessage.title);
    },
  });

  useEffect(() => {
    //Reset blog details when creating a new blog
    if (isCreating) {
      dispatch(resetBlogDetails());
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, defaultValues },
    setValue,
    trigger,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      title: blogDetails?.title,
      summary: blogDetails?.summary,
      cover_img_url: blogDetails?.cover_img_url,
    },
    mode: 'onBlur',
  });

  const hasBlogDetailsChanged = useMemo(() => {
    return !isEqual(initialBlogDetails.current, blogDetails);
  }, [blogDetails]);

  function handleImageLoad() {
    if (isMounted()) {
      setIsCoverImageValid(true);
      clearErrors('cover_img_url');
    }
  }
  function handleImageError() {
    if (isMounted()) {
      setIsCoverImageValid(false);
      trigger('cover_img_url');
    }
  }

  useEffect(() => {
    //Revalidate the image url in edit mode
    if (blogDetails?.cover_img_url && isEditing) {
      const img = new Image();
      img.src = blogDetails.cover_img_url;
      img.onload = handleImageLoad;
      img.onerror = handleImageError;
    }
  }, [blogDetails?.cover_img_url, isEditing]);

  const { markup: searchMarkup, searchQuery } = useSearch({
    placeholder: 'Search for category...',
  });

  const blogTagsConfig = useMemo(
    () => ({
      options: blogTags,
      showCount: false,
      placeholder: 'Select Blog Tags',
      defaultValues: blogDetails?.category,
      searchQuery,
      returnType: 'value',
      isDisabled: isCreatingBlogAndRelatedData || !userCanEdit,
      disabled:
        blogDetails?.category?.length >= 3
          ? blogTags
              .filter((tag) => !blogDetails?.category?.includes(tag.value))
              ?.map((tag) => tag.value)
          : [],
      onChange: (value) => {
        dispatch(updateBlogDetails({ category: value }));
      },
    }),
    [
      blogDetails?.category,
      searchQuery,
      isCreatingBlogAndRelatedData,
      userCanEdit,
    ]
  );

  const { markup: blogTagsMarkup, selected: selectedCategories } =
    useTags(blogTagsConfig);

  const validateCategories = useCallback(() => {
    let categoryError = null;
    if (selectedCategories.length === 0) {
      categoryError = 'Please select at least one category';
    } else if (selectedCategories.length > 3) {
      categoryError = 'Please select no more than three categories';
    }
    return categoryError;
  }, [selectedCategories]);

  ////////////////////////////////

  const onSubmit = (formData) => {
    if (!user) {
      toast.error('Please login to create a blog');
      return;
    }

    if (isEditing) {
      if (!blogSettings?.allow_edit) {
        toast.error('This blog cannot be edited at this time');
        return;
      }
    }

    const categoryError = validateCategories();
    if (categoryError) {
      toast.error(categoryError);
      return;
    }

    if (!hasBlogDetailsChanged) {
      toast.info('No changes detected to save');
      return;
    }

    //2. Create a blog post in the database

    if (isCreating) {
      const payload = {
        blog_id: nanoid(8),
        post: {
          title: formData.title,
          slug: slugify(formData.title),
          summary: formData.summary,
          category: selectedCategories,
          tags: [],
          cover_img_url: formData.cover_img_url,
          status: 'draft',
          type: 'single',
          metadata: {
            author_id: user?.id,
            author_first_name: user?.first_name,
            author_last_name: user?.last_name,
          },
        },
        content: {
          content: convertToRawContent(createEmptyEditorState()),
        },
      };

      createBlogMutation(payload);
    }

    if (isEditing) {
      const payload = {
        blog_id: blogDetails?.blog_id,
        post: {
          title: formData.title,
          slug: slugify(formData.title),
          summary: formData.summary,
          category: selectedCategories,
          tags: [],
          cover_img_url: formData.cover_img_url,
        },
      };

      updateBlogMutation(payload);
    }
  };

  const handleChange = useCallback(
    (name, value) => {
      if (name === 'title') {
        const newSlug = value ? slugify(value) : '';

        dispatch(
          updateBlogDetails({
            [name]: value,
            slug: newSlug,
          })
        );
      } else {
        dispatch(updateBlogDetails({ [name]: value }));
      }
    },
    [dispatch]
  );

  const handleBlogDetailsReset = useCallback(() => {
    if (!hasBlogDetailsChanged) {
      toast('No changes detected to reset');
      return;
    }

    if (isEditing) {
      dispatch(setBlogDetails(initialBlogDetails.current));
      reset({
        title: initialBlogDetails.current.title,
        summary: initialBlogDetails.current.summary,
        cover_img_url: initialBlogDetails.current.cover_img_url,
      });
    } else {
      dispatch(resetBlogDetails());
      reset({
        title: '',
        summary: '',
        cover_img_url: '',
      });
    }
    toast.success('Blog details reset successfully');
  }, [hasBlogDetailsChanged, isEditing, dispatch, reset]);

  // Add confirmation before leaving with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasBlogDetailsChanged) {
        e.preventDefault();
        e.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasBlogDetailsChanged]);

  // Add confirmation for closing aside with unsaved changes
  const handleClose = useCallback(() => {
    if (hasBlogDetailsChanged) {
      const confirm = window.confirm(
        'You have unsaved changes. Are you sure you want to close?'
      );
      if (!confirm) return;
    }

    if (isEditing) closeAside();
  }, [hasBlogDetailsChanged, closeAside]);

  // Prevent user from navigating back
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const shouldDisableForm = useMemo(() => {
    if (isBlogLoading)
      return {
        disabled: true,
        message: 'Loading blog details...',
      };
    if (!userCanEdit)
      return {
        disabled: true,
        message: 'You do not have permission to edit this blog',
      };
    if (isCreatingBlogAndRelatedData)
      return {
        disabled: true,
        message: 'Creating blog and related data...',
      };
    if (isUpdatingBlog)
      return {
        disabled: true,
        message: 'Updating blog...',
      };
    if (isEditing && !blogAndSettingsData)
      return {
        disabled: true,
        message: 'Blog details not found',
      };

    return {
      disabled: false,
      message: 'User can edit blog',
    };
  }, [
    userCanEdit,
    isBlogLoading,
    isCreatingBlogAndRelatedData,
    isUpdatingBlog,
    blogAndSettingsData,
    isEditing,
  ]);

  if (!userCanEdit) {
    return (
      <StyledBody>
        <StateDisplay
          variant='error'
          title='Blog Editing Access Required'
          description='This blog is currently in read-only mode. To make changes or publish, you need to enable editing mode first.'
          action={{
            onClick: () => closeAside?.(),
            label: 'Close',
            variant: 'secondary',
            icon: <HiOutlineX />,
          }}
        />
      </StyledBody>
    );
  }

  return (
    <StyledContainer
      size='medium'
      as={'form'}
      padding='0'
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledHeader>
        <FlexRow justify='between'>
          <SectionHeading>
            {isCreating && 'Create New Blog'}
            {isEditing && 'Edit Blog'}
          </SectionHeading>
          {isEditing && (
            <CloseButton
              disabled={
                isUpdatingBlog ||
                isCreatingBlogAndRelatedData ||
                hasBlogDetailsChanged
              }
              onClick={handleClose}
            />
          )}
        </FlexRow>
      </StyledHeader>

      <StyledBodyScroll>
        <FlexColumn gap={2.4}>
          <Controller
            name='title'
            control={control}
            rules={{
              required: 'Title is required',
              minLength: {
                value: 3,
                message: 'Title must be at least 3 characters',
              },
              maxLength: {
                value: 60,
                message: 'Title must be less than 60 characters',
              },
              validate: {
                noSpecialChars: (value) =>
                  /^[a-zA-Z0-9\s-.,!?'"()]+$/.test(value) ||
                  'Title contains invalid characters',
              },
            }}
            render={({ field }) => (
              <StyledInputContainer>
                <Input
                  {...field}
                  id='title'
                  placeholder='Title'
                  onChange={(e) => {
                    field.onChange(e);
                    handleChange('title', e.target.value);
                    clearErrors('title');
                  }}
                  maxLength={60}
                  error={errors?.title}
                  success={field?.value && !errors?.title}
                  disabled={shouldDisableForm?.disabled}
                />
                <InputLabel>
                  Title <RequiredIndicator show={true} />
                </InputLabel>
                {errors?.title && (
                  <InputError>{errors?.title?.message}</InputError>
                )}
              </StyledInputContainer>
            )}
          />

          <Controller
            name='summary'
            control={control}
            rules={{
              required: 'Blog summary is required',
              minLength: {
                value: 10,
                message: 'Summary must be at least 10 characters',
              },
              maxLength: {
                value: 250,
                message: 'Summary must be less than 250 characters',
              },
              validate: {
                noHtml: (value) =>
                  !/<[^>]*>/.test(value) ||
                  'HTML tags are not allowed in summary',
              },
            }}
            render={({ field }) => (
              <StyledTextareaContainer>
                <Textarea
                  {...field}
                  id='summary'
                  placeholder='Enter a short Summary of your blog'
                  onChange={(e) => {
                    field.onChange(e);
                    handleChange('summary', e.target.value);
                    clearErrors('summary');
                  }}
                  rows={4}
                  error={errors?.summary}
                  success={field?.value && !errors?.summary}
                  disabled={shouldDisableForm?.disabled}
                />
                <TextareaLabel>
                  Summary <RequiredIndicator show={true} />
                </TextareaLabel>
                {errors?.summary && (
                  <InputError>{errors?.summary?.message}</InputError>
                )}
              </StyledTextareaContainer>
            )}
          />

          {searchMarkup}

          <SelectionsWrapper>{blogTagsMarkup}</SelectionsWrapper>

          <Controller
            name='cover_img_url'
            control={control}
            rules={{
              required: 'Cover Image URL is required',
              validate: {
                isValidUrl: (value) =>
                  isURL(value) || 'Cover image URL must be a valid URL',
                isValidImage: (value) =>
                  isCoverImageValid || 'The provided URL is not a valid image',
              },
            }}
            render={({ field }) => (
              <FlexColumn>
                <StyledInputContainer>
                  <Input
                    {...field}
                    id='cover_img_url'
                    placeholder='Cover Image URL'
                    onChange={(e) => {
                      const newValue = e.target.value;
                      field.onChange(newValue);
                      handleChange('cover_img_url', newValue);
                      setIsCoverImageValid(false);
                      clearErrors('cover_img_url');
                      setValue('cover_img_url', newValue);
                      // Validate URL immediately
                      trigger('cover_img_url');
                    }}
                    error={errors.cover_img_url}
                    success={field.value && !errors.cover_img_url}
                    disabled={shouldDisableForm?.disabled}
                  />
                  <InputLabel>
                    Cover Image URL <RequiredIndicator show={true} />
                  </InputLabel>
                  {errors.cover_img_url && (
                    <InputError>{errors.cover_img_url.message}</InputError>
                  )}
                </StyledInputContainer>
                {field.value && (
                  <PreviewImage
                    alt='blog-cover-image'
                    src={field.value}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                )}
              </FlexColumn>
            )}
          />
        </FlexColumn>
      </StyledBodyScroll>

      <StyledFooter>
        <FlexRow>
          {isEditing && (
            <Button
              onClick={handleBlogDetailsReset}
              size='small'
              variant='danger'
              type='button'
              disabled={shouldDisableForm?.disabled}
            >
              Reset
            </Button>
          )}

          <ButtonGroup style={{ marginLeft: 'auto' }} justify='end'>
            {isEditing && (
              <Button
                type='button'
                onClick={handleClose}
                size='small'
                variant='secondary'
                disabled={shouldDisableForm?.disabled}
              >
                Cancel
              </Button>
            )}

            <Button
              type='submit'
              disabled={shouldDisableForm?.disabled || !isEmpty(errors)}
              isLoading={isCreatingBlogAndRelatedData || isUpdatingBlog}
              size={isEditing ? 'small' : 'medium'}
            >
              Save Blog
            </Button>
          </ButtonGroup>
        </FlexRow>
      </StyledFooter>
    </StyledContainer>
  );
};

export default CreateBlog;
