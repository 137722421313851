import { useMutation } from 'react-query';
import supabase from '../../services/supabase';
import { CLEAR_VERIFICATION_STATES, DEFAULT_PROFILE } from './signup';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import { getCurrentTimestamp } from '../../utils/helpers';

async function loginUser({ email, password }) {
  // 1. Sign in user
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  const { user, session } = data;

  return { user, session };
}

export function useLogin() {
  return useMutation(loginUser);
}
