import { createPortal } from 'react-dom';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationTriangle,
  HiOutlineInformationCircle,
  HiOutlineShieldExclamation,
  HiXMark,
} from 'react-icons/hi2';
import styled, { ThemeProvider, css, keyframes } from 'styled-components';
import { media } from '../../styles/ResponsiveDesign';

const slideIn = keyframes`
    from{
        top:-100%;
        left:50%;
        transform: translate(-50%, 0) ;
    }

    to{
        top:6.8rem;
        left:50%;
        transform: translate(-50%, 0) ;       
    }
`;

const slideOut = keyframes`
    from{
        top:6.8rem;
        left:50%;
        transform: translate(-50%, 0) ;       
    }

    to{
        top:-100%;
        left:50%;
        transform: translate(-50%, 0) ;
    }
`;

export const renderColorType = {
  success: 'var(--green-11)',

  info: 'var(--color-11)',

  warning: 'var(--orange-11)',

  danger: 'var(--red-11)',

  general: 'var(--gray-11)',
};

export const renderBackgroundType = {
  success: 'var(--green-3)',

  info: 'var(--color-3)',

  warning: 'var(--orange-3)',

  danger: 'var(--red-3)',

  general: 'var(--gray-3)',
};

const StyledToastContainer = styled.div`
  max-width: 44rem;
  width: max-content;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-md);
  padding: 1.6rem 1.2rem;
  overflow: hidden;
  font-size: 1.2rem;

  position: fixed;
  top: 6.8rem;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.8rem;

  z-index: 100000;

  background-color: ${(props) => renderBackgroundType[props.theme.type]};
  backdrop-filter: saturate(180%) blur(1.6rem);
  -webkit-backdrop-filter: saturate(180%) blur(1.6rem);
  backdrop-filter: blur(10rem);

  ${media.mobile`
    max-width: 100%;
  `}

  ${(props) =>
    props.$unmount
      ? css`
          -webkit-animation: ${slideOut} 1000ms ease forwards;
          animation: ${slideOut} 1000ms ease forwards;
        `
      : css`
          -webkit-animation: ${slideIn} 300ms ease forwards;
          animation: ${slideIn} 300ms ease forwards;
        `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0.4rem;
    background-color: ${(props) => renderColorType[props.theme.type]};
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
  }

  & svg {
    height: 2.4rem;
    width: 2.4rem;
    max-width: 2.4rem;
    transition: all 200ms ease;
    color: ${(props) => renderColorType[props.theme.type]};
  }

  & p {
    color: var(--gray-12);
  }
`;

const CloseButton = styled.button`
  grid-column: 3/-1;
  grid-row: 1/-1;

  height: 1.6rem !important;
  width: 1.6rem !important;

  background: none;
  background-color: transparent;
  border: none;
  border-radius: var(--border-radius-rd);
  outline: var(--border-md-non);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 500;
  transition: all 200ms ease;

  &:hover {
    background-color: var(--gray-3);
    & svg {
      color: var(--gray-12) !important;
    }
  }

  & svg {
    color: var(--gray-12) !important;
    height: 1.2rem !important;
    width: 1.2rem !important;
    transition: all 200ms ease;
  }

  &:focus {
    outline: none;
  }
`;

//Success=green, Information=blue, danger=red warning=amber

const StyledToast = (props) => {
  const { message, type, handleClose, close, unmount } = props;

  const toastType = { type, unmount };

  return (
    <ThemeProvider theme={toastType}>
      {createPortal(
        <StyledToastContainer $unmount={unmount}>
          {(type === 'success' && <HiOutlineCheckCircle />) ||
            (type === 'danger' && <HiOutlineExclamationTriangle />) ||
            (type === 'warning' && <HiOutlineShieldExclamation />) ||
            (type === 'info' && <HiOutlineInformationCircle />)}

          <p>{message}</p>

          {close && (
            <CloseButton onClick={handleClose}>
              <HiXMark />
            </CloseButton>
          )}
        </StyledToastContainer>,
        document.getElementById('toast')
      )}
    </ThemeProvider>
  );
};

export default StyledToast;
