import styled from 'styled-components';
import Navbar from '../../features/navigation/Navbar';
import Footer from '../../features/landing/footer/Footer';

const LandingLayout = ({ children }) => {
  return (
    <LayoutWrapper>
      <Navbar />
      <MainContent>{children}</MainContent>
      <Footer />
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default LandingLayout;
