import { useLocation } from 'react-router-dom';
import { useAuth } from '../../redux/features/auth-slice';
import { setEditorConfig, useBlogState } from '../../redux/features/blog-slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useRoleCheck from '../../hooks/useRoleCheck';

export const useEditorMode = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useAuth();
  const { blogDetails, blogSettings } = useBlogState();
  const { isAdmin, isModerator } = useRoleCheck();
  const isEditRoute = location.pathname.includes('/blog/editor');
  const isCreateRoute = location.pathname === '/blog/create/new';
  const isBlogOwner = user?.id === blogDetails?.user_id;
  const hasEditAccess = blogSettings?.allow_edit;

  useEffect(() => {
    const hasAccess =
      (isAdmin() || isModerator() || isBlogOwner) &&
      (isEditRoute || isCreateRoute) &&
      hasEditAccess;

    if (hasAccess) {
      dispatch(setEditorConfig({ mode: 'edit' }));
    } else {
      dispatch(setEditorConfig({ mode: 'view' }));
    }
  }, [hasEditAccess, isEditRoute, isCreateRoute]);

  const hasAccess =
    (isAdmin() || isModerator() || isBlogOwner) &&
    (isEditRoute || isCreateRoute) &&
    hasEditAccess;

  return { hasAccess };
};
