import styled from 'styled-components';
import {
  HiOutlineArrowRight,
  HiOutlineChartBar,
  HiOutlineGlobeAlt,
  HiOutlinePencilSquare,
} from 'react-icons/hi2';
import SvgCard from '../../ui/layout/SvgCard';
import Button from '../../ui/buttons/Button';
import ButtonGroup from '../../ui/buttons/ButtonGroup';
import { StyledCard } from './OnboardingDetails';
import Heading from '../../ui/typography/Heading';
import { FlexColumn } from '../../ui/layout/Flex';
import { TextFade } from '../../ui/typography/Text';
import { useAuth } from '../../redux/features/auth-slice';

export const Divider = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;
  margin: 0 auto;
  height: max-content;
  padding: var(--padding);
`;

const WelcomeHeading = styled(Heading)`
  text-align: center;
`;

const WelcomeDetails = ({ next, isOnboarding }) => {
  const handleNext = () => {
    next('welcome');
  };

  const { user } = useAuth();
  const isWelcomeCompleted =
    user?.user_metadata?.onboarding_steps?.welcome?.isCompleted;

  return (
    <StyledCard padding='0'>
      <FlexColumn
        style={{
          padding: 'var(--padding)',
        }}
      >
        <WelcomeHeading as='h3'>Welcome to BittsBlog</WelcomeHeading>
        <TextFade align='center'>— Think, Create, Share, Inspire —</TextFade>
      </FlexColumn>
      <Divider>
        <SvgCard
          title='Create Engaging Blogs'
          description='Craft appealing content with our intuitive blog creation tools, including customizable templates and a rich text editor.'
          svg={<HiOutlinePencilSquare />}
        />

        <SvgCard
          title='Discover Inspiring Content'
          description="Explore a diverse range of blogs and connect with creators through our 'Discover' feature, broadening your horizons."
          svg={<HiOutlineGlobeAlt />}
        />

        <SvgCard
          title='Track Engagement Analytics'
          description="Monitor your blog's performance with detailed analytics, helping you understand your audience and grow your readership."
          svg={<HiOutlineChartBar />}
        />

        <ButtonGroup>
          <Button
            disabled={isWelcomeCompleted}
            onClick={handleNext}
            type={isWelcomeCompleted ? 'success' : 'primary'}
            isLoading={isOnboarding}
          >
            Continue
            <HiOutlineArrowRight />
          </Button>
        </ButtonGroup>
      </Divider>
    </StyledCard>
  );
};

export default WelcomeDetails;
