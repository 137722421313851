import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { StyledContainer } from '../../styles/GeneralStyling';
import { useCallback, useState } from 'react';
import { Card } from '../../ui/layout/Card';
import CompleteProfile from './CompleteProfile';
import SetPreferences from './SetPreferences';
import WelcomeDetails from './WelcomeDetails';
import { useDispatch } from 'react-redux';
import { useNavigation } from '../../hooks/useNavigation';
import { updateUser, useAuth } from '../../redux/features/auth-slice';
import { motion } from 'framer-motion';
import { getCrudErrorMessage } from '../../utils/errorHandlers';
import toast from 'react-hot-toast';
import { updateCurrentUser } from '../../api/authentication/user';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

const OnboardingDetails = () => {
  const { navigateToPath } = useNavigation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [updateType, setUpdateType] = useState(null);
  const { user } = useAuth();
  const currentUserOnboardingSteps = user?.user_metadata?.onboarding_steps;
  const isOnboardingRoute = location.pathname === '/onboarding';

  const { mutate: updateUserMutation, isLoading: isOnboarding } = useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: ({ user }) => {
      dispatch(updateUser({ user }));
      if (user?.user_metadata?.onboarding_complete && isOnboardingRoute) {
        toast.success('Onboarding completed successfully');
        navigateToPath('/blog/discover', { replace: true });
      }
    },
    onError: (error) => {
      const errorMessage = getCrudErrorMessage('update', error, 'user');
      toast.error(errorMessage.description);
    },
  });

  const handleNextStep = (type, payload) => {
    setUpdateType(type);

    const updatedSteps = {
      ...currentUserOnboardingSteps,
      [type]: {
        ...currentUserOnboardingSteps[type],
        isCompleted: true,
      },
    };

    const updateData = {
      onboarding_steps: updatedSteps,
      ...payload,
    };

    updateUserMutation({ data: updateData });
  };

  const shouldShowStep = useCallback(
    (stepId) => {
      if (isOnboarding) return false;

      if (!currentUserOnboardingSteps) return stepId === 'welcome';

      const sortedSteps = Object.entries(currentUserOnboardingSteps).sort(
        (a, b) => a[1].order - b[1].order
      );

      const currentIncomplete = sortedSteps.find(
        ([_, step]) => !step.isCompleted
      );
      return currentIncomplete?.[0] === stepId;
    },
    [currentUserOnboardingSteps, isOnboarding]
  );

  const stepAnimation = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  };

  return (
    <OnboardingContainer>
      <ProgressBar>
        {!isOnboarding &&
          Object.entries(currentUserOnboardingSteps || {})
            .sort((a, b) => a[1].order - b[1].order)
            .map(([stepId, step]) => {
              const currentStep = shouldShowStep(stepId);
              return (
                <ProgressStep
                  key={stepId}
                  $isCompleted={step.isCompleted}
                  $isCurrent={currentStep}
                />
              );
            })}
      </ProgressBar>

      <OnboardingBody>
        <AnimatePresence mode='wait'>
          {shouldShowStep('welcome') && (
            <StepWrapper key='welcome' {...stepAnimation}>
              <WelcomeDetails
                next={handleNextStep}
                isOnboarding={isOnboarding}
                updateType={updateType === 'welcome'}
              />
            </StepWrapper>
          )}

          {shouldShowStep('bio') && (
            <StepWrapper key='bio' {...stepAnimation}>
              <CompleteProfile
                next={handleNextStep}
                isOnboarding={isOnboarding}
                updateType={updateType === 'bio'}
              />
            </StepWrapper>
          )}

          {shouldShowStep('preferences') && (
            <StepWrapper key='preferences' {...stepAnimation}>
              <SetPreferences
                next={handleNextStep}
                isOnboarding={isOnboarding}
                updateType={updateType === 'preferences'}
              />
            </StepWrapper>
          )}
        </AnimatePresence>
      </OnboardingBody>
    </OnboardingContainer>
  );
};

const OnboardingContainer = styled(StyledContainer)`
  height: 100%;
  padding: var(--padding);
  display: flex;
  justify-content: center;
`;

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background: var(--gray-2);
  padding: 2rem;
  z-index: 10;
  border-bottom: 1px solid var(--gray-4);
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

const ProgressStep = styled.div`
  flex: 1;
  height: 4px;
  background: ${(props) => {
    if (props.$isCompleted) return 'var(--green-9)';
    if (props.$isCurrent) return 'var(--orange-9)';
    return 'var(--gray-9)';
  }};
  border-radius: 2px;
  transition: background-color 0.3s ease;
`;

const OnboardingBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: var(--padding);
  max-width: 720px;
  width: 100%;
  align-self: center;
  padding-top: 8rem;
`;

export const StyledCard = styled(Card)``;

const StepWrapper = styled(motion.div)`
  width: 100%;
`;

export default OnboardingDetails;
