import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { media } from '../../styles/ResponsiveDesign';
import { FiMenu, FiX } from 'react-icons/fi';
import Button from '../../ui/buttons/Button';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../redux/features/auth-slice';
import useOutsideClick from '../../hooks/useOutsideClick';
import { HiOutlineGlobeAlt } from 'react-icons/hi2';

const Navbar = () => {
  const { session } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isLoginPage = location.pathname === '/auth/login';
  const isSignupPage = location.pathname === '/auth/signup';

  const navItems = [
    { label: 'About', href: '#about' },
    { label: 'Features', href: '#features' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > window.innerHeight * 0.1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function closeMobileMenu() {
    setIsMobileMenuOpen(false);
  }

  const ref = useOutsideClick(closeMobileMenu);

  return (
    <NavbarWrapper isScrolled={isScrolled}>
      <NavContainer ref={ref}>
        <LogoLink to='/'>
          <Logo>BittsBlog</Logo>
        </LogoLink>

        {isLandingPage && (
          <DesktopNav>
            <NavLinks>
              {navItems.map((item) => (
                <NavLink key={item.label} href={item.href}>
                  {item.label}
                </NavLink>
              ))}
            </NavLinks>

            <AuthButtons>
              {!isLoginPage && !session && (
                <Button as={Link} to='/auth/login' variant='secondary'>
                  Sign In
                </Button>
              )}
              {!isSignupPage && !session && (
                <Button as={Link} to='/auth/signup' variant='outline'>
                  Sign Up
                </Button>
              )}

              {session && (
                <Button as={Link} to='/blog/discover' variant='outline'>
                  Discover
                  <HiOutlineGlobeAlt />
                </Button>
              )}
            </AuthButtons>
          </DesktopNav>
        )}

        {isLandingPage && (
          <MobileMenuButton
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </MobileMenuButton>
        )}

        {isLandingPage && (
          <AnimatePresence>
            {isMobileMenuOpen && (
              <MobileNav
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {navItems.map((item) => (
                  <MobileNavLink
                    key={item.label}
                    href={item.href}
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.label}
                  </MobileNavLink>
                ))}
                {!session && (
                  <MobileAuthButtons>
                    {!isLoginPage && (
                      <Button as={Link} to='/auth/login' variant='secondary'>
                        Sign In
                      </Button>
                    )}
                    {!isSignupPage && (
                      <Button as={Link} to='/auth/signup' variant='primary'>
                        Sign Up
                      </Button>
                    )}
                  </MobileAuthButtons>
                )}
              </MobileNav>
            )}
          </AnimatePresence>
        )}
      </NavContainer>
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled.nav.withConfig({
  shouldForwardProp: (prop) => !['isScrolled', 'isLandingPage'].includes(prop),
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  height: 6rem;
  background: ${(props) =>
    props.isScrolled ? 'var(--gray-a2)' : 'transparent'};
  backdrop-filter: ${(props) =>
    props.isScrolled ? 'blur(20px) saturate(180%)' : 'none'};
`;

const NavContainer = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const Logo = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  background: linear-gradient(135deg, var(--color-11), var(--color-9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DesktopNav = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;

  ${media.tablet`
    display: none;
  `}
`;

const NavLinks = styled.div`
  display: flex;
  gap: 3rem;
`;

const NavLink = styled.a`
  color: var(--gray-12);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-9);
  }
`;

const AuthButtons = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: var(--gray-12);
  cursor: pointer;
  padding: 0.5rem;

  ${media.tablet`
    display: block;
  `}
`;

const MobileNav = styled(motion.div)`
  display: none;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--gray-1);
    top: 100%;
    left: 0;
    right: 0;
    padding: 2rem;
    border-bottom: 1px solid var(--gray-6);
    gap: 2rem;
  `}
`;

const MobileNavLink = styled.a`
  color: var(--gray-12);
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: var(--gray-3);
    border-radius: var(--border-radius-sm);
  }
`;

const MobileAuthButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-6);
`;

export default Navbar;
