import {
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useId,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { StyleSheetManager, css, keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import useOutsideClick from '../../hooks/useOutsideClick';
import Overlay, { overlayVariants } from './Overlay';
import { CloseButton } from '../buttons/CloseButton';
import { StyledFooter, StyledHeader } from '../../styles/GeneralStyling';
import { media } from '../../styles/ResponsiveDesign';

const StyledAsideHeader = styled(StyledHeader)`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const MotionAside = styled(motion.div)`
  position: fixed;
  top: 0.8rem;
  bottom: 0.8rem;
  right: 0.8rem;
  width: 72rem;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  background-color: var(--gray-1);
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 100;

  ${media.tablet`
    width: calc(98% - var(--padding-hor));
  `}

  ${media.mobile`
    width: calc(100% - var(--padding-hor));
  `}
`;

const PopupContext = createContext();

const Aside = ({ children }) => {
  const [openAside, setOpenAside] = useState('');

  const closeAside = () => {
    setOpenAside('');

    document.body.style.overflow = 'auto';
  };
  return (
    <PopupContext.Provider value={{ openAside, setOpenAside, closeAside }}>
      {children}
    </PopupContext.Provider>
  );
};

const Open = ({ children, asideName, onClick }) => {
  const { setOpenAside } = useContext(PopupContext);

  return cloneElement(children, {
    onClick: () => {
      onClick?.();
      setOpenAside(asideName);
      document.body.style.overflow = 'unset';
    },
  });
};

const Window = ({
  children,
  asideName,
  position = 'bottom',
  close = true,
  showButton = true,
  header,
  footer,
  size = 'medium',
}) => {
  const { openAside, closeAside } = useContext(PopupContext);
  const ref = useOutsideClick(closeAside);
  const isOpen = openAside === asideName;

  const isFunctionalOrClassComponent =
    isValidElement(children) && typeof children.type === 'function';

  return createPortal(
    <StyleSheetManager
      shouldForwardProp={(prop) =>
        prop !== 'position' ||
        prop !== 'size' ||
        prop !== 'unmount' ||
        prop !== 'closeAside'
      }
    >
      <AnimatePresence>
        {isOpen && (
          <>
            <Overlay
              variants={overlayVariants}
              initial='initial'
              animate='animate'
              exit='exit'
            />
            <MotionAside
              position={position}
              size={size}
              ref={close ? ref : null}
              initial={{
                x: '100%',
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              exit={{
                x: '100%',
                opacity: 0,
              }}
              transition={{
                type: 'tween',
                duration: 0.2,
                ease: [0.32, 0.72, 0, 1],
              }}
            >
              {header && (
                <StyledAsideHeader>
                  {header}
                  {(showButton || close) && (
                    <CloseButton onClick={closeAside} />
                  )}
                </StyledAsideHeader>
              )}

              {isFunctionalOrClassComponent
                ? cloneElement(children, { closeAside })
                : children}

              {footer && <StyledFooter>{footer}</StyledFooter>}
            </MotionAside>
          </>
        )}
      </AnimatePresence>
    </StyleSheetManager>,
    document.getElementById('modal')
  );
};

export const useAside = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('useAside must be used within a Popup provider');
  }
  return context;
};

export const ShowAside = ({
  asideName,
  button,
  component,
  onClick,
  close,
  showButton = true,
  header,
  footer,
  size,
}) => {
  const asideId = useId();

  return (
    <>
      {showButton && (
        <Aside.Open asideName={asideName || asideId} onClick={onClick}>
          {button}
        </Aside.Open>
      )}
      <Aside.Window
        asideName={asideName || asideId}
        header={header}
        footer={footer}
        size={size}
        close={close}
      >
        {component}
      </Aside.Window>
    </>
  );
};

Aside.Open = Open;
Aside.Window = Window;

export default Aside;
