import styled, { css } from 'styled-components';
import Spinner from '../loading/Spinner';

const getColorStyle = (color) => css`
  background-color: none;

  &:hover:not(:disabled) {
    background: var(--${color}-3);
  }

  &:active:not(:disabled) {
    background: var(--${color}-4);
  }

  & svg {
    color: var(--${color}-11);
  }
`;

const variant = {
  gray: css`
    background-color: var(--gray-3);
    font-size: 1.6rem;

    &:hover:not(:disabled) {
      background-color: var(--gray-4);
    }

    &:active:not(:disabled) {
      background-color: var(--gray-5);
    }

    & svg {
      color: var(--gray-12);
    }
  `,

  accent: css`
    background-color: var(--color-3);

    &:hover:not(:disabled) {
      background-color: var(--color-4);
    }

    &:active:not(:disabled) {
      background-color: var(--color-5);
    }

    & svg {
      color: var(--color-11);
    }
  `,

  danger: css`
    background-color: var(--red-3);

    &:hover:not(:disabled) {
      background-color: var(--red-4);
    }

    &:active:not(:disabled) {
      background-color: var(--red-5);
    }

    & svg {
      color: var(--red-11);
    }
  `,

  success: css`
    background-color: var(--green-3);

    &:hover:not(:disabled) {
      background-color: var(--green-4);
    }

    &:active:not(:disabled) {
      background-color: var(--green-5);
    }

    & svg {
      color: var(--green-11);
    }
  `,

  complementary: css`
    background-color: var(--comp-3);

    &:hover:not(:disabled) {
      background-color: var(--comp-4);
    }

    &:active:not(:disabled) {
      background-color: var(--comp-5);
    }

    & svg {
      color: var(--comp-11);
    }
  `,

  outline: css`
    outline: var(--border-sm-non);

    background-color: transparent;

    color: var(--gray-11);

    &:hover:not(:disabled) {
      outline: var(--border-sm-int);
      color: var(--gray-12);
    }
    &:active:not(:disabled) {
      outline: var(--border-sm-int-str);
      color: var(--gray-12);
    }
  `,

  transparent: css`
    background-color: transparent;

    &:hover:not(:disabled) {
      background-color: var(--gray-3);
    }

    &:active:not(:disabled) {
      background-color: var(--gray-4);
    }

    & svg {
      color: var(--gray-12);
    }
  `,
};

const StyledButtonIcon = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['active', 'size', 'variant', 'color'].includes(prop),
})`
  height: ${(props) =>
    props.size === 'small' ? '2.4rem' : props.size || '3.2rem'};
  width: ${(props) =>
    props.size === 'small' ? '2.4rem' : props.size || '3.2rem'};
  min-width: ${(props) =>
    props.size === 'small' ? '2.4rem' : props.size || '3.2rem'};
  flex: 0 0
    ${(props) => (props.size === 'small' ? '2.4rem' : props.size || '3.2rem')};
  background: none;
  border-radius: var(--border-radius-md);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 200ms ease;

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  ${(props) =>
    props.active &&
    css`
      background-color: var(--color-9);
    `}

  & svg {
    height: ${(props) => (props.size === 'small' ? '1.6rem' : '2rem')};
    width: ${(props) => (props.size === 'small' ? '1.6rem' : '2rem')};
    transition: all 200ms ease;
    margin: 0 !important;
  }

  ${(props) => {
    if (props.color) {
      return getColorStyle(props.color);
    }

    if (props.variant) {
      return variant[props.variant];
    }

    return variant.gray;
  }}

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    & svg {
      color: var(--gray-11);
    }
  }
`;

const ButtonIcon = ({ children, isLoading = false, ...props }) => {
  return (
    <StyledButtonIcon variant='transparent' {...props}>
      {isLoading ? <Spinner size='small' /> : children}
    </StyledButtonIcon>
  );
};

export default ButtonIcon;
