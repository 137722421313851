import { HiOutlinePencilSquare, HiOutlinePlus } from 'react-icons/hi2';
import {
  SectionHeader,
  SectionHeading,
  StyledBodyScroll,
  StyledContainer,
} from '../../styles/GeneralStyling';
import GradientCard from '../../ui/layout/GradientCard.jsx';
import styled from 'styled-components';
import { media } from '../../styles/ResponsiveDesign.js';
import { useDispatch } from 'react-redux';
import { resetBlogDetails } from '../../redux/features/blog-slice.js';
import { BackButton } from '../../ui/buttons/BackButton.jsx';
import { FlexRow } from '../../ui/layout/Flex.jsx';
import { usePageTitle } from '../../hooks/usePageTitle.jsx';
import { useNavigation } from '../../hooks/useNavigation.jsx';

const StyledCreateOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  width: 100%;

  ${media.tablet`
  grid-template-columns:  1fr;
  `}
`;

const CreateOptionsDetails = () => {
  const { navigateToPath } = useNavigation();
  const dispatch = useDispatch();

  usePageTitle('Create Blog Options');

  function handleNewBlog() {
    dispatch(resetBlogDetails());
    navigateToPath('/create/new');
  }

  function handleDraft() {
    navigateToPath('/my-blogs?status=draft');
  }

  const createOptions = [
    {
      hover: true,
      color: 'green',
      icon: <HiOutlinePlus />,
      title: 'Start Fresh',
      description:
        'Unleash your creativity. Share your latest idea with the world!',
      onClick: handleNewBlog,
      disabled: false,
    },
    {
      hover: true,
      color: 'yellow',
      icon: <HiOutlinePencilSquare />,
      title: 'Resume Your Thoughts',
      description:
        'Resume your draft work and bring your unfinished ideas to life!',
      onClick: handleDraft,
      disabled: false,
    },
  ];

  return (
    <StyledContainer size='medium'>
      <SectionHeader>
        <FlexRow justify='between'>
          <FlexRow align='center' gap={0.8}>
            <SectionHeading as='h3'>Create Options</SectionHeading>
          </FlexRow>
          <BackButton />
        </FlexRow>
      </SectionHeader>
      <StyledBodyScroll>
        <StyledCreateOptions>
          {createOptions.map((option, index) => (
            <GradientCard
              key={index}
              onClick={option.onClick}
              title={option.title}
              color={option.color}
              hover={option.hover}
              description={option.description}
              icon={option.icon}
              disabled={option.disabled}
              tip={option.tip}
            />
          ))}
        </StyledCreateOptions>
      </StyledBodyScroll>
    </StyledContainer>
  );
};

export default CreateOptionsDetails;
