import styled from 'styled-components';

const StyledSelect = styled.select`
  // Base styles
  width: 100%;
  height: ${({ size }) => (size === 'small' ? '3.3rem' : '4rem')};
  padding: 0 var(--padding-md);
  background-color: var(--gray-2);
  color: var(--gray-12);
  border: var(--border-md-non);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-size: ${({ size }) => (size === 'small' ? '1.2rem' : 'inherit')};

  // Custom arrow
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.8em;
  padding-right: 2.4rem;

  // Hover state
  &:hover:not(:disabled) {
    border-color: var(--gray-7);
    background-color: var(--gray-3);
  }

  &:active:not(:disabled) {
    border-color: var(--gray-8);
    background-color: var(--gray-4);
  }

  // Focus state
  &:focus:not(:disabled) {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-9);
  }

  // Disabled state
  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
    background-color: var(--gray-3);
  }

  // Option styles
  & option {
    background-color: var(--gray-1);
    color: var(--gray-12);
    padding: var(--padding-md);
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width || '100%'};
`;

const Select = ({ children, width, ...props }) => {
  return (
    <SelectWrapper width={width}>
      <StyledSelect {...props}>{children}</StyledSelect>
    </SelectWrapper>
  );
};

export default Select;
