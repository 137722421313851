import { createGlobalStyle } from 'styled-components';
import { media } from './ResponsiveDesign';

const GlobalStyles = createGlobalStyle`
:root{
  --black-a1: rgba(0, 0, 0, 0.05);
  --black-a2: rgba(0, 0, 0, 0.1);
  --black-a3: rgba(0, 0, 0, 0.15);
  --black-a4: rgba(0, 0, 0, 0.2);
  --black-a5: rgba(0, 0, 0, 0.3);
  --black-a6: rgba(0, 0, 0, 0.4);
  --black-a7: rgba(0, 0, 0, 0.5);
  --black-a8: rgba(0, 0, 0, 0.6);
  --black-a9: rgba(0, 0, 0, 0.7);
  --black-a10: rgba(0, 0, 0, 0.8);
  --black-a11: rgba(0, 0, 0, 0.9);
  --black-a12: rgba(0, 0, 0, 0.95);



  --white-a1: rgba(255, 255, 255, 0.05);
  --white-a2: rgba(255, 255, 255, 0.1);
  --white-a3: rgba(255, 255, 255, 0.15);
  --white-a4: rgba(255, 255, 255, 0.2);
  --white-a5: rgba(255, 255, 255, 0.3);
  --white-a6: rgba(255, 255, 255, 0.4);
  --white-a7: rgba(255, 255, 255, 0.5);
  --white-a8: rgba(255, 255, 255, 0.6);
  --white-a9: rgba(255, 255, 255, 0.7);
  --white-a10: rgba(255, 255, 255, 0.8);
  --white-a11: rgba(255, 255, 255, 0.9);
  --white-a12: rgba(255, 255, 255, 0.95);


&,&.light-mode{

  &.azure-theme{
 //Brand color
  --color-1: #fbfdff;
  --color-2: #f4faff;
  --color-3: #e6f4fe;
  --color-4: #d5efff;
  --color-5: #c2e5ff;
  --color-6: #acd8fc;
  --color-7: #8ec8f6;
  --color-8: #5eb1ef;
  --color-9: #0090ff;
  --color-10: #0588f0;
  --color-11: #0d74ce;
  --color-12: #113264;

  --color-a1: #0080ff04;
  --color-a2: #008cff0b;
  --color-a3: #008ff519;
  --color-a4: #009eff2a;
  --color-a5: #0093ff3d;
  --color-a6: #0088f653;
  --color-a7: #0083eb71;
  --color-a8: #0084e6a1;
  --color-a9: #0090ff;
  --color-a10: #0086f0fa;
  --color-a11: #006dcbf2;
  --color-a12: #002359ee;
  }

  &.emerald-theme{
  --color-1: #fbfefc;
  --color-2: #f4fbf6;
  --color-3: #e6f6eb;
  --color-4: #d6f1df;
  --color-5: #c4e8d1;
  --color-6: #adddc0;
  --color-7: #8eceaa;
  --color-8: #5bb98b;
  --color-9: #30a46c;
  --color-10: #2b9a66;
  --color-11: #218358;
  --color-12: #193b2d;


  --color-a1: #00c04004;
  --color-a2: #00a32f0b;
  --color-a3: #00a43319;
  --color-a4: #00a83829;
  --color-a5: #019c393b;
  --color-a6: #00963c52;
  --color-a7: #00914071;
  --color-a8: #00924ba4;
  --color-a9: #008f4acf;
  --color-a10: #008647d4;
  --color-a11: #00713fde;
  --color-a12: #002616e6;

  --comp-1: #fffcfe;
  --comp-2: #fef7fb;
  --comp-3: #fee9f5;
  --comp-4: #fbdcef;
  --comp-5: #f6cee7;
  --comp-6: #efbfdd;
  --comp-7: #e7acd0;
  --comp-8: #dd93c2;
  --comp-9: #d6409f;
  --comp-10: #cf3897;
  --comp-11: #c2298a;
  --comp-12: #651249;

 
  --comp-a1: #ff00aa03;
  --comp-a2: #e0008008;
  --comp-a3: #f4008c16;
  --comp-a4: #e2008b23;
  --comp-a5: #d1008331;
  --comp-a6: #c0007840;
  --comp-a7: #b6006f53;
  --comp-a8: #af006f6c;
  --comp-a9: #c8007fbf;
  --comp-a10: #c2007ac7;
  --comp-a11: #b60074d6;
  --comp-a12: #59003bed;
  }

  &.lavender-theme{
  --color-1: #fefcfe;
  --color-2: #fbf7fe;
  --color-3: #f7edfe;
  --color-4: #f2e2fc;
  --color-5: #ead5f9;
  --color-6: #e0c4f4;
  --color-7: #d1afec;
  --color-8: #be93e4;
  --color-9: #8e4ec6;
  --color-10: #8347b9;
  --color-11: #8145b5;
  --color-12: #402060;


  --color-a1: #aa00aa03;
  --color-a2: #8000e008;
  --color-a3: #8e00f112;
  --color-a4: #8d00e51d;
  --color-a5: #8000db2a;
  --color-a6: #7a01d03b;
  --color-a7: #6d00c350;
  --color-a8: #6600c06c;
  --color-a9: #5c00adb1;
  --color-a10: #53009eb8;
  --color-a11: #52009aba;
  --color-a12: #250049df;

  --comp-1: #fdfdf9;
  --comp-2: #fefce9;
  --comp-3: #fffab8;
  --comp-4: #fff394;
  --comp-5: #ffe770;
  --comp-6: #f3d768;
  --comp-7: #e4c767;
  --comp-8: #d5ae39;
  --comp-9: #ffe629;
  --comp-10: #ffdc00;
  --comp-11: #9e6c00;
  --comp-12: #473b1f;


  --comp-a1: #aaaa0006;
  --comp-a2: #f4dd0016;
  --comp-a3: #ffee0047;
  --comp-a4: #ffe3016b;
  --comp-a5: #ffd5008f;
  --comp-a6: #ebbc0097;
  --comp-a7: #d2a10098;
  --comp-a8: #c99700c6;
  --comp-a9: #ffe100d6;
  --comp-a10: #ffdc00;
  --comp-a11: #9e6c00;
  --comp-a12: #2e2000e0;
  }

  &.blush-theme{
  --color-1: #fffcfe;
  --color-2: #fef7fb;
  --color-3: #fee9f5;
  --color-4: #fbdcef;
  --color-5: #f6cee7;
  --color-6: #efbfdd;
  --color-7: #e7acd0;
  --color-8: #dd93c2;
  --color-9: #d6409f;
  --color-10: #cf3897;
  --color-11: #c2298a;
  --color-12: #651249;

 
  --color-a1: #ff00aa03;
  --color-a2: #e0008008;
  --color-a3: #f4008c16;
  --color-a4: #e2008b23;
  --color-a5: #d1008331;
  --color-a6: #c0007840;
  --color-a7: #b6006f53;
  --color-a8: #af006f6c;
  --color-a9: #c8007fbf;
  --color-a10: #c2007ac7;
  --color-a11: #b60074d6;
  --color-a12: #59003bed;

  --comp-1: #fbfefc;
  --comp-2: #f4fbf6;
  --comp-3: #e6f6eb;
  --comp-4: #d6f1df;
  --comp-5: #c4e8d1;
  --comp-6: #adddc0;
  --comp-7: #8eceaa;
  --comp-8: #5bb98b;
  --comp-9: #30a46c;
  --comp-10: #2b9a66;
  --comp-11: #218358;
  --comp-12: #193b2d;


  --comp-a1: #00c04004;
  --comp-a2: #00a32f0b;
  --comp-a3: #00a43319;
  --comp-a4: #00a83829;
  --comp-a5: #019c393b;
  --comp-a6: #00963c52;
  --comp-a7: #00914071;
  --comp-a8: #00924ba4;
  --comp-a9: #008f4acf;
  --comp-a10: #008647d4;
  --comp-a11: #00713fde;
  --comp-a12: #002616e6;
  }

  --comp-1: #fefcfb;
  --comp-2: #fff7ed;
  --comp-3: #ffefd6;
  --comp-4: #ffdfb5;
  --comp-5: #ffd19a;
  --comp-6: #ffc182;
  --comp-7: #f5ae73;
  --comp-8: #ec9455;
  --comp-9: #f76b15;
  --comp-10: #ef5f00;
  --comp-11: #cc4e00;
  --comp-12: #582d1d;


  --comp-a1: #c0400004;
  --comp-a2: #ff8e0012;
  --comp-a3: #ff9c0029;
  --comp-a4: #ff91014a;
  --comp-a5: #ff8b0065;
  --comp-a6: #ff81007d;
  --comp-a7: #ed6c008c;
  --comp-a8: #e35f00aa;
  --comp-a9: #f65e00ea;
  --comp-a10: #ef5f00;
  --comp-a11: #cc4e00;
  --comp-a12: #431200e2;


  --plum-1: #fefcff;
  --plum-2: #fdf7fd;
  --plum-3: #fbebfb;
  --plum-4: #f7def8;
  --plum-5: #f2d1f3;
  --plum-6: #e9c2ec;
  --plum-7: #deade3;
  --plum-8: #cf91d8;
  --plum-9: #ab4aba;
  --plum-10: #a144af;
  --plum-11: #953ea3;
  --plum-12: #53195d;


  --plum-a1: #aa00ff03;
  --plum-a2: #c000c008;
  --plum-a3: #cc00cc14;
  --plum-a4: #c200c921;
  --plum-a5: #b700bd2e;
  --plum-a6: #a400b03d;
  --plum-a7: #9900a852;
  --plum-a8: #9000a56e;
  --plum-a9: #89009eb5;
  --plum-a10: #7f0092bb;
  --plum-a11: #730086c1;
  --plum-a12: #40004be6;


  --lime-1: #fcfdfa;
  --lime-2: #f8faf3;
  --lime-3: #eef6d6;
  --lime-4: #e2f0bd;
  --lime-5: #d3e7a6;
  --lime-6: #c2da91;
  --lime-7: #abc978;
  --lime-8: #8db654;
  --lime-9: #bdee63;
  --lime-10: #b0e64c;
  --lime-11: #5c7c2f;
  --lime-12: #37401c;


  --lime-a1: #66990005;
  --lime-a2: #6b95000c;
  --lime-a3: #96c80029;
  --lime-a4: #8fc60042;
  --lime-a5: #81bb0059;
  --lime-a6: #72aa006e;
  --lime-a7: #61990087;
  --lime-a8: #559200ab;
  --lime-a9: #93e4009c;
  --lime-a10: #8fdc00b3;
  --lime-a11: #375f00d0;
  --lime-a12: #1e2900e3;

  --gray-1: #fcfcfd;
  --gray-2: #f9f9fb;
  --gray-3: #f0f0f3;
  --gray-4: #e8e8ec;
  --gray-5: #e0e1e6;
  --gray-6: #d9d9e0;
  --gray-7: #cdced6;
  --gray-8: #b9bbc6;
  --gray-9: #8b8d98;
  --gray-10: #80838d;
  --gray-11: #60646c;
  --gray-12: #1c2024;


  --gray-a1: #00005503;
  --gray-a2: #00005506;
  --gray-a3: #0000330f;
  --gray-a4: #00002d17;
  --gray-a5: #0009321f;
  --gray-a6: #00002f26;
  --gray-a7: #00062e32;
  --gray-a8: #00083046;
  --gray-a9: #00051d74;
  --gray-a10: #00071b7f;
  --gray-a11: #0007149f;
  --gray-a12: #000509e3;



  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;

  --blue-a1: #0080ff04;
  --blue-a2: #008cff0b;
  --blue-a3: #008ff519;
  --blue-a4: #009eff2a;
  --blue-a5: #0093ff3d;
  --blue-a6: #0088f653;
  --blue-a7: #0083eb71;
  --blue-a8: #0084e6a1;
  --blue-a9: #0090ff;
  --blue-a10: #0086f0fa;
  --blue-a11: #006dcbf2;
  --blue-a12: #002359ee;


  --lime-1: #11130c;
  --lime-2: #151a10;
  --lime-3: #1f2917;
  --lime-4: #29371d;
  --lime-5: #334423;
  --lime-6: #3d522a;
  --lime-7: #496231;
  --lime-8: #577538;
  --lime-9: #bdee63;
  --lime-10: #d4ff70;
  --lime-11: #bde56c;
  --lime-12: #e3f7ba;


  --lime-a1: #11bb0003;
  --lime-a2: #78f7000a;
  --lime-a3: #9bfd4c1a;
  --lime-a4: #a7fe5c29;
  --lime-a5: #affe6537;
  --lime-a6: #b2fe6d46;
  --lime-a7: #b6ff6f57;
  --lime-a8: #b6fd6d6c;
  --lime-a9: #caff69ed;
  --lime-a10: #d4ff70;
  --lime-a11: #d1fe77e4;
  --lime-a12: #e9febff7;


  --purple-1: #fefcfe;
  --purple-2: #fbf7fe;
  --purple-3: #f7edfe;
  --purple-4: #f2e2fc;
  --purple-5: #ead5f9;
  --purple-6: #e0c4f4;
  --purple-7: #d1afec;
  --purple-8: #be93e4;
  --purple-9: #8e4ec6;
  --purple-10: #8347b9;
  --purple-11: #8145b5;
  --purple-12: #402060;


  --purple-a1: #aa00aa03;
  --purple-a2: #8000e008;
  --purple-a3: #8e00f112;
  --purple-a4: #8d00e51d;
  --purple-a5: #8000db2a;
  --purple-a6: #7a01d03b;
  --purple-a7: #6d00c350;
  --purple-a8: #6600c06c;
  --purple-a9: #5c00adb1;
  --purple-a10: #53009eb8;
  --purple-a11: #52009aba;
  --purple-a12: #250049df;


  --gold-1: #fdfdfc;
  --gold-2: #faf9f2;
  --gold-3: #f2f0e7;
  --gold-4: #eae6db;
  --gold-5: #e1dccf;
  --gold-6: #d8d0bf;
  --gold-7: #cbc0aa;
  --gold-8: #b9a88d;
  --gold-9: #978365;
  --gold-10: #8c7a5e;
  --gold-11: #71624b;
  --gold-12: #3b352b;


  --gold-a1: #55550003;
  --gold-a2: #9d8a000d;
  --gold-a3: #75600018;
  --gold-a4: #6b4e0024;
  --gold-a5: #60460030;
  --gold-a6: #64440040;
  --gold-a7: #63420055;
  --gold-a8: #633d0072;
  --gold-a9: #5332009a;
  --gold-a10: #492d00a1;
  --gold-a11: #362100b4;
  --gold-a12: #130c00d4;


  --brown-1: #fefdfc;
  --brown-2: #fcf9f6;
  --brown-3: #f6eee7;
  --brown-4: #f0e4d9;
  --brown-5: #ebdaca;
  --brown-6: #e4cdb7;
  --brown-7: #dcbc9f;
  --brown-8: #cea37e;
  --brown-9: #ad7f58;
  --brown-10: #a07553;
  --brown-11: #815e46;
  --brown-12: #3e332e;


  --brown-a1: #aa550003;
  --brown-a2: #aa550009;
  --brown-a3: #a04b0018;
  --brown-a4: #9b4a0026;
  --brown-a5: #9f4d0035;
  --brown-a6: #a04e0048;
  --brown-a7: #a34e0060;
  --brown-a8: #9f4a0081;
  --brown-a9: #823c00a7;
  --brown-a10: #723300ac;
  --brown-a11: #522100b9;
  --brown-a12: #140600d1;


  --auto-1: #fcfcfc;
  --auto-2: #f9f9f9;
  --auto-3: #f0f0f0;
  --auto-4: #e8e8e8;
  --auto-5: #e0e0e0;
  --auto-6: #d9d9d9;
  --auto-7: #cecece;
  --auto-8: #bbbbbb;
  --auto-9: #8d8d8d;
  --auto-10: #838383;
  --auto-11: #646464;
  --auto-12: #202020;


  --auto-a1: #00000003;
  --auto-a2: #00000006;
  --auto-a3: #0000000f;
  --auto-a4: #00000017;
  --auto-a5: #0000001f;
  --auto-a6: #00000026;
  --auto-a7: #00000031;
  --auto-a8: #00000044;
  --auto-a9: #00000072;
  --auto-a10: #0000007c;
  --auto-a11: #0000009b;
  --auto-a12: #000000df;


  --orange-1: #fefcfb;
  --orange-2: #fff7ed;
  --orange-3: #ffefd6;
  --orange-4: #ffdfb5;
  --orange-5: #ffd19a;
  --orange-6: #ffc182;
  --orange-7: #f5ae73;
  --orange-8: #ec9455;
  --orange-9: #f76b15;
  --orange-10: #ef5f00;
  --orange-11: #cc4e00;
  --orange-12: #582d1d;


  --orange-a1: #c0400004;
  --orange-a2: #ff8e0012;
  --orange-a3: #ff9c0029;
  --orange-a4: #ff91014a;
  --orange-a5: #ff8b0065;
  --orange-a6: #ff81007d;
  --orange-a7: #ed6c008c;
  --orange-a8: #e35f00aa;
  --orange-a9: #f65e00ea;
  --orange-a10: #ef5f00;
  --orange-a11: #cc4e00;
  --orange-a12: #431200e2;


  --yellow-1: #fdfdf9;
  --yellow-2: #fefce9;
  --yellow-3: #fffab8;
  --yellow-4: #fff394;
  --yellow-5: #ffe770;
  --yellow-6: #f3d768;
  --yellow-7: #e4c767;
  --yellow-8: #d5ae39;
  --yellow-9: #ffe629;
  --yellow-10: #ffdc00;
  --yellow-11: #9e6c00;
  --yellow-12: #473b1f;


  --yellow-a1: #aaaa0006;
  --yellow-a2: #f4dd0016;
  --yellow-a3: #ffee0047;
  --yellow-a4: #ffe3016b;
  --yellow-a5: #ffd5008f;
  --yellow-a6: #ebbc0097;
  --yellow-a7: #d2a10098;
  --yellow-a8: #c99700c6;
  --yellow-a9: #ffe100d6;
  --yellow-a10: #ffdc00;
  --yellow-a11: #9e6c00;
  --yellow-a12: #2e2000e0;


  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;



  --amber-a1: #c0800004;
  --amber-a2: #f4d10016;
  --amber-a3: #ffde003d;
  --amber-a4: #ffd40063;
  --amber-a5: #f8cf0088;
  --amber-a6: #eab5008c;
  --amber-a7: #dc9b009d;
  --amber-a8: #da8a00c9;
  --amber-a9: #ffb300c2;
  --amber-a10: #ffb300e7;
  --amber-a11: #ab6400;
  --amber-a12: #341500dd;



  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;

 
  --red-a1: #ff000003;
  --red-a2: #ff000008;
  --red-a3: #f3000d14;
  --red-a4: #ff000824;
  --red-a5: #ff000632;
  --red-a6: #f8000442;
  --red-a7: #df000356;
  --red-a8: #d2000571;
  --red-a9: #db0007b7;
  --red-a10: #d10005c1;
  --red-a11: #c40006d3;
  --red-a12: #55000de8;


  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;


  --green-a1: #00c04004;
  --green-a2: #00a32f0b;
  --green-a3: #00a43319;
  --green-a4: #00a83829;
  --green-a5: #019c393b;
  --green-a6: #00963c52;
  --green-a7: #00914071;
  --green-a8: #00924ba4;
  --green-a9: #008f4acf;
  --green-a10: #008647d4;
  --green-a11: #00713fde;
  --green-a12: #002616e6;


  --teal-1: #fafefd;
  --teal-2: #f3fbf9;
  --teal-3: #e0f8f3;
  --teal-4: #ccf3ea;
  --teal-5: #b8eae0;
  --teal-6: #a1ded2;
  --teal-7: #83cdc1;
  --teal-8: #53b9ab;
  --teal-9: #12a594;
  --teal-10: #0d9b8a;
  --teal-11: #008573;
  --teal-12: #0d3d38;


  --teal-a1: #00cc9905;
  --teal-a2: #00aa800c;
  --teal-a3: #00c69d1f;
  --teal-a4: #00c39633;
  --teal-a5: #00b49047;
  --teal-a6: #00a6855e;
  --teal-a7: #0099807c;
  --teal-a8: #009783ac;
  --teal-a9: #009e8ced;
  --teal-a10: #009684f2;
  --teal-a11: #008573;
  --teal-a12: #00332df2;

  
  --pink-1: #fffcfe;
  --pink-2: #fef7fb;
  --pink-3: #fee9f5;
  --pink-4: #fbdcef;
  --pink-5: #f6cee7;
  --pink-6: #efbfdd;
  --pink-7: #e7acd0;
  --pink-8: #dd93c2;
  --pink-9: #d6409f;
  --pink-10: #cf3897;
  --pink-11: #c2298a;
  --pink-12: #651249;

 
  --pink-a1: #ff00aa03;
  --pink-a2: #e0008008;
  --pink-a3: #f4008c16;
  --pink-a4: #e2008b23;
  --pink-a5: #d1008331;
  --pink-a6: #c0007840;
  --pink-a7: #b6006f53;
  --pink-a8: #af006f6c;
  --pink-a9: #c8007fbf;
  --pink-a10: #c2007ac7;
  --pink-a11: #b60074d6;
  --pink-a12: #59003bed;

  --shadow-in:

    inset 0 1.5px 2px 0 var(--gray-a2),
    inset 0 1.5px 2px 0 var(--black-a2);

    /* Shadows for variant="classic" panels, like Card */
  --shadow-xsm:

    0 1px 1px 0 var(--gray-a2),
    0 2px 1px -1px var(--black-a1),
    0 1px 3px 0 var(--black-a1);

  --shadow-sm:

    0 2px 3px -2px var(--gray-a3),
    0 3px 12px -4px var(--black-a2),
    0 4px 16px -8px var(--black-a2);

    /* Shadows for smaller overlay panels, like Hover Card and Popover */
  --shadow-md:

    0 8px 40px var(--black-a1),
    0 12px 32px -16px var(--gray-a3);

  --shadow-lg:

    0 12px 60px var(--black-a3),
    0 12px 32px -16px var(--gray-a5);

    /* Shadows for larger overlay panels, like Dialog */
  --shadow-xlg:

    0 12px 60px var(--black-a3),
    0 16px 64px var(--gray-a2),
    0 16px 36px -20px var(--gray-a7);

}




&.dark-mode{

  &.azure-theme{
    //Brand color
  --color-1: #0d1520;
  --color-2: #111927;
  --color-3: #0d2847;
  --color-4: #003362;
  --color-5: #004074;
  --color-6: #104d87;
  --color-7: #205d9e;
  --color-8: #2870bd;
  --color-9: #0090ff;
  --color-10: #3b9eff;
  --color-11: #70b8ff;
  --color-12: #c2e6ff;


  --color-a1: #004df211;
  --color-a2: #1166fb18;
  --color-a3: #0077ff3a;
  --color-a4: #0075ff57;
  --color-a5: #0081fd6b;
  --color-a6: #0f89fd7f;
  --color-a7: #2a91fe98;
  --color-a8: #3094feb9;
  --color-a9: #0090ff;
  --color-a10: #3b9eff;
  --color-a11: #70b8ff;
  --color-a12: #c2e6ff;
  }

  &.emerald-theme{
  --color-1: #0e1512;
  --color-2: #121b17;
  --color-3: #132d21;
  --color-4: #113b29;
  --color-5: #174933;
  --color-6: #20573e;
  --color-7: #28684a;
  --color-8: #2f7c57;
  --color-9: #30a46c;
  --color-10: #33b074;
  --color-11: #3dd68c;
  --color-12: #b1f1cb;


  --color-a1: #00de4505;
  --color-a2: #29f99d0b;
  --color-a3: #22ff991e;
  --color-a4: #11ff992d;
  --color-a5: #2bffa23c;
  --color-a6: #44ffaa4b;
  --color-a7: #50fdac5e;
  --color-a8: #54ffad73;
  --color-a9: #44ffa49e;
  --color-a10: #43fea4ab;
  --color-a11: #46fea5d4;
  --color-a12: #bbffd7f0;

  --comp-1: #191117;
  --comp-2: #21121d;
  --comp-3: #37172f;
  --comp-4: #4b143d;
  --comp-5: #591c47;
  --comp-6: #692955;
  --comp-7: #833869;
  --comp-8: #a84885;
  --comp-9: #d6409f;
  --comp-10: #de51a8;
  --comp-11: #ff8dcc;
  --comp-12: #fdd1ea;

  
  --comp-a1: #f412bc09;
  --comp-a2: #f420bb12;
  --comp-a3: #fe37cc29;
  --comp-a4: #fc1ec43f;
  --comp-a5: #fd35c24e;
  --comp-a6: #fd51c75f;
  --comp-a7: #fd62c87b;
  --comp-a8: #ff68c8a2;
  --comp-a9: #fe49bcd4;
  --comp-a10: #ff5cc0dc;
  --comp-a11: #ff8dcc;
  --comp-a12: #ffd3ecfd;
  }

  &.lavender-theme{
  --color-1: #18111b;
  --color-2: #1e1523;
  --color-3: #301c3b;
  --color-4: #3d224e;
  --color-5: #48295c;
  --color-6: #54346b;
  --color-7: #664282;
  --color-8: #8457aa;
  --color-9: #8e4ec6;
  --color-10: #9a5cd0;
  --color-11: #d19dff;
  --color-12: #ecd9fa;


  --color-a1: #b412f90b;
  --color-a2: #b744f714;
  --color-a3: #c150ff2d;
  --color-a4: #bb53fd42;
  --color-a5: #be5cfd51;
  --color-a6: #c16dfd61;
  --color-a7: #c378fd7a;
  --color-a8: #c47effa4;
  --color-a9: #b661ffc2;
  --color-a10: #bc6fffcd;
  --color-a11: #d19dff;
  --color-a12: #f1ddfffa;

  --comp-1: #14120b;
  --comp-2: #1b180f;
  --comp-3: #2d2305;
  --comp-4: #362b00;
  --comp-5: #433500;
  --comp-6: #524202;
  --comp-7: #665417;
  --comp-8: #836a21;
  --comp-9: #ffe629;
  --comp-10: #ffff57;
  --comp-11: #f5e147;
  --comp-12: #f6eeb4;


  --comp-a1: #d1510004;
  --comp-a2: #f9b4000b;
  --comp-a3: #ffaa001e;
  --comp-a4: #fdb70028;
  --comp-a5: #febb0036;
  --comp-a6: #fec40046;
  --comp-a7: #fdcb225c;
  --comp-a8: #fdca327b;
  --comp-a9: #ffe629;
  --comp-a10: #ffff57;
  --comp-a11: #fee949f5;
  --comp-a12: #fef6baf6;
  }


  &.blush-theme{
  --color-1: #191117;
  --color-2: #21121d;
  --color-3: #37172f;
  --color-4: #4b143d;
  --color-5: #591c47;
  --color-6: #692955;
  --color-7: #833869;
  --color-8: #a84885;
  --color-9: #d6409f;
  --color-10: #de51a8;
  --color-11: #ff8dcc;
  --color-12: #fdd1ea;

  
  --color-a1: #f412bc09;
  --color-a2: #f420bb12;
  --color-a3: #fe37cc29;
  --color-a4: #fc1ec43f;
  --color-a5: #fd35c24e;
  --color-a6: #fd51c75f;
  --color-a7: #fd62c87b;
  --color-a8: #ff68c8a2;
  --color-a9: #fe49bcd4;
  --color-a10: #ff5cc0dc;
  --color-a11: #ff8dcc;
  --color-a12: #ffd3ecfd;

  --comp-1: #0e1512;
  --comp-2: #121b17;
  --comp-3: #132d21;
  --comp-4: #113b29;
  --comp-5: #174933;
  --comp-6: #20573e;
  --comp-7: #28684a;
  --comp-8: #2f7c57;
  --comp-9: #30a46c;
  --comp-10: #33b074;
  --comp-11: #3dd68c;
  --comp-12: #b1f1cb;


  --comp-a1: #00de4505;
  --comp-a2: #29f99d0b;
  --comp-a3: #22ff991e;
  --comp-a4: #11ff992d;
  --comp-a5: #2bffa23c;
  --comp-a6: #44ffaa4b;
  --comp-a7: #50fdac5e;
  --comp-a8: #54ffad73;
  --comp-a9: #44ffa49e;
  --comp-a10: #43fea4ab;
  --comp-a11: #46fea5d4;
  --comp-a12: #bbffd7f0;
  }

  --comp-1: #17120e;
  --comp-2: #1e160f;
  --comp-3: #331e0b;
  --comp-4: #462100;
  --comp-5: #562800;
  --comp-6: #66350c;
  --comp-7: #7e451d;
  --comp-8: #a35829;
  --comp-9: #f76b15;
  --comp-10: #ff801f;
  --comp-11: #ffa057;
  --comp-12: #ffe0c2;


  --comp-a1: #ec360007;
  --comp-a2: #fe6d000e;
  --comp-a3: #fb6a0025;
  --comp-a4: #ff590039;
  --comp-a5: #ff61004a;
  --comp-a6: #fd75045c;
  --comp-a7: #ff832c75;
  --comp-a8: #fe84389d;
  --comp-a9: #fe6d15f7;
  --comp-a10: #ff801f;
  --comp-a11: #ffa057;
  --comp-a12: #ffe0c2;


  --plum-1: #181118;
  --plum-2: #201320;
  --plum-3: #351a35;
  --plum-4: #451d47;
  --plum-5: #512454;
  --plum-6: #5e3061;
  --plum-7: #734079;
  --plum-8: #92549c;
  --plum-9: #ab4aba;
  --plum-10: #b658c4;
  --plum-11: #e796f3;
  --plum-12: #f4d4f4;


  --plum-a1: #f112f108;
  --plum-a2: #f22ff211;
  --plum-a3: #fd4cfd27;
  --plum-a4: #f646ff3a;
  --plum-a5: #f455ff48;
  --plum-a6: #f66dff56;
  --plum-a7: #f07cfd70;
  --plum-a8: #ee84ff95;
  --plum-a9: #e961feb6;
  --plum-a10: #ed70ffc0;
  --plum-a11: #f19cfef3;
  --plum-a12: #feddfef4;


  --gray-1: #111113;
  --gray-2: #18191b;
  --gray-3: #212225;
  --gray-4: #272a2d;
  --gray-5: #2e3135;
  --gray-6: #363a3f;
  --gray-7: #43484e;
  --gray-8: #5a6169;
  --gray-9: #696e77;
  --gray-10: #777b84;
  --gray-11: #b0b4ba;
  --gray-12: #edeef0;


  --gray-a1: #00000000;
  --gray-a2: #d8f4f609;
  --gray-a3: #ddeaf814;
  --gray-a4: #d3edf81d;
  --gray-a5: #d9edfe25;
  --gray-a6: #d6ebfd30;
  --gray-a7: #d9edff40;
  --gray-a8: #d9edff5d;
  --gray-a9: #dfebfd6d;
  --gray-a10: #e5edfd7b;
  --gray-a11: #f1f7feb5;
  --gray-a12: #fcfdffef;


  --blue-1: #0d1520;
  --blue-2: #111927;
  --blue-3: #0d2847;
  --blue-4: #003362;
  --blue-5: #004074;
  --blue-6: #104d87;
  --blue-7: #205d9e;
  --blue-8: #2870bd;
  --blue-9: #0090ff;
  --blue-10: #3b9eff;
  --blue-11: #70b8ff;
  --blue-12: #c2e6ff;


  --blue-a1: #004df211;
  --blue-a2: #1166fb18;
  --blue-a3: #0077ff3a;
  --blue-a4: #0075ff57;
  --blue-a5: #0081fd6b;
  --blue-a6: #0f89fd7f;
  --blue-a7: #2a91fe98;
  --blue-a8: #3094feb9;
  --blue-a9: #0090ff;
  --blue-a10: #3b9eff;
  --blue-a11: #70b8ff;
  --blue-a12: #c2e6ff;

 
  --purple-1: #18111b;
  --purple-2: #1e1523;
  --purple-3: #301c3b;
  --purple-4: #3d224e;
  --purple-5: #48295c;
  --purple-6: #54346b;
  --purple-7: #664282;
  --purple-8: #8457aa;
  --purple-9: #8e4ec6;
  --purple-10: #9a5cd0;
  --purple-11: #d19dff;
  --purple-12: #ecd9fa;


  --purple-a1: #b412f90b;
  --purple-a2: #b744f714;
  --purple-a3: #c150ff2d;
  --purple-a4: #bb53fd42;
  --purple-a5: #be5cfd51;
  --purple-a6: #c16dfd61;
  --purple-a7: #c378fd7a;
  --purple-a8: #c47effa4;
  --purple-a9: #b661ffc2;
  --purple-a10: #bc6fffcd;
  --purple-a11: #d19dff;
  --purple-a12: #f1ddfffa;


  --gold-1: #121211;
  --gold-2: #1b1a17;
  --gold-3: #24231f;
  --gold-4: #2d2b26;
  --gold-5: #38352e;
  --gold-6: #444039;
  --gold-7: #544f46;
  --gold-8: #696256;
  --gold-9: #978365;
  --gold-10: #a39073;
  --gold-11: #cbb99f;
  --gold-12: #e8e2d9;


  --gold-a1: #91911102;
  --gold-a2: #f9e29d0b;
  --gold-a3: #f8ecbb15;
  --gold-a4: #ffeec41e;
  --gold-a5: #feecc22a;
  --gold-a6: #feebcb37;
  --gold-a7: #ffedcd48;
  --gold-a8: #fdeaca5f;
  --gold-a9: #ffdba690;
  --gold-a10: #fedfb09d;
  --gold-a11: #fee7c6c8;
  --gold-a12: #fef7ede7;


  --brown-1: #12110f;
  --brown-2: #1c1816;
  --brown-3: #28211d;
  --brown-4: #322922;
  --brown-5: #3e3128;
  --brown-6: #4d3c2f;
  --brown-7: #614a39;
  --brown-8: #7c5f46;
  --brown-9: #ad7f58;
  --brown-10: #b88c67;
  --brown-11: #dbb594;
  --brown-12: #f2e1ca;


  --brown-a1: #91110002;
  --brown-a2: #fba67c0c;
  --brown-a3: #fcb58c19;
  --brown-a4: #fbbb8a24;
  --brown-a5: #fcb88931;
  --brown-a6: #fdba8741;
  --brown-a7: #ffbb8856;
  --brown-a8: #ffbe8773;
  --brown-a9: #feb87da8;
  --brown-a10: #ffc18cb3;
  --brown-a11: #fed1aad9;
  --brown-a12: #feecd4f2;


  --auto-1: #111111;
  --auto-2: #191919;
  --auto-3: #222222;
  --auto-4: #2a2a2a;
  --auto-5: #313131;
  --auto-6: #3a3a3a;
  --auto-7: #484848;
  --auto-8: #606060;
  --auto-9: #6e6e6e;
  --auto-10: #7b7b7b;
  --auto-11: #b4b4b4;
  --auto-12: #eeeeee; 

 
  --auto-a1: #00000000;
  --auto-a2: #ffffff09;
  --auto-a3: #ffffff12;
  --auto-a4: #ffffff1b;
  --auto-a5: #ffffff22;
  --auto-a6: #ffffff2c;
  --auto-a7: #ffffff3b;
  --auto-a8: #ffffff55;
  --auto-a9: #ffffff64;
  --auto-a10: #ffffff72;
  --auto-a11: #ffffffaf;
  --auto-a12: #ffffffed; 

  --orange-1: #17120e;
  --orange-2: #1e160f;
  --orange-3: #331e0b;
  --orange-4: #462100;
  --orange-5: #562800;
  --orange-6: #66350c;
  --orange-7: #7e451d;
  --orange-8: #a35829;
  --orange-9: #f76b15;
  --orange-10: #ff801f;
  --orange-11: #ffa057;
  --orange-12: #ffe0c2;


  --orange-a1: #ec360007;
  --orange-a2: #fe6d000e;
  --orange-a3: #fb6a0025;
  --orange-a4: #ff590039;
  --orange-a5: #ff61004a;
  --orange-a6: #fd75045c;
  --orange-a7: #ff832c75;
  --orange-a8: #fe84389d;
  --orange-a9: #fe6d15f7;
  --orange-a10: #ff801f;
  --orange-a11: #ffa057;
  --orange-a12: #ffe0c2;


  --yellow-1: #14120b;
  --yellow-2: #1b180f;
  --yellow-3: #2d2305;
  --yellow-4: #362b00;
  --yellow-5: #433500;
  --yellow-6: #524202;
  --yellow-7: #665417;
  --yellow-8: #836a21;
  --yellow-9: #ffe629;
  --yellow-10: #ffff57;
  --yellow-11: #f5e147;
  --yellow-12: #f6eeb4;


  --yellow-a1: #d1510004;
  --yellow-a2: #f9b4000b;
  --yellow-a3: #ffaa001e;
  --yellow-a4: #fdb70028;
  --yellow-a5: #febb0036;
  --yellow-a6: #fec40046;
  --yellow-a7: #fdcb225c;
  --yellow-a8: #fdca327b;
  --yellow-a9: #ffe629;
  --yellow-a10: #ffff57;
  --yellow-a11: #fee949f5;
  --yellow-a12: #fef6baf6;


  --amber-1: #16120c;
  --amber-2: #1d180f;
  --amber-3: #302008;
  --amber-4: #3f2700;
  --amber-5: #4d3000;
  --amber-6: #5c3d05;
  --amber-7: #714f19;
  --amber-8: #8f6424;
  --amber-9: #ffc53d;
  --amber-10: #ffd60a;
  --amber-11: #ffca16;
  --amber-12: #ffe7b3;



  --amber-a1: #e63c0006;
  --amber-a2: #fd9b000d;
  --amber-a3: #fa820022;
  --amber-a4: #fc820032;
  --amber-a5: #fd8b0041;
  --amber-a6: #fd9b0051;
  --amber-a7: #ffab2567;
  --amber-a8: #ffae3587;
  --amber-a9: #ffc53d;
  --amber-a10: #ffd60a;
  --amber-a11: #ffca16;
  --amber-a12: #ffe7b3;

  
 
  --red-1: #191111;
  --red-2: #201314;
  --red-3: #3b1219;
  --red-4: #500f1c;
  --red-5: #611623;
  --red-6: #72232d;
  --red-7: #8c333a;
  --red-8: #b54548;
  --red-9: #e5484d;
  --red-10: #ec5d5e;
  --red-11: #ff9592;
  --red-12: #ffd1d9;


  --red-a1: #f4121209;
  --red-a2: #f22f3e11;
  --red-a3: #ff173f2d;
  --red-a4: #fe0a3b44;
  --red-a5: #ff204756;
  --red-a6: #ff3e5668;
  --red-a7: #ff536184;
  --red-a8: #ff5d61b0;
  --red-a9: #fe4e54e4;
  --red-a10: #ff6465eb;
  --red-a11: #ff9592;
  --red-a12: #ffd1d9;

 
  --green-1: #0e1512;
  --green-2: #121b17;
  --green-3: #132d21;
  --green-4: #113b29;
  --green-5: #174933;
  --green-6: #20573e;
  --green-7: #28684a;
  --green-8: #2f7c57;
  --green-9: #30a46c;
  --green-10: #33b074;
  --green-11: #3dd68c;
  --green-12: #b1f1cb;


  --green-a1: #00de4505;
  --green-a2: #29f99d0b;
  --green-a3: #22ff991e;
  --green-a4: #11ff992d;
  --green-a5: #2bffa23c;
  --green-a6: #44ffaa4b;
  --green-a7: #50fdac5e;
  --green-a8: #54ffad73;
  --green-a9: #44ffa49e;
  --green-a10: #43fea4ab;
  --green-a11: #46fea5d4;
  --green-a12: #bbffd7f0;



  --teal-1: #0d1514;
  --teal-2: #111c1b;
  --teal-3: #0d2d2a;
  --teal-4: #023b37;
  --teal-5: #084843;
  --teal-6: #145750;
  --teal-7: #1c6961;
  --teal-8: #207e73;
  --teal-9: #12a594;
  --teal-10: #0eb39e;
  --teal-11: #0bd8b6;
  --teal-12: #adf0dd;


  --teal-a1: #00deab05;
  --teal-a2: #12fbe60c;
  --teal-a3: #00ffe61e;
  --teal-a4: #00ffe92d;
  --teal-a5: #00ffea3b;
  --teal-a6: #1cffe84b;
  --teal-a7: #2efde85f;
  --teal-a8: #32ffe775;
  --teal-a9: #13ffe49f;
  --teal-a10: #0dffe0ae;
  --teal-a11: #0afed5d6;
  --teal-a12: #b8ffebef;


  --pink-1: #191117;
  --pink-2: #21121d;
  --pink-3: #37172f;
  --pink-4: #4b143d;
  --pink-5: #591c47;
  --pink-6: #692955;
  --pink-7: #833869;
  --pink-8: #a84885;
  --pink-9: #d6409f;
  --pink-10: #de51a8;
  --pink-11: #ff8dcc;
  --pink-12: #fdd1ea;

  
  --pink-a1: #f412bc09;
  --pink-a2: #f420bb12;
  --pink-a3: #fe37cc29;
  --pink-a4: #fc1ec43f;
  --pink-a5: #fd35c24e;
  --pink-a6: #fd51c75f;
  --pink-a7: #fd62c87b;
  --pink-a8: #ff68c8a2;
  --pink-a9: #fe49bcd4;
  --pink-a10: #ff5cc0dc;
  --pink-a11: #ff8dcc;
  --pink-a12: #ffd3ecfd;

  --shadow-in:

    inset 0 3px 4px 0 var(--black-a5),


  --shadow-xsm:

    0 1px 1px 0 var(--black-a6),
    0 2px 1px -1px var(--black-a6),
    0 1px 3px 0 var(--black-a5);

  --shadow-sm:

    0 2px 3px -2px var(--black-a3),
    0 3px 8px -2px var(--black-a6),
    0 4px 12px -4px var(--black-a7);

  --shadow-md:

    0 8px 40px var(--black-a3),
    0 12px 32px -16px var(--black-a5);

  --shadow-lg:
   
    0 12px 60px var(--black-a5),
    0 12px 32px -16px var(--black-a7);

  --shadow-xlg:

    0 12px 60px var(--black-a4),
    0 16px 64px var(--black-a6),
    0 16px 36px -20px var(--black-a11);

}



  --family-fira:"Fira Code", monospace;
  --family-merriweather:"Merriweather", serif;
  --family-manrope:"Manrope", sans-serif;
  --family-nunito:"Nunito Sans", sans-serif;
  --family-poppins:"Poppins", sans-serif;
  --family-open-sans:"Open Sans", sans-serif;


  //border/outline
    --border-sm-non:0.5px solid var(--gray-6);
    --border-md-non:1px solid var(--gray-6);
    --border-sm-int:0.5px solid var(--gray-7);
    --border-md-int:1px solid var(--gray-7);
    --border-sm-int-str:0.5px solid var(--gray-8);
    --border-md-int-str:1px solid var(--gray-8);


//General colors
    --label-white:#ffffff;
    --label-black:#000000;
    --label-dark-fade:rgba(235, 235, 245, 0.6);
    --label-light-fade:rgba(60, 60, 67, 0.6);

    //Gradient colors
    --card-gradient:linear-gradient(135deg, var(--gray-3), var(--gray-4));
    --success-gradient:linear-gradient(135deg, var(--green-3), var(--green-4));
    --error-gradient:linear-gradient(135deg, var(--red-3), var(--red-4));
    --warning-gradient:linear-gradient(135deg, var(--orange-3), var(--orange-4));
    --info-gradient:linear-gradient(135deg, var(--blue-3), var(--blue-4));

 

// Border radius

    --border-radius-btn:0.8rem;
    --border-radius-in:0.8rem;

    --border-radius-xs:4px;
    --border-radius-sm:6px;
    --border-radius-md:8px;
    --border-radius-lg:12px;
    --border-radius-xl:16px;
    --border-radius-cd:18px;
    --border-radius-rd:999rem;


//padding
    --padding: 1.6rem 2.4rem;

    --padding-hor:  2.4rem;
    --padding-ver:  1.6rem;

    --padding-sm: 0.8rem;
    --padding-md: 1.2rem;
    --padding-lg: 2.4rem;
  
    --gap-sm: 0.4rem;
    --gap-md: 0.8rem;
    --gap-lg: 1.2rem;
    
    ${media.mobile`
    --padding: 1.2rem 1.8rem;
    --padding-hor:  1.8rem;
    --padding-ver:  1.2rem;
    `}

//heights
    --header-height: 5.2rem;
}




*,*::before,*::after{
    box-sizing:border-box;
    padding:0;
    margin:0;
}

//Translates 1rem to 10px
html{
    font-size:62.5%;
    scroll-behavior:smooth;
}

body{
    line-height:1.5;
    overflow-x:hidden;
    overflow-y: scroll;
    font-family: 'Manrope', sans-serif; 
    background-color:var(--gray-1);
    letter-spacing: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    color: var(--gray-12);
    font-size: clamp(1.4rem, 1.6rem + 0.125vw, 1.6rem);
    font-weight:normal;
    transition: color 200ms ease;
}



h1,h2,h3,h4,h5,h6{
    margin:0;
    font-size:inherit;
    font-weight:inherit;
}

input, textarea, label,select, option, button {
    font:inherit;
}

/* input {
    &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 4.4rem var(--bg-pri) inset !important;
    box-shadow: 0 0 0 4.4rem var(--bg-pri) inset !important;
    -webkit-text-fill-color: var(--label-pri) !important;
  }

  &:-moz-autofill,
  &:-moz-autofill:hover,
  &:-moz-autofill:focus,
  &:-moz-autofill:active {
    -webkit-box-shadow: 0 0 0 4.4rem var(--bg-tet) inset !important;
    box-shadow: 0 0 0 4.4rem var(--bg-tet) inset !important;
    -webkit-text-fill-color: var(--label-pri) !important;
  }

  &:-internal-autofill-selected {
    background-color: var(--bg-tet) !important;
    color: var(--label-pri) !important;
  }
} */

li{

   margin:0;
   padding:0;
}

img,video,canvas,audio,iframe{
    display:block;
    max-width:100%;
    height:auto;
}

a{
    text-decoration:none;
}

button{
    cursor:pointer;
    transition:all 200ms ease;
}

svg{
    height:1.6rem;
    width:1.6rem;
    color:var(--gray-11);
    transition:color 200ms ease;
}


a{
    transition: all 200ms ease;
} 

.toast{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100dvh;
}

fieldset{
    border: none;
    padding: 0;
    margin: 0;
}


`;

export default GlobalStyles;
