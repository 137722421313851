import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import { usePermissionsState } from '../../redux/features/permissions-slice';
import { lazy, Suspense, useMemo } from 'react';
import { FullScreenLoading } from '../loading/Spinner';
import AppLayout from './AppLayout';
import Suspended from './Suspended';
import NotFound from './NotFound';
import Login from '../../pages/Login';
import Signup from '../../pages/Signup';
import Reset from '../../pages/Reset';
import Onboarding from '../../pages/Onboarding';
import Support from '../../pages/Support';
import Profile from '../../pages/Profile';
import CreateOptions from '../../pages/CreateOptions';
import Unauthorized from './Unauthorized';
import { EditorProvider } from '../../context/EditorContext';
import { filter } from 'lodash';
import { useProfileState } from '../../redux/features/profile-slice';
import CreateBlog from '../../features/blog/CreateBlog';
import About from '../../pages/About';
import LandingLayout from './LandingLayout';
import VerifySuccess from '../../pages/VerifySuccess';
import VerifyEmail from '../../pages/VerifyEmail';
import UpdatePassword from '../../pages/UpdatePassword';

// Lazy load all components
const AdminDashboard = lazy(() => import('../../pages/AdminDashboard'));
const Dashboard = lazy(() => import('../../pages/Dashboard'));
const Bookmarks = lazy(() => import('../../pages/Bookmarks'));
const Preferences = lazy(() => import('../../pages/Preferences'));
const Blog = lazy(() => import('../../pages/Blog'));
const MyBlogs = lazy(() => import('../../features/blog/MyBlogs'));
const BlogDetailedAnalytics = lazy(() =>
  import('../../features/analytics/BlogDetailedAnalytics')
);
const Analytics = lazy(() => import('../../pages/Analytics'));
const Media = lazy(() => import('../../pages/Media'));
const MediaCollection = lazy(() =>
  import('../../features/media/MediaCollection')
);
const BlogEditor = lazy(() => import('../../features/blog/BlogEditor'));
const BlogDescription = lazy(() =>
  import('../../features/blog/BlogDescription')
);
const BlogManager = lazy(() => import('../../pages/BlogManager'));
const BlogReviews = lazy(() => import('../../pages/BlogReviews'));
const ReviewWorkspace = lazy(() => import('../../pages/ReviewWorkspace'));
const CreatorProfile = lazy(() => import('../../pages/CreatorProfile'));
const Notifications = lazy(() => import('../../pages/Notifications'));
const ModeratorDashboard = lazy(() => import('../../pages/ModeratorDashboard'));
const Landing = lazy(() => import('../../pages/Landing'));

const AppRoutes = () => {
  const { routes, initialization } = usePermissionsState();
  const { profile } = useProfileState();
  const isSuspended = profile?.account_status === 'suspended';
  const { isLoading, isInitialized, error } = initialization; //initialization state

  // Cache the component mapping
  const memoizedComponentMapping = useMemo(
    () => ({
      landing: Landing,
      about: About,
      admin: AdminDashboard,
      moderator: ModeratorDashboard,
      my_reviews: BlogReviews,
      review_workspace: ReviewWorkspace,
      dashboard: Dashboard,
      bookmarks: Bookmarks,
      profile: Profile,
      preferences: Preferences,
      discover: Blog,
      create: CreateOptions,
      create_new: CreateBlog,
      my_blogs: MyBlogs,
      blog_analytics_details: BlogDetailedAnalytics,
      analytics: Analytics,
      media: Media,
      media_collection: MediaCollection,
      notifications: Notifications,
      blog_editor: BlogEditor,
      support: Support,
      onboarding: Onboarding,
      login: Login,
      signup: Signup,
      reset: Reset,
      description: BlogDescription,
      not_found: NotFound,
      suspended: Suspended,
      unauthorized: Unauthorized,
      blog_manager: BlogManager,
      creator_profile: CreatorProfile,
      verify_email: VerifyEmail,
      verify_success: VerifySuccess,
      update_password: UpdatePassword,
    }),
    []
  );

  const ROUTES_NEEDING_EDITOR = [
    'blog_editor',
    'description',
    'review_workspace',
  ];
  const ROUTES_NEEDING_LANDING_LAYOUT = ['landing', 'about'];
  const ROUTES_NEEDING_REDIRECT_IF_AUTHENTICATED = ['login', 'signup', 'reset'];

  const wrapWithEditorIfNeeded = (Component, value) => {
    if (ROUTES_NEEDING_EDITOR.includes(value)) {
      return (
        <EditorProvider>
          <Component />
        </EditorProvider>
      );
    }

    return <Component />;
  };

  const renderComponent = (value) => {
    const Component = memoizedComponentMapping[value];
    return Component
      ? wrapWithEditorIfNeeded(() => <Component />, value)
      : null;
  };

  const protectedRoutesWithLayout = filter(routes?.protected, {
    layout: true,
  });

  const protectedRoutesWithoutLayout = filter(routes?.protected, {
    layout: false,
  });

  const publicRoutesWithLayout = filter(routes?.public, {
    layout: true,
  });

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (isSuspended) {
    return (
      <Routes>
        <Route path='/suspended' element={renderComponent('suspended')} />
        <Route path='*' element={renderComponent('suspended')} />
      </Routes>
    );
  }

  if (isInitialized && !error) {
    return (
      <Suspense fallback={<FullScreenLoading />}>
        <Routes>
          {/* Public Routes */}
          {routes?.public?.map((route) => {
            if (ROUTES_NEEDING_LANDING_LAYOUT.includes(route?.value)) {
              return (
                <Route
                  key={route?.path}
                  path={route?.path}
                  element={
                    <LandingLayout>
                      {renderComponent(route?.value)}
                    </LandingLayout>
                  }
                />
              );
            }

            if (
              ROUTES_NEEDING_REDIRECT_IF_AUTHENTICATED.includes(route?.value)
            ) {
              return (
                <Route
                  key={route?.path}
                  path={route?.path}
                  element={
                    <RedirectIfAuthenticated>
                      {renderComponent(route?.value)}
                    </RedirectIfAuthenticated>
                  }
                />
              );
            }

            return (
              <Route
                key={route?.path}
                path={route?.path}
                element={renderComponent(route?.value)}
              />
            );
          })}

          {/* Protected Routes with Layout */}
          <Route element={<AppLayout />}>
            {protectedRoutesWithLayout.map((route) => {
              return (
                <Route
                  key={route?.path}
                  path={route?.path}
                  element={
                    <ProtectedRoutes>
                      {renderComponent(route?.value)}
                    </ProtectedRoutes>
                  }
                />
              );
            })}
          </Route>

          {/* Protected Routes without Layout */}
          {protectedRoutesWithoutLayout.map((route) => {
            return (
              <Route
                key={route?.path}
                path={route?.path}
                element={
                  <ProtectedRoutes permissions={route?.permissions}>
                    {renderComponent(route?.value)}
                  </ProtectedRoutes>
                }
              />
            );
          })}

          <Route path='*' element={renderComponent('not_found')} />
          <Route
            path='/unauthorized'
            element={renderComponent('unauthorized')}
          />
        </Routes>
      </Suspense>
    );
  }

  return null;
};

export default AppRoutes;
