import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Custom hook for navigation within the application.
 *
 * @returns {Object} An object containing navigation functions.
 * @property {Function} navigateBack - Function to navigate to the previous page.
 * @property {Function} navigateToPath - Function to navigate to a specific path.
 */

export const useNavigation = () => {
  const navigate = useNavigate();

  const navigateBack = useCallback(
    (options = {}) => {
      const { replace = false, state = {} } = options;
      navigate(-1, { replace, state });
    },
    [navigate]
  );

  const navigateToPath = useCallback(
    (path, options = {}) => {
      const { replace = false, state = {} } = options;
      if (path && path.trim() !== '') {
        navigate(path, { replace, state });
      } else {
        console.warn('No path provided to navigateToPath');
      }
    },
    [navigate]
  );

  return { navigateBack, navigateToPath };
};
