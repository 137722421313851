import { HiOutlineHome, HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import { useNavigation } from '../../hooks/useNavigation';
import { useAuth } from '../../redux/features/auth-slice';
import { StyledBody, StyledContainer } from '../../styles/GeneralStyling';
import StateDisplay from '../notifications/StateDisplay';
import { getRedirectPathOnAuth } from '../../auth/auth';

const NotFound = () => {
  const { navigateToPath } = useNavigation();
  const { user } = useAuth();
  function handleBackToHome() {
    const redirectPath = getRedirectPathOnAuth(user);
    navigateToPath(redirectPath, { replace: true });
  }

  return (
    <StyledContainer size='small'>
      <StyledBody>
        <StateDisplay
          icon={<HiOutlineMagnifyingGlass />}
          variant='error'
          title='404 Page Not Found'
          description='Sorry, the page you are looking for does not exist or has been moved.'
          action={{
            label: `Take me home`,
            onClick: handleBackToHome,
            icon: <HiOutlineHome />,
          }}
        />
      </StyledBody>
    </StyledContainer>
  );
};

export default NotFound;
